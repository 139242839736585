import React, { useState } from 'react';
import './style.scss';
import { usePagination, useTable } from 'react-table';
import CopyIcon from '../../../assets/icons/copy.svg'
import CalendarIcon from '../../../assets/icons/calender.svg';
import Search from '../../../assets/icons/search.svg';
import CaretLeftIcon from '../../../assets/icons/caret-left.svg';
import CaretRightIcon from '../../../assets/icons/caret-right.svg';
import Calendar from '../../../reusableComponents/Calendar';

function Commission() {
  return (
    <div className="commission_codes">
      <div className="commission_table">
        <div className='table_scroll_wrapper'>
        <Table /> </div>
      </div>
    </div>
  );
}

export default Commission;

const Table = () => {
  const [filter, setFilter] = useState('All');
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  
  const data = [
    { date: '2023-07-01', commission: '5%', amount: '$1000', url: '/mick-vitamixc-proftrading', status: 'accepted' },
    { date: '2023-07-02', commission: '3%', amount: '$750', url: '/john-doe-proftrading', status: 'pending' },
    { date: '2023-07-03', commission: '2%', amount: '$500', url: '/jane-smith-proftrading', status: 'rejected' },
    { date: '2023-07-04', commission: '4%', amount: '$900', url: '/jim-brown-proftrading', status: 'accepted' },
    { date: '2023-07-05', commission: '6%', amount: '$1200', url: '/alice-johnson-proftrading', status: 'pending' },
    { date: '2023-07-06', commission: '1%', amount: '$250', url: '/sam-wilson-proftrading', status: 'rejected' },
    { date: '2023-07-07', commission: '3%', amount: '$700', url: '/emily-clark-proftrading', status: 'accepted' },
    { date: '2023-07-08', commission: '2%', amount: '$450', url: '/michael-smith-proftrading', status: 'pending' },
    { date: '2023-07-09', commission: '4%', amount: '$850', url: '/susan-miller-proftrading', status: 'rejected' },
    { date: '2023-07-10', commission: '5%', amount: '$1100', url: '/peter-jones-proftrading', status: 'accepted' }
  ];
  
  

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredData = filter === 'All' ? data : data.filter((item) => item.status.toLowerCase() === filter.toLowerCase());

  const columns = React.useMemo(
    () => [
      { Header: 'Date', accessor: 'date' },
      { Header: 'Commission %', accessor: 'commission' },
      { Header: 'Amount', accessor: 'amount' },
      { Header: 'URL', accessor: 'url' , Cell: () => <button className='tracking_btn'>/mick-vitamixc-proftrading</button> },
      { Header: 'Status', accessor: 'status' , Cell: ({value}) => <button className={`${value}_tag`}>{value}</button> },
    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 7 },
    },
    usePagination
  );

  return (
    <div className='table_scroll'>
    <div className="table_wrapper">
      <div className="table_header">
        <div className="search">
          <div className="search_bar">
            <input type="text" className="search_input" placeholder="Search..." />
            <img className="search_icon" src={Search} alt="search icon" />
          </div>
          <div className="select_wrapper">
            <div
              className="calendar-button"
              onClick={() => setIsCalender((prev) => !prev)}
            >
              <img src={CalendarIcon} alt="calender icon" />
            </div>

            <div className="calendar_wrapper">
              {isCalender && (
                <Calendar
                  onChange={(date) => setDate(date)}
                  value={date}
                  // formatMonth={"MMM"}
                  selectRange={true}
                  showNeighboringMonth={false}
                />
              )}
            </div> </div>          </div>
        <div className="filter_buttons">
        <button
            className={filter === 'All' ? 'active_btn' : ''}
            onClick={() => handleFilter('All')}
          >
            All
          </button>
          <button
            className={filter === 'Accepted' ? 'active_btn' : ''}
            onClick={() => handleFilter('Accepted')}
          >
            Accepted
          </button>
          <button
            className={filter === 'Pending' ? 'active_btn' : ''}
            onClick={() => handleFilter('Pending')}
          >
            Pending
          </button>
          <button
            className={filter === 'Rejected' ? 'active_btn' : ''}
            onClick={() => handleFilter('Rejected')}
          >
            Rejected
          </button>
        </div>
      </div>
      <table {...getTableProps()} className="leaderboard-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table> </div>
      <div className="pagination">
        <img src={CaretLeftIcon} alt="" />
        <button onClick={previousPage} disabled={!canPreviousPage}>
          Previous
        </button>
        {pageOptions.map((pageNumber, index) => (
          <>
            <button
              key={index}
              onClick={() => gotoPage(index)}
              className={pageIndex === index ? "btn-active" : "non-active"}
            >
              {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
            </button>
          </>
        ))}
        <button onClick={nextPage} disabled={!canNextPage}>
          Next
        </button>
        <img src={CaretRightIcon} alt="" />
      </div>
    </div>
  );
};
