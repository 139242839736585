import React, { useEffect, useState } from "react";
import "./style.scss";
import CreateFunding from "../../components/FundingEvaluation/CreateFunding";
import AccountDetails from "../../components/FundingEvaluation/AccountDetails";
import Evaluation from "../../components/FundingEvaluation/Evaluation";
import AccountBalance from "../../components/FundingEvaluation/AccountBalance";
import EditPlan from "../EditPlan";
import CreatePlan from "../CreatePlan";
import CreatePlanModal from "./CreatePlanModal";
import ConfirmationModal from "./ConfirmationModal";
import ViewAndEditFundingEval from "./ViewAndEditFundingEval";
import { useDispatch, useSelector } from "react-redux";
import {
  getFundingDataFailure,
  getFundingDataSuccess,
} from "../../store/reducers/fundingSlice";
import { getFundingDetails } from "../../utils/api/apis";
import { setIsLoading } from "../../store/reducers/authSlice";
import CreateTradingAccount from "../../components/FundingEvaluation/CreateTradingAccount";
import { useLocation } from "react-router-dom";

function FundingEvaluation() {
  const [isPlanModal, setIsPlanModal] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const idToken = useSelector((state) => state.auth.idToken);
  const location = useLocation();
  const tab = location.state?.tab;
  const [selectedMode, setSelectedMode] = useState(0);
  useEffect(() => {
    setSelectedMode(tab || 0)
  }, [tab]);
  const dispatch = useDispatch();
  useEffect(() => {
   
  }, [selectedPlanId]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    dispatch(setIsLoading(true));
    const response = await getFundingDetails(idToken);

    if ((response.status = 200)) {
      dispatch(getFundingDataSuccess(response.data));
      dispatch(setIsLoading(false));
    } else {
      dispatch(getFundingDataFailure());
      dispatch(setIsLoading(false));
    }
  };
  return (
    <div className="funding_evaluation">
      {isPlanModal && <CreatePlanModal setIsPlanModal={setIsPlanModal} />}
      <div className="grid_container">
        {/* <div className="col1">
          <CreateFunding
            selectedMode={selectedMode}
            setSelectedMode={setSelectedMode}
          />
        </div> */}

        <div className="col2">
          {/* {selectedMode === 0 && (
            <>
              <ViewAndEditFundingEval />
            </>
          )}
          {selectedMode === 1 && (
            <>
              <AccountDetails />
              <Evaluation
                selectedPlanId={selectedPlanId}
                setSelectedPlanId={setSelectedPlanId}
                setIsPlanModal={setIsPlanModal}
                setConfirmationModal={setConfirmationModal}
              />
              <CreatePlan selectedPlanId={selectedPlanId} />
              <AccountBalance />
            </>
          )}
          {
            selectedMode === 2 && (
              <> */}
                <CreateTradingAccount />
              {/* </>
            )
          } */}
        </div>
      </div>
    </div>
  );
}

export default FundingEvaluation;
