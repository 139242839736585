import React from "react";
import "./style.scss";

import ChartRepGain from "../../assets/images/chart-rep-gain.png";
import ChartRepLoss from "../../assets/images/chart-rep-loss.png";

function DailyDataBanner({ className, value }) {
  function limitDecimalPlaces(number, maxDecimalPlaces) {
    const numString = number?.toString();
    const decimalIndex = numString?.indexOf(".") + 1; // Get the index of the decimal point

    if (
      decimalIndex !== 0 &&
      numString?.length - decimalIndex > maxDecimalPlaces
    ) {
      const truncatedString = numString?.slice(
        0,
        decimalIndex + maxDecimalPlaces
      );
      return parseFloat(truncatedString);
    } else {
      return number;
    }
  }
  return (
    <div className={`dailydatabanner-container ${className ? className : ""}`}>
      <div className="dailydatabanner-subcontainer dailydatabanner-container_chartrep">
        <div>
          <p
            className={
              value?.profit < 0
                ? "program_objective_font_active loss"
                : "profit"
            }
          >
            {value?.profit <= 0
              ? `${value?.profit !== undefined ? value?.profit : "Na"}`
              : `${value?.profit !== undefined ? value?.profit : "Na"}`}
          </p>
          <h5>Daily Gain</h5>
        </div>
        {/* <AreaPreviewChart
          isGain={+15.41}
          style={{ transform: "translateY(-17.5px)" }}
        /> */}
      </div>
      <div className="dailydatabanner-subcontainer dailydatabanner-container_chartrep">
        <div>
          <p
            className={
              value?.absolute_gain <= 0
                ? "program_objective_font_active loss"
                : "profit"
            }
          >
            {/* {value?.absolute_gain < 0 ? `${(Math.trunc(value?.absolute_gain * 100000) / 100000).toFixed(2)}%` : `${(Math.trunc(value?.absolute_gain * 100000) / 100000).toFixed(2)}%`} */}
            {value?.absolute_gain <= 0
              ? `${(Math.trunc(value?.absolute_gain * 100000) / 100000).toFixed(
                  3
                )}`
              : `${(Math.trunc(value?.absolute_gain * 100000) / 100000).toFixed(
                  3
                )}`}
          </p>
          <h5>Abs Gain</h5>
        </div>
        {/* <AreaPreviewChart
          isGain={-15.43}
          style={{ transform: "translateY(-17.5px)" }}
        /> */}
      </div>
      <div className="dailydatabanner-subcontainer">
        <p
          className={
            value?.daily_max_loss < 0
              ? "program_objective_font_active loss"
              : "profit"
          }
        >
          $
          {value?.daily_max_loss <= 0
            ? `${
                value?.daily_max_loss !== undefined
                  ? +value?.daily_max_loss
                  : "Na"
              }`
            : `${
                value?.daily_max_loss !== undefined
                  ? +value?.daily_max_loss
                  : "Na"
              }`}
        </p>
        <h5>Daily Drawdown</h5>
      </div>
      <div className="dailydatabanner-subcontainer">
        <p
          className={
            value?.max_loss_target < 0
              ? "program_objective_font_active loss"
              : "profit"
          }
        >
          $
          {value?.max_loss_target <= 0
            ? `${
                value?.max_loss_target !== undefined
                  ? +value?.max_loss_target
                  : "Na"
              }`
            : `${
                value?.max_loss_target !== undefined
                  ? +value?.max_loss_target
                  : "Na"
              }`}
        </p>
        <h5>Max Drawdown</h5>
      </div>
      <div className="dailydatabanner-subcontainer">
        <p
          className={
            value?.leverage < 0
              ? "program_objective_font_active loss"
              : "profit"
          }
        >
          1:
          {value?.leverage <= 0
            ? `${value?.leverage !== undefined ? +value?.leverage : "Na"}`
            : `${value?.leverage !== undefined ? +value?.leverage : "Na"}`}
        </p>
        <h5>Leverage</h5>
      </div>
      <div className="dailydatabanner-subcontainer">
        <p
          className={
            value?.win_rate <= 0
              ? "program_objective_font_active loss"
              : "profit"
          }
        >
          {value?.win_rate <= 0
            ? `${
                (Math.trunc(value?.win_rate * 100000) / 100000).toFixed(2) !==
                "NaN"
                  ? value?.win_rate.toFixed(2)
                  : "Na"
              }`
            : `${
                (Math.trunc(value?.win_rate * 100000) / 100000).toFixed(2) !==
                "NaN"
                  ? value?.win_rate.toFixed(2)
                  : "Na"
              }`}
          %
        </p>
        <h5>Win Rate</h5>
      </div>
    </div>
  );
}

export default DailyDataBanner;
