import React, { useState } from "react";
import './style.scss'
import Select from "react-select";
import ElevatorInput from "../../components/CreatePlan/ElevatorInput";
import AddCircle from "../../assets/icons/add-circle.svg";
import MinusCircle from "../../assets/icons/minus-circle-blue.svg";
import PlanDetailsBox from "../../components/Plans/PlanDetailsBox";
import Card from "../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { createPlansData, getPlansData, setPlansData } from "../../store/reducers/plansSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import SuccessModal from "../../reusableComponents/SuccessModal";

function EditPlan() {
  const plans = useSelector((state) => state.plans.plans);
  const plansName = useSelector(state => state.plansTable.plansTable)
  const plan = useSelector((state) => state.plans);
  //
  const {id} = useParams()
  const matchingChallenge = plans?.find(plan => plan.id === parseInt(id, 10));
  //
  
  
  const options = plansName?.map((plan) => ({
    value: plan.id,
    label: plan.name,
  }));

  //
  


  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      border: "1px solid #eaf0f8;",
      boxShadow: "none",
      fontFamily: "DM Sans, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "transparent",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };
  const [formState, setFormState] = useState({})
   const navigate = useNavigate()
  useEffect(() => {
    //
    if(matchingChallenge){
       setFormState({
        ea: matchingChallenge.ea,
        hold_over_the_weekend: matchingChallenge.hold_over_the_weekend,
        inactivity_days: matchingChallenge.inactivity_days,
        initial_withdrawal_delay: matchingChallenge.initial_withdrawal_delay,
        subsequent_withdrawal_delay: matchingChallenge.subsequent_withdrawal_delay,
        leverage: matchingChallenge.leverage,
        max_capital_growth: matchingChallenge.max_capital_growth,
        max_daily_loss: matchingChallenge.max_daily_loss,
        max_days: matchingChallenge.max_days,
        max_loss: matchingChallenge.max_loss,
        min_trading_days: matchingChallenge.min_trading_days,
        name: matchingChallenge.name,
        price: matchingChallenge.price,
        profit_share: matchingChallenge.profit_share,
        publish: matchingChallenge.publish,
        starting_balance: matchingChallenge.starting_balance,
        trading_requirements: matchingChallenge.trading_requirements,
        tradable_asset: matchingChallenge.tradable_asset,
        trade_through_news: matchingChallenge.trade_through_news,
        upgrade_delay_hours: matchingChallenge.upgrade_delay_hours,
        upgrade_threshold: matchingChallenge.upgrade_threshold,
        liquidate_friday: matchingChallenge.liquidate_friday,
        require_stoploss: matchingChallenge.require_stoploss,
        create_as_disabled: matchingChallenge.create_as_disabled,
        visible_on_leaderboard: matchingChallenge.visible_on_leaderboard,
        daily_dd_by_balance_equity: matchingChallenge.daily_dd_by_balance_equity,
      });
    }
  }, [])
 
  

  const handleInputChange = (name, value) => {
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const incrementValue = (setter) => {
    setter((prevValue) => prevValue + 1);
  };

  const decrementValue = (setter) => {
    setter((prevValue) => prevValue - 1);
  };

  const initialSelectedOption = matchingChallenge
  ? options.find(option => option.value === matchingChallenge.plan)
  : null;
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption); 
  
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const [modalVisible, setModalVisible] = useState(false);

  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(
      setPlansData({
        idToken,
        id,
        data:{...formState , plan:selectedOption.value}
      }),
    );
    setModalVisible(true)
    dispatch(getPlansData(idToken))
    //
  }
  function formatLabel(label) {
    return label.replace(/_/g, ' ');
  }
  
  
  return (
    <div className="create_plan">
      <div className="grid_container">
        <div className="col1">
          <div className="col1_top">
            <div className="create_plan_wrapper">
              <h4>Create Plan</h4>
              <div className="plan_input_wrapper">
                <label htmlFor="plan_selector" className="label_black">
                  Plan Category/Server
                </label>
                <Select
                  className="plan_selector"
                  options={options}
                  styles={customStyles}
                  placeholder={"Select event..."}
                  value={selectedOption} 
                  onChange={handleSelectChange}
                />
                <br />
                <label htmlFor="plan_name" className="label_black">
                  Plan Name
                </label>
                <input
                  className="plan_name"
                  type="text"
                  name="name"
                  value={formState.name}
                />
                <br />
              </div>
            </div>
          </div>
          <div className="col1_middle">
            {Object.entries(formState).map(([name, value]) => {
              const isBoolean = typeof value === 'boolean';
              const formattedLabel = formatLabel(name);
              return (
                <div key={name} className="input_wrapper">
                  <label htmlFor={name} className="label_black">
                    {formattedLabel}
                  </label>
                  <br />
                  {isBoolean ? (
                    <div className="toggle_input_wrapper">
                      <div className="switch_wrapper">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name={name}
                            checked={formState[name]}
                            onChange={() => handleInputChange(name, !formState[name])}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  ) : typeof value === 'number' ? (
                    <ElevatorInput
                      label={name}
                      value={value}
                      setValue={(newValue) => {
                        handleInputChange(name, newValue);
                      }}
                    />
                  ) : (
                    <input
                      type="text"
                      name={name}
                      value={value}
                      onChange={(e) => handleInputChange(name, e.target.value)}
                      className="plan_name"
                    />
                  )}
                </div>
              );
            })}
          </div>
          <button className="create_plan_btn" onClick={handleSubmit}>Edit Plan</button>
        </div>
        <div className="col2">
          <div className="preview_wrapper_2">
            <h4>Preview</h4>
            <div className="preview">
              {/* <PlanDetailsBox plan={plans} /> */}
            </div>
          </div>
        </div>
      </div>
      {modalVisible ? 
      <SuccessModal
          setModalOpen={setModalVisible}
          title="Successful!!"
          desc="Plan Edited Successfully"
          btnText="Close"
        /> : '' }
    </div>
  );
}

export default EditPlan;
