import React from "react";
import "./style.scss";

function SliderComponent({ value, handleChange, min, max, step }) {
  return (
    <div className="Container">
      0%
      <input
        className="Slider"
        type="range"
        defaultValue={value}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
      />
      100%
    </div>
  );
}

export default SliderComponent;
