import { Provider, useDispatch } from "react-redux";
import Router from "./routes/Router";
import { store, persistor, clearPersistedData } from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import { deAuthenticate } from "./store/reducers/authSlice";
import LoaderOverlay from "./components/Loader";
import ErrorModal from "./components/Alerts/ErrorModal/index";
import SuccessModal from "./components/Alerts/SuccessModal/index";
import { ToastContainer } from "react-toastify";

function App() {
  const dispatch = useDispatch();
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 403 || error?.response?.status === 401 ) {
        // if (
        //   error?.response?.data?.detail ===
        //   "You do not have permission to perform this action."
        // ) {
         
        //   // window.location.reload();

        //   return;
        // }
        dispatch(deAuthenticate());
        clearPersistedData();
       
       
       
      }
      return error;
    }
  );
  return (
    <div className="App">
    <ToastContainer/>
      <Router />
      <SuccessModal />
      <ErrorModal />
    </div>
  );
}

export default App;
