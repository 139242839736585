import "./index.scss";

function LoaderOverlay({desc}) {
  return (
    <>
    
   <div className="loader_overlay">
   
     
     </div>
     <div className="container">
        <div className="loader"></div>
        {<h1>{desc} </h1> ? <h1>Loading...</h1> : ""}
      </div>
    </>
 
  );
}

export default LoaderOverlay;
