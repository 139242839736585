import axios from "axios";
import { baseUrl } from "../../../utils/api/apis";
const urls = { login: `${baseUrl}auth/admin/login/` };

function* authApi(payload) {
  const { email, password } = payload;
 
  try {
    const response = yield axios.post(urls.login, {
      email,
      password,
    });

    return response;
  } catch (err) {
    throw err;
  }
}

export { authApi };
