import React, { useEffect, useState } from "react";
import "./style.scss";
import ReactApexChart from "react-apexcharts";
import Tabination from "../../../../reusableComponents/Tabination";
import axios from "axios";
import { useSelector } from "react-redux";
import { LTTB } from "downsample";

function FinancialPerformance({ chartData }) {
  const items = [
    // { name: "Growth", content: <GrowthChart /> },
    { name: "Balance", content: <BalanceChart /> },
    {
      name: "Profit",
      content: <ProfitChart chartData={chartData.profit_chart} />,
    },
    {
      name: "Drawdown",
      content: <DrawdownChart chartData={chartData.drawdown_chart} />,
    },
  ];

  return (
    <div className="financialperformance-container">
      <h4>Financial Performance</h4>
      <div className="financialperformance-subcontainer">
        <div className="financialperformance-innercontainer">
          <Tabination
            variant={2}
            activeTab={0}
            tabItems={items}
            isChart={true}
            isToolbar={true}
          />
        </div>
      </div>
    </div>
  );
}

export default FinancialPerformance;

function BalanceChart() {
  const accountsData = useSelector(
    (state) => state.account.accountMetrics.data
  );

  const [balance_chart, setbalance_chart] = useState([]);

  useEffect(() => {
    setbalance_chart(accountsData?.balance_chart || []);
  }, [accountsData]);

  const formattedDates = accountsData?.balance_chart?.map((dateString) => {
    const date = new Date(dateString.time);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  });
  const balanceData = balance_chart.map((entry) => entry.balance);
  const equityData = balance_chart.map((entry) => entry.equity);
  let smooth = [];
  let combinedData = [];
  let separatedData = [];
  if (balanceData && equityData && formattedDates) {
    combinedData = [balanceData, equityData, formattedDates].reduce(
      (result, array) => {
        array.forEach((value, index) => {
          if (!result[index]) {
            result[index] = [];
          }
          result[index].push(value);
        });
        return result;
      },
      []
    );
    try {
      smooth = LTTB(combinedData, 1000); // 1000 is the number of points you want to display
      separatedData = smooth[0].map((_, index) =>
        smooth.map((array) => array[index])
      );
    } catch (error) {}
  }

  const series = [
    {
      name: "Balance",
      data: separatedData[0],
    },
    {
      name: "Equity",
      data: separatedData[1],
    },
  ];

  const options = {
    chart: {
      type: "line",
      stacked: false,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetY: -10,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
        zoom: {
          enabled: false,
        },
      },
    },
    stroke: {
      width: [0, 0, 4], // Set the width for the third series (profit) only
    },
    tooltip: {
      enabled: true,
      x: {
        formatter: separatedData[2],
      },
      style: {
        background: "#282c34", // Set the background color of the tooltip
        // color: "#010101", // Set the text color of the tooltip
      },
    },

    dataLabels: {
      enabled: false,
    },
    colors: ["#80E0C4", "#36d66b"],
    stroke: {
      width: [3, 3],
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    // labels: formattedDates,
    xaxis: {
      categories: separatedData[2],
      labels: {
        rotate: 0,
        style: {
          colors: "#010101",
        },
      },
      tickAmount: 6, // Adjust as needed based on your preference
      formatter: function (value, index) {
        // Display every 10th label to avoid clutter
        return index % 10 === 0 ? value : "";
      },
    },

    yaxis: [
      {
        labels: {
          formatter: (value) => `$${value?.toFixed(2) ?? ""}`, // Apply toFixed only if value is defined
          style: {
            colors: "#010101",
          },
        },
      },
    ],

    legend: {
      horizontalAlign: "left",
      offsetX: 40,
      fontWeight: 700,
      markers: {
        radius: 50,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  return (
    // <></>
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={300}
      id="balance_chart"
    />
  );
}

function ProfitChart() {
  const accountsData = useSelector((state) => state.account.accountMetrics);
  const [date, setdate] = useState([]);
  const [profit, setprofit] = useState([]);
  // const [metrics, setmetrics] = useState([]);
  useEffect(() => {
    setdate(accountsData?.data?.profit_chart?.date || []);
    setprofit(accountsData?.data?.profit_chart?.profit || []);
    // setmetrics(accountsData.data.account_metrics);
  }, [accountsData]);

  const positiveArray = profit?.map((value) => Math.abs(value));

  const formattedDates = date?.map((dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  });

  const series = [
    {
      name: "Balance",
      type: "column",
      data: positiveArray,
    },
    // {
    //   name: "Balance",
    //   type: "column",
    //   data: positiveArray,
    // },
    {
      name: "profit",
      type: "line",
      data: positiveArray,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: true,
        offsetY: -100,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
        zoom: {
          enabled: false,
        },
      },
    },
    tooltip: {
      enabled: true,

      x: {
        formatter: formattedDates,
      },
    },
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    xaxis: {
      categories: formattedDates,
      labels: {
        style: {
          colors: "#010101",
        },
        rotate: 0,
      },
      tickAmount: 6, // Adjust as needed based on your preference
      formatter: function (value, index) {
        // Display every 10th label to avoid clutter
        return index % 10 === 0 ? value : "";
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
      fontWeight: 700,
      markers: {
        radius: 50,
      },
      itemMargin: {
        horizontal: 10,
      },
    },

    yaxis: [
      {
        labels: {
          style: {
            colors: "#010101",
          },
          formatter: (value) => `$${value?.toFixed(2) ?? ""}`,
        },
      },
    ],
    colors: ["#80E0C4", "#f25f5c"],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      // type="line"
      height={300}
    />
  );
}

function DrawdownChart() {
  const accountsData = useSelector((state) => state.account.accountMetrics);
  const [date, setdate] = useState([]);
  const [draw_down, setdraw_down] = useState([]);
  // const [metrics, setmetrics] = useState([]);
  useEffect(() => {
    const dateData = accountsData?.data?.drawdown_chart?.date || [];
    const drawDownData = accountsData?.data?.drawdown_chart?.draw_down || [];

    setdate(dateData || []);
    setdraw_down(drawDownData || []);

    // setmetrics(accountsData.data.account_metrics);
  }, [accountsData]);

  const negativeArray = draw_down?.map((value) => -value);

  const formattedDates = date?.map((dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
  });

  const series = [
    {
      name: "Loss",
      data: negativeArray,
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        offsetY: -10,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
        zoom: {
          enabled: false,
        },
      },
    },
    labels: formattedDates,
    xaxis: {
      labels: {
        style: {
          colors: "#010101", // Change x-axis label color to white
        },
      },
    },

    yaxis: [
      {
        labels: {
          style: {
            colors: "#010101",
          },
          formatter: (value) => `$${Math.abs(value)?.toFixed(2) ?? ""}`,
        },
        max: 0,
      },
    ],

    fill: {
      // type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 100],
        colorStops: [],
        inverseColors: false,
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        borderRadius: 0,
        colors: {
          ranges: [
            {
              from: -Infinity, // Negative infinity to 0
              to: 0,
              color: "#f25f5c", // Red color for negative values
            },
            // {
            //   from: 100,
            //   to: Infinity, // 0 to positive infinity
            //   color: "#4CB944", // Green color for non-negative values
            // },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    legend: {
      position: "bottom",
      show: true,
      horizontalAlign: "left",
      offsetX: 40,
      fontWeight: 700,
      markers: {
        radius: 50,
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={300} />
  );
}
