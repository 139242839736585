import React from 'react';
import './style.scss';
import Deel from '../../../../assets/images/deel.png'
import VerifiedIcon from '../../../../assets/icons/tick-green-circle.svg'

function Banks() {
  const bankDetails = [
    {
      name: 'Deel',
      desc: 'Lorem ipsum dolor sit amet orem ipsum dolor sit ameconsectetur. ',
      verified: true,
      img: Deel
    },
    {
        name: 'Deel',
        desc: 'Lorem ipsum dolor sit amet orem ipsum dolor sit ameconsectetur. ',
        verified: true,
        img: Deel
      },
      {
        name: 'Deel',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        verified: true,
        img: Deel
      }
  ];

  return (
    <div className='banks'>
      <div className='banks_container'>
        {bankDetails.map((bank) => (
          <RadioBox key={bank.name} bank={bank} />
        ))}
      </div>
    </div>
  );
}

export default Banks;

const RadioBox = ({ bank }) => {
  return (
    <div className='radiobox'>
      <div className='radiobox_header'>
        <div>
          <img src={bank.img} alt={bank.name} />
          <p>{bank.name}</p>
        </div>
        <input type='radio' name='bank-radio' />
      </div>
      <div className='radiobox_details'>
        <p>{bank.desc}</p>
        <div>
        <img src={VerifiedIcon} alt={bank.name} />
        Verified
        </div>
    </div>
    </div>
  );
};
