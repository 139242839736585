import React from 'react'
import './style.scss'
import BillingIcon from '../../../assets/icons/billing.svg'
import PointerIcon from '../../../assets/icons/cursor-pointer.svg'

function RequestToChange() {
  return (
    <div className='rtc'>
        <div className='rtc_upper'>
            <img src={BillingIcon} alt='' />
            Billing
            </div>
        <div className='rtc_lower'>
            <img src={PointerIcon} alt='' />
            Request To Change</div>
    </div>
  )
}

export default RequestToChange