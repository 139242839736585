import React from 'react'
import './style.scss'
import CreateTemplate from './CreateTemplate'
import Tabination from '../../reusableComponents/Tabination';
import Preview from './Preview';

function Createemail() {
    const items = [
        { name: "Email/Notifications", content: <Email /> },
        { name: "Transcripts (Discord)", content: <Email /> },
    ];
    return (
        <div className='createemail_tabs'>
             <Tabination
        variant={4}
        tabItems={items}
        tabStyle={{ marginLeft: "0" }}
        radioButton={true}
        bg={true}
      />
        </div>
    )
}

export default Createemail


const Email = () => {
    return (
        <div className='email'>       
         <CreateTemplate />
         <Preview/>
        </div>
    )
}