import React, { useEffect, useState } from "react";
import "./style.scss";
import Overview from "../../components/Affiliate/Overview";
import AffilateCodes from "../../components/Affiliate/AffiliateCodes";
import Commission from "../../components/Affiliate/Commission";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Tabination from "../../reusableComponents/Tabination";
import CreateCodes from "../../components/Affiliate/CreateCodes";
import OverviewTable from "../../components/Affiliate/Overview/OverviewTable";
import AffiliateTable from "../../layouts/TradeList/AffiliateList";

function Affiliate() {
  const location = useLocation();
  const tab = location.state?.tab;
  const [activeTab, setActiveTab] = useState(tab);
  // useEffect(() => {
  //   
  //   // setActiveTab(tab);
  // }, [tab]);
  const items = [
    {
      name: "Affiliates list",
      path: "affiliate-list",
    },
    // {
    //   name: "Overview",
    //   path: "overview",
    // },
    {
      name: "Codes List",
      path: "codes-list",
    },
    // {
    //   name: "Commission Payment",
    //   path: "commission-payment",
    // },
    {
      name: "Create Affiliate Code",
      path: "create-codes",
    },
  ];
  

  return (
    <>
      {/* <Tabination
        variant={3}
        tabItems={items}
        activeTab={activeTab}
        style={{ margin: "0 14px" }}
      /> */}
      <div className="affiliate">
        <Routes>
          <Route
            path="/"
            element={<Navigate to="affiliate-list" replace={true} />}
          />

          <Route path="affiliate-list" element={<AffiliateTable />} />
          <Route
            path="overview"
            element={<Overview setActiveTab={setActiveTab} />}
          />
          <Route path="codes-list" element={<AffilateCodes />} />
          <Route path="commission-payment" element={<Commission />} />
          <Route path="create-codes" element={<CreateCodes />} />
        </Routes>
      </div>
    </>
  );
}

export default Affiliate;
