import React, { useState } from 'react';
import './style.scss';
import { usePagination, useTable } from 'react-table';
import CalendarIcon from '../../../assets/icons/calender.svg';
import Search from '../../../assets/icons/search.svg';
import CreateIcon from '../../../assets/icons/plus-circle-blue.svg'

import CaretLeftIcon from '../../../assets/icons/caret-left.svg';
import CaretRightIcon from '../../../assets/icons/caret-right.svg';
import { useMemo } from 'react';
import { Fragment } from 'react';
import Calendar from '../../../reusableComponents/Calendar';

function Stage2Pass() {
  return (
    <div className="stage1pass_table">
      <div className='table_scroll_wrapper'>
        <Table />
      </div>
    </div>
  );
}

export default Stage2Pass

const Table = () => {
 
  const [filter, setFilter] = useState("All");
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());

  const data = useMemo(
    () => [
      { name: 'John Doe', accountid: '123456', subject: 'Account 12212 has passed Stage 1', date: '2023-07-01', status: 'passed', action: 'View Details', staus: 'Active' },
      { name: 'Jane Smith', accountid: '789012', subject: 'Account 13345 inquiry', date: '2023-07-02', status: 'failed', action: 'View Details', staus: 'Inactive' },
      { name: 'Jim Brown', accountid: '345678', subject: 'Account 15478 Technical Issue', date: '2023-07-03', status: 'passed', action: 'View Details', staus: 'Active' },
      { name: 'Alice Johnson', accountid: '901234', subject: 'Account 16567 has passed Stage 2', date: '2023-07-04', status: 'passed', action: 'View Details', staus: 'Inactive' },
      { name: 'Sam Wilson', accountid: '567890', subject: 'Account 17689 inquiry', date: '2023-07-05', status: 'failed', action: 'View Details', staus: 'Active' },
      { name: 'Emily Clark', accountid: '234567', subject: 'Account 18890 Technical Issue', date: '2023-07-06', status: 'passed', action: 'View Details', staus: 'Active' },
      { name: 'Michael Smith', accountid: '678901', subject: 'Account 19987 has passed Stage 3', date: '2023-07-07', status: 'failed', action: 'View Details', staus: 'Inactive' },
      { name: 'Susan Miller', accountid: '345678', subject: 'Account 20012 inquiry', date: '2023-07-08', status: 'passed', action: 'View Details', staus: 'Active' },
      { name: 'Peter Jones', accountid: '789012', subject: 'Account 21123 Technical Issue', date: '2023-07-09', status: 'passed', action: 'View Details', staus: 'Active' },
      { name: 'Mick Vitamixc', accountid: '123456', subject: 'Account 22234 has passed Stage 4', date: '2023-07-10', status: 'failed', action: 'View Details', staus: 'Inactive' },
      { name: 'Susan Miller', accountid: '345678', subject: 'Account 20012 inquiry', date: '2023-07-08', status: 'passed', action: 'View Details', staus: 'Active' },
      { name: 'Peter Jones', accountid: '789012', subject: 'Account 21123 Technical Issue', date: '2023-07-09', status: 'passed', action: 'View Details', staus: 'Active' },
      { name: 'Mick Vitamixc', accountid: '123456', subject: 'Account 22234 has passed Stage 4', date: '2023-07-10', status: 'failed', action: 'View Details', staus: 'Inactive' },
      { name: 'Susan Miller', accountid: '345678', subject: 'Account 20012 inquiry', date: '2023-07-08', status: 'passed', action: 'View Details', staus: 'Active' },
      { name: 'Peter Jones', accountid: '789012', subject: 'Account 21123 Technical Issue', date: '2023-07-09', status: 'passed', action: 'View Details', staus: 'Active' },
      { name: 'Mick Vitamixc', accountid: '123456', subject: 'Account 22234 has passed Stage 4', date: '2023-07-10', status: 'failed', action: 'View Details', staus: 'Inactive' },
    ],
    []
  );

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredData = useMemo(
    () =>
      filter === "All"
        ? data
        : data.filter(
            (item) => item.status.toLowerCase() === filter.toLowerCase()
          ),
    [data, filter]
  );
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name', accessor: 'name', Cell: ({ value }) => (
          <div className="column_wrapper">
            <div className="name_initial">{value[0]}</div>
            <div className="name_text">{value}</div>
          </div>)
      },
      { Header: 'Account ID', accessor: 'accountid' },
      { Header: 'Subject', accessor: 'subject' },
      { Header: 'Date', accessor: 'date' },
      { Header: 'Status', accessor: 'status', Cell: ({ value }) => <button className={`${value}_tag`}>{value}</button> },
      {
        Header: 'Action', accessor: 'action', Cell: ({ value }) => <div className='column_wrapper'>
          <img className='create_icon' src={CreateIcon} alt='create' />
          <p>Create Affiliate Code</p>
        </div>
      },
      { Header: 'Staus', accessor: 'staus', Cell: ({ value }) => <button className={`metrics_tag`}>Go To Metrics</button> },
    ],
    []
  );


  const initialState = useMemo(
    () => [
      {
        pageSize: 10,
        pageIndex: 0,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex, pageSize},
  } = useTable({columns, data: filteredData, initialState}, usePagination);

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    <div className='table_scroll'>
      <div className="table_wrapper" style={{paddingBottom:"24px"}}>
        <div className="table_header">
          <div className="search">
            <div className="search_bar">
              <input
                type="text"
                className="search_input"
                placeholder="Search..."
              />
              <img className="search_icon" src={Search} alt="search icon" />
            </div>
            <div className="select_wrapper">
            <div
              className="calendar-button"
              onClick={() => setIsCalender((prev) => !prev)}
            >
              <img src={CalendarIcon} alt="calender icon" />
            </div>

            <div className="calendar_wrapper">
              {isCalender && (
                <Calendar
                  onChange={(date) => setDate(date)}
                  value={date}
                  // formatMonth={"MMM"}
                  selectRange={true}
                  showNeighboringMonth={false}
                />
              )}
            </div> </div>           </div>
          <div className="filter_buttons">
            <button
              className={filter === "All" ? "active_btn" : ""}
              onClick={() => handleFilter("All")}
            >
              All
            </button>
            <button
              className={filter === "Accepted" ? "active_btn" : ""}
              onClick={() => handleFilter("Accepted")}
            >
              Accepted
            </button>
            <button
              className={filter === "Rejected" ? "active_btn" : ""}
              onClick={() => handleFilter("Rejected")}
            >
              Rejected
            </button>
            <button
              className={filter === "Flagged" ? "active_btn" : ""}
              onClick={() => handleFilter("Flagged")}
            >
              Flagged
            </button>
          </div>
        </div>
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup?.headers?.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={rowIndex}>
                  <tr
                    {...row.getRowProps()}
                  >
                         {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                  
                          {cell.render("Cell")}
                      
                      </td>
                    ))}
                  </tr>
                 
                </Fragment>
              );
            })}
          </tbody>
        </table>
        {pageOptions.length > 1 && (
          <div className="pagination">
            <img src={CaretLeftIcon} alt="left-arrow" />
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className={!canPreviousPage ? "disabled" : ""}
            >
              Previous
            </button>
            {pageOptions.map((pageNumber, index) => (
              <>
                <button
                  key={index}
                  onClick={() => gotoPage(index)}
                  className={pageIndex === index ? "btn-active" : "non-active"}
                >
                  {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
                </button>
              </>
            ))}
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className={!canNextPage ? "disabled" : ""}
            >
              Next
            </button>
            <img src={CaretRightIcon} alt="right-arrow" />
          </div>
        )}
      </div>
    </div>
  );
};

