import React, { useState } from "react";
import "./style.scss";
import Close from "../../../assets/icons/circle-cross.svg";
import EmailIcon from "../../../assets/icons/email.svg";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import UserIcon from "../../../assets/icons/user.svg";
import PhoneIcon from "../../../assets/icons/phone.svg";
import ApartmentNoIcon from "../../../assets/icons/apartment-no.svg";
import StreetIcon from "../../../assets/icons/street.svg";
import StateIcon from "../../../assets/icons/state.svg";
import ZipCodeIcon from "../../../assets/icons/zip-code.svg";
import CountryIcon from "../../../assets/icons/country.svg";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../../utils/helpers/string";
import { addUserAddress, postPlansTableDetails } from "../../../utils/api/apis";
import { country } from "../../../utils/constants/country";
import ErrorModal from "../../../reusableComponents/ErrorModal";
import SuccessModal from "../../../reusableComponents/SuccessModal";
import { getFundingData } from "../../../store/reducers/fundingSlice";

function CreatePlanModal({ closeAddAddressModal, setIsPlanModal }) {
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.auth.idToken);
  const [modalVisible, setModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [inputValues, setInputValues] = useState({
    description: "",
    name: "",
    mt5_server: null,
    ctrader_server: null,
  });
  //

  const [state, setstate] = useState(null);

  // const [country, setcountry] = useState(null);

  const [inputErrors, setInputErrors] = useState({});

  const [focusedInput, setFocusedInput] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
    setFocusedInput(name);

    if (name === "email") {
      const isValidEmail = validateEmail(value);
      setInputErrors((prev) => ({
        ...prev,
        email: isValidEmail ? "" : "Invalid email format",
      }));
    } else if (name === "zip_code") {
      const numericOnly = value.replace(/[^0-9]/g, "");
      setInputValues((prev) => ({ ...prev, [name]: numericOnly }));

      if (numericOnly.length < 5) {
        setInputErrors((prev) => ({
          ...prev,
          zip_code: "Zip Code must be at least 5 characters",
        }));
      } else {
        setInputErrors((prev) => ({ ...prev, zip_code: "" }));
      }
    } else {
      setInputErrors((prev) => ({ ...prev, [name]: "" })); // Clear error for this field
    }

    // if (name === "name") {
    //   const alphabeticOnly = value.replace(/[^a-zA-Z]/g, "");
    //   setInputValues((prev) => ({ ...prev, [name]: alphabeticOnly }));
    // } else if (name === "contact") {
    //   const numericOnly = value.replace(/[^0-9]/g, "");
    //   setInputValues((prev) => ({ ...prev, [name]: numericOnly }));
    // }
  };

  const handleSelectChange = (name, selectedOption) => {
    if (name === "mt5_server") {
      setInputValues((prev) => ({ ...prev, ctrader_server: null }));
    }
    if (name === "ctrader_server") {
      setInputValues((prev) => ({ ...prev, mt5_server: null }));
    }
    setInputValues((prev) => ({ ...prev, [name]: selectedOption }));
    setInputErrors((prev) => ({ ...prev, [name]: "" })); // Clear error for this field
  };
  const servers = ["mt5_server", "ctrader_server"];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      marginTop: "-10px",
      background: "transparent",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        borderColor: "#A4A8AE",
      },
    }),
    menu: (provided) => ({
      ...provided,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#0A3266",
    }),
  };

  const handleSubmit = async (e) => {
    try {
      setModalVisible(false);
      const response = await postPlansTableDetails(idToken, inputValues);
      response.status < 399 && setModalVisible(true);
      dispatch(getFundingData(idToken));
      // setIsPlanModal(false);
    } catch (error) {
      setErrorModalVisible(true);
    }

    // e.preventDefault();
    // addUserAddress(idToken, inputValues);
    //

    const errors = {};

    if (!inputValues.name.trim()) errors.name = "Please fill in the name field";
    // if (!validateEmail(inputValues.email))
    //   errors.email = "Invalid email format";
    // if (!inputValues.contact.trim())
    //   errors.contact = "Please fill in the contact field";
    // if (!inputValues.apartment_no.trim())
    //   errors.apartment_no = "Please fill in the apartment number field";
    // if (!inputValues.street_address.trim())
    //   errors.street_address = "Please fill in the street address field";
    // if (!inputValues.state) errors.state = "Please select a state";
    // if (!inputValues.zip_code.toString().trim())
    //   errors.zip_code = "Please fill in the zip code field";
    if (!inputValues.server) errors.country = "Please select a country";

    setInputErrors(errors);

    if (Object.keys(errors).length === 0) {
      //
      closeAddAddressModal();
    }
  };
  const handleInputFocus = (name) => {
    setFocusedInput(name);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };

  return (
    <div className="add_address_modal_container">
      <div className="modal_header">
        <h4>Add New Plan</h4>
        <img
          src={Close}
          alt="close icon"
          onClick={() => setIsPlanModal(false)}
        />
      </div>
      <div className="add_address_info_container">
        <div className="general_info_form_container">
          <p>General Information</p>
          <div className="general_info_form">
            <div
              className={`address_info_form_input_container ${
                focusedInput === "name" ? "focused" : ""
              } ${inputErrors.name ? "input_error" : ""}`}
            >
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="name">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  id="description"
                  value={inputValues.description}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("description")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            <div
              className={`address_info_form_input_container ${
                focusedInput === "name" ? "focused" : ""
              } ${inputErrors.name ? "input_error" : ""}`}
            >
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={inputValues.name}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("name")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>

            <div
              className={`address_info_form_input_container ${
                focusedInput === "name" ? "focused" : ""
              } ${inputErrors.name ? "input_error" : ""}`}
            >
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="name">
                  Server
                </label>
                <Select
                  styles={customStyles}
                  options={servers?.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="react_select_container"
                  name="server"
                  // value={inputValues.country}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption.label, 1)
                  }
                  onFocus={() => handleInputFocus("server")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      <div className="btn_container">
        <button onClick={handleSubmit} className="save_info_btn">
          Save Info
        </button>
      </div>
      {modalVisible ? (
        <SuccessModal
          setModalOpen={setModalVisible}
          title="Successful!!"
          desc="Plan Created Successfully"
          btnText="Close"
        />
      ) : (
        ""
      )}

      {errorModalVisible ? (
        <ErrorModal
          setModalOpen={setErrorModalVisible}
          title="Error!!"
          desc="Could'nt create plan"
          btnText="Close"
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default CreatePlanModal;
