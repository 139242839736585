import { takeEvery, call, put, all } from "redux-saga/effects";
import {
  getAffiliateDetails,
  postAffiliateDetails,
} from "../../../utils/api/apis";
import {
  createAffiliateDataSuccess,
  getAffiliateDataSuccess,
} from "../../reducers/affiliateSlice";
import { setIsLoading } from "../../reducers/authSlice";
import { returnErrors } from "../../reducers/error";

function* fetchAffiliateData(action) {
  yield put(setIsLoading(true));
  try {
    const idToken = action.payload;
    const affiliateData = yield call(getAffiliateDetails, idToken);
    //
    yield put(getAffiliateDataSuccess(affiliateData));
    yield put(setIsLoading(false));
  } catch (error) {
    yield put(returnErrors("Failed to fetch Affiliate List", 400));
    yield put(setIsLoading(false));
  }
}

function* postAffiliateData(action) {
  try {
    const { idToken, data } = action.payload;
    const affiliateData = yield call(postAffiliateDetails, idToken, data);
    //
    yield put(createAffiliateDataSuccess(affiliateData));
  } catch (error) {
   
  }
}

export default function* affiliateSaga() {
  yield all([
    takeEvery("affiliate/getAffiliateData", fetchAffiliateData),
    takeEvery("affiliate/createAffiliateData", postAffiliateData),
  ]);
}
