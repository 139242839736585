import React, { useEffect, useState } from "react";
import './style.scss'
import Tabination from "../../reusableComponents/Tabination";
import All from "../../components/Plans/All";
import CreateButtonHeader from "../../components/CreateButtonHeader";
import PlansTable from "../../components/Plans/PlansTable";
import { useDispatch, useSelector } from "react-redux";
import { getPlansData } from "../../store/reducers/plansSlice";
import { getPlansTableData } from "../../store/reducers/plansTableSlice";

function Plans() {
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.plans.plans);
  const plansTable = useSelector((state) => state.plansTable.plansTable);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getPlansData(idToken));
        await dispatch(getPlansTableData(idToken));
        setLoading(false); 
      } catch (error) {
        // console.error(error);
      }
    };

    fetchData();
  }, [idToken, dispatch]);

  //
  const plansTableData = plansTable || [];

  if (loading) {
    return <div>Loading...</div>; 
  }

  const tabItems = plansTableData.map((table) => {
    return {
      name: table.name,
      content: (
        <div className="ad_list_wrapper">
          <div className="ad_list">
            <All plans={plans} name={table.id} />
          </div>
          <CreateButtonHeader buttonText="Create Plans" link="/create-plan" />
        </div>
      ),
    };
  });

  tabItems.push({
    name: "All Plans",
    content: (
      <div className="ad_list_wrapper">
        <div className="ad_list">
          <PlansTable plans={plansTable} />
        </div>
        <CreateButtonHeader buttonText="Create Plans" link="/create-plan" />
      </div>
    ),
  });

  return <Tabination variant={3} tabItems={tabItems} activeTab={0} />;
}

export default Plans;
