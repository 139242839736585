import React from "react";
import "./style.scss";
import IPlogsTable from "../../components/IPlogs/Index";

function IPlogs({data}) {
  const userEmail = data?.email
  return (
    <>
      <IPlogsTable userEmail={userEmail} />

      {/* <CreateButtonHeader buttonText="Create Coupons" link={"/create-coupon"} /> */}
    </>
  );
}

export default IPlogs;
