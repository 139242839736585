import React, { useState } from "react";
import "./style.scss";

import ArrowIncrease from "../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../assets/icons/arrow-decrease.svg";
import InfoBox from "../../reusableComponents/InfoBox";
import LeaderboardWinners from "../../components/Leaderboard/LeaderboardWinners/Index";
import LeaderboardTable from "../../components/Leaderboard/LeaderboardTable.js";
import LeaderboardNews from "../../components/Leaderboard/LeaderboardSymbolData";
import LeaderboardSymbolData from "../../components/Leaderboard/LeaderboardSymbolData";
import { getLeaderboardDetailsReq } from "../../utils/api/apis.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { returnErrors } from "../../store/reducers/error.js";
import { useParams } from "react-router-dom";
import { setIsLoading } from "../../store/reducers/authSlice.js";

function Leaderboard() {
  const idToken = useSelector((state) => state.auth.idToken)
  const {id} = useParams()
  const infoBoxArr = [
    {
      title: "Equity",
      value: "30872",
      icon: ArrowIncrease,
      percentage: "+2.53%",
      color: "#F75C03",
    },
    {
      title: "Balance",
      value: "$5000",
      icon: ArrowIncrease,
      percentage: "-1.2%",
      color: "#4CB944",
    },
    {
      title: "PnL",
      value: "1000",
      icon: ArrowIncrease,
      percentage: "+5.8%",
      color: "#36d66b",
    },
    {
      title: "Win Rate",
      value: "500",
      icon: ArrowDecrease,
      percentage: "+10%",
      color: "#DF2935",
    },
  ];
  const dispatch = useDispatch()
  const [tableData,setTableData] = useState([])

const fetchData = async() => {
  dispatch(setIsLoading(true))
  const response = await getLeaderboardDetailsReq(idToken,id)
  response?.status < 399 ? setTableData(response?.data) : dispatch(returnErrors("Something went wrong", 400)) 
  dispatch(setIsLoading(false))
}
  useEffect(() => {
    fetchData()

  }, [id]);

  return (
    <div className="leaderboard">
      <div className="leaderboard_upper">
        <div className="grid_container">
          <div className="leaderboard_winner_wrapper">
            <LeaderboardWinners data={tableData} />
          </div>
          {/* <div className="leaderboard_infobox_wrapper">
            {infoBoxArr.map((info) => (
              <InfoBox
                key={info.title}
                title={info.title}
                value={info.value}
                icon={info.icon}
                percentage={info.percentage}
                color={info.color}
              />
            ))}
          </div> */}
          {/* <div className="leaderboard_symbol_data">
            <LeaderboardSymbolData />
          </div> */}
        </div>
      </div>
      <div className="leaderboard_lower">
        <LeaderboardTable tableData={tableData} id={id}/>
      </div>
    </div>
  );
}

export default Leaderboard;
