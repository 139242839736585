import EditIcon from "./icons/editIcon";
import DeleteIcon from "./icons/deleteIcon";
import ArchiveIcon from "./icons/archiveIcon";
import DuplicateIcon from "./icons/duplicateIcon";

import PlanIcon from "../../../assets/icons/medal-orange.svg";
import TickGreen from "../../../assets/icons/tick-green-circle.svg";

import { useEffect, useRef } from "react";

import "./style.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { deletePlans } from "../../../store/reducers/plansSlice";
import { useDispatch, useSelector } from "react-redux";
function PlanDetailsBox({ plan, challengeId }) {
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [activeColor, setActiveColor] = useState("#36d66b");

  //
  const handleToggleSideMenu = () => {
    if (isSideMenuOpen) {
      setActiveColor("#36d66b");
    } else setActiveColor("#EAF0F8");
    setSideMenuOpen(!isSideMenuOpen);
  };

  const sideMenuOpts = [
    { title: "Edit", Icon: EditIcon },
    { title: "Delete", Icon: DeleteIcon },
  ];

  const idToken = useSelector(state => state.auth.idToken)
  const dispatch = useDispatch()

  const handleDelete = (id) => {
    //
    dispatch(deletePlans({ idToken: idToken, id: id }))
  }
  const challengesArray = Object.entries(plan);

  const SideMenu = () => (
    <div className="sidemenu">
      <div className="sidemenu_list_container">
        <div className="sidemenu_list">
          {sideMenuOpts.map(({ title, Icon }, index) => (
            <div key={title} className="option_container">
              <Icon />
              {title === 'Edit' ?
                <Link to={`/edit-plan/${plan.id}`}>
                  <h5>{title}</h5>
                </Link> :
                <h5>{title}</h5>}

            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="plan_details_box_wrapper">
      <div className="plan_details_box">
        <div className="plan_details_box_upper">
          <div className="plan_details_box_upper_wrapper">
            {/* <div className="header">
              <img src={PlanIcon} alt="" className="header_img" /> 
               <h2>{plan.name}</h2>
            </div> */}

            <div
              className="bg_img_container_header"
              style={
                isSideMenuOpen
                  ? { backgroundColor: "#36d66b" }
                  : { backgroundColor: "#EAF0F8" }
              }
              onClick={handleToggleSideMenu}
            >
              <svg
                className="menu"
                width="22"
                height="6"
                viewBox="0 0 22 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Menu Meatballs">
                  <g id="Vector">
                    <path
                      d="M11 1C12.1046 1 13 1.89543 13 3C13 4.10457 12.1046 5 11 5C9.89543 5 9 4.10457 9 3C9 1.89543 9.89543 1 11 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                    <path
                      d="M3 1C4.10457 1 5 1.89543 5 3C5 4.10457 4.10457 5 3 5C1.89543 5 1 4.10457 1 3C1 1.89543 1.89543 1 3 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                    <path
                      d="M19 1C20.1046 1 21 1.89543 21 3C21 4.10457 20.1046 5 19 5C17.8954 5 17 4.10457 17 3C17 1.89543 17.8954 1 19 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                  </g>
                </g>
              </svg>
              {isSideMenuOpen ? <SideMenu /> : ""}
            </div>
            <h4>{plan.name}</h4>
          </div>
        </div>
        {/* <div className="plan_details_box_middle">
          <div className="plan_details_box_middle_wrapper">
            <div className="balance_wrapper">
     
            </div>

            <div className="date">
              <h5>
              </h5>
            </div>
          </div>
        </div> */}
        <div className="plan_details_box_bottom">
          <div className="plan_details_box_bottom_wrapper">
            {challengesArray?.map((perk, index) => (
              <div className="perk_wrapper" key={index}>
                <img src={TickGreen} alt="CheckBox" />
                {typeof(perk[1]) === "boolean" ? <p>{perk[0]} - {perk[1] ? 'Yes' : 'No'}</p>
                  :
                  <p>{perk[0]} - {perk[1]}</p>
                }
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanDetailsBox;
