import React from 'react'
import './style.scss'
import Deel from '../../../../assets/images/deel.png'
import LaptopSignin from '../../../../assets/images/laptop-signin.png'
import Select from 'react-select';
import ArrowLeft from '../../../../assets/icons/dropdown-arrow-gray.svg'
import Tabination from '../../../../reusableComponents/Tabination';

function RequestPayout({isReqPayout , setReqPayout}) {
    const items = [
        {
          name: "Trading Account",
          content: (
         
           <TradingAccount />
                 
          ),
        },
    
        {
          name: "Affiliate",
          content: (
         
         <TradingAccount />  
                 
          ),
        },
  
    
      ];


      const options = [
        { value: 'ac', label: 'Account' },

    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minWidth: 384,
            width:'100%',
            height: 48,
            marginTop: '12px',
            borderRadius: 8,
            border: '1px solid #EAF0F8',
            boxShadow: 'none',
            paddingRight: '20px',
            fontFamily: "DM Sans , sans-serif",
            fontSize: '14px',
            fontWeight: 500
        }),
        menu: (provided) => ({
            ...provided,
            width: 384
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#36d66b'
        })
    };
    return (
        <div className='reqpayout'>
            <div className='grid_container'>
                <div className='col1'>
                    <div className='col1_upper'>
                        <div className='col1_header' onClick={() => setReqPayout(!isReqPayout)}>
                            <img src={ArrowLeft} alt='back icon' />
                            Back
                        </div>
                        <div className='col1_content'>
                            <h4>Ready to request Your Payout</h4>
                            <p>Please click on the request payout button and proceed to fill out the needed information; our staff will contact you for further developments.
                            </p>
                        </div>
                    </div>
                    <div className='col1_lower'>
                    <Tabination tabItems={items} activeTab={0} />
                    <div className='col1_lower_select_container'>
                        <p>Choose Account Login ID*</p>
                        <Select
                                    options={options}
                                    styles={customStyles}
                                    value={options[0]}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                 
                                />
                    </div>
                    </div>
                </div>
                <div className='col2'>
                <img src={LaptopSignin} alt='' />
                        <div className='col2_content'>
                        <h3>
                            February 2022 <br></br>
                              Trading Competition 
                            </h3>
                        <button>Enter Competition</button>
                    </div>
                </div>
            </div>    </div>
    )
}

export default RequestPayout


const TradingAccount = () => {
    return (
        <div className='tradingaccount'>
        <div className='radiobox'>
        <div className='radiobox_header'>
          <div>
            <img src={Deel} alt='' />
            <p>Standard</p>
          </div>
          <input type='radio' name='bank-radio' />
        </div>
        <div className='radiobox_details'>
          <p>Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit.</p>
        
      </div>
      </div>

      <div className='radiobox'>
        <div className='radiobox_header'>
          <div>
            <img src={Deel} alt='' />
            <p>Standard</p>
          </div>
          <input type='radio' name='bank-radio' />
        </div>
        <div className='radiobox_details'>
          <p>Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit.</p>
    
      </div>
      </div>
      </div>
    )
}