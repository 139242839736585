import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import CaretDownIcon from "../../../../assets/icons/caret-down.svg";
import ProfileIcon from "../../../../assets/icons/profile.svg";
import Card from "../../../../reusableComponents/Card";
import ProfileDropdown from "../../../ProfileDropdown";
import { useSelector } from "react-redux";
import { TruncateString } from "../../../../utils/helpers/string";

function ProfitCard() {
  const [profileDropdown, setProfileDropdown] = useState(false);
  const userDetails = useSelector(
    (state) => state.account.accountList.activeUser
  );
  console.log(userDetails, "userDetails");
  const activeAccount = useSelector(
    (state) => state.account.accountList.activeAccount.id
  );
  const sidebarRef = useRef(null);
  //
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setProfileDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Card className="profilecard_details_upper">
      {/* <div
      
        className="profilecard_img_wrapper"
        ref={sidebarRef}
        onMouseDown={(e) => e.preventDefault()}
      >
        <img src={ProfileIcon} alt="profile icon" />
      </div> */}
      <div
        onClick={() => setProfileDropdown(!profileDropdown)}
        className="profilecard_details_upper_content"
      >
        <h4 style={{ textTransform: "capitalize" }}>
          {" "}
          {userDetails?.name
            ?.split("_")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
        </h4>
        <p>
          Challenge :
          <b>
            {" "}
            {userDetails?.challenge
              ?.split("_")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
          </b>
        </p>
        <p>
          Challenge Type :
          <b>
            {" "}
            {userDetails?.challenge_type
              ?.split("_")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
          </b>
        </p>
        <p>
          Phase :
          <b>
            {userDetails?.phase
              ?.split("_")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
          </b>
        </p>
        <p>
          {" "}
          Active Account: <span>{activeAccount}</span>
        </p>
      </div>
      <div
        onClick={() => setProfileDropdown(!profileDropdown)}
        className="profilecard_edit_img_wrapper"
        style={{
          cursor: "pointer",
          position: "relative",
          marginTop: "4px",
          scale: "1.3",
        }}
      >
        <img src={CaretDownIcon} alt="caret icon" />
        {profileDropdown ? (
          <ProfileDropdown
            profileDropdown={profileDropdown}
            setProfileDropdown={setProfileDropdown}
          />
        ) : (
          <></>
        )}
      </div>
    </Card>
  );
}

export default ProfitCard;
