import React from "react";
import "./style.scss";

function Card({ handleActive, className = "", children }) {
  return (
    <div onClick={handleActive} className={`card-container ${className}`}>
      {children}
    </div>
  );
}

export default Card;
