import React from 'react'
import './style.scss'
import WarningIcon from '../../../../assets/icons/exclamation-warning.svg'

function Warning() {
  return (
    <div className='warning'>
        <img src={WarningIcon} alt='exclamation' />
        <p>Your recipient may be charged an additional fee by their bank for incoming wire transfers. the fee may vary from $12 to $20 </p>
    </div>
  )
}

export default Warning