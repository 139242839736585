import React from 'react'
import './style.scss'
import Dollar from '../../../assets/icons/dollar-circle.svg'
import GreenChart from '../../../assets/icons/green-chart.svg'
import Bitcoin from '../../../assets/icons/bitcoin-logo.svg'
import ArrowUp from '../../../assets/icons/arrow-up-green.svg'

function General() {

    const tradingStatsInfo = [
        {
            headerImg: Dollar,
            headerTitle:'Most Traded Symbol',
            contentImg1: Bitcoin,
            contentImg2: GreenChart,
            contentTitle:'Bitcoin',
            contentPercentage:'22',
            contentValue:'$72,23,324 BTC',
            bottomTitle: 'No. of positions Placed',
            bottomValue: '$72,23,324'
        },
        {
            headerImg: Dollar,
            headerTitle:'Most Traded Symbol',
            contentImg1: Bitcoin,
            contentImg2: GreenChart,
            contentTitle:'Bitcoin',
            contentPercentage:'22',
            contentValue:'$72,23,324 BTC',
            bottomTitle: 'No. of positions Placed',
            bottomValue: '$72,23,324'
        },
        {
            headerImg: Dollar,
            headerTitle:'Most Traded Symbol',
            contentImg1: Bitcoin,
            contentImg2: GreenChart,
            contentTitle:'Bitcoin',
            contentPercentage:'22',
            contentValue:'$72,23,324 BTC',
            bottomTitle: 'No. of positions Placed',
            bottomValue: '$72,23,324'
        },
        {
            headerImg: Dollar,
            headerTitle:'Most Traded Symbol',
            contentImg1: Bitcoin,
            contentImg2: GreenChart,
            contentTitle:'Bitcoin',
            contentPercentage:'22',
            contentValue:'$72,23,324 BTC',
            bottomTitle: 'No. of positions Placed',
            bottomValue: '$72,23,324'
        },
    ]
  return (
    <div className='trading_stats'>
        <div className='trading_stats_box_container'>
        {
            tradingStatsInfo.map((item , index) => (
                <TradingStatsBox key={index} item={item} />
            ))
        }
        </div>
    </div>
  )
}

export default General


const TradingStatsBox = ({item}) => {
    return (
        <div className='trading_stats_box'>
            <div className='box_header'>
                <img src={item.headerImg} alt='' />
                <h2>{item.headerTitle}</h2>
            </div> 
            <div className='box_content'>
               <img src={item.contentImg1} alt='' />
               <div className='box_content_info'>
                <div>
                    <p>{item.contentTitle}</p>
                    <span>{item.contentPercentage}
                    <img src={ArrowUp} alt='' />
                    </span>
                </div>
                <h2>{item.contentValue}</h2>
               </div>
               <img src={item.contentImg2} alt='' />
            </div>
            <div className='box_bottom'>
                <p>{item.bottomTitle}</p>
                <h2>{item.bottomValue}</h2>
            </div>
        </div>
    )
}