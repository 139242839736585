import React from 'react'
import './style.scss'
import ReactApexChart from 'react-apexcharts';

function Stage({ data, title, chartData1, chartData2 }) {
  return (
    <div className='stage'>
      <p className='chart_title'>{title}</p>
      {title === "Stage 01" && <div className='details_wrapper' >
        <p>Total: <span>{data?.stage_1_accounts}</span></p>
        <p>Pass: <span className='pass'>{data?.stage_1_account_pass}</span></p>
        <p>Fail: <span className='fail'>{data?.stage_1_account_fail}</span></p>
        <p>In progress: <span className=''>{data?.stage_1_accounts - (data?.stage_1_account_fail +  data?.stage_1_account_pass) }</span></p>
      </div>}
      {title === "Stage 02" && <div className='details_wrapper' >
        <p>Total: <span>{data?.stage_2_accounts}</span></p>
        <p>Pass: <span className='pass'>{data?.stage_2_account_pass}</span></p>
        <p>Fail: <span className='fail'>{data?.stage_2_account_fail}</span></p>
        <p>In progress: <span className=''>{data?.stage_2_accounts - (data?.stage_2_account_fail +  data?.stage_2_account_pass) }</span></p>
      </div>}
      <div className='stage_chart_container'>
        <div className='stage_chart_wrapper'>
          <GaugeChart options={chartData1} />
        </div>
        <div className='stage_chart_wrapper'>
          <GaugeChart options={chartData2} />
        </div>
      </div>
    </div>
  )
}

export default Stage



function GaugeChart({ options }) {


  return (
    <ReactApexChart options={options} series={options.series} type="radialBar" height={250} />
  );
}




