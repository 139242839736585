import React, { useState } from "react";
import "./style.scss";
import Tooltip from "../Tooltip";

function CopyButton({ content, moveY, children }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <button className="copyButton" onClick={() => setIsActive(true)}>
        {children}
      </button>
      <Tooltip
        variant="disappear"
        isActive={isActive}
        setIsActive={() => setIsActive()}
        moveY={moveY}
      >
        {/* {content || "Copied to Clipboard!"} */}
      </Tooltip>
    </>
  );
}

export default CopyButton;