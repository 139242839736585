import React from "react";
import Stage1 from "./stage1";
import Tabination from "../../reusableComponents/Tabination";

function OverviewPage() {
  const items = [
    { name: "Overview", content: <Stage1 phase={""} /> },
    // { name: "Stage2", content: <Stage1 phase={"stage_2"} /> },
    // { name: "Funded Account", content: <Stage1 phase={"funded"} /> },
    // { name: "No Cap", content: <Stage1 phase={"nocap"} /> },
  ];

  return (
    <div>
      <div className="trading_stats_tab">
        <Tabination
          variant={1}
          activeTab={0}
          tabItems={items}
          radioButton={true}
        />
      </div>
    </div>
  );
}

export default OverviewPage;
