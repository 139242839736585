import React, { useEffect, useState } from "react";
import "./style.scss";
import Edit from "../../../../assets/icons/edit-pencil.svg";
import Profile from "../../../../assets/icons/profile.svg";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { patchUser } from "../../../../store/reducers/userSlice";
import { country } from "../../../../utils/constants/country";
function AccountDetails({ userDetails, editMode, setEditMode }) {
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  //
  const options = country.map((item) => {
    return {
      value: item.country,
      label: item.country,
    };
  });

  useEffect(() => {}, []);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: 48,
      marginTop: "12px",
      borderRadius: 8,
      border: "1px solid #EAF0F8",
      boxShadow: "none",
      paddingRight: "20px",
      fontFamily: "DM Sans , sans-serif",
      fontSize: "14px",
      fontWeight: 500,
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#36d66b",
    }),
  };

  const [formData, setFormData] = useState({
    first_name: userDetails.first_name || "",
    last_name: userDetails.last_name || "",
    contact: userDetails.contact || "+91 ",
    email: userDetails.email || "",
    selectedCountry: userDetails.country || "",
    city: userDetails.city || "",
  });

  const [inputErrors, setInputErrors] = useState({
    email: "",

    contact: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCountryChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedCountry: selectedOption,
    }));
  };

  const handleCityChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedCity: selectedOption,
    }));
  };
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      selectedCountry: userDetails.country,
    }));
  }, []);

  const handleSave = async () => {
    // const apiData = loadUser( idToken);
    dispatch(patchUser({ formData, idToken }));
    // const apiData = updateUserDetailsRequest(formData, idToken);
  };
  return (
    <div className="account_details">
      <div className="account_details_upper">
        <div className="account_details_upper_left">
          <div className="account_img_wrapper">
            <img src={Profile} alt="profile icon" />
          </div>
          <div className="account_details_upper_content">
            <h4>
              {`${userDetails.first_name} ${userDetails.last_name}` ||
                "Daniel Radcliffe"}
            </h4>
            <p>Funded Trader</p>
          </div>
          <div className="account_edit_img_wrapper" onClick={handleEditMode}>
            <img src={Edit} alt="edit icon" />
          </div>
        </div>
        {!editMode ? (
          <button className="save-btn" onClick={handleSave}>
            Save changes
          </button>
        ) : (
          ""
        )}
      </div>
      <hr />

      <div className="account_details_lower">
        <div className="account_form">
          <form>
            <div className="account_input_container_upper">
              <div className="account_input">
                <label htmlFor="firstName" className="label_black">
                  First Name
                </label>
                <br />
                <input
                  className="account_details_input"
                  type="text"
                  value={formData.first_name}
                  name="first_name"
                  onChange={handleChange}
                  disabled={editMode}
                />
              </div>
              <div className="account_input">
                <label htmlFor="lastName">Last Name</label>
                <br />
                <input
                  className="account_details_input"
                  type="text"
                  value={formData.last_name}
                  name="last_name"
                  onChange={handleChange}
                  disabled={editMode}
                />
              </div>
              <div className="account_input">
                <label htmlFor="contact">Contact Number</label>
                <br />
                <input
                  className={`account_details_input ${
                    inputErrors.contact ? "input_error" : ""
                  }`}
                  type="text"
                  value={formData.contact}
                  name="contact"
                  onChange={handleChange}
                  disabled={editMode}
                />
              </div>
            </div>

            <div className="account_input_container_lower">
              <div className="account_input">
                <label htmlFor="email" className="label_black">
                  Email
                </label>
                <br />
                <input
                  className={`account_details_input ${
                    inputErrors.email ? "input_error" : ""
                  }`}
                  type="text"
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                  disabled={editMode}
                />
              </div>
              <div className="account_input">
                <label htmlFor="country">Country</label>
                <br />
                <Select
                  options={options}
                  styles={customStyles}
                  value={formData.selectedCountry}
                  onChange={handleCountryChange}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  name="selectedCountry"
                  id="country"
                  isDisabled={editMode}
                />
              </div>
              <div className="account_input">
                <label htmlFor="city">City</label>
                <br />
                <input
                  className={`account_details_input ${
                    inputErrors.city ? "input_error" : ""
                  }`}
                  type="text"
                  name="city"
                  // placeholder={userDetails.city}
                  value={userDetails.city}
                  onChange={handleChange}
                  disabled={editMode}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
