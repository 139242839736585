import React from "react";
import ReactApexChart from "react-apexcharts";

function AreaPreviewChart({
  style,
  isGain = 0,
  outerHeight = 60,
  innerHeight = 100,
  outerWidth = 100,
  innerWidth = 100,
}) {
  const series = [
    {
      name: "series",
      data: [10, 40, 18, 51, 100, 80, 112, 120, 90, 50, 64],
    },
  ];

  const options = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: [isGain >= 0 ? "#4CB944" : "#DF2935"],
    grid: {
      show: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      enabled: false,
    },
  };

  const chartStyle = { position: "absolute", ...style };

  return (
    <div
      style={{
        position: "relative",
        height: `${outerHeight}px`,
        width: `${outerWidth}px`,
      }}
    >
      <div style={chartStyle}>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={innerHeight}
          width={innerWidth}
        />
      </div>
    </div>
  );
}

export default AreaPreviewChart;
