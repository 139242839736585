import React, { useState } from "react";
import "./style.scss";
import { useTable } from "react-table";
import EditIcon from "../../../../assets/icons/edit.svg";
import FilterIcon from '../../../../assets/icons/filter.svg'

import CalenderIcon from "../../../../assets/icons/calender.svg";
import Search from "../../../../assets/icons/search.svg";
import CaretLeftIcon from "../../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../../assets/icons/caret-right.svg";
import CreateButtonHeader from "../../../CreateButtonHeader";
import ActionIcon from '../../../../assets/icons/black-dots.svg'
import Error from '../../../../assets/icons/error-mark.svg'
import Success from '../../../../assets/icons/tick-green-circle.svg'

function Email() {
  return (

    <div className="email_wrapper">
      <div className="email">
        <div className="email_table">
          <div className="table_scroll_wrapper">
            <Table /> </div>
        </div></div>
        <div className="create_btn">
      <CreateButtonHeader buttonText="Create Email/Notifications" link={"/create-email"} />
      </div>
    </div>
  );
}

export default Email

const Table = () => {

  const data = [
    { type: "Email", date: "12 Jan, 2022 06:11:42", title: "BlackFriday 50% OFF", description: "Special offer for Black Friday", reached: 234243, status: "Success", action: <img src="your_icon_url.png" alt="Action Icon" /> },
    { type: "Notification", date: "15 Feb, 2022 08:30:15", title: "New Product Launch", description: "Introducing our latest product", reached: 123456, status: "Error", action: <img src="your_icon_url.png" alt="Action Icon" /> },
    { type: "Email", date: "22 Mar, 2022 14:45:00", title: "Exclusive Discount", description: "Limited-time discount offer", reached: 345678, status: "Success", action: <img src="your_icon_url.png" alt="Action Icon" /> },
    { type: "Notification", date: "05 Apr, 2022 10:20:30", title: "Important Update", description: "Updates regarding our services", reached: 567890, status: "Error", action: <img src="your_icon_url.png" alt="Action Icon" /> },
    { type: "Email", date: "18 May, 2022 19:55:12", title: "Weekly Newsletter", description: "Stay updated with our newsletter", reached: 789012, status: "Success", action: <img src="your_icon_url.png" alt="Action Icon" /> },
    { type: "Notification", date: "07 Jun, 2022 12:10:05", title: "Account Security Alert", description: "Action required for your account", reached: 987654, status: "Error", action: <img src="your_icon_url.png" alt="Action Icon" /> },
    { type: "Email", date: "11 Jul, 2022 09:25:48", title: "Product Survey", description: "Share your feedback with us", reached: 345678, status: "Success", action: <img src="your_icon_url.png" alt="Action Icon" /> },

  ];





  const columns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ value }) => <button className={`${value}_tag`}>{value}</button>,
      }, { Header: "Date", accessor: "date" },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value }) => <td className="code">{value}</td>,
      },

      { Header: "Description", accessor: "description" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <div className="status_wrapper">
            <button className={`${value}_tag`}>{ value === 'Error' ? <img src={Error} alt="" /> : 
            <img src={Success} alt="" />
            } {value}</button>
          </div>
        ),
      },
      { Header: "Reached To", accessor: "reached" },
      {
        Header: "Action", accessor: "action",
        Cell: ({ value }) => <img src={ActionIcon} alt="" />,
      },
 
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="table_wrapper">
      <div className="table_header header_filter">
        <div className="search">
          <div className="search_bar">
            <input type="text" className="search_input" placeholder="Search..." />
            <img className="search_icon" src={Search} alt="search icon" />
          </div>
          <img src={FilterIcon} alt='filter' />
        </div>

      </div>
      <div className="table_scroll">
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table> </div>
    </div>
  );
};
