import React, { useState } from "react";
import "./style.scss";
import Card from "../../../reusableComponents/Card";
import { useTable, usePagination } from "react-table";

import CalenderIcon from "../../../assets/icons/calender.svg";
import DropdownIcon from "../../../assets/icons/dropdown-arrow-gray.svg";
import Profile from "../../../assets/icons/profile.svg";
import Download from "../../../assets/icons/download.svg";
import Tooltip from "@mui/material/Tooltip";

import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import moment from "moment";
import { convertTimestamp } from "../../../utils/string";

function TradeHistory({ tableData }) {
  console.log(tableData, "tableDatatableData");
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      marginTop: "4px",
      height: 48,
      borderRadius: 8,
      border: "none",
      boxShadow: "none",
      paddingRight: "20px",
      fontFamily: "DM Sans , sans-serif",
      fontSize: "14px",
      fontWeight: 700,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
  };

  return (
    <Card className="transactionhistory">
      <div className="transactionhistory_header">
        <h3>Trade History</h3>

        <div className="select_wrapper">
          {/* <img className="select_icon" src={CalenderIcon} alt="calender icon" /> */}
        </div>
      </div>
      <div className="table_wrapper">
        <Table tableData={tableData || []} />
      </div>
    </Card>
  );
}

export default TradeHistory;

const Table = ({ tableData }) => {
  const data = tableData;

  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: () => (
  //         <>
  //           <div className="column_header">
  //             Action
  //             <img src={DropdownIcon} alt="arrow-down" />
  //           </div>
  //         </>
  //       ),
  //       accessor: "action",
  //       Cell: ({ value }) => (
  //         <div className="column_one_wrapper">
  //           <div className={value === "Buy" ? "profit" : "loss"}>{value}</div>
  //         </div>
  //       ),
  //     },
  //     {
  //       Header: "Entry",
  //       accessor: "entry",
  //       Cell: ({ value }) => <div>{value || "NA"}</div>,
  //     },
  //     // {
  //     //   Header: () => (
  //     //     <>
  //     //       <div className="column_header">
  //     //         Deal
  //     //         <img src={DropdownIcon} alt="arrow-down" />
  //     //       </div>
  //     //     </>
  //     //   ),
  //     //   accessor: "deal",
  //     // },
  //     {
  //       Header: () => (
  //         <>
  //           <div className="column_header">
  //           Profit/Loss <img src={DropdownIcon} alt="arrow-down" />
  //           </div>
  //         </>
  //       ),
  //       accessor: "profit",
  //       Cell: ({ value }) => (
  //         <div className={value < 0 ? "loss" : "profit"}>{value}</div>
  //       ),
  //     },
  //     {
  //       Header: () => (
  //         <>
  //           <div className="column_header">
  //             Commission <img src={DropdownIcon} alt="arrow-down" />
  //           </div>
  //         </>
  //       ),
  //       accessor: "commission",
  //       Cell: ({ value }) => (
  //         <div className={value < 0 ? "loss" : "profit"}>{value}</div>
  //       ),
  //     },
  //     {
  //       Header: () => (
  //         <>
  //           <div className="column_header">
  //             Order <img src={DropdownIcon} alt="arrow-down" />
  //           </div>
  //         </>
  //       ),
  //       accessor: "order",
  //     },
  //     {
  //       Header: () => (
  //         <>
  //           <div className="column_header">
  //             Position <img src={DropdownIcon} alt="arrow-down" />
  //           </div>
  //         </>
  //       ),
  //       accessor: "position",
  //     },

  //     {
  //       Header: "Symbol",
  //       accessor: "symbol",
  //     },
  //     {
  //       Header: "Price",
  //       accessor: "price",
  //     },
  //     {
  //       Header: "Swap",
  //       accessor: "swap",
  //     },
  //     {
  //       Header: (
  //         <Tooltip title="SymbolContractSize" placement="top" arrow>
  //           <span>S B C</span>
  //         </Tooltip>
  //       ),
  //       accessor: "symbolBaseCurrency",
  //     },
  //     {
  //       Header: (
  //         <Tooltip title="SymbolQuoteCurrency" placement="top" arrow>
  //           <span>S Q C</span>
  //         </Tooltip>
  //       ),
  //       accessor: "symbolQuoteCurrency",
  //     },
  //     {
  //       Header: (
  //         <Tooltip title="SymbolContractSize" placement="top" arrow>
  //           <span>S C S</span>
  //         </Tooltip>
  //       ),
  //       accessor: "symbolContractSize",
  //     },
  //     {
  //       Header: "Login Id",
  //       accessor: "login",
  //     },
  //     {
  //       Header: "Date",
  //       accessor: "date",
  //       Cell: ({ value, cell }) => (
  //         <div className="column_data">
  //           {convertTimestamp(cell.row.original.date)}
  //         </div>
  //       ),
  //     },
  //     // {
  //     //   Header: () => (
  //     //     <>
  //     //       <div className="column_header">
  //     //         Status <img src={DropdownIcon} alt="arrow-down" />
  //     //       </div>
  //     //     </>
  //     //   ),
  //     //   accessor: "Status",
  //     //   Cell: (params) => (
  //     //     <div className="column_data">
  //     //       <span
  //     //         className={`${
  //     //           params.value === "In Progress"
  //     //             ? "status-pending"
  //     //             : params.value === "Error"
  //     //             ? "status-error"
  //     //             : "status-successful"
  //     //         }`}
  //     //       >
  //     //         {params.value}
  //     //       </span>
  //     //     </div>
  //     //   ),
  //     // },
  //   ],
  //   []
  // );

  const columns = React.useMemo(
    () => [
      {
        Header: "Instrument",
        accessor: "instrument",
      },
      // {
      //   Header: "Open Time (Milliseconds)",
      //   accessor: "openMilliseconds",
      // },
      {
        Header: "Open Date/Time",
        accessor: "openDateTime",
        Cell: ({ value }) => <div>{moment(value).format("DD-MM-YY")}</div>,
      },
      {
        Header: "Order Type",
        accessor: "orderType",
      },
      {
        Header: "Position Side",
        accessor: "positionSide",
        Cell: ({ value }) => (
          <div className={value === "Buy" ? "profit" : "loss"}>{value}</div>
        ),
      },
      {
        Header: "Close Amount",
        accessor: "closeAmount",
      },
      {
        Header: "Average Open Price",
        accessor: "averageOpenPrice",
      },
      {
        Header: "Close Price",
        accessor: "closePrice",
      },
      // {
      //   Header: "Close Time (Milliseconds)",
      //   accessor: "closeMilliseconds",
      // },
      {
        Header: "Close Date/Time",
        accessor: "closeDateTime",
        Cell: ({ value }) => <div>{moment(value).format("DD-MM-YY")}</div>,
      },
      {
        Header: "Open Amount",
        accessor: "openAmount",
      },
      // {
      //   Header: "Close Trade ID",
      //   accessor: "closeTradeId",
      // },
      // {
      //   Header: "Open Trade ID",
      //   accessor: "openTradeId",
      // },
      // {
      //   Header: "Close Order ID",
      //   accessor: "closeOrderId",
      // },
      // {
      //   Header: "Position ID",
      //   accessor: "positionId",
      // },
      // {
      //   Header: "Open Order ID",
      //   accessor: "openOrderId",
      // },
      // {
      //   Header: "Strategy ID",
      //   accessor: "strategyId",
      // },
      // {
      //   Header: "Stop Loss Price",
      //   accessor: "slPrice",
      // },
      // {
      //   Header: "Stop Loss Order Type",
      //   accessor: "slOrderType",
      // },
      // {
      //   Header: "Stop Loss Trailing Offset",
      //   accessor: "slTrailingOffset",
      // },
      // {
      //   Header: "Take Profit Price",
      //   accessor: "tpPrice",
      // },
      {
        Header: "Commission",
        accessor: "commission",
        Cell: ({ value }) => (
          <div className={value > 0 ? "profit" : "loss"}>{value}</div>
        ),
      },
      {
        Header: "Swap",
        accessor: "swap",
      },
      {
        Header: "Profit",
        accessor: "profit",
        Cell: ({ value }) => (
          <div className={value > 0 ? "profit" : "loss"}>{value}</div>
        ),
      },
      {
        Header: "Net Profit",
        accessor: "netProfit",
        Cell: ({ value }) => (
          <div className={value > 0 ? "profit" : "loss"}>{value}</div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    pageOptions,
    state,
    pageIndex,
    rows,
    prepareRow,
  } = useTable({ columns, data }, usePagination);

  const [currentPage, setCurrentPage] = useState(1);
  const pageButtons = [];

  for (let i = 1; i <= pageCount; i++) {
    pageButtons.push(
      <button
        key={i}
        className={`${i === currentPage ? "button-active" : "button-inactive"}`}
        onClick={() => {
          gotoPage(i - 1);
          setCurrentPage(i);
        }}
      >
        {i < 10 ? `0${i}` : i}
      </button>
    );
  }

  return (
    <>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <img src={CaretLeftIcon} alt="" />
        <button
          onClick={() => {
            previousPage();
            setCurrentPage(currentPage - 1);
          }}
          disabled={!canPreviousPage}
        >
          Previous
        </button>
        {pageButtons}
        <button
          onClick={() => {
            nextPage();
            setCurrentPage(currentPage + 1);
          }}
          disabled={!canNextPage}
        >
          Next
        </button>
        <img src={CaretRightIcon} alt="" />
      </div>
    </>
  );
};
