import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import CameraIcon from "../../assets/icons/camera.svg";
import CaretIcon from "../../assets/icons/caret-down.svg";

export default function Tabination({
  variant = 1,
  tabItems,
  activeTab,
  style,
  tab,
  tabStyle,
  radioButton = false,
  onTabChange = false,
  isChart = false,
  isToolbar = false,
  bg = false,
  setActiveTab,
  resetClickEvent = () => {},
}) {
  const [items, setItems] = useState(tabItems);
  const [active, setActive] = useState(
    typeof activeTab === "number" ? activeTab : 0
  );
  const [none, setNone] = useState([1, 2, 3]);

  const [chartTimeline, setChartTimeline] = useState("1M");
  function handleTabMobile(e, idx) {
   
    // navigate(e);
    setActive(idx);
    resetClickEvent(false);
    setNone(none);
    if (tab === true) {
      localStorage.setItem("activeTab", JSON.stringify(tabItems[idx]));
    }
  }
  function handleTab(e, idx) {
    setActive(idx);
    {
      setActiveTab && setActiveTab(idx);
    }
    resetClickEvent(false);
    if (tab === true) {
      localStorage.setItem("activeTab", JSON.stringify(tabItems[idx]));
    }
  }
  useEffect(() => {
    const handleTabChange = (activeTab) => {
      setActive(activeTab);
      resetClickEvent(false);
    };
    handleTabChange(activeTab);
  }, [onTabChange]);

  // const location = useLocation();

  return (
    <>
      {variant === 1 && (
        <div className="tabination-container">
          <div className="tabination_subcontainer_wrapper">
            <ul style={style} className="tabination-subcontainer">
              {items.map((item, index) => {
                return (
                  <li
                    style={tabStyle}
                    key={index}
                    onClick={(e) => handleTab(e, index)}
                    className={index === active ? "tabination-toggle" : ""}
                  >
                    {radioButton ? (
                      <>
                        <input
                          type="radio"
                          id={`tab${index}`}
                          checked={index === active}
                          onChange={() => {}}
                        />
                        <label htmlFor={`tab${index}`}>{item["name"]}</label>
                      </>
                    ) : (
                      item["name"]
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="tabination_wrapper">{items[active]?.content}</div>
        </div>
      )}

      {variant === 2 && (
        <div className="tabination-container_variant2">
          <div style={style} className="tabination-subcontainer">
            <div>
              <ul className="tabination-container_toggle">
                {items.map((item, index) => {
                  return (
                    <li
                      style={tabStyle}
                      key={index}
                      onClick={(e) => handleTab(e, index)}
                      className={index === active ? "tabination-toggle" : ""}
                    >
                      {item["name"]}
                    </li>
                  );
                })}
              </ul>
              {isChart ? (
                <ul className="tabination-container_chart_toggle">
                  {/* {["1M", "5M", "1D", "1W", "1MO"].map((item, index) => (
                    <li
                      className={
                        chartTimeline === item ? "chartToggle_active" : ""
                      }
                      key={index}
                      id={item}
                      onClick={() => setChartTimeline(item)}
                    >
                      {item}
                    </li>
                  ))} */}
                </ul>
              ) : (
                <></>
              )}
            </div>
            {isToolbar ? (
              <div className="tabination-container_toolbar">
                {/* <div>
                  <p>Lots of</p>
                  <img src={CaretIcon} alt="camera" />
                </div>
                <img src={CameraIcon} alt="camera" /> */}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="tabination_wrapper">{items[active]?.content}</div>
        </div>
      )}

      {variant === 3 && (
        <div className="tabination-container variant_3">
          <div className="tabination_subcontainer_wrapper">
            <ul style={style} className="tabination-subcontainer">
              {items.map((item, index) => {
                return (
                  <li
                    style={tabStyle}
                    key={index}
                    onClick={(e) => handleTab(e, index)}
                  >
                    <Link to={item.path}>
                      <span
                        className={index === active ? "tabination-toggle" : ""}
                      >
                        {item["name"]}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="tabination_wrapper">{items[active]?.content}</div>
        </div>
      )}

      {/* {variant === 3 ? (
        <div className="tabination-container variant_3">
          <div className="tabination_subcontainer_wrapper">
            <div className="tabination-subcontainer">
              <ul className="tabination-container_toggle">
                {items.map((item, index) => {
                  return (
                    <li
                      style={tabStyle}
                      key={index}
                      onClick={(e) => handleTab(e, index)}
                      className={
                        location.pathname.includes(item.path)
                          ? "tabination-toggle active"
                          : ""
                      }
                    >
                      <Link to={item.path}>{item["name"]}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="tabination_wrapper">{items[active]?.content}</div>
        </div>
      ) : (
        ""
      )} */}

      {variant === 4 ? (
        <div className="tabination-container variant_4">
          <ul
            style={style}
            className={`tabination-subcontainer ${bg ? "bg_white" : ""}`}
          >
            {items.map((item, index) => {
              return (
                <li
                  style={tabStyle}
                  key={index}
                  onClick={(e) => handleTab(e, index)}
                  className={index === active ? "active" : ""}
                >
                  {radioButton ? (
                    <>
                      <input
                        type="radio"
                        id={`tab${index}`}
                        checked={index === active}
                        onChange={() => {}}
                      />
                      <label htmlFor={`tab${index}`}>{item["name"]}</label>
                    </>
                  ) : (
                    item["name"]
                  )}
                </li>
              );
            })}
          </ul>
          <div className="tabination_wrapper">{items[active]?.content}</div>
        </div>
      ) : (
        ""
      )}
      {variant === 5 && (
        <div className="tabination-container variant_5">
          <div className="tabination_subcontainer_wrapper">
            <ul
              style={style}
              className="tabination-subcontainer tabination_normal_5"
            >
              {items?.map((item, index) => (
                <li
                  style={tabStyle}
                  key={index}
                  onClick={(e) => handleTabMobile(e, index)}
                >
                  <Link to={item.path}>
                    <span
                      className={index === active ? "tabination-toggle" : ""}
                    >
                      {item.name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
            <select
              name="tabs"
              id="tabs"
              className="tabination_mobile"
              onChange={(e) => {
                const [path, index] = e.target.value.split(",");
                handleTab(path, index);
              }}
            >
              {items?.map((item, index) => (
                <option
                  className="options"
                  key={index}
                  value={[item.path, index].join(",")}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="tabination_wrapper">{items[active]?.content}</div>
        </div>
      )}
    </>
  );
}
