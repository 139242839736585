import React, { useState } from 'react'
import './style.scss'
import LaptopSignin from '../../../assets/images/laptop-signin.png'
import DropdownIcon from '../../../assets/icons/dropdown-arrow-gray.svg'
import { useTable } from 'react-table'
import RequestPayout from './RequestPayout'
import InvoiceIcon from '../../../assets/icons/download.svg'

function Payout() {

    const [isReqPayout , setReqPayout] = useState(false)

    return (
        <>
        {!isReqPayout ? 
        <div className='payout'>
            <div className='payout_upper'>
                <div className='grid_container'>
                    <div className='col1'>
                        <div className='col1_content'>
                            <h4>Ready to request Your Payout</h4>
                            <p>Please click on the request payout button and proceed to fill out the needed information; our staff will contact you for further developments.
                            </p>
                            <p>Note:  Please wait for at least 15mins after you close your latest trade to request a payout.</p>
                        </div>
                        <div className='col1_btn'>
                            <button onClick={() => setReqPayout(!isReqPayout)}>Request Payout</button>
                        </div>
                    </div>
                    <div className='col2'>
                        <img src={LaptopSignin} alt='' />
                        <div className='col2_content'>
                        <h3>
                            February 2022 <br></br>
                              Trading Competition 
                            </h3>
                        <button>Enter Competition</button>
                    </div>
                    </div>
                </div> </div>
                <div className='payout_lower'>
                <div className=' table_wrapper'>
                    <div className='table_header'>
                        <p>Cases</p>
                    </div>
                    <Table />
                </div>                </div>
        </div> : <RequestPayout isReqPayout={isReqPayout} setReqPayout={setReqPayout} /> }  </>
    )
}

export default Payout



const Table = () => {

  
    const data = React.useMemo(
        () => [
          { id: '#12236', payout: 'Type 1', requestedOn: '2023-07-01', method: 'Method 1', amount: '$100', prizeWon: 'Prize 1', status: 'In Progress', invoice: 'Invoice 1' },
          { id: '#12237', payout: 'Type 2', requestedOn: '2023-07-02', method: 'Method 2', amount: '$200', prizeWon: 'Prize 2', status: 'Completed', invoice: 'Invoice 2' },
          { id: '#12238', payout: 'Type 3', requestedOn: '2023-07-03', method: 'Method 3', amount: '$300', prizeWon: 'Prize 3', status: 'In Progress', invoice: 'Invoice 3' },
          { id: '#12239', payout: 'Type 4', requestedOn: '2023-07-04', method: 'Method 4', amount: '$400', prizeWon: 'Prize 4', status: 'In Progress', invoice: 'Invoice 4' },
          { id: '#12240', payout: 'Type 5', requestedOn: '2023-07-05', method: 'Method 5', amount: '$500', prizeWon: 'Prize 5', status: 'Completed', invoice: 'Invoice 5' },
          { id: '#12241', payout: 'Type 6', requestedOn: '2023-07-06', method: 'Method 6', amount: '$600', prizeWon: 'Prize 6', status: 'In Progress', invoice: 'Invoice 6' },
          { id: '#12242', payout: 'Type 7', requestedOn: '2023-07-07', method: 'Method 7', amount: '$700', prizeWon: 'Prize 7', status: 'Completed', invoice: 'Invoice 7' },
          { id: '#12243', payout: 'Type 8', requestedOn: '2023-07-08', method: 'Method 8', amount: '$800', prizeWon: 'Prize 8', status: 'In Progress', invoice: 'Invoice 8' },
          { id: '#12244', payout: 'Type 9', requestedOn: '2023-07-09', method: 'Method 9', amount: '$900', prizeWon: 'Prize 9', status: 'Completed', invoice: 'Invoice 9' },
          { id: '#12245', payout: 'Type 10', requestedOn: '2023-07-10', method: 'Method 10', amount: '$1000', prizeWon: 'Prize 10', status: 'In Progress', invoice: 'Invoice 10' },
        ],
        []
      );
      
  
    const columns = React.useMemo(
        () => [
          { Header: '#', accessor: 'id' },
          {
            Header: <>
              <div className='column_header'>
                Payout Type <img src={DropdownIcon} alt='arrow-down' />
              </div>
            </>,
            accessor: 'payout',
          },
          {
            Header: <>
              <div className='column_header'>
                Requested On <img src={DropdownIcon} alt='arrow-down' />
              </div>
            </>,
            accessor: 'requestedOn',
          },
          {
            Header: <>
              <div className='column_header'>
                Method <img src={DropdownIcon} alt='arrow-down' />
              </div>
            </>,
            accessor: 'method',
          },
          {
            Header: <>
              <div className='column_header'>
                Amount <img src={DropdownIcon} alt='arrow-down' />
              </div>
            </>,
            accessor: 'amount',
          },
          {
            Header: 'Prize Won',
            accessor: 'prizeWon',
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: () => <button className='progress_tag'>In Progress</button>,
          },
          {
            Header: 'Invoice',
            accessor: 'invoice',
            Cell: () => <img src={InvoiceIcon} alt='download icon' />,
          },
        ],
      );
      
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data });
  
    return (
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };