import React, { useState, useMemo, Fragment, useEffect, useRef } from "react";
import "./style.scss";
import { useTable, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";

import Card from "../../reusableComponents/Card";
import { ReactComponent as DropdownIcon } from "../../assets/icons/dropdown-arrow-gray.svg";
import StarBlueIcon from "../../assets/icons/star-blue.svg";
import StarGreyIcon from "../../assets/icons/star-grey.svg";
import { ReactComponent as SearchNew } from "../../assets/icons/search-svgrepo-com (1).svg";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import AddSupportModal from "../../components/Support/Stage1pass/AddSupportModal";
import {
  deleteSupport,
  getSupportData,
} from "../../store/reducers/supportSlice";
import Edit from "../../assets/icons/edit.svg";
import Delete from "../../assets/icons/delete.svg";
import { getSupportTableDetails } from "../../utils/api/apis";
import { setIsLoading } from "../../store/reducers/authSlice";
import Select from "react-select";
import { returnErrors } from "../../store/reducers/error";
import { formatText } from "../../utils/string";
import { TruncateString } from "../../utils/helpers/string";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
// import { editSupportCases } from "../../utils/api/apis";

function Support({ onTabChange }) {
  const supportRedux = useSelector((state) => state.support.support);
  const [supportCase, setSupportCase] = useState(supportRedux || []);
  const idToken = useSelector((state) => state.auth.idToken);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // onTabChange(1);
    navigate("/support-center/create-case");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    setSupportCase(supportRedux);
  }, [supportRedux]);

  useEffect(() => {
    dispatch(getSupportData(idToken));
    window.scrollTo(0, 0);
  }, []);

  // action states and handlers

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [deleteConfirmationModalContent, setDeleteConfirmationModalContent] =
    useState(null);

  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleAddClick = (rowData) => {
    setSelectedRowData(rowData);
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  const handleAddModalSave = (newSupportData) => {
    //
    const data = new FormData();
    handleAddModalClose();
  };
  // Server side pagination logic
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTraders, setTotalTraders] = useState(0);
  const [allTraders, setAllTraders] = useState([]);
  const [type, setType] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState(null);

  const typeOptions = [
    { label: "All", value: null },
    { label: "Trade Related", value: "trade_related" },
    { label: "Feedback", value: "feedback" },
    { label: "General", value: "general" },
    { label: "Technical Support", value: "technical_support" },
  ];
  const severityOptions = [
    { label: "All", value: null },
    { label: "Low", value: "low" },
    { label: "High", value: "high" },
    { label: "Medium", value: "medium" },
    { label: "Critical", value: "critical" },
  ];
  const statusOptions = [
    { label: "All", value: null },
    { label: "Resolved", value: "Resolved" },
    { label: "In Progress", value: "in_progress" },
    { label: "Dismissed", value: "Dismissed" },
  ];

  const addPadding = (tableData, pageNumber) => {
    const padding = [];
    for (let index = 0; index < (pageNumber - 1) * 50; index++) {
      padding.push({
        attachment: "",
        case_type: "",
        category: "",
        comment: "",
        created: "",
        description: "",
        id: "",
        login_id: "",
        severity: "",
        status: "",
        subject: "",
        updated: "",
        user: "",
        user_email: "",
      });
    }

    return [...padding, ...tableData];
  };
  const handlePagination = async (pageNumber) => {
    // setCurrentPage(pageNumber)
    if (pageNumber !== currentPage) {
      // let searchValue = search;
      try {
        setAllTraders([]);
        const data = await getSupportTableDetails(idToken, pageNumber, type);
        setAllTraders(addPadding(data.results, pageNumber));
        setCurrentPage(pageNumber);
      } catch (error) {
        console.error("Error fetching traders:", error);
      }
    }
  };
  const fetchData = async () => {
    dispatch(setIsLoading(true));
    try {
      const data = await getSupportTableDetails(
        idToken,
        search ? 1 : currentPage || 1,
        type,
        severity,
        status,
        search
      );
      setTotalTraders(data.count);
      setAllTraders(addPadding(data.results, currentPage));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(returnErrors("Something went wrong", 400));
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [severity, type, status, search]);

  const handleCopyToClipboard = (text) => {
    toast("Copied email", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: "success",
      theme: "dark",
      className: "custom-toast-container",
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <>
            <div className="column_header">
              Case ID <DropdownIcon />
            </div>
          </>
        ),
        accessor: "id",
      },
      {
        Header: (
          <>
            <div className="column_header">
              User <DropdownIcon />
            </div>
          </>
        ),
        accessor: "user_email",
        Cell: ({ value }) => {
          const [randomColor, setRandomColor] = useState();
          const getRandomColor = `#${Math.floor(
            Math.random() * 16777215
          ).toString(16)}`;
          if (!randomColor) {
            setRandomColor(getRandomColor);
          }

          return (
            <>
              <div className="column_one_wrapper">
                <div
                  className="sno_wrapper"
                  style={{ backgroundColor: randomColor }}
                >
                  {value.charAt(0).toUpperCase()}
                </div>
                <div>{value}</div>
                <CopyToClipboard onCopy={handleCopyToClipboard} text={value}>
                  {/* <button> */}
                  <p
                    className="table_copy_button"
                    style={{
                      marginRight: "20px",
                      // backgroundColor: "#35d269",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleCopyToClipboard(row.original?.payment_id)}
                  >
                    {/* <ContentCopyIcon sx={{fontSize: "20px", backgroundColor: "#35d269", color: "black"}} /> */}
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#FFF" />
                      <path
                        d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                        stroke="#36D66B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                  {/* </button> */}
                </CopyToClipboard>
              </div>
            </>
          );
        },
      },
      {
        Header: (
          <>
            <div className="column_header">
              Login id <DropdownIcon />
            </div>
          </>
        ),
        accessor: "login_id",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Subject <DropdownIcon />
            </div>
          </>
        ),
        accessor: "subject",
        Cell: ({ value }) => {
          return value ? TruncateString(value, 40) : "-";
        },
      },

      {
        Header: (
          <>
            <div className="column_header">
              Created <DropdownIcon />
            </div>
          </>
        ),
        accessor: "created",
        Cell: ({ cell, row }) => (
          <div>{moment(cell.row.original.created).format("ll")}</div>
        ),
      },

      {
        Header: (
          <>
            <div className="column_header">
              Case type <DropdownIcon />
            </div>
          </>
        ),

        accessor: "case_type",
        Cell: ({ row }) => {
          const originalCaseType = row.original.case_type;

          if (originalCaseType) {
            return <span>{formatText(originalCaseType)}</span>;
          }

          return <span>N/A</span>;
        },
      },
      {
        Header: (
          <>
            <div className="column_header">
              Severity <DropdownIcon />
            </div>
          </>
        ),
        accessor: "severity",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Actions",
        accessor: "action",
      },
    ],
    []
  );

  const data = supportCase?.results || [];

  const inputRef = useRef(null);

  const handleResetClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setSearch(null);
    }
  };

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  return (
    <Card className="economiccalender-container">
      <div className="economiccalender-container-wrapper">
        <div className="economiccalender-subcontainer">
          <h2 className="table-head">Cases</h2>
          <div
            className="economiccalender-innercontainer selected-row-header"
            style={{ flexDirection: "column" }}
          >
            <form
              style={{ margin: "20px 0 0 10px" }}
              className="search_container"
              onSubmit={(e) => {
                e.preventDefault();
                setSearch(searchValue);
              }}
            >
              <input
                type="text"
                placeholder="Search..."
                onChange={handleSearchChange}
              />
              <button type="submit">
                <div style={{ width: "20px", padding: "3px 10px 0 0" }}>
                  <SearchNew style={{ width: "100%" }} />
                </div>
              </button>
            </form>
            <div className="sort_buttons">
              <div className="mobile_dropDowns">
                <Select
                  placeholder="Type"
                  classNamePrefix="react-select"
                  options={typeOptions}
                  value={type}
                  onChange={(selectedOption) =>
                    selectedOption.value === null
                      ? setType(null)
                      : setType(selectedOption)
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <div
                  className="newTabs_main"
                  style={{ display: "flex", gap: "10px" }}
                >
                  <div
                    className="newTabs desktop_pills"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "6px",
                      width: "100%",
                    }}
                  >
                    <span>Options</span>
                    <div
                      className="tabContainer"
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {typeOptions.map((tab, index) => (
                        <div
                          key={index}
                          className={
                            type && type.label === tab.label
                              ? "active"
                              : "normalbtn"
                          }
                          onClick={() => setType(tab)}
                        >
                          {tab.label}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="line" style={{ height: "64px" }}></div>
                  <div className="mobile_dropDowns">
                    <Select
                      placeholder="Severity"
                      classNamePrefix="react-select"
                      options={severityOptions}
                      value={severity}
                      onChange={(selectedOption) =>
                        selectedOption.value === null
                          ? setSeverity(null)
                          : setSeverity(selectedOption)
                      }
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "6px",
                      width: "100%",
                    }}
                    className="desktop_pills"
                  >
                    <span>Severity</span>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                      className=""
                    >
                      {severityOptions.map((tab, index) => (
                        <div
                          key={index}
                          className={
                            severity && severity.label === tab.label
                              ? "active"
                              : "normalbtn"
                          }
                          onClick={() => setSeverity(tab)}
                        >
                          {tab.label}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobile_dropDowns">
                <Select
                  placeholder="Status"
                  classNamePrefix="react-select"
                  options={statusOptions}
                  value={status}
                  onChange={(selectedOption) =>
                    selectedOption.value === null
                      ? setStatus(null)
                      : setStatus(selectedOption)
                  }
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "6px",
                  width: "100%",
                }}
                className="desktop_pills"
              >
                <span>Status</span>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {statusOptions.map((tab, index) => (
                    <div
                      key={index}
                      className={
                        status && status.label === tab.label
                          ? "active"
                          : "normalbtn"
                      }
                      onClick={() => setStatus(tab)}
                    >
                      {tab.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="economiccalender-container_table">
            {isAddModalOpen && (
              <AddSupportModal
                isOpen={isAddModalOpen}
                onClose={handleAddModalClose}
                onSave={handleAddModalSave}
                columns={columns}
                setIsAddModalOpen={setIsAddModalOpen}
                selectedRowData={selectedRowData}
              />
            )}
            {isDeleteConfirmationModalOpen && (
              <DeleteModal
                isModalOpen={isDeleteConfirmationModalOpen}
                closeModal={() => setDeleteConfirmationModalOpen(false)}
                deleteContent={deleteConfirmationModalContent}
                idToken={idToken}
              />
            )}
            {
              <Table
                handleAddClick={handleAddClick}
                columns={columns}
                // tableData={data}
                setSelectedRow={setSelectedRow}
                selectedRow={selectedRow}
                setDeleteConfirmationModalContent={
                  setDeleteConfirmationModalContent
                }
                setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen}
                tableData={allTraders || []}
                handlePagination={handlePagination}
                currentPage={currentPage - 1}
                totalTraders={totalTraders}
                fetchData={fetchData}
              />
            }
          </div>
        </div>
      </div>
    </Card>
  );
}

export default Support;

function Table({
  columns,

  setSelectedRow,
  selectedRow,
  handleAddClick,
  setDeleteConfirmationModalContent,
  setDeleteConfirmationModalOpen,
  tableData,
  handlePagination,
  currentPage,
  totalTraders,
  fetchData,
}) {
  const data = tableData;
  const handleDeleteAction = (row) => {
    setDeleteConfirmationModalContent(row);
    setDeleteConfirmationModalOpen(true);
  };
  const initialState = useMemo(
    () => [
      {
        pageSize: 10,
        pageIndex: 0,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: currentPage, pageSize: 50 },
    },
    usePagination
  );
  const totalPages = Math.ceil(totalTraders / pageSize);

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  // edit
  const idToken = useSelector((state) => state.auth.idToken);

  const [editData, setEditData] = useState({
    id: 32,
    subject: "",
    category: "",
    severity: "",
    comment: "",
    attachment: null,
  });

  const handleEdit = (e) => {
    setEditData(e.target.value);
    // editSupportCases(idToken, editData);
  };
  const handleChange = (e, value) => {
    handlePagination(value - 1);
    // setCurrentPage(value);
  };

  const [gotoPageInput, setGotoPageInput] = useState();

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      gotoPage(pageNumber);
      handlePagination(pageNumber);
      setGotoPageInput("");
    }
  };
  return (
    <>
      <div className="table_scroll">
        <table {...getTableProps()} className="">
          <thead>
            {headerGroups.map((headerGroup, headerIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerIndex}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    {...(column.getHeaderProps().key === "header_status"
                      ? { ...column.getHeaderProps() }
                      : {
                          ...column
                            .getHeaderProps
                            // column.getSortByToggleProps()
                            (),
                        })}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort down"
                          : "sort up"
                        : ""
                    }
                    key={columnIndex}
                  >
                    <span>{column.render("Header")} </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={rowIndex}>
                  <tr
                    {...row.getRowProps()}
                    className={`economiccalender-row ${
                      selectedRow === rowIndex
                        ? "economiccalender-row-active"
                        : ""
                    } `}
                    onClick={() => setSelectedRow(rowIndex)}
                  >
                    {row?.cells?.map((cell, cellIndex) => (
                      <td {...cell.getCellProps()} key={cellIndex}>
                        {cell.column.id === "status" ? (
                          <>
                            {cell.row.values.status === "in_progress" ? (
                              <div
                                className="status-container"
                                style={{
                                  backgroundColor: "#fdeeb4",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  padding: "6px 16px",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  color: "rgb(255, 196, 61)",
                                  borderRadius: "4px",
                                  textTransform: "capitalize",
                                  maxWidth: "100px",
                                  textWrap: "nowrap",
                                }}
                              >
                                In Progress
                              </div>
                            ) : cell.row.values.status === "resolved" ? (
                              <div
                                className="status-container"
                                style={{
                                  backgroundColor: "rgba(200, 233, 197, 0.5)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  padding: "6px 16px",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  color: "#4cb944",
                                  borderRadius: "4px",
                                  textTransform: "capitalize",
                                  maxWidth: "100px",
                                }}
                              >
                                Resolved
                              </div>
                            ) : cell.row.values.status === "dismissed" ? (
                              <div
                                className="status-container"
                                style={{
                                  backgroundColor: "#f5bdc0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  padding: "6px 16px",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  color: "#9e1d26",
                                  borderRadius: "4px",
                                  textTransform: "capitalize",
                                  maxWidth: "100px",
                                }}
                              >
                                Dismissed
                              </div>
                            ) : (
                              cell.row.values.status === 3 && (
                                <div
                                  className="status-container"
                                  style={{
                                    color: "#F25F5C",
                                  }}
                                >
                                  Error
                                </div>
                              )
                            )}
                          </>
                        ) : cell.column.id === "importance" ? (
                          <Importance activeStars={cell.value} />
                        ) : cell.column.id === "created" ? (
                          <div>{moment(cell.value).format("DD MMM YYYY")}</div>
                        ) : cell.column.id === "action" ? (
                          <div style={{ display: "flex", gap: "10px" }}>
                            {" "}
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => handleAddClick(row.original)}
                              src={Edit}
                              alt=""
                            />
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDeleteAction(row.original)}
                              src={Delete}
                              alt=""
                            />
                          </div>
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                  {selectedRow === rowIndex && (
                    <tr>
                      <td colSpan="7" className="row-selected">
                        <div className="supportcases-td-container">
                          <div className="td-main-div">
                            <div className="header_main">
                              <h3 className="row-head">
                                {row.original.subject}
                              </h3>
                              {/* <button className="edit_button">Edit</button>
                              <button
                                className="edit_button"
                                onClick={handleEdit}
                              >
                                Update
                              </button> */}
                            </div>
                            <div className="supportcases-type-details">
                              <div className="supportcases-box">
                                <p>Case ID</p>
                                <h4>{row.values.id}</h4>
                              </div>
                              <div className="supportcases-box">
                                <p>Case Type</p>
                                <h4>{formatText(row.values.case_type)}</h4>
                              </div>
                              <div className="supportcases-box">
                                <p>Status</p>
                                <h4>
                                  {row.values.status === "in_progress" ? (
                                    <div
                                      className="status-container"
                                      style={{
                                        backgroundColor: "#fdeeb4",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                        padding: "6px 16px",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        color: "rgb(255, 196, 61)",
                                        borderRadius: "4px",
                                        textTransform: "capitalize",
                                        maxWidth: "100px",
                                      }}
                                    >
                                      In Progress
                                    </div>
                                  ) : row.values.status === "resolved" ? (
                                    <div
                                      className="status-container"
                                      style={{
                                        backgroundColor:
                                          "rgba(200, 233, 197, 0.5)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                        padding: "6px 16px",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        color: "#4cb944",
                                        borderRadius: "4px",
                                        textTransform: "capitalize",
                                        maxWidth: "100px",
                                      }}
                                    >
                                      Resolved
                                    </div>
                                  ) : (
                                    row.values.status === "dismissed" && (
                                      <div
                                        className="status-container"
                                        style={{
                                          backgroundColor: "#f5bdc0",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          gap: "10px",
                                          padding: "6px 16px",
                                          fontSize: "12px",
                                          fontWeight: 500,
                                          color: "#9e1d26",
                                          borderRadius: "4px",
                                          textTransform: "capitalize",
                                          maxWidth: "100px",
                                        }}
                                      >
                                        Dismissed
                                      </div>
                                    )
                                  )}
                                </h4>
                              </div>
                              {/* <div className="supportcases-box">
                                <p>Opened by</p>
                                <h4>Abhishek</h4>
                              </div> */}
                            </div>
                            <div className="supportcases-comments">
                              <p>Comments</p>
                              <h4>{row.original.comment}</h4>
                            </div>
                            <div className="supportcases-comments">
                              <p>Description</p>
                              <h4>{row.original.description}</h4>
                            </div>
                            <div className="supportcases-category">
                              <div className="category-details">
                                <p>Created</p>
                                <h5>
                                  {moment(row.original.created).format(
                                    "DD MMM YYYY"
                                  )}
                                </h5>
                              </div>
                              <div className="category-details">
                                <p>Subject</p>
                                <h4>{row.original.category}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {tableData.length <= 0 && (
        <h2
          style={{ textAlign: "center", marginTop: "20px", color: "#36d66b" }}
        >
          No tickets
        </h2>
      )}
      {/* <div className="pagination">
        <img src={CaretLeftIcon} alt="" />
        <button
          onClick={() => {
            if (currentPage === 0) {
              return;
            } else {
              handlePagination(currentPage);
            }
          }}
          disabled={!canPreviousPage}
        >
          Previous
        </button>
        <span style={{ display: "flex", gap: "15px", fontWeight: "100" }}>
          {pageNumbers.map((pageNumber, idx) => (
            <strong key={pageNumber}>
              {pageIndex + 1 === pageNumber ? (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: "900",
                  }}
                  // onClick={() => handlePagination(pageNumber)}
                >
                  {pageNumber}
                </span>
              ) : (
                <div
                  onClick={() => {
                    gotoPage(pageNumber - 1);
                    handlePagination(pageNumber);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: "25",
                    padding: "4px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    cursor: "pointer",
                  }}
                >
                  {pageNumber}
                </div>
              )}
            </strong>
          ))}
        </span>
        <button
          onClick={() => {
            if (currentPage + 1 === totalPages) {
              return;
            } else {
              handlePagination(currentPage + 2);
            }
          }}
          disabled={!canNextPage}
        >
          Next
        </button>
        <img src={CaretRightIcon} alt="" />
      </div> */}
      <div className="spacerT30p"></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "20px 0px",
        }}
      >
        <Pagination
          onChange={handleChange}
          count={totalPages}
          variant="outlined"
          page={currentPage + 1}
          color="primary"
        />
        <div className="goto-page-input">
          <input
            type="number"
            placeholder="Go to Page"
            value={gotoPageInput}
            onChange={(e) => setGotoPageInput(e.target.value)}
          />
          <button className="goToButton" onClick={handleGotoPage}>
            Go
          </button>
        </div>
      </div>
    </>
  );
}

function Importance({ activeStars }) {
  let totalStars = 3;
  let inactiveStars = totalStars - activeStars;
  const stars = [];
  for (let i = 0; i < activeStars; i++) {
    stars?.push(
      <img
        src={StarBlueIcon}
        alt=""
        key={`active${i}`}
        className="importance-star"
      />
    );
  }
  for (let i = 0; i < inactiveStars; i++) {
    stars?.push(
      <img
        src={StarGreyIcon}
        alt=""
        key={`inactive${i}`}
        className="importance-star"
      />
    );
  }
  return <>{stars}</>;
}
const DeleteModal = ({
  isModalOpen,
  closeModal,
  deleteContent,
  onConfirmDelete,
  idToken,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    //
    dispatch(deleteSupport({ idToken: idToken, id: deleteContent.id }));
    closeModal();
  };

  return (
    <div className="confirmationModal_wrapper">
      <h2>Are you sure?</h2>
      <div className="buttons_wrapper">
        <button className="confirm" onClick={handleDelete}>
          Confirm
        </button>
        <button className="cancel" onClick={closeModal}>
          No
        </button>
      </div>
    </div>
  );
};
