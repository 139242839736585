import React from 'react'
import './style.scss'
import Createemail from '../../components/CreateEmail'

function CreateEmail() {
  return (
    <div className='create_email'>
        <Createemail />
    </div>
  )
}

export default CreateEmail