import React, { Fragment, useEffect, useRef, useState } from "react";
import "./style.scss";
import { useTable } from "react-table";
import download from "../../../assets/icons/download.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteCoupon } from "../../../store/reducers/couponSlice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  generatePayoutRequest,
  generatePayoutRequestNew,
  getPayoutListRequest,
} from "../../../utils/api/apis";
import { returnErrors } from "../../../store/reducers/error";
import { setIsLoading } from "../../../store/reducers/authSlice";
import moment from "moment";
import Select from "react-select";
import Modal from "@mui/material/Modal";
import { ReactComponent as SearchNew } from "../../../assets/icons/search-svgrepo-com (1).svg";

import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Tooltip,
} from "@mui/material";
import { returnMessages } from "../../../store/reducers/message";
import ConfirmationModal from "../../../pages/FundingEvaluation/ConfirmationModal";
import "./style.scss";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import {
  setActiveAccount,
  setActiveEmail,
  setActiveUser,
} from "../../../store/reducers/accountSlice";

function Withdraw() {
  const handleNavigateToOverView = async (value) => {
    // dispatch(setIsLoading(true));
  };

  const [searchedUser, setSearchUser] = useState([]);
  const [activeTab, setactiveTab] = useState();
  const [payoutType, setPayoutType] = useState(null);
  const [searchDropDown, setSearchDropDown] = useState(false);
  const [search, setSearch] = useState(null);
  console.log("search", search);
  const dropdownRef = useRef(null);
  // const methods = [
  //   { label: "All", value: null },
  //   { label: "DEEL", value: "DEEL" },
  //   { label: "ACH", value: "ACH" },
  //   { label: "WIRE", value: "WIRE" },
  //   { label: "SWIFT", value: "SWIFT" },
  //   { label: "PAYPAL", value: "PAYPAL" },
  // ];
  const methods = [
    { label: "All", value: null },
    { label: "New", value: "New" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "rejected" },
    { label: "Pending", value: "pending" },
  ];

  const [method, setMethod] = useState(methods[0]);
  const [status, setStatus] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const inputRef = useRef(null);
  const handleResetClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setSearch(null);
    }
  };

  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [deleteConfirmationModalContent, setDeleteConfirmationModalContent] =
    useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [payout, setPayout] = useState({
    amount: "",
    payout_type: "PFS",
    method: "",
  });
  console.log(payout, "payoutpayout");
  const payoutTypes = [
    {
      label: "Funding Account",
      value: "PFS",
    },
    {
      label: "Affiliate Payout",
      value: "AFS",
    },
  ];
  const paymentMethods = [
    { label: "SWIFT", value: "SWIFT" },
    // { label: "PAYPAL", value: "PAYPAL" },
    { label: "USDT TRC20", value: "USDT TRC20" },
  ];
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const handlePayoutReq = async () => {
    const response = await generatePayoutRequestNew(idToken, payout);
    console.log(response, "success message");
    if (response.status < 399) {
      dispatch(returnMessages("Successfully generate payout request", 500));
    } else {
      const error = response.response.data;
      let msg;
      if (Object.keys(error).length > 0) {
        const firstKey = Object.keys(error)[0];
        const firstString = error[firstKey] || error[firstKey][0];

        msg = firstString;
      } else {
        msg = "Something went wrong";
      }
      dispatch(returnErrors(msg, 500));
    }
  };

  console.log("method", method ? true : false);

  return (
    <div className="table_scroll_wrapper">
      <div className="withdraw_table alltable">
        <div className="alltable_header">
          <form
            className="search_container"
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(searchValue);
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
            />
            <button type="submit">
              <div style={{ padding: "3px 10px 0 0" }}>
                <SearchNew style={{ width: "100%" }} />
              </div>
            </button>
          </form>
          <div className="sort_buttons">
            {/* <Select
              placeholder="Status"
              classNamePrefix="react-select"
              options={methods}
              value={method}
              onChange={(selectedOption) => {
                selectedOption.value === null
                  ? setMethod(null)
                  : setMethod(selectedOption);
              }}
            /> */}
            <div style={{ flex: "1", display: "flex", gap: "10px" }}>
              {methods.map((item) => (
                <button
                  onClick={() =>
                    setMethod({ lable: item.label, value: item.value })
                  }
                  className={method?.value === item?.value ? "activeBtn" : ""}
                >
                  {item.label}
                </button>
              ))}
              <button
                onClick={() => handleOpenModal()}
                // className={method?.value === item?.value ? "activeBtn" : ""}
                style={{
                  border: "1px solid black",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px !important",
                  gap: "20px",
                }}
              >
                <span style={{ textWrap: "nowrap" }}>Generate Payout</span>{" "}
                <span style={{ fontSize: "30px" }}>+</span>
              </button>
            </div>
          </div>
        </div>
        {searchDropDown && (
          <div className="challenge_dropdown" ref={dropdownRef}>
            <ul>
              {searchedUser.map((user) => (
                <li
                  key={user.email}
                  style={{
                    position: "relative",
                    zIndex: "100",
                    backgroundColor: "#111142",
                  }}
                  onClick={() => {
                    handleNavigateToOverView(user.email);
                  }}
                >
                  {user.email}
                </li>
              ))}
            </ul>
          </div>
        )}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="payoutModal">
            <Select
              placeholder="Payout type"
              classNamePrefix="react-select"
              options={payoutTypes}
              // value={method}
              onChange={(selectedOption) => {
                setPayout((pre) => ({
                  ...pre,
                  payout_type: selectedOption.value,
                }));
              }}
            />
            <Select
              placeholder="Payout method"
              classNamePrefix="react-select"
              options={paymentMethods}
              // value={method}
              onChange={(selectedOption) => {
                setPayout((pre) => ({
                  ...pre,
                  method: selectedOption.value,
                }));
              }}
            />
            <TextField
              placeholder="Amount"
              id="outlined-basic"
              variant="outlined"
              size="small"
              type="number"
              onChange={(e) => {
                setPayout((pre) => ({
                  ...pre,
                  amount: e.target.value,
                }));
              }}
            />
            {payout.payout_type === "AFS" ? (
              <TextField
                placeholder="Email Address"
                id="outlined-basic"
                variant="outlined"
                type="text"
                size="small"
                onChange={(e) => {
                  setPayout((pre) => ({
                    ...pre,
                    email: e.target.value,
                  }));
                }}
              />
            ) : (
              <TextField
                placeholder="Login Id"
                id="outlined-basic"
                variant="outlined"
                type="number"
                size="small"
                onChange={(e) => {
                  setPayout((pre) => ({
                    ...pre,
                    login_id: e.target.value,
                  }));
                }}
              />
            )}
            <button
              onClick={() => handlePayoutReq()}
              style={{
                backgroundColor: "var(--secondary-color)",
                border: "1px solid var(--secondary-color)",
                color: "white",
                borderRadius: "10px",
                padding: "10px",
                flex: "1 1",
                margin: "0px",
              }}
            >
              Generate Payout
            </button>
          </div>
        </Modal>

        <div className="table_wrapper">
          <div className="table_scroll_wrapper">
            <Table
              search={search}
              payoutType={payoutType}
              method={method?.value}
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
              setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen}
              setDeleteConfirmationModalContent={
                setDeleteConfirmationModalContent
              }
            />
          </div>
        </div>
      </div>

      {isDeleteConfirmationModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteConfirmationModalOpen}
          closeModal={() => setDeleteConfirmationModalOpen(false)}
          deleteContent={deleteConfirmationModalContent}
        />
      )}
    </div>
  );
}

export default Withdraw;

const Table = ({
  search,
  method,
  payoutType,
  setSelectedRow,
  selectedRow,
  handleAddClick,
}) => {
  const [filter, setFilter] = useState(null);
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  // const coupon = useSelector((state) => state.coupon.coupon);
  const [tableData, setTableData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);

  console.log("method1", method?.length > 0 ? true : false);

  useEffect(() => {
    setCurrentPage(method?.length > 0 ? 1 : currentPage);
  }, [method]);

  const fetch = async () => {
    dispatch(setIsLoading(true));
    // return
    const response = await getPayoutListRequest(
      idToken,
      search,
      method,
      payoutType,
      currentPage
    );

    if (response?.status < 399) {
      setTableData(response?.data?.results);
      const test = Math.ceil(response?.data?.count / 20);
      setTotalPages(Math.ceil(response?.data?.count / 20));
    } else {
      dispatch(returnErrors("Error getting Payout list"));
    }
    dispatch(setIsLoading(false));
  };
  useEffect(() => {
    fetch();
  }, [idToken, filter, search, method, payoutType, currentPage]);

  // const data = tableData;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [code, setCode] = useState();

  const handleDelete = (couponCode) => {
    setCode(couponCode);
  };

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredData =
    filter === "All"
      ? tableData
      : filter === true
      ? tableData.filter((item) => item.is_active === true)
      : tableData.filter((item) => item.is_active === false);
  const handleCopyToClipboard = (text) => {
    toast(`Copied ${text.replace("_", " ")}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: "success",
      theme: "dark",
      className: "custom-toast-container",
    });
  };
  const statusClassMap = {
    new: "Pending_tag",
    succeeded: "Active_tag",
    rejected: "Inactive_tag",
    approved: "Active_tag",
  };

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "UID",
      //   accessor: "uid",
      //   Cell: ({ value }) => (
      //     <td
      //       style={{ maxWidth: "240px", overflow: "hidden" }}
      //       className="code"
      //     >
      //       {value}
      //     </td>
      //   ),
      // },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => {
          const [randomColor, setRandomColor] = useState();
          const getRandomColor = `#${Math.floor(
            Math.random() * 16777215
          ).toString(16)}`;
          if (!randomColor) {
            setRandomColor(getRandomColor);
          }
          const handleCopyToClipboard = (text) => {
            toast("Copied email", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              type: "success",
              theme: "dark",
              className: "custom-toast-container",
            });
          };

          return (
            <>
              <div className="column_one_wrapper">
                <div
                  className="sno_wrapper"
                  style={{ backgroundColor: randomColor }}
                >
                  {value.charAt(0).toUpperCase()}
                </div>
                <div>{value}</div>
                <CopyToClipboard onCopy={handleCopyToClipboard} text={value}>
                  {/* <button> */}
                  <p
                    className="table_copy_button"
                    style={{
                      marginRight: "20px",
                      // backgroundColor: "#35d269",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleCopyToClipboard(row.original?.payment_id)}
                  >
                    {/* <ContentCopyIcon sx={{fontSize: "20px", backgroundColor: "#35d269", color: "black"}} /> */}
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="24" rx="12" fill="#FFF" />
                      <path
                        d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                        stroke="#36D66B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </p>
                  {/* </button> */}
                </CopyToClipboard>
              </div>
            </>
          );
        },
      },
      {
        Header: "Login ID",
        accessor: "login_id",
        Cell: ({ row }) => {
          const handleActiveAccount = (row) => {
            row.original?.login_id
              ? dispatch(setActiveAccount(row.original.login_id))
              : dispatch(setActiveAccount(null));
            dispatch(setActiveUser({ ...row.original }));
          };
          return (
            <Link
              onClick={(e) => {
                e.stopPropagation();
                handleActiveAccount(row);
              }}
              to="/traders-list-2"
            >
              {row.original.login_id}
            </Link>
          );
        },
      },

      {
        Header: "Date Created",
        accessor: "created_at",
        Cell: ({ value }) => (
          <span style={{ paddingRight: "20px" }}>
            {moment(value).format("ll")}
          </span>
        ),
      },
      { Header: "Payout type", accessor: "payout_type" },
      { Header: "Method", accessor: "method" },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <div>${value}</div>,
      },
      {
        Header: "Profit Split",
        accessor: "profit_split",
        Cell: ({ value }) => <div>{value || "-"}</div>,
      },
      // { Header: "User", accessor: "user" },

      // mahendra code

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <span className={`status_wrapper ${statusClassMap[value]}`}>
            {value.toUpperCase()}
          </span>
        ),
      },
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => (
          <>
            {value ? (
              <a target="_blank" href={value}>
                <img src={download} alt="" />
              </a>
            ) : (
              "-"
            )}
          </>
        ),
      },

      {
        Header: "Action",
        accessor: "",
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = React.useState(null);
          const [confirmationModal, setConfirmationModal] =
            React.useState(false);

          const open = Boolean(anchorEl);
          const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
          };
          const handleClose = () => {
            setAnchorEl(null);
          };
          const dispatch = useDispatch();

          const [data, setData] = useState();
          const [email, setEmail] = useState();
          const handleStatus = async (e, status) => {
            setConfirmationModal(true);
            const userDetails = {
              status: status,
            };
            setData({ data: userDetails, id: e.id });
          };
          const handleAction = async (e) => {
            const response = await generatePayoutRequest(idToken, data);
            if (response?.status < 399) {
              let msg = response?.data?.detail || "Successful";
              dispatch(returnMessages(msg, response?.status));
            } else {
              let msg =
                response?.response?.data?.detail || "Something went wrong";
              dispatch(returnErrors(msg, 400));
            }
          };
          const createPayoutReq = () => {
            alert("Create");
          };

          return (
            <div>
              {confirmationModal && (
                <ConfirmationModal
                  // id={selectedCompId}
                  setConfirmationModal={setConfirmationModal}
                  onConfirm={handleAction}
                />
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    minWidth: "150px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {row.original.status === "approved" ||
                row.original.status === "rejected" ? (
                  <>
                    <MenuItem>{"No Options"}</MenuItem>
                  </>
                ) : (
                  <>
                    {" "}
                    <MenuItem
                      onClick={() => handleStatus(row.original, "approved")}
                    >
                      {"Approve Payout"}
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleStatus(row.original, "rejected")}
                    >
                      {"Reject Payout"}
                    </MenuItem>
                  </>
                )}
              </Menu>
            </div>
          );
        },
      },
    ],
    []
  );
  const [inputText, setInputText] = useState("");

  // const filteredDataCoupon = tableData.filter((el) => {
  //
  //   return el.code.toLowerCase().includes(inputText);
  // });
  //

  // useEffect(() => {
  //   setPageNumbers([...Array(totalPages).keys()].map((i) => i + 1));
  // }, [totalPages]);

  // const handlePagination = async (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    pageCount,
    prepareRow,
  } = useTable({ columns, data: tableData });

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const [gotoPageInput, setGotoPageInput] = useState();

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setGotoPageInput("");
    }
  };

  console.log("currentPage", currentPage);
  return (
    <div className="table_wrapper">
      <div style={{ marginTop: "20px" }} className="table_scroll">
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {/* {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      className={`${
                        cell.column.Header === "Status" ? "status_column" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })} */}
            {rows?.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={rowIndex}>
                  <tr
                    {...row.getRowProps()}
                    className={`economiccalender-row ${
                      selectedRow === rowIndex
                        ? "economiccalender-row-active"
                        : ""
                    } `}
                    onClick={() => setSelectedRow(rowIndex)}
                  >
                    {row.cells.map((cell) => (
                      <td
                        className={`${
                          cell.column.Header === "Status" ? "status_column" : ""
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                  {selectedRow === rowIndex && (
                    <tr>
                      <td
                        colSpan="7"
                        className="row-selected"
                        style={{ width: "100%" }}
                      >
                        <div className="supportcases-td-container">
                          <div className="td-main-div">
                            <div className="header_main">
                              <h3 className="row-head">
                                {row.original.subject}
                              </h3>
                            </div>
                            <div className="supportcases-type-details">
                              {Object.keys(
                                row?.original?.settlement_details
                              ).map((key) => (
                                <React.Fragment key={key}>
                                  {key !== "address" &&
                                    key !== "account_name" &&
                                    key !== "account_number" &&
                                    key !== "account_type" && (
                                      <div
                                        className="supportcases-box"
                                        key={key}
                                      >
                                        <p
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {key.replace("_", " ")}
                                        </p>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                          }}
                                        >
                                          <h4>
                                            {row.original.settlement_details[
                                              key
                                            ] || "NA"}
                                          </h4>
                                          <CopyToClipboard
                                            onCopy={() =>
                                              handleCopyToClipboard(key)
                                            }
                                            text={
                                              row.original.settlement_details[
                                                key
                                              ]
                                            }
                                          >
                                            <p
                                              className="table_copy_button"
                                              style={{
                                                marginRight: "20px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  width="24"
                                                  height="24"
                                                  rx="12"
                                                  fill="#FFF"
                                                />
                                                <path
                                                  d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                                                  stroke="#36D66B"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                            </p>
                                          </CopyToClipboard>
                                        </div>
                                      </div>
                                    )}
                                  {console.log(
                                    key,
                                    "street_addressstreet_addressstreet_address"
                                  )}
                                  {key === "address" &&
                                    row?.original?.settlement_details[
                                      "address"
                                    ] && (
                                      <>
                                        <div className="supportcases-box">
                                          {Object?.keys(
                                            row?.original?.settlement_details[
                                              "address"
                                            ]
                                          ).map((item) => (
                                            <>
                                              {item !== "id" && (
                                                <React.Fragment
                                                  key={item}
                                                  style={{ display: "flex" }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "start",
                                                      width: "100%",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                        color: "#8b8e93",
                                                        width: "200px",
                                                      }}
                                                    >
                                                      {item.replace("_", " ")}
                                                    </p>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                      }}
                                                    >
                                                      <h4>
                                                        {row.original
                                                          .settlement_details[
                                                          "address"
                                                        ][item] || "NA"}
                                                      </h4>
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              )}
                                            </>
                                          ))}
                                        </div>
                                      </>
                                    )}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>{" "}
      </div>

      <div className="spacerT30p"></div>
      {rows?.length > 0 ? (
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Pagination
            onChange={handleChange}
            count={totalPages}
            variant="outlined"
            page={currentPage}
            color="primary"
          />
          <div className="goto-page-input">
            <input
              type="number"
              placeholder="Go to Page"
              value={gotoPageInput}
              onChange={(e) => setGotoPageInput(e.target.value)}
            />
            <button className="goToButton" onClick={handleGotoPage}>
              Go
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          No data
        </div>
      )}
    </div>
  );
};

const DeleteModal = ({
  isModalOpen,
  closeModal,
  deleteContent,
  onConfirmDelete,
  idToken,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteCoupon({ idToken: idToken, code: deleteContent }));
    closeModal();
  };

  return (
    <div className="modal">
      <div className="modal_content">
        <p>Are you sure you want to delete?</p>
        <div className="modal_btn">
          <button onClick={handleDelete}>Yes</button>
          <button onClick={closeModal}>No</button>
        </div>
      </div>
    </div>
  );
};
