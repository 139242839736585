import React, { useEffect, useState } from "react";
import "./style.scss";
import Calender from "../../assets/icons/calender.svg";
import FirstPrize from "../../assets/icons/trophy-green.svg";
import TrophyBlue from "../../assets/icons/trophy-blue.svg";
import ThirdPrize from "../../assets/icons/medal-orange.svg";
import OthersPrize from "../../assets/icons/medal-orange.svg";
import UploadIcon from "../../assets/icons/Upload.svg";
import AddCircle from "../../assets/icons/add-circle.svg";
import img1 from "../../assets/images/competition-bg-img.png";
import img2 from "../../assets/images/competition-bg-img2.png";
import img3 from "../../assets/images/carousel-background.jpg";
import {
  createCompData,
  createCompDataSuccess,
  getCompDataSuccess,
  setCompData,
} from "../../store/reducers/compSlice";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getPlansTableData } from "../../store/reducers/plansTableSlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  createAwardRequest,
  getChallengesDetails,
  getCompDetails,
  postCompDetails,
  updateAwardRequest,
} from "../../utils/api/apis";
import CreatePrizeModal from "./CreatePrizeModal";
import { getFundingDataSuccess } from "../../store/reducers/fundingSlice";
import { returnErrors } from "../../store/reducers/error";
import { returnMessages } from "../../store/reducers/message";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
function CreateCompetition() {
  const idToken = useSelector((state) => state.auth.idToken);
  const { id } = useParams();
  const navigate = useNavigate();
  const compData = useSelector((state) => state.comp.compData);
  const fundingData = useSelector((state) => state.funding.fundingData);

  const fetch = async () => {
    const response = await getCompDetails(idToken);
    if (response?.status < 399) {
      dispatch(getCompDataSuccess(response?.data));
    } else {
      dispatch(returnErrors("Failed to fetch competitions list", 400));
    }
  };
  useEffect(() => {
    fetch();
  }, [idToken]);
  const matchingCompetition = compData?.find(
    (competition) => competition.id.toString() === id
  );
  useEffect(() => {
   
    matchingCompetition &&
      setFormState({
        name: matchingCompetition.name,
        challenge: matchingCompetition.challenge,
        start_date: matchingCompetition.start_date,
        end_date: matchingCompetition.end_date,
        close_entry_on: moment(matchingCompetition.close_entry_on).format(
          "YYYY-MM-DD"
        ),
        rules: matchingCompetition.rules,
        total_contestants: matchingCompetition.total_contestants,
        awards: matchingCompetition.awards,
      });

    fundingData.map((item) => {
      item.challenges.map((subitem) => {
        if (subitem.id === matchingCompetition?.challenge) {
          setSelectedCompetition({ label: subitem.name, value: subitem.id });
          setSelectedOption({ label: item.name, value: item.id });

          return;
        }
      });
    });

    // let matchingPlan = fundingData.filter((plan) =>
    //   plan?.challenges?.some(
    //     (challenge) => challenge?.id === matchingCompetition?.challenge
    //   )
    // );
    // setSelectedOption({
    //   value: matchingPlan[0].id,
    //   label: matchingPlan[0].name,
    // });
    // let matchingChallenge = matchingPlan.challenges.filter(
    //   (challenge) => challenge.id === matchingCompetition.challenge
    // );
    // setSelectedCompetition({
    //   value: matchingChallenge[0].id,
    //   label: matchingChallenge[0].name,
    // });
  }, []);

  const apiImages = [{ image: img1 }, { image: img2 }, { image: img3 }];
  const apiRules = ["rules"];

  const [isEditor, setIsEditor] = useState(false);
  const [planOptions, setPlanOptions] = useState([]);
  const [competitonOptions, setCompetitionOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCompetition, setSelectedCompetition] = useState(null);

  const [prizesArray, setPrizesArray] = useState(
    matchingCompetition?.awards || []
  );
  const [fieldError, setfieldError] = useState(false);
  const [prizeIdList, setPrizeIdList] = useState([]);
  const handleFieldError = () => {
    setfieldError(true);
    const timeout = setTimeout(() => {
      setfieldError(false);
    }, 2000);
    return () => clearTimeout(timeout);
  };

  // const [fundingData, setFundingData] = useState([]);
  const [isPrizeModal, setIsPrizeModal] = useState({ open: false, id: null });
  const fetchFundingData = async () => {
    const response = await getChallengesDetails(idToken);
    response.status < 399
      ? dispatch(getFundingDataSuccess(response.data))
      : console.log(response);
  };
  useEffect(() => {
    fetchFundingData();
  }, []);

  useEffect(() => {
    let planArray = [];
    fundingData.map((item) => {
      planArray.push({ value: item.id, label: item.name });
    });
    setPlanOptions(planArray);
  }, [fundingData]);

  useEffect(() => {
    let competitionArray = [];
    fundingData.map((item) => {
      if (selectedOption?.value === item.id) {
        item.challenges.map((item) => {
          competitionArray.push({ value: item.id, label: item.name });
        });
      }
      setCompetitionOptions(competitionArray);
    });
  }, [selectedOption]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      border: "1px solid #eaf0f8;",
      boxShadow: "none",
      fontFamily: "DM Sans, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "transparent",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };
  const handleSelectChange = (selectedOption) => {
   
    setSelectedOption(selectedOption);
    setSelectedCompetition({});
    // setSelectedCompetition(null);
  };
  const handleSelectCompetitionChange = (selectedOption) => {
    setSelectedCompetition(selectedOption);
    setFormState((prev) => ({ ...prev, challenge: selectedOption.value }));
  };
  // useEffect(() => {
  //   setSelectedCompetition({})
  // }, [selectedOption]);
  const [formState, setFormState] = useState({
    name: null,
    challenge: null,
    start_date: null,
    end_date: null,
    close_entry_on: null,
    rules: null,
    total_contestants: null,

    // rules: apiRules.map((rule, index) => ({ id: index, text: rule })),
  });
  const [totalWinners, setTotalWinners] = useState(
    id ? matchingCompetition?.awards?.length : 0
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormState({ ...formState, imageFile: file });
    }
  };
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setFormState({
      ...formState,
      rules: data,
    });
    //
  };

  useEffect(() => {
    dispatch(getPlansTableData(idToken));
  }, [idToken]);

  const plansData = useSelector((state) => state.plansTable.plansTable || []);

  const handleAddRule = () => {
    const newId = formState.rules?.length;
    const newRule = { id: newId, text: "" };
    const updatedRules = [...formState.rules, newRule];
    setFormState({ ...formState, rules: updatedRules });
  };

  const dispatch = useDispatch();
  const handleSubmit = async () => {
    if (matchingCompetition) {
      const updatedData = {
        id: id,
        idToken: idToken,
        data: {
          ...formState,
        },
      };
     
      prizesArray.map((prize, IDX) => {
        const updatedPrize = { award_id: prize.id, title: prize.title };
        let responsePrize = updateAwardRequest(idToken, updatedPrize, dispatch);
        setPrizeIdList((prev) => [...prev, responsePrize?.data?.id]);
      });

      dispatch(setCompData(updatedData));
      // navigate("/competition-list");

      return;
    }
    let compId = null;
    const response = await postCompDetails(idToken, formState);
    if (response.status < 399) {
      dispatch(createCompDataSuccess(response.data));
      compId = response.data.id;
      dispatch(returnMessages("Successfully created the competition", 201));
    } else {
      dispatch(returnErrors("Failed to create the competition", 400));
    }
   
    prizesArray.map(async (prize, IDX) => {
      const updatedPrize = { ...prize, competition: compId };
      let responsePrize = await createAwardRequest(
        idToken,
        updatedPrize,
        dispatch
      );
      setPrizeIdList((prev) => [...prev, responsePrize.data.id]);
    });
    // navigate("/competition-list");
    //

    // setFormState({
    //   name: null,
    // challenge: null,
    // start_date: null,
    // end_date: null,
    // close_entry_on: null,
    // rules: null,
    // total_contestants: null,
    // });
  };
  function addOrdinalSuffix(idx) {
    const j = idx % 10,
      k = idx % 100;
    if (j === 1 && k !== 11) {
      return idx + "st";
    }
    if (j === 2 && k !== 12) {
      return idx + "nd";
    }
    if (j === 3 && k !== 13) {
      return idx + "rd";
    }
    return idx + "th";
  }

  const renderPrizeDetails = () => {
    const prizeComponents = [];

    for (let idx = 0; idx < totalWinners; idx++) {
      const prizeImage =
        idx === 0
          ? FirstPrize
          : idx === 1
          ? TrophyBlue
          : idx === 2
          ? ThirdPrize
          : OthersPrize;

      prizeComponents.push(
        <div key={idx} className="input_wrapper">
          <div className="innerdiv">
            <div
              className={
                idx === 0
                  ? "prize_header1"
                  : idx === 1
                  ? "prize_header2"
                  : idx === 2
                  ? "prize_header3"
                  : "prize_header4"
              }
            >
              <img src={prizeImage} alt={`Prize ${idx + 1}`} />
              <span>{addOrdinalSuffix(idx + 1)} Prize</span>
            </div>
            {/* <Seperator /> */}
            {prizesArray[idx] && <p>{prizesArray[idx]?.title}</p>}
            <div
              className="addbutton"
              onClick={() =>
                selectedOption && selectedCompetition
                  ? setIsPrizeModal({ open: true, id: idx })
                  : handleFieldError()
              }
            >
              {prizesArray[idx] ? <p>Edit Prize</p> : <p>Add Prize</p>}
            </div>
          </div>
          {fieldError && (
            <span className="fieldError">
              Please select Plan type and Competition name
            </span>
          )}
        </div>
      );
    }

    return prizeComponents;
  };

  return (
    <div className="create_competition">
      {
        <CreatePrizeModal
          prizeId={isPrizeModal.id}
          selectedOption={selectedOption}
          selectedCompetition={selectedCompetition}
          isOpen={isPrizeModal.open}
          setOpen={setIsPrizeModal}
          prizesArray={prizesArray}
          setPrizesArray={setPrizesArray}
        />
      }
      <div className="grid_container">
        <div className="col1">
          <div className="col1_top">
            <div className="create_event_wrapper">
              <h4>Create event</h4>
              <div className="event_input_wrapper">
                <label htmlFor="event_name" className="label_black">
                  Event name
                </label>
                <input
                  className="event_name"
                  type="text"
                  name="event_name"
                  value={formState.name}
                  // onfocus="this.showPicker()"
                  onChange={(e) =>
                    setFormState({ ...formState, name: e.target.value })
                  }
                />
                <br />

                {
                  <div className="plan_input_wrapper">
                    <label htmlFor="plan_selector" className="label_black">
                      Plan Type
                    </label>
                    <Select
                      className="plan_selector"
                      options={planOptions}
                      styles={customStyles}
                      value={selectedOption}
                      onChange={handleSelectChange}
                    />
                    <br />
                    <label htmlFor="plan_name" className="label_black">
                      Competition Name
                    </label>
                    <Select
                      className="plan_selector"
                      options={competitonOptions}
                      styles={customStyles}
                      value={selectedCompetition}
                      onChange={handleSelectCompetitionChange}
                    />

                    <br />
                  </div>
                }

                {/* <label htmlFor="event_name" className="label_black">
                  Plan Type
                </label>
                <Select
                  options={
                    Array.isArray(plansData)
                      ? plansData?.map((plan) => ({
                          label: plan.name,
                          value: plan.id,
                        }))
                      : []
                  }
                  onChange={(selectedOption) =>
                    setFormState({
                      ...formState,
                      challenge: selectedOption.value,
                    })
                  }
                />
                <label htmlFor="event_name" className="label_black">
                  Challenge Type
                </label>
                <Select
                  options={
                    Array.isArray(plansData)
                      ? plansData?.map((plan) => ({
                          label: plan.name,
                          value: plan.id,
                        }))
                      : []
                  }
                  onChange={(selectedOption) =>
                    setFormState({
                      ...formState,
                      challenge: selectedOption.value,
                    })
                  }
                />
                <br /> */}
                <label htmlFor="date_wrapper" className="label_black">
                  Date
                </label>

                <div className="date_wrapper">
                  <button className="date_from">
                    <p>Date from</p>
                    <button
                      className=""
                      style={{ border: "none", width: "100%", padding: "0px" }}
                    >
                      <div className="date-container">
                        <img src={Calender} alt="" />
                        <input
                          className="input_date"
                          type="date"
                          name="dateTo"
                          value={formState.start_date}
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              start_date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </button>

                    {/* <div className="container">
                      <img src={Calender} alt="" />
                      <input
                        type="date"
                        className="input_date"
                        value={formState.start_date}
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            start_date: e.target.value,
                          })
                        }
                      />
                    </div> */}
                  </button>

                  <span>
                    <h5>-</h5>
                  </span>
                  <button className="date_to">
                    <p>Date to</p>
                    <button
                      className=""
                      style={{ border: "none", width: "100%", padding: "0px" }}
                    >
                      <div className="date-container">
                        <img src={Calender} alt="" />
                        <input
                          className="input_date"
                          type="date"
                          name="dateTo"
                          value={formState.end_date}
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              end_date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </button>

                    {/* <div className="container">
                      <img src={Calender} alt="" />
                      <input
                        type="date"
                        className="input_date"
                        value={formState.end_date}
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            end_date: e.target.value,
                          })
                        }
                      />
                    </div> */}
                  </button>
                </div>
                <br />
                <label htmlFor="">Close entry on</label>
                <button className="date_to">
                  <button
                    className=""
                    style={{ border: "none", width: "100%", padding: "0px" }}
                  >
                    <div className="date-container">
                      <img src={Calender} alt="" />
                      <input
                        className="input_date"
                        type="date"
                        name="dateTo"
                        value={formState.close_entry_on}
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            close_entry_on: e.target.value,
                          })
                        }
                      />
                    </div>
                  </button>

                  {/* <div className="container">
                    <img src={Calender} alt="" />
                    <input
                      type="date"
                      className="input_date"
                      value={formState.close_entry_on}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          close_entry_on: e.target.value,
                        })
                      }
                    />
                  </div> */}
                </button>
              </div>
            </div>
          </div>
          <div className="col1_middle">
            <div className="constestants_wrapper">
              <h4>Holding Contestants</h4>
              <div className="const_input_wrapper">
                <div className="input_wrapper">
                  How many contestants are you expecting?
                  <div className="box">
                    <input
                      type="text"
                      value={formState.total_contestants}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          total_contestants: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col1_bottom">
            <div className="winners_wrapper">
              <h4>Holding Contestants</h4>
              <div className="winners_input_wrapper">
                <label htmlFor="total_winners" className="label_black">
                  Total Winners
                </label>
                <input
                  className="total_winners"
                  type="text"
                  name="total_winners"
                  value={totalWinners}
                  onChange={(e) => setTotalWinners(e.target.value)}
                />
                <br />
                <label htmlFor="prize_Details_wrapper" className="label_black">
                  Prize Details
                </label>
                <div className="prize_details_wrapper">
                  {renderPrizeDetails()}
                  {/* <div className="input_wrapper">
                    <div className="prize_header2">
                      <img src={TrophyBlue} alt="Second Prize" />
                      <span>2nd Prize</span>
                    </div>

                    <input
                      type="text"
                      value={formState.secondPrize}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          secondPrize: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input_wrapper">
                    <div className="prize_header3">
                      <img src={ThirdPrize} alt="ThirdPrize" />
                      <span>3nd Prize</span>
                    </div>

                    <input
                      type="text"
                      value={formState.thirdPrize}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          thirdPrize: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input_wrapper">
                    <div className="prize_header4">
                      <img src={OthersPrize} alt="OthersPrize" />
                      <span>Others</span>
                    </div>

                    <input
                      type="text"
                      value={formState.othersPrize}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          othersPrize: e.target.value,
                        })
                      }
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col2">
          <div className="col2_top">
            {/* <div className="image_upload_wrapper">
              <h4>Upload Image</h4>
              <div className="image_selector_wrapper">
                <div
                  className="image_input_wrapper"
                  onClick={() => {
                    document.querySelector(".image_input").click();
                  }}
                >
                  <img src={UploadIcon} alt="UploadIcon" />
                  <br />
                  <h4>
                    <span>Click to upload</span> or <br /> drag and drop
                  </h4>
                  <input
                    className="image_input"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
                <div className="previous_images">
                  {apiImages?.map((object, index) => {
                    return (
                      <div className="img" key={index}>
                        <img src={object.image} alt="" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
          </div>
          <div className="col2_bottom">
            <div className="competition_rules_wrapper">
              <h4>Competitions Rules</h4>
              <div className="rules_wrapper">
                <div className="rules_toggle">
                  <h4>What to add competition rules and regulations</h4>
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={() => setIsEditor(!isEditor)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                {isEditor && (
                  <div className="rule_inputs">
                    <CKEditor
                      editor={ClassicEditor}
                      onChange={handleEditorChange}
                    />
                  </div>
                )}
                {/* <div className="rule_inputs">
                  {formState?.rules?.map((rule, index) => (
                    <div key={rule.id} className="rule_input">
                      <input
                        type="text"
                        value={rule.text}
                        onChange={(e) => {
                          const updatedRules = [...formState.rules];
                          updatedRules[index].text = e.target.value;
                          setFormState({ ...formState, rules: updatedRules });
                        }}
                      />
                    </div>
                  ))}
                  <div className="add_rule_button" onClick={handleAddRule}>
                    <img src={AddCircle} alt="" />
                    Add
                  </div>
                </div> */}
              </div>
              <button onClick={handleSubmit}>Create Competition</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCompetition;
