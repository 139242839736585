import React, { useEffect, useState } from "react";
import "./style.scss";
import CompetitionDetailsBox from "../CompetitionDetailsBox";
import FirstPrize from "../../../assets/icons/trophy-green.svg";
import TrophyBlue from "../../../assets/icons/trophy-blue.svg";
import ThirdPrize from "../../../assets/icons/medal-orange.svg";
import bgImg1 from "../../../assets/images/competition-bg-img.png";
import bgImg2 from "../../../assets/images/competition-bg-img2.png";
import { useSelector } from "react-redux";
import moment from "moment";

function Upcoming({ setModalVisible, setErrorModalVisible }) {
  const compDataRedux = useSelector((state) => state.comp.compData);
  const [compData, setCompData] = useState([]);
  const today = moment();
  useEffect(() => {
    setCompData(
      (Array.isArray(compDataRedux) &&
        compDataRedux?.filter(
          (comp) =>
            moment(comp.end_date) > today && moment(comp.start_date) > today
        )) ||
        []
    );
  }, [compDataRedux]);

 

  // const data =
  //   (Array.isArray(compData) &&
  //     compData?.filter(
  //       (comp) =>
  //         moment(comp.end_date) > today && moment(comp.start_date) > today
  //     )) ||
  //   [];
  return (
    <div className="upcoming">
      {compData?.map((competition, index) => (
        <CompetitionDetailsBox
          setErrorModalVisible={setErrorModalVisible}
          setModalVisible={setModalVisible}
          key={index}
          competition={competition}
          status={"Upcoming"}
        />
      ))}
    </div>
  );
}

export default Upcoming;
