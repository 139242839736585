import { all, fork } from "redux-saga/effects";
import authSaga from "./handlers/authSaga";
import userSaga from "./handlers/userSaga";
import fundingSaga from "./handlers/fundingSaga";
import supportSaga from "./handlers/supportSaga";
import affiliateSaga from "./handlers/affiliateSaga";
import couponSaga from "./handlers/couponSaga";
import advSaga from "./handlers/advSaga";
import compSaga from "./handlers/compSaga";
import plansSaga from "./handlers/plansSaga";
import plansTableSaga from "./handlers/plansTableSaga";
import accountSaga from "./handlers/accountSaga";

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(fundingSaga),
    fork(supportSaga),
    fork(affiliateSaga),
    fork(couponSaga),
    fork(advSaga),
    fork(compSaga),
    fork(plansSaga),
    fork(plansTableSaga),
    fork(accountSaga),
  ]);
}
