import { takeEvery, call, put, all } from "redux-saga/effects";
import {
  getPlansDetails,
  postPlansDetails,
  updatePlansDetails,
  deletePlansDetails,
} from "../../../utils/api/apis";
import {
  createPlansDataFailure,
  createPlansDataSuccess,
  deletePlansSuccess,
  getPlansDataFailure,
  getPlansDataSuccess,
  setPlansDataSuccess,
} from "../../reducers/plansSlice";

function* fetchPlansData(action) {
  try {
    const idToken = action.payload;
    const plans = yield call(getPlansDetails, idToken);
   
    yield put(getPlansDataSuccess(plans));
  } catch (error) {
    console.error("Error fetching plans data:", error);
    yield put(getPlansDataFailure(error));
  }
}

function* postPlansData(action) {
  try {
    const { idToken, data } = action.payload;
    const createdData = yield call(postPlansDetails, idToken, data);

    yield put(createPlansDataSuccess(createdData));
  } catch (error) {
    yield put(createPlansDataFailure(error));
    console.error("Error creating plan:", error);
  }
}

function* updatePlansData(action) {
  try {
    const { id, idToken, data } = action.payload;
    const updatedData = yield call(updatePlansDetails, idToken, data, id);
    //
    yield put(setPlansDataSuccess(updatedData));
  } catch (error) {
    console.error("Error updating plan:", error);
  }
}

// function* deletePlansData(action) {
//   try {
//     const { idToken, id } = action.payload;
//     yield call(deletePlansDetails, idToken, id);
//     yield put(deletePlansSuccess(id));
//   } catch (error) {
//     console.error("Error deleting plan:", error);
//   }
// }

export default function* planSaga() {
  yield all([
    takeEvery("plans/getPlansData", fetchPlansData),
    takeEvery("plans/createPlansData", postPlansData),
    takeEvery("plans/setPlansData", updatePlansData),
    // takeEvery("plans/deletePlans", deletePlansData),
  ]);
}
