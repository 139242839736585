import "./style.scss";

function ConfirmationModal({ id, setConfirmationModal, onConfirm }) {
  return (
    <div className="confirmationModal_wrapper">
      <h2>Are you sure?</h2>
      <div className="buttons_wrapper">
        <button
          className="confirm"
          onClick={() => {
            onConfirm(id);
            setConfirmationModal(false);
          }}
        >
          Confirm
        </button>
        <button className="cancel" onClick={() => setConfirmationModal(false)}>
          Cancel
        </button>
      </div>
    </div>
  );
}
export default ConfirmationModal;
