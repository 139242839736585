import React, { useState } from "react";
import "./style.scss";
import AccountDetails from "./AccountDetails";
import AddressDetails from "./AddressDetails";
import PaymentHistoryTable from "./PaymentHistory";
import { useLocation } from "react-router-dom";

function TraderInfo({ data }) {
  const [userDetails, setUserDetails] = useState(data || {});
  
  return (
    <div className="traderinfo">
      <h4>My Profile</h4>
      <div className="traderinfo_top">
        <div className="traderinfo_top_wrapper">
          <AccountDetails userDetails={userDetails} />
        </div>
      </div>

      <div className="traderinfo_middle">{/* <AddressDetails /> */}</div>
      <div className="traderinfo_bottom">
        <PaymentHistoryTable userDetails={userDetails} />
      </div>
    </div>
  );
}

export default TraderInfo;
