import React, { useEffect } from "react";
import bg1 from "../../../assets/images/card-background.jpg";
import img1 from "../../../assets/images/laptop-signin.png";
import bg2 from "../../../assets/images/winner-1-bg.png";
import img2 from "../../../assets/images/competition-bg-img2.png";
import AdDetailsBox from "../AdDetailsBox";

import "./style.scss";
import { useSelector } from "react-redux";

function UpcomingAds() {

  const adv = useSelector(state => state.adv.advData)
  //
  const data = adv || []

useEffect(() => {

} , [data])

  const adDataArray = [
    {
      name: "February 2022 - 200k Challenge",
      date: "01-03-2022",
      heading: " February 2022 Trading Competition",
      background: bg1,
      image: img1,
      status: "Upcoming",
    },
    {
      name: "February 2022 - 200k Challenge",
      date: "01-03-2022",
      heading: " February 2022 Trading Competition",
      background: bg1,
      image: img1,
      status: "Upcoming",
    },
    {
      name: "February 2022 - 200k Challenge",
      date: "01-03-2022",
      heading: " February 2022 Trading Competition",
      background: bg1,
      image: img1,
      status: "Upcoming",
    },
  ];

  const today = new Date();
  const upcomingAds = data.filter((adData) => {
    const startDate = new Date(adData.start_date); 
    return startDate > today;
  });

  return (
    <div className="upcoming_ads">
      {upcomingAds.map((adData, index) => {
        return (
          <AdDetailsBox adData={adData} key={index} />
        );
      })}
    </div>
  );
}

export default UpcomingAds;
