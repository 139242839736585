import { useDispatch, useSelector } from "react-redux";
import {
  CreateTradingAccountReq,
  UserSearchReq,
} from "../../../utils/api/apis";
import "./style.scss";
import Select from "react-select";
import { useEffect, useState } from "react";
import { returnErrors } from "../../../store/reducers/error";
import { returnMessages } from "../../../store/reducers/message";
import { CircularProgress } from "@mui/material";
const CreateTradingAccount = () => {
  const [emailOpts, setEmailOpts] = useState([{ label: "", value: "" }]);
  const [isLoading, setIsLoading] = useState(false);
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();

  // User search by email
  const fetch = async (value) => {
    setIsLoading(true);
    const response = await UserSearchReq(idToken, value);
    setIsLoading(false);
    if (response?.status < 399) {
      let userArray = [];
      response.data.map((item) =>
        userArray.push({
          label: item?.email,
          value: item?.id,
        })
      );

      setEmailOpts(userArray);
    } else {
      if (response.response.data.message) {
        return;
      }
      let msg = response?.response?.data?.detail || "Something went wrong";
      dispatch(returnErrors(msg, 400));
    }
  };

  // create trading account req
  const fundingData = useSelector((state) => state.funding.fundingData);
  const [planOptions, setPlanOptions] = useState([]);
  const [competitonOptions, setCompetitionOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [phase, setPhase] = useState(null);
  const [isSpinner, setIsSpinner] = useState(false);
  const phaseOptions = [
    { label: "Free", value: "free_trial" },
    { label: "Stage 1", value: "stage_1" },
    { label: "Stage 2", value: "stage_2" },
    { label: "Funded", value: "funded" },
  ];
  const brokerOptions = [
    { label: "Match Trader", value: "matchtrade" },
    { label: "Trade Locker", value: "tradelocker" },
  ];
  const [data, setData] = useState({ broker: "matchtrade" });
  const [broker, setBroker] = useState(brokerOptions[0]);

  // use Effects

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedCompetition(null);
  };
  const handleSelectCompetitionChange = (selectedOption) => {
    setSelectedCompetition(selectedOption);
  };
  useEffect(() => {
    let planArray = [];
    fundingData.map((item) => {
      planArray.push({ value: item.id, label: item.name });
    });
    setPlanOptions(planArray);
  }, [fundingData]);

  useEffect(() => {
    let competitionArray = [];
    fundingData.map((item) => {
      if (selectedOption?.value === item.id) {
        item.challenges.map((item) => {
          competitionArray.push({ value: item.id, label: item.name });
        });
      }
      setCompetitionOptions(competitionArray);
    });
  }, [selectedOption]);
  const CreateTradingAccount = async () => {
    setIsSpinner(true);
    const response = await CreateTradingAccountReq(idToken, data);
    if (response?.status < 399) {
      dispatch(returnMessages("Created Account Successfully", 201));
    } else {
      let msg = response?.response?.data?.detail || "Something went wrong";
      dispatch(returnErrors(msg, 400));
    }
    setIsSpinner(false);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      border: "1px solid #eaf0f8;",
      boxShadow: "none",
      fontFamily: "DM Sans, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
      color: "#000",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "#000",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };
  let timeoutId = "";
  const handleOninputChange = (value) => {
    if (typeof value === "string" && value.length > 0) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fetch(value);
      }, 1500);
    }
  };
  return (
    <>
      <div className="create_trading_account">
        <div className="create_header">
          <p>Create Trading Account</p>
        </div>
        <div className="create_trading_account_wrapper">
          <div className="input_form">
            {/* <div className="input_form-top">
              <div className="input_wrapper">
                <label htmlFor="accountName">Account name</label>
                <input
                  type="text"
                  name="accountName"
                  placeholder="account name"
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="password"> Password </label>
                <input type="text" name="password" placeholder="********" />
              </div>
            </div> */}
            <div className="input_form-mid">
              <div className="input_wrapper">
                <label htmlFor="Email">Email</label>
                <Select
                  isLoading={isLoading}
                  placeholder={"Search for a user"}
                  styles={customStyles}
                  options={emailOpts}
                  onInputChange={(value) => handleOninputChange(value)}
                  onChange={(selectedOption) =>
                    setData((prev) => ({ ...prev, user: selectedOption.value }))
                  }
                />
              </div>
            </div>
            <div className="input_wrapper">
              <label htmlFor="">Broker</label>
              <Select
                className="plan_selector"
                options={brokerOptions}
                styles={customStyles}
                value={broker}
                onChange={(selectedOption) => {
                  setData((prev) => ({
                    ...prev,
                    broker: selectedOption.value,
                  }));
                  setBroker(selectedOption);

                  handleSelectCompetitionChange(selectedOption);
                }}
              />
            </div>
            <div className="input_form-btm">
              <div className="input_wrapper">
                <label htmlFor="">Plan Type</label>
                <Select
                  className="plan_selector"
                  options={planOptions}
                  styles={customStyles}
                  value={selectedOption}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="">Account Size</label>
                <Select
                  className="plan_selector"
                  options={competitonOptions}
                  styles={customStyles}
                  value={selectedCompetition}
                  onChange={(selectedOption) => {
                    setData((prev) => ({
                      ...prev,
                      challenge: selectedOption.value,
                    }));

                    handleSelectCompetitionChange(selectedOption);
                  }}
                />
              </div>
            </div>
            <div className="input_form-btm">
              <div className="input_wrapper">
                <label htmlFor="">Phase</label>
                <Select
                  styles={customStyles}
                  placeholder="Phase"
                  classNamePrefix="react-select"
                  options={phaseOptions}
                  value={phase}
                  onChange={(selectedOption) => {
                    selectedOption.value === null
                      ? setPhase(null)
                      : setPhase(selectedOption);

                    setData((prev) => ({
                      ...prev,
                      phase: selectedOption.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div
              onClick={() => CreateTradingAccount()}
              className="view_details_btn"
            >
              {isSpinner ? (
                <CircularProgress color="inherit" />
              ) : (
                "Create Account"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTradingAccount;
