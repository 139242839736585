import React, { useEffect, useState } from "react";
import "./style.scss";
import { useTable } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { ModalPopup } from "../../../layouts/TradeList/AffiliateList";
import { getAffiliateCodelist } from "../../../utils/api/apis";
import { setIsLoading } from "../../../store/reducers/authSlice";
import { returnErrors } from "../../../store/reducers/error";
import { TruncateString } from "../../../utils/helpers/string";
import { Pagination } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { ReactComponent as SearchNew } from "../../../assets/icons/search-svgrepo-com (1).svg";


function AffilateCodes({ userData }) {
  const [userEmail, setUserEmail] = useState(userData?.email || false);

  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const affiliate = useSelector((state) => state?.affiliate?.affiliate || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [affiliateId, setAffiliateId] = useState(null);
  //
  // useEffect(() => {
  //   dispatch(getAffiliateData(idToken));
  // }, [idToken]);
  const openModal = (id) => {
    setAffiliateId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setIsModalOpen(false);
  };
  const handleCopyToClipboard = (text) => {
    toast("Copied email", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: "success",
      theme: "dark",
      className: "custom-toast-container",
    });
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ value }) => <div className="column_wrapper">{value}</div>,
      },
      { Header: "Name", accessor: "name" },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => (
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {value}
            <CopyToClipboard onCopy={handleCopyToClipboard} text={value}>
              {/* <button> */}
              <p
                className="table_copy_button"
                style={{
                  marginRight: "20px",
                  // backgroundColor: "#35d269",
                  cursor: "pointer",
                }}
                // onClick={() => handleCopyToClipboard(row.original?.payment_id)}
              >
                {/* <ContentCopyIcon sx={{fontSize: "20px", backgroundColor: "#35d269", color: "black"}} /> */}
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="12" fill="#FFF" />
                  <path
                    d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                    stroke="#36D66B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
              {/* </button> */}
            </CopyToClipboard>
          </div>
        ),
      },
      {
        Header: "URL",
        accessor: "url",
        Cell: ({ value }) => (
          <a
            href={value}
            target="_blank"
            rel="noreferrer"
            className="tracking_btn"
          >
            {TruncateString(value, 20)}
          </a>
        ),
      },
      { Header: "Commission %", accessor: "percentage" },
      {
        Header: "Discount %",
        accessor: "",
        Cell: ({ row }) => <td>{15 - row.original.percentage}</td>,
      },
      // { Header: "%(Repeat)", accessor: "percentage_repeat" },
      // { Header: "PushLeads", accessor: "pushed_leads" },
      // { Header: "Conversions", accessor: "consumed_by_count" },
      {
        Header: "Referred List",
        accessor: "referredList",
      },
    ],
    []
  );
  const data = affiliate?.data || [];
  return (
    <div className="affiliate_codes">
      <div className="affiliate_table">
        <div className="table_scroll_wrapper">
          {affiliate && (
            <Table
              openModal={openModal}
              columns={columns}
              data={data}
              userEmail={userEmail}
            />
          )}
        </div>
        <ModalPopup
          id={affiliateId}
          open={isModalOpen}
          onClose={closeModal}
          modalContent={modalContent}
          openModal={openModal}
          closeModal={closeModal}
        />
      </div>
    </div>
  );
}

export default AffilateCodes;

const Table = ({ columns, openModal, userEmail }) => {
  const [filter, setFilter] = useState("All");
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const idToken = useSelector((state) => state.auth.idToken);

  const handleFilter = (status) => {
    setFilter(status);
  };
  const [tableData, setTableData] = useState([]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });

  const dispatch = useDispatch();
  let timeoutId;
  const [search, setSearch] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const fetch = async () => {
    dispatch(setIsLoading(true));
    const response = await getAffiliateCodelist(idToken, search, currentPage);
    if (response?.status < 399) {
      setTableData(response.data.results);

      setTotalPages(Math.ceil(response?.data?.count / 21));
    } else {
      dispatch(returnErrors("Error getting payment list"));
    }
    dispatch(setIsLoading(false));
  };
  useEffect(() => {
    fetch();
  }, [idToken, filter, search, currentPage]);

  useEffect(() => {
    setPageNumbers([...Array(totalPages).keys()].map((i) => i + 1));
  }, [totalPages]);

  const handlePagination = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const [gotoPageInput, setGotoPageInput] = useState();

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setGotoPageInput("");
    }
  };

  return (
    <div className="table_scroll">
      <div className="table_wrapper">
        {typeof userEmail !== "string" && (
          <div className="table_header">
          <form
          className="search_container"
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(searchValue);
          }}
        >
          <input
            type="text"
            placeholder="Search..."
            onChange={handleSearchChange}
          />
          <button type="submit">
            <div style={{ width: "20px", padding: "3px 10px 0 0" }}>
              <SearchNew style={{ width: "100%" }} />
            </div>
          </button>
        </form>
          </div>
        )}
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>
                      {cell.column.Header === "Referred List" ? (
                        <button
                          className="view_details_btn"
                          onClick={() => openModal(row.original.id)}
                        >
                          View Referrals
                        </button>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div className="pagination">
          <img src={CaretLeftIcon} alt="" />
          <button
            style={{ color: `${currentPage === 1 ? "#a8a8a8" : "black"}` }}
            onClick={() => {
              if (currentPage === 1) {
                return;
              } else {
                handlePagination(currentPage - 1);
              }
            }}
          >
            Previous
          </button>
          <span style={{ display: "flex", gap: "15px", fontWeight: "100" }}>
            {pageNumbers.map((pageNumber, idx) => (
              <strong key={pageNumber}>
                {currentPage === pageNumber ? (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontWeight: "900",
                    }}
                  >
                    {pageNumber}
                  </span>
                ) : (
                  <div
                    onClick={() => {
                      handlePagination(pageNumber);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontWeight: "20",
                      padding: "4px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      cursor: "pointer",
                    }}
                  >
                    {pageNumber}
                  </div>
                )}
              </strong>
            ))}
          </span>
          <button
            style={{
              color: `${currentPage + 1 > totalPages ? "#a8a8a8" : "black"}`,
            }}
            onClick={() => {
              if (currentPage + 1 > totalPages) {
                return;
              } else {
                handlePagination(currentPage + 1);
              }
            }}
          >
            Next
          </button>
          <img src={CaretRightIcon} alt="" />
        </div> */}
        <div className="spacerT30p"></div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            padding: "20px 0px",
          }}
        >
          <Pagination
            onChange={handleChange}
            count={totalPages}
            variant="outlined"
            page={currentPage}
            color="primary"
          />
          <div className="goto-page-input">
            <input
              type="number"
              placeholder="Go to Page"
              value={gotoPageInput}
              onChange={(e) => setGotoPageInput(e.target.value)}
            />
            <button className="goToButton" onClick={handleGotoPage}>
              Go
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
