import EditIcon from "./icons/editIcon";
import DeleteIcon from "./icons/deleteIcon";
import Info from "../../../assets/icons/Information Circle.svg";
import { useEffect, useRef } from "react";
import bg1 from "../../../assets/images/card-background.jpg";
import img1 from "../../../assets/images/laptop-signin.png";
import "./style.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdv } from "../../../store/reducers/advSlice";
import { deleteAdvDetails } from "../../../utils/api/apis";
import { useNavigate } from "react-router-dom";

function AdDetailsBox({ adData }) {
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [activeColor, setActiveColor] = useState("#36d66b");
  const handleToggleSideMenu = () => {
    if (isSideMenuOpen) {
      setActiveColor("#36d66b");
    } else setActiveColor("#EAF0F8");
    setSideMenuOpen(!isSideMenuOpen);
  };

  const idToken = useSelector((state) => state.auth.idToken);

  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteAdv({ idToken: idToken, Id: adData.id }));
    // deleteAdvDetails(idToken , adData.id).then(() =>
  };

  const navigate = useNavigate();

  const sideMenuOpts = [
    {
      title: "Edit",
      Icon: EditIcon,
      onClick: () => navigate(`/edit-adv/${adData.id}`),
    },
    {
      title: "Delete",
      Icon: DeleteIcon,
      onClick: () => handleDelete(adData.id),
    },
  ];

  const SideMenu = () => (
    <div className="sidemenu">
      <div className="sidemenu_list_container">
        <div className="sidemenu_list">
          {sideMenuOpts.map(({ title, Icon, onClick }, index) => (
            <div key={title} className="option_container" onClick={onClick}>
              <Icon />
              <h5>{title}</h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const Display1 = ({ adData }) => {
    const displayRef = useRef(null);

    useEffect(() => {
      const handleDisplayClick = () => {
       
      };

      displayRef.current.addEventListener("click", handleDisplayClick);

      // return () => {
      //   displayRef.current.removeEventListener("click", handleDisplayClick);
      // };
    }, []);

    return (
      <div className="display1 display" ref={displayRef}>
        <img className="bg_img" src={bg1} alt="" />
        <img className="main_img" src={img1} alt="" />
        <div className="heading">
          {adData.event_name} <br></br>
          {adData.name}
        </div>
        {/* <button className="enter_button">Enter Competition</button> */}
      </div>
    );
  };
  // const Display2 = ({ adData }) => {
  //   return (
  //     <div className="display2 display">
  //       <img className="bg_img" src={adData.background} alt="" />
  //       <img className="main_img" src={adData.image} alt="" />
  //       <div className="heading">{adData.heading}</div>
  //       <button className="enter_button">Enter Competition</button>
  //     </div>
  //   );
  // };

  return (
    <div className="ad_details_box_wrapper">
      <div className="ad_details_box">
        <div className="ad_details_box_upper">
          <div className="ad_details_box_upper_wrapper">
            {/* <div className="header">
              <img src={img1} alt="" className="header_img" />
            </div> */}

            <div
              className="bg_img_container_header"
              style={
                isSideMenuOpen
                  ? { backgroundColor: "#36d66b" }
                  : { backgroundColor: "#EAF0F8" }
              }
              onClick={handleToggleSideMenu}
            >
              <svg
                className="menu"
                width="22"
                height="6"
                viewBox="0 0 22 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Menu Meatballs">
                  <g id="Vector">
                    <path
                      d="M11 1C12.1046 1 13 1.89543 13 3C13 4.10457 12.1046 5 11 5C9.89543 5 9 4.10457 9 3C9 1.89543 9.89543 1 11 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                    <path
                      d="M3 1C4.10457 1 5 1.89543 5 3C5 4.10457 4.10457 5 3 5C1.89543 5 1 4.10457 1 3C1 1.89543 1.89543 1 3 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                    <path
                      d="M19 1C20.1046 1 21 1.89543 21 3C21 4.10457 20.1046 5 19 5C17.8954 5 17 4.10457 17 3C17 1.89543 17.8954 1 19 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                  </g>
                </g>
              </svg>
              {isSideMenuOpen ? <SideMenu /> : ""}
            </div>
          </div>
        </div>
        <div className="ad_details_box_middle">
          <div className="ad_details_box_middle_wrapper">
            <div className="date">
              <h4>
                {adData.start_date}: {adData.end_date}
              </h4>
            </div>
            <div className="display_wrapper">
              {/* display will take a single image from api  */}
              <Display1 adData={adData} />
            </div>
          </div>
        </div>
        {/* <div className="ad_details_box_bottom">
          <div className="ad_details_box_bottom_wrapper">
            <div className="header">
              <span>
                This ad has multiple versions <img src={Info} alt="" />
              </span>
              1 of 3
            </div>
            <div className="display_selector">
              <div className="display_wrapper">
                <Display1 adData={adData} />
                <Display1 adData={adData} />
                <Display1 adData={adData} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default AdDetailsBox;
