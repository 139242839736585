import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import CreatePlanModal from "./CreatePlanModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getFundingDataFailure,
  getFundingDataSuccess,
} from "../../store/reducers/fundingSlice";
import {
  EligibleCertificateAccount,
  UserSearchReq,
  GetCertificatesADMIN,
  getFundingDetails,
  CreateCertificate as CreateCertificateAPI,
  getCertificatesDetails,
} from "../../utils/api/apis";
import { setIsLoading } from "../../store/reducers/authSlice";
import BillingIcon from "../../assets/icons/billing.svg";
import { CircularProgress } from "@mui/material";
import Select from "react-select";
import { returnErrors } from "../../store/reducers/error";
import { returnMessages } from "../../store/reducers/message";
import random from "../../assets/images/background.jpg";
import Pagination from "@mui/material/Pagination";
import { useLocation } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import { ReactComponent as SearchNew } from "../../assets/icons/search-svgrepo-com (1).svg";

import Search from "../../assets/icons/search.svg";
function CertificateManagement() {
  const [isPlanModal, setIsPlanModal] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const location = useLocation();
  const tab = location.state?.tab;
  const [selectedMode, setSelectedMode] = useState(0);
  useEffect(() => {
    setSelectedMode(tab || 0);
  }, [tab]);
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  useEffect(() => {}, [selectedPlanId]);

  useEffect(() => {
    // getData();
  }, []);

  const getData = async () => {
    dispatch(setIsLoading(true));
    const response = await getFundingDetails(idToken);

    if ((response.status = 200)) {
      dispatch(getFundingDataSuccess(response.data));
      dispatch(setIsLoading(false));
    } else {
      dispatch(getFundingDataFailure());
      dispatch(setIsLoading(false));
    }
  };
  return (
    <div className="funding_evaluation">
      <div className="grid_container">
        {/* <div className="col1">
          <CertificateSidebar
            selectedMode={selectedMode}
            setSelectedMode={setSelectedMode}
          />
        </div> */}

        <div className="divs">
          {selectedMode === 0 && (
            <>
              <ViewCertificate />
            </>
          )}

          {selectedMode === 1 && (
            <>
              <CreateCertificate />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CertificateManagement;

function CertificateSidebar({ selectedMode, setSelectedMode }) {
  return (
    <>
      <div
        onClick={() => setSelectedMode(1)}
        className={"cf " + (selectedMode === 1 && "active")}
      >
        <div className="cf_upper">
          <img src={BillingIcon} alt="" />
          View All Certificates
        </div>
      </div>{" "}
      <div
        onClick={() => setSelectedMode(3)}
        className={"cf " + (selectedMode === 3 && "active")}
      >
        <div className="cf_upper">
          <img src={BillingIcon} alt="" />
          Create Certificate
        </div>
      </div>
    </>
  );
}

function CreateCertificate() {
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const [isSpinner, setIsSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailOpts, setEmailOpts] = useState([{ label: "", value: "" }]);
  const [validUsers, setValidUsers] = useState([{ label: "", value: "" }]);
  const [certificateType, setCertificateType] = useState([]);
  console.log(validUsers, "certificateType");
  const [data, setData] = useState({});
  console.log(data, "datadata");
  const [validAccounts, setValidAccounts] = useState({});
  console.log(data, "valid");
  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      border: "1px solid #eaf0f8;",
      boxShadow: "none",
      fontFamily: "DM Sans, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
      color: "#000",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "#000",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };

  // User search by email
  const fetch = async (value) => {
    setIsLoading(true);
    const response = await UserSearchReq(idToken, value);
    setIsLoading(false);
    if (response?.status < 399) {
      let userArray = [];
      response.data.map((item) =>
        userArray.push({
          label: item?.email,
          value: item?.id,
        })
      );

      setEmailOpts(userArray);
    } else {
      if (response?.response?.data?.message) {
        return;
      }
      let msg = response?.response?.data?.detail || "Something went wrong";
      dispatch(returnErrors(msg, 400));
    }
  };

  useEffect(() => {
    setData((prev) => ({ ...prev, login_id: "" }));

    const validLoginID = [];

    if (data.type && certificateType) {
      certificateType.forEach((item) => {
        if (item.label === data.type) {
          item.value.map((value) => {
            validLoginID.push({ value: value, label: value });
          });
        }
      });
      setValidUsers(validLoginID);
    }
    console.log(validLoginID, "validLoginID");
  }, [data.type, certificateType]);

  useEffect(() => {
    const data = Object.keys(validAccounts).map((key) => ({
      value: validAccounts?.[key],
      label: (key =
        key === "step_1"
          ? "1 Step"
          : key === "step_2"
          ? "2 Step"
          : key === "payout"
          ? "Payout"
          : "Funded"),
    }));
    setCertificateType(data);
  }, [validAccounts]);

  //get valid accounts
  const getValidAccounts = async (email) => {
    setIsLoading(true);
    const response = await EligibleCertificateAccount(idToken, email);
    setIsLoading(false);
    if (response?.status < 399) {
      setValidAccounts(response.data);
    } else {
      if (response.response.data.message) {
        return;
      }
      let msg = response?.response?.data?.detail || "Something went wrong";
      dispatch(returnErrors(msg, 400));
    }
  };

  const CreateCertificate = async () => {
    if (!data.type || !data.user || !data.login_id) {
      dispatch(returnErrors("All fields are required", 400));
      return;
    }
    if (data.type === "profit_split" && !data.amount) {
      dispatch(returnErrors("Amount is required", 400));
      return;
    }
    setIsSpinner(true);
    const newData = {
      type:
        data.type === "1 Step"
          ? "1_step"
          : data.type === "2 Step"
          ? "2_step"
          : data.type === "Payout"
          ? "profit_split"
          : "funded",
      user: data.user,
      login_id: data.login_id,
      amount: data.amount,
    };
    const response = await CreateCertificateAPI(idToken, newData);
    if (response?.status < 399) {
      dispatch(returnMessages("Certificate Created", 200));
      setIsSpinner(false);
    } else {
      setIsSpinner(false);
      if (response.response.data.message) {
        return;
      }
      let msg = response?.response?.data?.detail || "Something went wrong";
      dispatch(returnErrors(msg, 400));
    }
  };
  let timeoutId;

  const handleOninputChange = (value) => {
    if (typeof value === "string" && value.length > 0) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fetch(value);
      }, 1500);
    }
  };
  return (
    <>
      <div className="create_trading_account">
        <div className="create_header">
          <p>Create Certificate</p>
        </div>
        <div className="create_trading_account_wrapper">
          <div className="input_form">
            {/* <div className="input_form-top">
              <div className="input_wrapper">
                <label htmlFor="accountName">Account name</label>
                <input
                  type="text"
                  name="accountName"
                  placeholder="account name"
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="password"> Password </label>
                <input type="text" name="password" placeholder="********" />
              </div>
            </div> */}
            <div className="input_form-mid">
              <div className="input_wrapper">
                <label htmlFor="Email">Email</label>
                <Select
                  isLoading={isLoading}
                  placeholder={"Search for a user"}
                  styles={customStyles}
                  options={emailOpts}
                  onInputChange={(value) => handleOninputChange(value)}
                  onChange={(selectedOption) => {
                    setData((prev) => ({
                      ...prev,
                      user: selectedOption.label,
                    }));
                    getValidAccounts(selectedOption.label);
                  }}
                />
              </div>
            </div>

            <div className="input_form-btm">
              <div className="input_wrapper">
                <label htmlFor="">Type</label>
                <Select
                  isLoading={isLoading}
                  placeholder={"Select Certificate Type"}
                  styles={customStyles}
                  options={certificateType}
                  onChange={(selectedOption) => {
                    setData((prev) => ({
                      ...prev,
                      type: selectedOption.label,
                    }));
                  }}
                />
              </div>
              <div className="input_wrapper">
                <label htmlFor="Email">Login ID</label>
                <Select
                  isLoading={isLoading}
                  placeholder={"Select Certificate Type"}
                  styles={customStyles}
                  options={validUsers}
                  // value={data.login_id}
                  // onInputChange={(value) => {
                  //   const valid = typeof value;
                  //   valid === "string" && fetch(value);
                  // }}
                  value={{ label: data.login_id, value: data.login_id }}
                  onChange={(selectedOption) => {
                    setData((prev) => ({
                      ...prev,
                      login_id: selectedOption.value,
                    }));
                  }}
                />
              </div>
            </div>
            {data.type && (
              <div className="input_form-mid">
                <div className="input_wrapper">
                  <label htmlFor="Email">Amount</label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, amount: e.target.value }));
                    }}
                  />
                </div>
              </div>
            )}

            <div
              onClick={() => CreateCertificate()}
              className="view_details_btn"
            >
              {isSpinner ? <CircularProgress color="inherit" /> : "Create"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const ViewCertificate = () => {
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      border: "1px solid #eaf0f8;",
      boxShadow: "none",
      fontFamily: "DM Sans, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "transparent",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };

  //For Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const[refresh,setRefresh] = useState(false)
  const [totalPages, setTotalPages] = useState(1);

  const [certificateList, setCertificateList] = useState([]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    setRefresh(!refresh)
  };
  const inputRef = useRef(null);
  const [search, setSearch] = useState(null);

  const handleResetClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setSearch(null);
    }
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const getData = async () => {
    const response = await getCertificatesDetails(
      idToken,
      currentPage,
      "",
      search,
      searchValue
    );

    setTotalPages(Math.ceil(response?.data?.count / 21)); //21 refers to the page size of api call

    if (response.status === 200) {
      setCertificateList(response.data?.results);
      setSearch(null)
    } else {
    }
  };
  useEffect(() => {
    getData();
  }, [refresh]);

  const [gotoPageInput, setGotoPageInput] = useState();

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setRefresh(!refresh)
      setGotoPageInput("");
    }
  };

  return (
    <div className="viewCertificateCon">
      <div className="viewCertificate-Head">Certificates</div>

      <div style={{ padding: "5px 0" }}>
        <form
          className="search_container"
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(searchValue);
            setRefresh(!refresh)
          }}
        >
          <input
            type="text"
            placeholder="Search..."
            onChange={handleSearchChange}
          />
          <button type="submit">
            <div style={{ padding: "3px 10px 0 0" }}>
              <SearchNew style={{ width: "100%" }} />
            </div>
          </button>
        </form>
      </div>
      <div className="CertificateContainer">
        {certificateList?.map((item, index) => {
          return (
            <CertificateItem
              key={index}
              img={item.file}
              title={item.title}
              loginID={item.login_id}
              createdAt={item.created_at}
              type={item.type}
            />
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "20px 0px",
        }}
      >
        <div className="spacerT30p"></div>
        <Pagination
          onChange={handleChange}
          count={totalPages}
          page={currentPage}
          variant="outlined"
          color="primary"
        />
        <div className="goto-page-input">
          <input
            type="number"
            placeholder="Go to Page"
            value={gotoPageInput}
            onChange={(e) => setGotoPageInput(e.target.value)}
          />
          <button className="goToButton" onClick={handleGotoPage}>
            Go
          </button>
        </div>
      </div>
    </div>
  );

  function CertificateItem({ img, title, loginID, createdAt, type }) {
    return (
      <a
        href={img}
        target="_blank"
        rel="noreferrer"
        className="CertificateItem"
      >
        <img src={img ? img : random} alt="" />

        <div className="Twrapper">
          <div className="tcon">
            <p>Title:</p>
            <span>{title.split(" - ")[1]}</span>
          </div>
          <div className="tcon">
            <p>Login ID:</p>
            <span>{loginID}</span>
          </div>

          <div className="tcon">
            <p>Created At:</p>
            <span>{createdAt}</span>
          </div>

          <div className="tcon">
            <p>Type:</p>
            <span>{type}</span>
          </div>
        </div>
      </a>
    );
  }
};
