import React from "react";
import "./style.scss";
import { Navigate, Route, Routes } from "react-router-dom";

import Tabination from "../../reusableComponents/Tabination";
import Stage1Pass from "../../components/Support/Stage1pass";
import Stage2Pass from "../../components/Support/Stage2pass";

import Withdraw from "../../components/Support/Withdraw";
import StagePass1 from "../../components/SuperAdmin/StagePass1";
import StagePass2 from "../../components/SuperAdmin/StagePass2";
import Support from "../Support";
import Certificate from "../../components/Support/Certificate";
import "./style.scss"
function SuperAdmin() {
  const items = [
    // {
    //   name: "Stage 1 pass",
    //   path: "stagepass1",
    // },
    // {
    //   name: "Stage 2 Pass",
    //   path: "stagepass2",
    // },
    {
      name: "Tickets",
      path: "support",
    },
    {
      name: "Certificates",
      path: "certificates",
    },
    // {
    //   name: "Withdraw Requests",
    //   path: "withdraw-requests",
    // },
  ];

  return (
    <>
      <Tabination variant={3} tabItems={items} activeTab={0} />
      <div className="support_wrapper support">
        <Routes>
          <Route
            path="/"
            element={<Navigate to="support" replace={true} />}
          />
          {/* <Route path="stagepass1" element={<StagePass1 />} /> */}
          {/* <Route
            path="/stagepass1/stagepass2"
            element={<Navigate to="stagepass2" replace={true} />}
          /> */}
          {/* <Route path="stagepass2" element={<StagePass2 />} /> */}

          <Route path="support" element={<Support />} />
          <Route path="certificates" element={<Certificate />} />
          {/* <Route path="withdraw-requests" element={<Withdraw />} /> */}
        </Routes>
      </div>
    </>
  );
}

export default SuperAdmin;
