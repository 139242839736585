import "./style.scss";
import CaretLeftIcon from "../../assets/icons/caret-left.svg";
import Calender from "../../assets/icons/calender.svg";
import AddCircle from "../../assets/icons/add-circle.svg";
import MinusCircle from "../../assets/icons/minus-circle-blue.svg";
import CopyButton from "../../reusableComponents/CopyButton";
import SliderComponent from "../../components/Slider";
import SuccessModal from "../../reusableComponents/SuccessModal";
import ErrorModal from "../../reusableComponents/ErrorModal";
import Select from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useEffect, useState } from "react";
import IncrementDecrementInput from "../../components/Affiliate/CreateCodes/IncrementDecrementInput";
import { useDispatch, useSelector } from "react-redux";
import {
  createCouponData,
  setCouponData,
} from "../../store/reducers/couponSlice";
import { Link, useParams } from "react-router-dom";

function EditCoupon() {
  const [copyToClipboard, setCopyToClipboard] = useState({
    value: "",
    copied: false,
  });
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const coupon = useSelector((state) => state.coupon.coupon);

  const [modalVisible, setModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  //

  const { id } = useParams();

  const matchingCoupon = coupon.find((c) => c.id.toString() === id);
  //

  const [formData, setFormData] = useState({
    code: matchingCoupon.code,
    percent: matchingCoupon.percent,
    users: [],
    couponAmount: matchingCoupon.amount,
    public: matchingCoupon.public,
    is_active: matchingCoupon.is_active,
    expiry: matchingCoupon.expiry,
  });

  useEffect(() => {
    if (id) {
      if (matchingCoupon) {
        setFormData({
          code: matchingCoupon.code,
          percent: matchingCoupon.percent,
          users: [],
          couponAmount: matchingCoupon.amount,
          public: matchingCoupon.public,
          is_active: matchingCoupon.is_active,
          expiry: matchingCoupon.expiry,
        });
      }
    }
  }, [id, coupon, matchingCoupon]);
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    if (type === "checkbox") {
      newValue = checked;
    } else if (name === "users") {
      newValue = value.split(",").map((user) => user.trim());
    }
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleDateChange = (e, name) => {
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    dispatch(
      setCouponData({
        idToken: idToken,
        formData: { ...formData, id: id },
      })
    );

    // setFormData({
    //   couponName: "",
    //   couponCode: "",
    //   couponValue: 0,
    //   users: [],
    //   couponAmount: "",
    //   isPublic: false,
    //   isActive: false,
    //   dateFrom: "12/11/2023",
    //   dateTo: "",
    // });
    //
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,

      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      border: "1px solid #eaf0f8;",
      boxShadow: "none",

      fontFamily: "DM Sans , sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "transparent",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };

  return (
    <div className="create_coupon">
      <div className="create_coupon_top">
        <div className="coupon_wrapper">
          <div className="back_button_wrapper">
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.53522 0.714697C6.19351 0.372988 5.63949 0.372988 5.29779 0.714697L0.631118 5.38136C0.28941 5.72307 0.28941 6.27709 0.631118 6.6188L5.29778 11.2855C5.63949 11.6272 6.19351 11.6272 6.53522 11.2855C6.87693 10.9438 6.87693 10.3897 6.53522 10.048L2.48727 6.00008L6.53522 1.95213C6.87693 1.61042 6.87693 1.05641 6.53522 0.714697Z"
                fill="var(--text-grey)"
              />
            </svg>
            <Link to="/coupons">
              <button>Back</button>
            </Link>
          </div>
          <h2>Edit Coupon Code</h2>
          <div className="coupon_input_wrapper">
            {/* <label htmlFor="couponName" className="label_black">
            Coupon Name
          </label>
          <Select
            className="coupon_name"
            options={options}
            styles={customStyles}
            placeholder="Black Friday"
            name="couponName"
            value={formData.couponName}
            onChange={(selectedOption) =>
              setFormData({ ...formData, couponName: selectedOption })
            }
          /> */}

            <label htmlFor="coupon_code" className="label_black">
              Coupon Code
            </label>
            <div className="coupon_code_wrapper">
              <input
                className="coupon_code"
                type="text"
                name="code"
                value={formData.code}
                onChange={handleInputChange}
              />
              <div>
                <CopyButton moveY={0}>
                  <CopyToClipboard
                    text={formData.code}
                    onCopy={() => setCopyToClipboard({ copied: true })}
                  >
                    <div className="copy_button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14.6 5.27832C15.2365 5.27832 15.847 5.53118 16.2971 5.98126C16.7471 6.43135 17 7.0418 17 7.67832L17 14.6783C17 15.3148 16.7471 15.9253 16.2971 16.3754C15.847 16.8255 15.2365 17.0783 14.6 17.0783L7.4 17.0783C6.76348 17.0783 6.15303 16.8255 5.70295 16.3754C5.25286 15.9253 5 15.3148 5 14.6783L5 7.67832C5 7.0418 5.25286 6.43135 5.70294 5.98127C6.15303 5.53118 6.76348 5.27832 7.4 5.27832L14.6 5.27832ZM15.8 7.67832C15.8 7.36006 15.6736 7.05484 15.4485 6.82979C15.2235 6.60475 14.9183 6.47832 14.6 6.47832L7.4 6.47832C7.08174 6.47832 6.77652 6.60475 6.55147 6.82979C6.32643 7.05484 6.2 7.36006 6.2 7.67832L6.2 14.6783C6.2 14.9966 6.32643 15.3018 6.55147 15.5269C6.77652 15.7519 7.08174 15.8783 7.4 15.8783L14.6 15.8783C14.9183 15.8783 15.2235 15.7519 15.4485 15.5268C15.6736 15.3018 15.8 14.9966 15.8 14.6783L15.8 7.67832Z"
                          fill="white"
                        />
                        <path
                          d="M19.3984 7.0784C19.3984 6.65712 19.2875 6.24327 19.0769 5.87843C18.8662 5.5136 18.5633 5.21064 18.1984 5L18.1984 15.2784C18.1984 16.0741 17.8824 16.8371 17.3198 17.3997C16.7572 17.9623 15.9941 18.2784 15.1984 18.2784L7.72004 18.2784C7.93068 18.6432 8.23364 18.9462 8.59847 19.1568C8.96331 19.3675 9.37716 19.4784 9.79844 19.4784L15.1984 19.4784C16.3123 19.4784 17.3806 19.0359 18.1683 18.2483C18.9559 17.4606 19.3984 16.3923 19.3984 15.2784L19.3984 7.0784Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CopyToClipboard>
                </CopyButton>
              </div>
            </div>

            <br />
            <div className="note_wrapper">
              <p>
                {/* Lorem ipsum dolor sit amet consectetur. Purus risus lacus
              vulputate at. Fringilla arcu quisque pharetra risus vitae eu
              egestas dolor. Id viverra duis ipsum porttitor quis ut. Nunc
              proin ornare fringilla diam. */}
              </p>
              <h3>NOTE: Fixed Commission Income rates for comapny is 15%</h3>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="create_coupon_bottom">
        <div className="coupon_opts_wrapper" style={{display:"block"}}>
          {/* <div className="upper">
          <label for="user_count" className="label_black">
            Users
          </label>
          <input
            type="text"
            className="user_count_input"
            name="users"
            value={
              Array.isArray(formData.users) ? formData.users.join(", ") : ""
            }
            onChange={handleInputChange}
          />
        </div> */}
          <br />
          <div className="middle">
            <div className="wrapper">
              <label for="coupon_value" className="label_black">
                Coupon % - {formData.percent}%
              </label>
              <div className="slider-container">
                <SliderComponent
                  value={formData.percent}
                  handleChange={(e) =>
                    setFormData({
                      ...formData,
                      percent: parseFloat(e.target.value),
                    })
                  }
                  min={0}
                  max={100}
                  step={1}
                />
              </div>
            </div>
            <div className=" wrapper date_wrapper ">
              <button className="date_to">
                <div className="date-container">
                  <img src={Calender} alt="" />
                  <input
                    className="input_date"
                    type="date"
                    name="expiry"
                    value={formData.expiry}
                    onChange={(e) => handleDateChange(e, "expiry")}
                  />
                </div>
              </button>
            </div>
          </div>
          <br />
          <div className="lower">
            <label for="date_wrapper" className="label_black">
              Date
            </label>

            <br />
            <div className="toggle_switches_wrapper">
              <div className="switch_wrapper">
                <label className="switch">
                  <input
                    name="public"
                    type="checkbox"
                    checked={formData.public}
                    onChange={handleInputChange}
                  />
                  <span className="slider round"></span>
                </label>
                <span>Is Public</span>
              </div>

              <div className="switch_wrapper">
                <label className="switch">
                  <input
                    name="is_active"
                    type="checkbox"
                    checked={formData.is_active}
                    onChange={handleInputChange}
                  />
                  <span className="slider round"></span>
                </label>
                <span>Is Active</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="create_coupon_button">
        <button onClick={handleSubmit}>Update</button>
      </div>
      {modalVisible ? (
        <SuccessModal
          setModalOpen={setModalVisible}
          title="Successful!!"
          desc="Coupon Created Successfully"
          btnText="Close"
        />
      ) : (
        ""
      )}

      {errorModalVisible ? (
        <ErrorModal
          setModalOpen={setErrorModalVisible}
          title="Error!!"
          desc="Could'nt create coupon"
          btnText="Close"
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default EditCoupon;
