import React from "react";
import "./style.scss";
import TickIcon from "../../assets/icons/successful-tick.svg";

function SuccessModal({
  setModalOpen,
  title,
  desc,
  btnText,
  handleNavigation,
}) {
  return (
    <div className="success_modal_overlay">
      <div className="success_modal_container">
        <div className="success_modal_container_top">
          <div className="tick_icon">
            <img src={TickIcon} alt="successful tick" />{" "}
          </div>
        </div>

        <div className="success_modal_container_bottom">
          <h1>{title}</h1>
          <p>{desc}</p>
          <button
            className="success_modal_close_btn"
            onClick={() => {
              setModalOpen(false);
              handleNavigation && handleNavigation();
            }}
          >
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
