import React, { useEffect, useState } from 'react'
import './style.scss'
import { useTable } from 'react-table';
import DeleteIcon from '../../../assets/icons/delete.svg'
import EditIcon from "../../../assets/icons/edit.svg";
import { useDispatch, useSelector } from 'react-redux';
import { createPlansTableData, deletePlansTable, setPlansTableData } from '../../../store/reducers/plansTableSlice';

function PlansTable({plans}) {

  const plansTable = useSelector((state => state.plansTable.plansTable))
//  
const data = plansTable || []

useEffect(() => {

} , [data])
const [isAddModalOpen, setAddModalOpen] = useState(false); 
const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
const [deleteConfirmationModalContent, setDeleteConfirmationModalContent] = useState(null);

return (
  <div className='table_scroll_wrapper'>
    <button onClick={() => setAddModalOpen(true)}>
      Add Plan
    </button>
    <Table data={data} setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen} setDeleteConfirmationModalContent={setDeleteConfirmationModalContent} />
    {isAddModalOpen && (
      <AddModalPopup
        isModalOpen={isAddModalOpen}
        closeModal={() => setAddModalOpen(false)}
      />
    )}
    {isDeleteConfirmationModalOpen && (
      <DeleteModal
        isModalOpen={isDeleteConfirmationModalOpen}
        closeModal={() => setDeleteConfirmationModalOpen(false)}
        deleteContent={deleteConfirmationModalContent}
      />
    )}
  </div>
    )
}

export default PlansTable



const Table = ({ data , setDeleteConfirmationModalOpen , setDeleteConfirmationModalContent }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleEdit = (row) => {
        setModalContent(row.original);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleDeleteAction = (id) => {
        setDeleteConfirmationModalContent(id);
        setDeleteConfirmationModalOpen(true);
      };
    
    const columns = React.useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Server",
                accessor: "server",
                Cell: ({ row }) => {
                    const mt5Server = row.original?.mt5_server;
                    const ctraderServer = row.original?.ctrader_server;

                    if (ctraderServer === null) {
                        return "mt5 Server";
                    } else {
                        return "ctrader Server";
                    }
                },
            },
            {
                Header: "",
                accessor: "delete",
                Cell: ({ value, row }) => (
                    <div className='col'>

                        <img style={{ height: '20px' , marginRight:'20px' }} onClick={() => handleEdit(row)}
                            src={EditIcon} alt="" />
                        <img style={{ height: '20px' }} onClick={() => handleDeleteAction(row.original.id)}
                            src={DeleteIcon} alt="" />


                    </div>

                ),
            },

        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    });

    return (
        <div className="table_scroll">
            <table {...getTableProps()} className="plans_table">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>  
            {isModalOpen && (
                <ModalPopup isModalOpen={isModalOpen} modalContent={modalContent} closeModal={closeModal} data={data} />
            )}             </div>
    );
}



const AddModalPopup = ({ isModalOpen, modalContent, closeModal , data }) => {


    const [formData, setFormData] = useState({
        description: '',
        name: '',
        server: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRadioChange = (e) => {
        const server = e.target.value;
        setFormData({
            ...formData,
            server,
        });
    };


    const idToken = useSelector((state) => state.auth.idToken);
    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(
            createPlansTableData({
              idToken,
              data: formData
            }),
      
          );
        //
        closeModal();
    };


 

    return (
        <div className="modal">
            <div className="modal-content">
                <button className="close" onClick={closeModal}>
                    &times;
                </button>
                <h2>Edit Plan</h2>
                <div className='modal_inputs'>
                    <div>
                    <label htmlFor="description">Description:</label>
                    <input
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        className='plan_input'
                    />
                </div>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className='plan_input'
                    />
                </div>
                <div>
                    <label>Server:</label>
                    <input
                        type="radio"
                        id="mt5"
                        name="server"
                        value="mt5"
                        checked={formData.server === "mt5"}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="mt5">MT5 Server</label>
                    <input
                        type="radio"
                        id="ctrader"
                        name="server"
                        value="ctrader"
                        checked={formData.server === "ctrader"}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="ctrader">cTrader Server</label>
                </div>
                </div>
                <button className='save_btn' onClick={handleSubmit}>Save Changes</button>
            </div>
        </div>
    );
};



const ModalPopup = ({ isModalOpen, modalContent, closeModal , data }) => {
    

    const [formData, setFormData] = useState({
        description: modalContent.description,
        name: modalContent.name,
        server: modalContent.ctraderServer ? "ctrader" : "mt5",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRadioChange = (e) => {
        const server = e.target.value;
        setFormData({
            ...formData,
            server,
        });
    };


    const idToken = useSelector((state) => state.auth.idToken);
    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(
            setPlansTableData({
              idToken,
              id: modalContent.id,
              data: formData
            }),
      
          );
        //
        closeModal();
    };


 

    return (
        <div className="modal">
            <div className="modal-content">
                <button className="close" onClick={closeModal}>
                    &times;
                </button>
                <h2>Edit Plan</h2>
                <div className='modal_inputs'>
                    <div>
                    <label htmlFor="description">Description:</label>
                    <input
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        className='plan_input'
                    />
                </div>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className='plan_input'
                    />
                </div>
                <div>
                    <label>Server:</label>
                    <input
                        type="radio"
                        id="mt5"
                        name="server"
                        value="mt5"
                        checked={formData.server === "mt5"}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="mt5">MT5 Server</label>
                    <input
                        type="radio"
                        id="ctrader"
                        name="server"
                        value="ctrader"
                        checked={formData.server === "ctrader"}
                        onChange={handleRadioChange}
                    />
                    <label htmlFor="ctrader">cTrader Server</label>
                </div>
                </div>
                <button className='save_btn' onClick={handleSubmit}>Save Changes</button>
            </div>
        </div>
    );
};


const DeleteModal = ({ isModalOpen, closeModal, deleteContent, onConfirmDelete }) => {
  
    
    const idToken = useSelector(state => state.auth.idToken)
    const dispatch = useDispatch()
    const handleDelete = () => {
        //
       dispatch(deletePlansTable({idToken:idToken , id:deleteContent}))
      closeModal();
    };
  
    return (
      <div className="modal">
        <div className="modal_content">
          <p>Are you sure you want to delete?</p>
          <div className='modal_btn'>
          <button onClick={handleDelete}>Yes</button>
          <button onClick={closeModal}>No</button>
          </div>
        </div>
      </div>
    );
  };
  