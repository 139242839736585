import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { usePagination, useTable } from "react-table";
import TrophyGreen from "../../../../assets/icons/trophy-green.svg";
import DropdownIcon from "../../../../assets/icons/dropdown-arrow-gray.svg";

import CaretDownIcon from "../../../../assets/icons/caret-down.svg";
import CaretLeftIcon from "../../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../../assets/icons/caret-right.svg";
import BlueTickIcon from "../../../../assets/icons/tick-blue-circle.svg";
import CalenderIcon from "../../../../assets/icons/calender.svg";
import InvoiceIcon from "../../../../assets/icons/download.svg";
import { statusClassMap } from "../../../../utils/string";
import Calendar from "../../../../reusableComponents/Calendar";
import Card from "../../../../reusableComponents/Card";
import { getPaymentList } from "../../../../utils/api/apis";
import { useSelector } from "react-redux";
import { TruncateString } from "../../../../utils/helpers/string";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import moment from "moment";
import { Pagination } from "@mui/material";

function PaymentHistoryTable({ userDetails }) {
  const { idToken } = useSelector((state) => state.auth);
  const { email } = userDetails;
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const [tableData, setTableData] = useState([]);
  const fetchData = async () => {
    const res = await getPaymentList(idToken, email);
    
    setTableData(res?.data?.results);
  };
  useEffect(() => {
    fetchData();
  }, [email]);
  return (
    <Card className="paymenthistory_table">
      <div className=" table_wrapper">
        <div className="table_header">
          <p>Payment History</p>
          <div className="select_wrapper">
            {/* <div
              className="calendar-button"
              onClick={() => setIsCalender((prev) => !prev)}
            >
              <img src={CalenderIcon} alt="calender icon" />
              <p>Past 90 Days</p>
              <img src={CaretDownIcon} alt="" />
            </div> */}

            <div className="calendar_wrapper">
              {isCalender && (
                <Calendar
                  onChange={(date) => setDate(date)}
                  value={date}
                  // formatMonth={"MMM"}
                  selectRange={true}
                  showNeighboringMonth={false}
                />
              )}
            </div>
          </div>
        </div>
        <div className="table_scroll_wrapper">
          <Table tableData={tableData} />
        </div>
      </div>
    </Card>
  );
}

export default PaymentHistoryTable;

const Table = ({ tableData }) => {
  const [isFundingDropdown, setFundingDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [isBalanceDropdown, setBalanceDropdown] = useState(false);
  const balanceRef = useRef(null);
  const handleCopyToClipboard = (text) => {
    toast("Copied Transaction Id", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: "success",
      theme: "dark",
      className: "custom-toast-container",
    });
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setFundingDropdown(false);
      }

      if (
        balanceRef.current &&
        !balanceRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setBalanceDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const data = tableData;

  const fundingBackgroundColors = {
    "Aggressive Plan": "aggressive_background",
    "Standard Plan": "standard_background",
    "Swing Plan": "swing_background",
  };

  const columns = React.useMemo(
    () => [
      // {
      //   Header: (
      //     <>
      //       <div
      //         onClick={(e) => {
      //           e.stopPropagation();
      //           setFundingDropdown(!isFundingDropdown);
      //         }}
      //         className="column_header"
      //       >
      //         Funding Evaluation <img src={DropdownIcon} alt="arrow-down" />
      //       </div>
      //       {isFundingDropdown && (
      //         <div
      //           className="table_dropdown funding_dropdown"
      //           ref={dropdownRef}
      //         >
      //           <ul>
      //             <li>
      //               All
      //               <img src={BlueTickIcon} alt="blue tick" />
      //             </li>
      //             <li>Standard Plan</li>
      //             <li>Aggressive Plan</li>
      //             <li>Swing Plan</li>
      //             <li>Instant Plan</li>
      //           </ul>
      //         </div>
      //       )}
      //     </>
      //   ),
      //   accessor: "funding",
      //   Cell: ({ value }) => (
      //     <>
      //       <div className="column_one_wrapper">
      //         <div className={`sno_wrapper  ${fundingBackgroundColors[value]}`}>
      //           {value.charAt(0)}
      //         </div>
      //         <div>{value}</div>
      //       </div>
      //     </>
      //   ),
      // },
      {Header:"Account", 
    accessor:"account"},
      {
        Header: (
          <>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setBalanceDropdown(!isBalanceDropdown);
              }}
              className="column_header"
            >
              {" "}
              Challenge Type <img src={DropdownIcon} alt="arrow-down" />{" "}
            </div>
            {isBalanceDropdown && (
              <div className="table_dropdown balance_dropdown" ref={balanceRef}>
                <ul>
                  <li>
                    All
                    <img src={BlueTickIcon} alt="blue tick" />
                  </li>
                  <li>25K Challenge</li>
                  <li>50K Challenge</li>
                  <li>100K Challenge</li>
                  <li>200K Challenge</li>
                </ul>
              </div>
            )}
          </>
        ),
        accessor: "funding_evaluation",
      },
      {
        Header: "Plan",
        accessor: "plan",
        Cell: ({ value }) => value,
      },
      {
        Header: (
          <>
            <div className="column_header">
              Transaction Id <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "payment_id",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                alignItems: "center",
              }}
            >
              {TruncateString(value, 20)}
              <CopyToClipboard onCopy={handleCopyToClipboard} text={value}>
                {/* <button> */}
                <p
                  className="table_copy_button"
                  style={{
                    marginRight: "20px",
                    // backgroundColor: "#35d269",
                    cursor: "pointer",
                  }}
                  // onClick={() => handleCopyToClipboard(row.original?.payment_id)}
                >
                  {/* <ContentCopyIcon sx={{fontSize: "20px", backgroundColor: "#35d269", color: "black"}} /> */}
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" rx="12" fill="#FFF" />
                    <path
                      d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                      stroke="#36D66B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
                {/* </button> */}
              </CopyToClipboard>
            </div>
          );
        },
      },
      {
        Header: (
          <>
            <div className="column_header">
              Date <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "created_at",
        Cell: ({ value }) => moment(value).format("ll"),
        sortType: "datetime",
      },
      {
        Header: (
          <>
            <div className="column_header">
              Amount <img src={DropdownIcon} alt="arrow-down" />
            </div>
          </>
        ),
        accessor: "amount",
        Cell: ({ value }) => "$" + value,
      },

      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) =>
          value ? (
            <a href={value} target="_blank" rel="noreferrer">
              <img src={InvoiceIcon} alt="download icon" />
            </a>
          ) : (
            "-"
          ),
      },
      {
        Header: "Status",
        accessor: "payment_status",
        Cell: ({ value, row }) => (
          <span className={`status_wrapper ${statusClassMap[value]}`}>
            {value.toUpperCase()}
          </span>
        ),
      },
    ],
    [isFundingDropdown, isBalanceDropdown]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );
  const handleChange = (event, value) => {
    
    gotoPage(value -1);
  };
  const totalPages = Math.ceil(tableData.length / 10);
  return (
    <div className="table_scroll">
      <table {...getTableProps()} className="leaderboard-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="spacerT30p"></div>
      <Pagination
        onChange={handleChange}
        count={totalPages}
        variant="outlined"
        color="primary"
      />
      {/* <div className="pagination">
        <img src={CaretLeftIcon} alt="" />
        <button onClick={previousPage} disabled={!canPreviousPage}>
          Previous
        </button>
        {pageOptions.map((pageNumber, index) => (
          <>
            <button
              key={index}
              onClick={() => gotoPage(index)}
              className={pageIndex === index ? "btn-active" : "non-active"}
            >
              {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
            </button>
          </>
        ))}
        <button onClick={nextPage} disabled={!canNextPage}>
          Next
        </button>
        <img src={CaretRightIcon} alt="" />
      </div> */}
    </div>
  );
};
