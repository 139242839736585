import React from 'react'
import './style.scss'
import Deel from '../../../../assets/images/deel.png'
import WarningIcon from '../../../../assets/icons/warning-blue.svg'

function VerifyNow({openBankDetailsModal}) {

  return (
    <div className='verifynow'>
        <div className='verifynow_container'>
        <div className='verifynow_header'>
        <div>
          <img src={Deel} alt='' />
          <p>Deel</p>
        </div>
        <input type='radio' name='bank-radio' />
        </div>
        <div className='verifynow_details'>
        <h4>One verification platform to secure the whole user journey. Secure every step of the your customer journey</h4>
         <div className='verifynow_details_warning'>
            <div className='verifynow_details_warning_header'><img src={WarningIcon} alt='' /> <p>In order to complete, upload and avoid delays when verifying account. Please make sure below:</p></div>
         <ul className='verify_now_details_warning_list'>
            <li>Chosen credential must not be expired.</li>
            <li>Chosen credential must not be expired.</li>
            <li>Chosen credential must not be expired.</li>

        </ul>
        <button onClick={openBankDetailsModal}>Verify Now</button>
         </div>
   
        </div>
        </div>
    </div>
  )
}

export default VerifyNow