import React, { useEffect, useState } from "react";
import "./style.scss";
import CouponTable from "../../components/Coupons";
import CreateButtonHeader from "../../components/CreateButtonHeader/index";
import LoaderOverlay from "../../components/Loader";
import { useSelector } from "react-redux";

function Coupons() {
  const isLoading = useSelector((state) => state.coupon.isLoading);
  const [loading, setLoading] = useState(isLoading);
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);
  return (
    <div className="coupons_wrapper">
      {loading && <LoaderOverlay />}
      <div className="coupons">
        <CouponTable />
      </div>
      {/* <CreateButtonHeader buttonText="Create Coupons" link={"/create-coupon"} /> */}
    </div>
  );
}

export default Coupons;
