import React from "react";
import "./style.scss";
import Tabination from "../../reusableComponents/Tabination";
import TradingProfit10 from "./TradingProfit10";

function TradingTag() {
  const items = [
    { name: "Trader profit 5% - 9.9% ", content: <TradingProfit10 /> },
    { name: "Trading profit 10%", content: <TradingProfit10 /> },
    { name: "Trader profit 10%+ ", content: <TradingProfit10 /> },
    { name: "Custom Tagged", content: <TradingProfit10 /> },
  ];
  return (
    <>
      <div className="myprofile">
        <div className="trading_stats_tab">
          <Tabination
            activeTab={0}
            variant={1}
            tabItems={items}
            radioButton={true}
          />
        </div>
      </div>
    </>
  );
}

export default TradingTag;
