import React, { useEffect, useState } from "react";
import "./style.scss";
import Select from "react-select";
import ElevatorInput from "../../components/CreatePlan/ElevatorInput";
import AddCircle from "../../assets/icons/add-circle.svg";
import MinusCircle from "../../assets/icons/minus-circle-blue.svg";
import PlanDetailsBox from "../../components/Plans/PlanDetailsBox";
import Card from "../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { createPlansData } from "../../store/reducers/plansSlice";
import { useNavigate } from "react-router-dom";
import SuccessModal from "../../reusableComponents/SuccessModal";
import { getPlansTableData } from "../../store/reducers/plansTableSlice";
import ErrorModal from "../../reusableComponents/ErrorModal";
import { getFundingDetails, postPlansDetails } from "../../utils/api/apis";
import { getFundingDataFailure, getFundingDataSuccess } from "../../store/reducers/fundingSlice";

function CreatePlan({ selectedPlanId }) {
  const plansRedux = useSelector((state) => state.plansTable.plansTable);
  const [plans, setPlans] = useState([]);
 

  const [selectedOption, setSelectedOption] = useState(selectedPlanId || null);
  useEffect(() => {
    setSelectedOption(selectedPlanId);
  }, [selectedPlanId]);
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      border: "1px solid #eaf0f8;",
      boxShadow: "none",
      fontFamily: "DM Sans, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "transparent",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };

  const [formState, setFormState] = useState({
    ea: false,
    hold_over_the_weekend: false,
    inactivity_days: 12,
    initial_withdrawal_delay: 12,
    subsequent_withdrawal_delay: 12,
    leverage: 12,
    max_capital_growth: "0",
    max_daily_loss: "0.00",
    max_days: 10,
    max_loss: "0.00",
    min_trading_days: 10,
    name: "Ultra Pro Challenge",
    price: "1000.00",
    profit_share: "12.00",
    publish: false,
    starting_balance: 100,
    trading_requirements: "Demo",
    tradable_asset: "Demo",
    trade_through_news: false,
    upgrade_delay_hours: 12,
    upgrade_threshold: "21.00",
    liquidate_friday: true,
    require_stoploss: false,
    create_as_disabled: false,
    visible_on_leaderboard: false,
    daily_dd_by_balance_equity: true,
  });
  const [formData, setFormData] = useState({
    ea: false,
    hold_over_the_weekend: false,
    inactivity_days: 12,
    initial_withdrawal_delay: 12,
    subsequent_withdrawal_delay: 12,
    leverage: 12,
    max_capital_growth: "0",
    max_daily_loss: "0.00",
    max_days: 10,
    max_loss: "0.00",
    min_trading_days: 10,
    name: "Ultra Pro Challenge",
    price: "1000.00",
    profit_share: "12.00",
    publish: false,
    starting_balance: 100,
    trading_requirements: "Demo",
    tradable_asset: "Demo",
    trade_through_news: false,
    upgrade_delay_hours: 12,
    upgrade_threshold: "21.00",
    liquidate_friday: true,
    require_stoploss: false,
    create_as_disabled: false,
    visible_on_leaderboard: false,
    daily_dd_by_balance_equity: true,
  });

  const handleInputChange = (name, value) => {
   
    const validatedValue = !isNaN(value) ? parseFloat(value) : value;

    const clampedValue = [
      "max_loss",
      "max_daily_loss",
      "profit_share",
    ].includes(name)
      ? Math.min(validatedValue, 100)
      : validatedValue;
    setFormData({
      ...formData,
      [name]: clampedValue || value,
    });
  };

  const incrementValue = (setter) => {
    setter((prevValue) => prevValue + 1);
  };

  const decrementValue = (setter) => {
    setter((prevValue) => prevValue - 1);
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const navigate = useNavigate();
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const plansErrorRedux = useSelector((state) => state.plans.error);
  const [plansError, setPlansError] = useState(plansErrorRedux);
  //
  useEffect(() => setPlansError(plansErrorRedux), [plansErrorRedux]);

  const handleSubmit = async () => {
    try {
      const response = await postPlansDetails({
        idToken,
        data: { ...formData, plan: selectedOption },
      });
      response.status < 399 && setModalVisible(true);
      !response.status && setErrorModalVisible(true);
      getData()
    } catch (error) {
      setErrorModalVisible(true);
    }
  };

  const getData = async () => {
    const response = await getFundingDetails(idToken)

    if ((response.status = 200)) {
      dispatch(getFundingDataSuccess(response.data))
    } else dispatch(getFundingDataFailure())
  }

  // useEffect(() => {
  //   if (plansError === null) {
  //     return;
  //   }
  //   if (plansError === false) {
  //     setModalVisible(true);
  //   } else {
  //     setErrorModalVisible(true);
  //   }
  // }, [plansError]);
  function formatLabel(label) {
    return label.replace(/_/g, " ");
  }
  useEffect(() => {
    dispatch(getPlansTableData(idToken));
  }, []);
  useEffect(() => {
    setPlans(plansRedux);
  }, [plansRedux]);

  return (
    <div className="create_plan">
      <div className="grid_container">
        <div className="col1">
          <div className="col1_top">
            <div className="create_plan_wrapper">
              <h4>Create Plan</h4>
              {/* <div className="plan_input_wrapper">
                <label htmlFor="plan_selector" className="label_black">
                  Plan Category/Server
                </label>
                <Select
                  className="plan_selector"
                  options={options}
                  styles={customStyles}
                  placeholder={"Select event..."}
                  value={selectedOption}
                  onChange={handleSelectChange}
                />
                <br />
                <label htmlFor="plan_name" className="label_black">
                  Plan Name
                </label>
                <input
                  className="plan_name"
                  type="text"
                  name="name"
                  value={formState.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />

                <br />
              </div> */}
            </div>
          </div>
          <div className="col1_middle">
            {Object.entries(formState).map(([name, value]) => {
              const isBoolean = typeof value === "boolean";
              const formattedLabel = formatLabel(name);
              return (
                <div
                  key={name}
                  className={`input_wrapper ${isBoolean ? "inputBoxDiv" : ""}`}
                >
                  <label htmlFor={name} for={name} className="label_black">
                    {formattedLabel}
                  </label>
                  <br />
                  {isBoolean ? (
                    <div className="toggle_input_wrapper">
                      <div className="switch_wrapper">
                        <input
                          id={name}
                          type="checkbox"
                          name={name}
                          checked={formData[name]}
                          onChange={() =>
                            handleInputChange(name, !formData[name])
                          }
                        />
                        <span className="slider round"></span>
                      </div>
                    </div>
                  ) : typeof value === "number" ? (
                    <ElevatorInput
                      label={name}
                      value={formData[name]}
                      setValue={(newValue) => {
                        handleInputChange(name, newValue);
                      }}
                    />
                  ) : (
                    <input
                      type="text"
                      name={name}
                      value={formData[name]}
                      max={"100"}
                      onChange={(e) => handleInputChange(name, e.target.value)}
                      className="plan_name"
                    />
                  )}
                </div>
              );
            })}
          </div>
          <button className="create_plan_btn" onClick={handleSubmit}>
            Create Plan
          </button>
        </div>
        <div className="col2">
          <div className="preview_wrapper_2">
            <h4>Preview</h4>
            <div className="preview">
              {/* <PlanDetailsBox plan={plans} /> */}
            </div>
          </div>
        </div>
      </div>
      {modalVisible ? (
        <SuccessModal
          setModalOpen={setModalVisible}
          title="Successful!!"
          desc="Plan Created Successfully"
          btnText="Close"
        />
      ) : (
        ""
      )}

      {errorModalVisible ? (
        <ErrorModal
          setModalOpen={setErrorModalVisible}
          title="Error!!"
          desc="Could'nt create plan"
          btnText="Close"
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default CreatePlan;
