import React from 'react';
import './style.scss';
import Winner from '../../../assets/icons/profile.svg';

function LeaderboardWinners({data}) {

  return (
    <div className='winners'>
      <div className='winners_header'>
        <h4>Leaderboard Winners</h4>
        {/* <span>10 Jan, 2022</span> */}
      </div>
      <div className='winners_list'>
      { data[1]?.email &&  <div className='winner-container'>
          <img src={Winner} alt='winner' className='winner-2' />
          <p>{data[1]?.email}</p>
          <div className='winner-2-bg'>
            <div className='winner-bg-content'>
            <h2>2nd</h2>
            {/* <p>Iphone 14 pro</p> */}
            </div>
          </div>
        </div>}
       {data[0]?.email &&  <div className='winner-container'>
          <img src={Winner} alt='winner' className='winner-1' />
          <p>{data[0]?.email}</p>
          <div className='winner-1-bg'>
          <div className='winner-bg-content'>
            <h2>1st</h2>
            {/* <p>Iphone 14 pro</p> */}
            </div>
          </div>
        </div>}
       {data[2]?.email &&  <div className='winner-container'>
          <img src={Winner} alt='winner' className='winner-3' />
          <p>{data[2]?.email}</p>
          <div className='winner-3-bg'>
          <div className='winner-bg-content'>
            <h2>3rd</h2>
            {/* <p>Iphone 14 pro</p> */}
            </div>
          </div>
        </div>}
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default LeaderboardWinners;
