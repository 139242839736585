import React from "react";
import "./style.scss";
import NotificationsTable from "../../components/Notifications";

function Notifications() {
  return (
    <div className="notifications">
      <NotificationsTable />
    </div>
  );
}

export default Notifications;
