import React, { useState } from "react";
import "./style.scss";
import Eye from "../../../../assets/icons/eye.svg";
import EyeBlue from "../../../../assets/icons/eye-blue.svg";

function AccountPassword() {
  const [isFocused, setIsFocused] = useState(false); //to change eye icon on input focus

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <div className="account_password">
      <div className="account_password_input_container">
        <div className="account_input">
          <label htmlFor="password" className="label_black">
            Password
          </label>
          <br></br>
          <div className="account_input_inner_wrapper">
            <div className="input_container">
              <input
                className="account_password_input"
                type="password"
                value="Daniel"
                name="password"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <img
                className="eye_icon"
                src={isFocused ? EyeBlue : Eye}
                alt="eye icon"
              />
            </div>
          </div>
        </div>
        <div className="account_input">
          <label htmlFor="newPassword">New Password</label>
          <br></br>
          <div className="account_input_inner_wrapper">
            <div className="input_container">
              <input
                className="account_password_input"
                type="password"
                value="Daniel"
                name="newPassword"
              />
              <img className="eye_icon" src={Eye} alt="eye icon" />
            </div>
          </div>
        </div>
        <div className="account_input">
          <label htmlFor="verifyPassword">Verify Password</label>
          <br></br>
          <div className="account_input_inner_wrapper">
            <div className="input_container">
              <input
                className="account_password_input"
                type="password"
                value="Daniel"
                name="verifyPassword"
              />
              <img className="eye_icon" src={Eye} alt="eye icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountPassword;
