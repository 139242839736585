import React from "react";
import "./style.scss";

import Tabination from "../../../reusableComponents/Tabination";

import Email from "./Email";

function Discussion() {
  const items = [
    { name: "Email/Notifications", content: <Email /> },
    { name: "Transcripts (Discord)", content: <Email />  },
  ];

  return (
    <div className="discussion">
      <div className="discussion_tabs">
      <Tabination
        variant={4}
        tabItems={items}
        tabStyle={{ marginLeft: "0" }}
        radioButton={true}
      /> </div>
    </div>
  );
}

export default Discussion
