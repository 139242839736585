import React, { useEffect, useRef, useState } from "react";
import './style.scss'
import Select from "react-select";
import Calender from "../../assets/icons/calender.svg";
import UploadIcon from "../../assets/icons/Upload.svg";
import img1 from "../../assets/images/competition-bg-img.png";
import img2 from "../../assets/images/competition-bg-img2.png";
import img3 from "../../assets/images/carousel-background.jpg";
import bg1 from "../../assets/images/card-background.jpg";
import Adimg1 from "../../assets/images/laptop-signin.png";
import AdDetailsBox from "../../components/Ads/AdDetailsBox";
import SliderComponent from "../../components/Slider";
import { useDispatch, useSelector } from "react-redux";
import { createAdvData } from "../../store/reducers/advSlice";
import img4 from "../../assets/images/laptop-signin.png";


function CreateAd() {
  const [formData, setFormData] = useState({
    eventName: "",
    adName: "",
    adDescription: "",
    startDate: "",
    endDate: "",
  });

  const [adData, setAdData] = useState({

  });

  const idToken = useSelector(state => state.auth.idToken)
  const dispatch = useDispatch()
  const adv = useSelector(state => state.adv.advData)
  //

  const handleFieldChange = (fieldName, value) => {



    setFormData({
      ...formData,
      [fieldName]: value,
    });
    setAdData({
      ...adData,
      [fieldName]: value,
    });

  };
  const handleImageChange = (e) => {
    const selectedImages = e.target.files;
    setFormData({
      ...formData,
      images: selectedImages,
    });
  };
  const [error, setError] = useState(null); 

  const handleSubmit = () => {

    const {eventName, adName , adDescription , startDate  , endDate} = formData
    
    if (!eventName || !adName || !adDescription || !startDate || !endDate) {
      setError('Please fill all the required fields.');
    } else {
      setError(null);
    
    const data = new FormData();
    data.append('event_name', formData.eventName);
    data.append('name', formData.adName);
    data.append('description', formData.adDescription);
    data.append('start_date', formData.startDate);
    data.append('end_date', formData.endDate);

    if (formData.images && formData.images.length > 0) {
      for (let i = 0; i < formData.images.length; i++) {
        data.append('images', formData.images[i]);
      }
    }
    //


    dispatch(createAdvData({ idToken, data: data }));
  

    setFormData({
      eventName: "",
      adName: "",
      adDescription: "",
      startDate: "",
      endDate: "",
      images: [] 
    });
    }
  }



  const apiImages = [{ image: img1 }, { image: img2 }, { image: img3 }];
  const adDataArray = [
    {
      name: "February 2022 - 200k Challenge",
      date: "01-03-2022",
      heading: " February 2022 Trading Competition",
      background: bg1,
      image: Adimg1,
      status: "Published",
    },
  ];

  const Seperator = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="25"
        viewBox="0 0 2 25"
        fill="none"
      >
        <path d="M1 0V24.5" stroke="#EAF0F8" strokeLinejoin="round" />
      </svg>
    );
  };

  const options = [
    { value: "Select Event", label: "Select Event" },
    { value: "Select ", label: "Select " },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      border: "1px solid #eaf0f8;",
      boxShadow: "none",
      fontFamily: "DM Sans , sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "transparent",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };


  return (
    <div className="create_ad">
      <div className="grid_container">
        <div className="col1">
          <div className="col1_top">
            <div className="create_ad_wrapper">
              <h4>Create event</h4>
              <div className="ad_input_wrapper">
                <label htmlFor="event_selector" className="label_black">
                  Event name
                </label>
                <Select
                  className="event_selector"
                  options={options}
                  styles={customStyles}
                  placeholder={"Select event..."}
                  onChange={(selectedOption) =>
                    handleFieldChange("eventName", selectedOption.value)
                  }
                />
                <br />
                <label htmlFor="ad_name" className="label_black">
                  Ad Name
                </label>
                <input
                  className="ad_name"
                  type="text"
                  name="event_name"
                  value={formData.adName}
                  placeholder="February 2022 - 200k Challenge"
                  onChange={(e) => handleFieldChange("adName", e.target.value)}
                />
                <br />
                <label className="label_black">Ad Description</label>
                <textarea
                  name="ad_description"
                  id="ad_description"
                  cols="100"
                  rows="8"
                  placeholder="add description"
                  value={formData.adDescription}
                  onChange={(e) =>
                    handleFieldChange("adDescription", e.target.value)
                  }
                ></textarea>
                <br />
                <label htmlFor="ad_scheduler_wrapper" className="label_black">
                  Schedule an Ad
                </label>
                <div className="ad_scheduler_wrapper">
                  <img src={Calender} alt="" />
                  <input
                    className="ad_scheduler"
                    type="text"
                    name="event_name"
                    value={formData.adName}
                    onChange={(e) =>
                      handleFieldChange("adName", e.target.value)
                    }
                  />
                </div>
                <br />
                <label htmlFor="date_wrapper" className="label_black">
                  Date
                </label>
                <div className="date_wrapper">
                  <button className="date_from">
                    <div className="container">
                      <img src={Calender} alt="" />
                      <input
                        type="date"
                        className="input_date"
                        value={formData.startDate}
                        onChange={(e) =>
                          handleFieldChange("startDate", e.target.value)
                        }
                      />
                    </div>
                  </button>
                  <span>
                    <h5>-</h5>
                  </span>
                  <button className="date_to">
                    <div className="container">
                      <img src={Calender} alt="" />
                      <input
                        type="date"
                        className="input_date"
                        value={formData.endDate}
                        onChange={(e) =>
                          handleFieldChange("endDate", e.target.value)
                        }
                      />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col1_bottom">
            <div className="image_upload_wrapper">
              <h4>Ad Image</h4>
              <div className="image_selector_wrapper">
                <div
                  className="image_input_wrapper"
                  onClick={() => {
                    document.querySelector(".image_input").click();
                  }}
                >
                  <img src={UploadIcon} alt="UploadIcon" />
                  <br />
                  <h4>
                    <span>Click to upload</span> or <br /> drag and drop
                  </h4>
                  <input
                    className="image_input"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => handleImageChange(e)}
                  />                </div>
                <div className="previous_images">
                  {apiImages.map((object, index) => {
                    return (
                      <div className="img" key={index}>
                        <img src={object.image} alt="" />
                      </div>
                    );
                  })}
                </div>

              </div>
              <h5>You can add multiple versions, should be Less than 5 mb</h5>
            </div>
          </div>
        </div>
        <div className="col2">
          <div className="col2_top">
            <div className="preview_wrapper">
              <h4>Preview</h4>

              <PreviewAd adData={adData} />

            </div>
          </div>
          <div className="col2_bottom">
          <div className="error_message">{error}</div>
            <button onClick={handleSubmit}>Create Competition</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAd;




function PreviewAd({ adData }) {
  const [activeColor, setActiveColor] = useState("#36d66b");

  //
  const Display1 = ({ adData }) => {
    const displayRef = useRef(null);

    useEffect(() => {
      const handleDisplayClick = () => {
        //
      };

      displayRef.current.addEventListener("click", handleDisplayClick);

      // return () => {
      //   displayRef.current.removeEventListener("click", handleDisplayClick);
      // };
    }, []);

    return (
      <div className="display1 display" ref={displayRef}>
        <img className="bg_img" src={bg1} alt="" />
        <img className="main_img" src={img4} alt="" />
        <div className="heading">{adData.event_name} <br></br>{adData.name}</div>
      </div>
    );
  };


  return (
    <div className="ad_details_box_wrapper">
      <div className="ad_details_box">
        <div className="ad_details_box_upper">
          <div className="ad_details_box_upper_wrapper">
            <div className="header">
              <img src={img1} alt="" className="header_img" />
            </div>

            <div
              className="bg_img_container_header"
              style={{ backgroundColor: "#EAF0F8" }
              }
            >
              <svg
                className="menu"
                width="22"
                height="6"
                viewBox="0 0 22 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Menu Meatballs">
                  <g id="Vector">
                    <path
                      d="M11 1C12.1046 1 13 1.89543 13 3C13 4.10457 12.1046 5 11 5C9.89543 5 9 4.10457 9 3C9 1.89543 9.89543 1 11 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                    <path
                      d="M3 1C4.10457 1 5 1.89543 5 3C5 4.10457 4.10457 5 3 5C1.89543 5 1 4.10457 1 3C1 1.89543 1.89543 1 3 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                    <path
                      d="M19 1C20.1046 1 21 1.89543 21 3C21 4.10457 20.1046 5 19 5C17.8954 5 17 4.10457 17 3C17 1.89543 17.8954 1 19 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className="ad_details_box_middle">
          <div className="ad_details_box_middle_wrapper">
            <div className="date">
              <h4>
                {adData.start_date}: {adData.end_date}
              </h4>
            </div>
            <div className="display_wrapper">
              <Display1 adData={adData} />
            </div>
          </div>
        </div>
        {/* <div className="ad_details_box_bottom">
          <div className="ad_details_box_bottom_wrapper">
            <div className="header">
              <span>
                This ad has multiple versions
              </span>
              1 of 3
            </div>
            <div className="display_selector">
              <div className="display_wrapper">
                <Display1 adData={adData} />
                <Display1 adData={adData} />
                <Display1 adData={adData} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}


