import React, { useEffect, useState } from "react";
import "./style.scss";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
// function Tooltip({
//   children,
//   variant = "appear",
//   isActive,
//   setIsActive,
//   moveY = 0,
// }) {
//   useEffect(() => {
//     let timer;
//     if (variant === "disappear") {
//       timer = setTimeout(() => {
//         setIsActive();
//       }, 2000);
//     }

//     return () => {
//       if (timer) {
//         clearTimeout(timer);
//       }
//     };
//   }, [variant, setIsActive]);

//   const top = moveY !== 0 ? 100 + +`${moveY}` : "";

//   return (
//     <div
//       id="tooltip"
//       style={{ top: `${top}%` }}
//       className={`tooltip ${variant === "disappear" ? "tooltip-variant" : ""} ${
//         isActive ? "active" : ""
//       }`}
//     >
//       {children}
//     </div>
//   );
// }
export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "#0E0F19",
    fontFamily: "Space Grotesk",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    maxWidth: 220,
    minHeight: "26px",
    display: "flex",
    alignItems: "center",
    filter: "drop-shadow(0px 0px 2px #1154AC)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: "#FFF",
    },
  },
}));

export default Tooltip;
