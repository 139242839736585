import React from 'react'
import './style.scss'
import EmailIcon from '../../../assets/icons/email-green.svg'
import img1 from "../../../assets/images/laptop-signin.png";
import addCircle from "../../../assets/icons/Add Circle.svg";
import MenuDots from '../../../assets/icons/menu-dots.svg'

function Preview() {
    return (
        <div className='preview'>
            <h4>Preview</h4>
            <div className='preview_wrapper'>
                <div className='preview_header'>
                    <div className='preview_header_left'>
                        <img src={EmailIcon} alt='' />
                        <h4>Email Template Name</h4>
                    </div>
                    <div className='preview_heder_right'>
                          <img src={MenuDots} alt='' />
                    </div>
                </div>
                <div className='preview_email'>
                    <div className='preview_email_container'>
                        <div className='preview_email_header'>
                            <div><span>To:</span> Example Contact</div>
                            <div><span>Subject:</span> Example Contact </div>
                        </div>
                        <div className='preview_email_message'>
                            Hi Message,
                            <br></br>
                            <br></br>
                            Noticed to all members to receive a message regarding a ne upcoming trading competition of $200. Hurry Up NOW!!!
                            <div className="preview_competition">
                                <img className="main_img" src={img1} alt="" />
                                <div className="heading">February 2022
                                    Trading Competition </div>
                            </div>
                            <button className="ent_btn">Enter Competition</button>
                          <div className='preview_email_footer'>
                            <p>Regards,</p>
                            <p>Team</p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className='save_btn'>
                <img src={addCircle} alt='' />
                Save</button>
        </div>
    )
}

export default Preview