import React, { useEffect, useState } from "react";
import "./style.scss";
import Calender from "../../assets/icons/calender.svg";
import FirstPrize from "../../assets/icons/trophy-green.svg";
import TrophyBlue from "../../assets/icons/trophy-blue.svg";
import ThirdPrize from "../../assets/icons/medal-orange.svg";
import OthersPrize from "../../assets/icons/medal-orange.svg";
import UploadIcon from "../../assets/icons/Upload.svg";
import AddCircle from "../../assets/icons/add-circle.svg";
import img1 from "../../assets/images/competition-bg-img.png";
import img2 from "../../assets/images/competition-bg-img2.png";
import img3 from "../../assets/images/carousel-background.jpg";
import { createCompData, setCompData } from "../../store/reducers/compSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function EditCompetition({}) {
  const apiImages = [{ image: img1 }, { image: img2 }, { image: img3 }];
  const apiRules = [
    "Free & Paid entry options",
    "Live leader board within dashboard.",
    "Monthly prizes from free evaluations to a new PC",
    "Winners based on Max $$$ gain.",
    "5 Minimum Trading Days",
  ];

  const { id } = useParams();
  //

  const navigate = useNavigate();

  const [formState, setFormState] = useState({});
  const compData = useSelector((state) => state.comp.compData);
  const matchingCompetition = compData.find(
    (competition) => competition.id.toString() === id
  );

  useEffect(() => {
    //

    if (matchingCompetition) {
      setFormState({
        eventName: matchingCompetition.name,
        dateFrom: matchingCompetition.start_date,
        dateTo: matchingCompetition.end_date,
        contestants: matchingCompetition.total_contestants,
        totalWinners: matchingCompetition.challenge,
        firstPrize: matchingCompetition.firstPrize,
        secondPrize: matchingCompetition.secondPrize,
        thirdPrize: matchingCompetition.thirdPrize,
        othersPrize: matchingCompetition.othersPrize,
        rules: matchingCompetition.rules ? matchingCompetition.rules : [],
        imageFile: null,
      });
    }
  }, [id, compData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormState({ ...formState, imageFile: file });
    }
  };

  const handleAddRule = () => {
    const newId = formState.rules.length;
    const newRule = { id: newId, text: "" };
    const updatedRules = [...formState.rules, newRule];
    setFormState({ ...formState, rules: updatedRules });
  };

  const idToken = useSelector((state) => state.auth.idToken);
  //
  //

  const dispatch = useDispatch();
  const handleSubmit = () => {
    const updatedData = {
      id: id,
      idToken: idToken,
      data: {
        name: formState.eventName,
        start_date: formState.dateFrom,
        end_Date: formState.dateTo,
        close_entry_on: "2023-10-31T11:26:43Z",
        rules: "This will be the rule of the competition",
        total_contestants: formState.contestants,
        challenge: 1,
      },
    };
    dispatch(setCompData(updatedData));
    navigate("/competition-list");
  };

  return (
    <div className="create_competition">
      <div className="grid_container">
        <div className="col1">
          <div className="col1_top">
            <div className="create_event_wrapper">
              <h4>Create event</h4>
              <div className="event_input_wrapper">
                <label htmlFor="event_name" className="label_black">
                  Event name
                </label>
                <input
                  className="event_name"
                  type="text"
                  name="event_name"
                  value={formState.eventName}
                  onChange={(e) =>
                    setFormState({ ...formState, eventName: e.target.value })
                  }
                />
                <br />
                <label htmlFor="date_wrapper" className="label_black">
                  Date
                </label>
                <div className="date_wrapper">
                  <button className="date_from">
                    <div className="date-container">
                      <img src={Calender} alt="" />
                      <input
                        type="date"
                        className="input_date"
                        value={formState.dateFrom}
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            dateFrom: e.target.value,
                          })
                        }
                      />
                    </div>
                  </button>

                  <span>
                    <h5>-</h5>
                  </span>
                  <button className="date_to">
                    <div className="date-container">
                      <img src={Calender} alt="" />
                      <input
                        type="date"
                        className="input_date"
                        value={formState.dateTo}
                        onChange={(e) =>
                          setFormState({ ...formState, dateTo: e.target.value })
                        }
                      />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col1_middle">
            <div className="constestants_wrapper">
              <h4>Holding Contestants</h4>
              <div className="const_input_wrapper">
                <div className="input_wrapper">
                  How many contestants are you expecting?
                  <div className="box">
                    <input
                      type="text"
                      value={formState.contestants}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          contestants: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col1_bottom">
            <div className="winners_wrapper">
              <h4>Holding Contestants</h4>
              <div className="winners_input_wrapper">
                <label htmlFor="total_winners" className="label_black">
                  Total Winners
                </label>
                <input
                  className="total_winners"
                  type="text"
                  name="total_winners"
                  value={formState.totalWinners}
                  onChange={(e) =>
                    setFormState({ ...formState, totalWinners: e.target.value })
                  }
                />
                <br />
                <label htmlFor="prize_Details_wrapper" className="label_black">
                  Prize Details
                </label>
                <div className="prize_details_wrapper">
                  <div className="input_wrapper">
                    <div className="prize_header1">
                      <img src={FirstPrize} alt="FirstPrize" />
                      <span>1st Prize</span>
                    </div>
                    {/* <Seperator /> */}
                    <input
                      type="text"
                      value={formState.firstPrize}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          firstPrize: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input_wrapper">
                    <div className="prize_header2">
                      <img src={TrophyBlue} alt="Second Prize" />
                      <span>2nd Prize</span>
                    </div>
                    {/* <Seperator /> */}
                    <input
                      type="text"
                      value={formState.secondPrize}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          secondPrize: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input_wrapper">
                    <div className="prize_header3">
                      <img src={ThirdPrize} alt="ThirdPrize" />
                      <span>3nd Prize</span>
                    </div>
                    {/* <Seperator /> */}
                    <input
                      type="text"
                      value={formState.thirdPrize}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          thirdPrize: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input_wrapper">
                    <div className="prize_header4">
                      <img src={OthersPrize} alt="OthersPrize" />
                      <span>Others</span>
                    </div>
                    {/* <Seperator /> */}
                    <input
                      type="text"
                      value={formState.othersPrize}
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          othersPrize: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col2">
          <div className="col2_top">
            <div className="image_upload_wrapper">
              <h4>Upload Image</h4>
              <div className="image_selector_wrapper">
                <div
                  className="image_input_wrapper"
                  onClick={() => {
                    document.querySelector(".image_input").click();
                  }}
                >
                  <img src={UploadIcon} alt="UploadIcon" />
                  <br />
                  <h4>
                    <span>Click to upload</span> or <br /> drag and drop
                  </h4>
                  <input
                    className="image_input"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
                <div className="previous_images">
                  {/* {apiImages.map((object, index) => {
                    return (
                      <div className="img" key={index}>
                        <img src={object.image} alt="" />
                      </div>
                    );
                  })} */}
                </div>
              </div>
            </div>
          </div>
          <div className="col2_bottom">
            <div className="competition_rules_wrapper">
              <h4>Competitions Rules</h4>
              <div className="rules_wrapper">
                <div className="rules_toggle">
                  <h4>What to add competition rules and regulations</h4>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div className="rule_inputs">
                  {/* {formState.rules.map((rule, index) => (
              <div key={rule.id} className="rule_input">
                <input
                  type="text"
                  value={rule.text}
                  onChange={(e) => {
                    const updatedRules = [...formState.rules];
                    updatedRules[index].text = e.target.value;
                    setFormState({ ...formState, rules: updatedRules });
                  }}
                />
              </div>

                  ))} */}
                  <div className="add_rule_button" onClick={handleAddRule}>
                    <img src={AddCircle} alt="" />
                    Add
                  </div>
                </div>
              </div>
              <button onClick={handleSubmit}>Edit Competition</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCompetition;
