import React, { Fragment, useMemo, useState } from "react";
import "./style.scss";
import { usePagination, useTable } from "react-table";
import CalendarIcon from "../../assets/icons/calender.svg";
import Search from "../../assets/icons/search.svg";
import CaretLeftIcon from "../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../assets/icons/caret-right.svg";
import Calendar from "../../reusableComponents/Calendar";

function NotificationTable() {
  return (
    <div className="notification_table">
      <div className="table_scroll_wrapper">
      <Table /> </div>
    </div>
  );
}

export default NotificationTable;

const Table = () => {

  const [filter, setFilter] = useState("All");
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());

  const data = useMemo(
    () => [
      {
        title: "Commission 001",
        description: "Lorem ipsum...",
        dateCreated: "2023-07-01",
        timeSince: "2 hours ago",
        createdBy: "John Doe",
        status: "accepted",
      },
      {
        title: "Commission 002",
        description: "Sed do eiusmod...",
        dateCreated: "2023-07-02",
        timeSince: "1 day ago",
        createdBy: "Jane Smith",
        status: "pending",
      },
      {
        title: "Commission 003",
        description: "Ut enim ad minim...",
        dateCreated: "2023-07-03",
        timeSince: "3 days ago",
        createdBy: "Jim Brown",
        status: "rejected",
      },
      {
        title: "Commission 004",
        description: "Consectetur adipiscing...",
        dateCreated: "2023-07-04",
        timeSince: "5 hours ago",
        createdBy: "Alice Johnson",
        status: "accepted",
      },
      {
        title: "Commission 005",
        description: "Duis aute irure...",
        dateCreated: "2023-07-05",
        timeSince: "2 days ago",
        createdBy: "Sam Wilson",
        status: "pending",
      },
      {
        title: "Commission 006",
        description: "Excepteur sint occaecat...",
        dateCreated: "2023-07-06",
        timeSince: "4 hours ago",
        createdBy: "Emily Clark",
        status: "accepted",
      },
      {
        title: "Commission 007",
        description: "Cupidatat non proident...",
        dateCreated: "2023-07-07",
        timeSince: "1 day ago",
        createdBy: "Michael Smith",
        status: "pending",
      },
      {
        title: "Commission 008",
        description: "Sunt in culpa...",
        dateCreated: "2023-07-08",
        timeSince: "6 hours ago",
        createdBy: "Susan Miller",
        status: "rejected",
      },
      {
        title: "Commission 009",
        description: "Qui officia deserunt...",
        dateCreated: "2023-07-09",
        timeSince: "3 days ago",
        createdBy: "Peter Jones",
        status: "accepted",
      },
      {
        title: "Commission 010",
        description: "Mollit anim id est...",
        dateCreated: "2023-07-10",
        timeSince: "2 hours ago",
        createdBy: "Laura Smith",
        status: "rejected",
      },
      {
        title: "Commission 008",
        description: "Sunt in culpa...",
        dateCreated: "2023-07-08",
        timeSince: "6 hours ago",
        createdBy: "Susan Miller",
        status: "rejected",
      },
      {
        title: "Commission 009",
        description: "Qui officia deserunt...",
        dateCreated: "2023-07-09",
        timeSince: "3 days ago",
        createdBy: "Peter Jones",
        status: "accepted",
      },
      {
        title: "Commission 010",
        description: "Mollit anim id est...",
        dateCreated: "2023-07-10",
        timeSince: "2 hours ago",
        createdBy: "Laura Smith",
        status: "rejected",
      },
      {
        title: "Commission 008",
        description: "Sunt in culpa...",
        dateCreated: "2023-07-08",
        timeSince: "6 hours ago",
        createdBy: "Susan Miller",
        status: "rejected",
      },
      {
        title: "Commission 009",
        description: "Qui officia deserunt...",
        dateCreated: "2023-07-09",
        timeSince: "3 days ago",
        createdBy: "Peter Jones",
        status: "accepted",
      },
      {
        title: "Commission 010",
        description: "Mollit anim id est...",
        dateCreated: "2023-07-10",
        timeSince: "2 hours ago",
        createdBy: "Laura Smith",
        status: "rejected",
      },
    ],
    []
  );

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredData = useMemo(
    () =>
      filter === "All"
        ? data
        : data.filter(
            (item) => item.status.toLowerCase() === filter.toLowerCase()
          ),
    [data, filter]
  );
  
  const columns = React.useMemo(
    () => [
      { Header: "Title", accessor: "title" },
      { Header: "Description", accessor: "description" },
      { Header: "Date Created", accessor: "dateCreated" },
      { Header: "Time since", accessor: "timeSince" },
      {
        Header: "Created By",
        accessor: "createdBy",
        Cell: ({ value }) => <button className="created">{value}</button>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <button className={`${value}_tag`}>{value}</button>
        ),
      },
    ],
    []
  );

  const initialState = useMemo(
    () => [
      {
        pageSize: 7,
        pageIndex: 0,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex, pageSize},
  } = useTable({columns, data: filteredData, initialState}, usePagination);


  return (
    <div className="table_scroll">
      <div className="table_wrapper">
        <div className="table_header">
          <div className="search">
            <div className="search_bar">
              <input
                type="text"
                className="search_input"
                placeholder="Search..."
              />
              <img className="search_icon" src={Search} alt="search icon" />
            </div>
            <div className="select_wrapper">
            <div
              className="calendar-button"
              onClick={() => setIsCalender((prev) => !prev)}
            >
              <img src={CalendarIcon} alt="calender icon" />
            </div>

            <div className="calendar_wrapper">
              {isCalender && (
                <Calendar
                  onChange={(date) => setDate(date)}
                  value={date}
                  // formatMonth={"MMM"}
                  selectRange={true}
                  showNeighboringMonth={false}
                />
              )}
            </div> </div>           </div>
          <div className="filter_buttons">
            <button
              className={filter === "All" ? "active_btn" : ""}
              onClick={() => handleFilter("All")}
            >
              All
            </button>
            <button
              className={filter === "Accepted" ? "active_btn" : ""}
              onClick={() => handleFilter("Accepted")}
            >
              Accepted
            </button>
            <button
              className={filter === "Rejected" ? "active_btn" : ""}
              onClick={() => handleFilter("Rejected")}
            >
              Rejected
            </button>
            <button
              className={filter === "Flagged" ? "active_btn" : ""}
              onClick={() => handleFilter("Flagged")}
            >
              Flagged
            </button>
          </div>
        </div>
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup?.headers?.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={rowIndex}>
                  <tr
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                  
                          {cell.render("Cell")}
                      
                      </td>
                    ))}
                  </tr>
          
                </Fragment>
              );
            })}
          </tbody>
        </table>
        {pageOptions.length > 1 && (
          <div className="pagination">
            <img src={CaretLeftIcon} alt="left-arrow" />
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className={!canPreviousPage ? "disabled" : ""}
            >
              Previous
            </button>
            {pageOptions.map((pageNumber, index) => (
              <>
                <button
                  key={index}
                  onClick={() => gotoPage(index)}
                  className={pageIndex === index ? "btn-active" : "non-active"}
                >
                  {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
                </button>
              </>
            ))}
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className={!canNextPage ? "disabled" : ""}
            >
              Next
            </button>
            <img src={CaretRightIcon} alt="right-arrow" />
          </div>
        )}
      </div>
    </div>
  );
};


