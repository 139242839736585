import React, { useEffect, useState } from "react";
import "./style.scss";
import ReactApexChart from "react-apexcharts";
import laptop from "../../../assets/images/laptop-signin.png";
import bg1 from "../../../assets/images/carousel-background.jpg";
import bg2 from "../../../assets/images/carousel-background2.jpg";
import bg3 from "../../../assets/images/carousel-background3.jpg";
import bg4 from "../../../assets/images/carousel-background4.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from "react-select";
import Card from "../../../reusableComponents/Card";
// import 'react-select/dist/react-select.css';

function RevenueGrowth({ revenuData }) {
 
  const options = [
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
    { value: "quarterly", label: "Quarterly" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
  };

  const SliderData = [
    {
      title: "Trading Competition",
      image: bg1,
      secondaryImg: laptop,
    },
    {
      title: "Trading Pro",
      image: bg2,
      secondaryImg: laptop,
    },
    {
      title: "Trading Ultra Competition",
      image: bg3,
      secondaryImg: laptop,
    },
    {
      title: "Trading Max Competition",
      image: bg4,
      secondaryImg: laptop,
    },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "110px",
      height: "20px",
      boxSizing: "content-box",
      fontSize: "12px",
      color: "#133C55",
      fontWeight: "500",
      fontFamily: "Space Grotesk",
      borderRadius: "8px",
      float: "right",
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "#133C55",
        width: "20px",
      }),
    }),
  };
  return (
    <Card className="revenue_growth">
      <div className="revenue_growth_wrapper">
        <div className="revenue_growth_upper">
          <div className="revenue_growth_upper_header">
            <h3 className="chart_title">Revenue Growth</h3>
            {/* <Select
              options={options}
              value={selectedOption}
              onChange={handleSelectChange}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null,
              }}
              menuPortalTarget={document.body}
              menuPlacement="bottom"
              menuPosition="fixed"
              menuStyle={{ width: "120px", zIndex: "100" }}
            /> */}
          </div>
          <div className="chart_wrapper">
            <ColumnChart
              revenuData={
                revenuData
                  ? revenuData
                  : {
                      stripe: [
                        {
                          month: "2024-1",
                          amount: 0,
                        },
                      ],
                      coinbase: [
                        {
                          month: "2024-1",
                          amount: 0,
                        },
                      ],
                    }
              }
            />
          </div>
        </div>
        <div className="revenue_growth_lower">
          <div className="carousel_container">
            {/* <div className="carousel_img_container">
              <img src={images[activeIndex].image} alt="laptop-signin" />
              <div className="carousel_content">
                <h3>{images[activeIndex].title}</h3>
                <button>Enter Competition</button>
              </div>
            </div>
            <div className="carousel_nav">
              {images.map((_, index) => (
                <span
                  key={index}
                  className={`carousel_nav_btn ${
                    index === activeIndex ? "active" : ""
                  }`}
                  onClick={() => handleNavClick(index)}
                ></span>
              ))}
            </div> */}
            {/* <ImageSlider slides={SliderData} /> */}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default RevenueGrowth;

function ColumnChart({ revenuData }) {
  const stripeAmountArr = revenuData?.stripe?.map((item) => item?.amount);
  const coinbaseAmountArr = revenuData?.coinbase?.map((item) => item?.amount);
  const monthArr = revenuData?.stripe?.map((item) => item?.month);
  const series = [
    {
      name: "Stripe",
      data: stripeAmountArr,
    },
    {
      name: "Paypal",
      data: coinbaseAmountArr,
    },
    // {
    //   name: "E-Com",
    //   data: [76, 85, 101, 91, 114, 94],
    // },
    // {
    //   name: "Links",
    //   data: [35, 45, 48, 52, 53, 41],
    // },
    // {
    //   name: "Others",
    //   data: [35, 45, 48, 52, 53, 41],
    // },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
        zoom: {
          enabled: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20px",
        barWidth: "50%",
        endingShape: "rounded",
      },
    },
    legend: {
      itemMargin: {
        vertical: 20,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: monthArr,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return "$" + value;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      offsetY: 10,
      offsetX: 20,
      markers: {
        radius: 6,
        offsetY: 1,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    colors: ["#635bff", "#F9C80E", "#133C55", "#36d66b"],
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={280} />
  );
}

const ImageSlider = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide-wrapper">
            <div
              key={index}
              className="slide"
              style={{
                backgroundImage: `url(${slide.image})`,
              }}
            >
              <img src={slide.secondaryImg} alt="laptop-signin" />

              <div className="carousel_content">
                <h3>{slide.title}</h3>
                <button>Enter Competition</button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
