import React from "react";
import "./style.scss";

import UserListTable from "../../components/UserList";

function UserList() {
  return (
    <div className="coupons_wrapper">
      <div className="coupons">
        <UserListTable />
      </div>
    </div>
  );
}

export default UserList;
