import React, { useEffect, useState } from "react";
import "./style.scss";
import Tabination from "../../reusableComponents/Tabination";
import AllTable from "../../components/TradersList/AllTable";

function TradersListSuper() {
  // const [activeTab,setActiveTab] = useState(0)

  // useEffect(()=>{
  //  localStorage.setItem("ActiveTab", activeTab)
  // },[activeTab])
  // const [active, setActive] = useState(true);

  const items = [
    {
      name: "All",
      content: (
        <div className="traderslist">
          <AllTable activeTab={0} admin={true} />
        </div>
      ),
    },
    {
      name: "1 Step",
      content: (
        <div className="traderslist">
          <AllTable activeTab={1} admin={true} />
        </div>
      ),
    },
    {
      name: "2 Step",
      content: (
        <div className="traderslist">
          <AllTable activeTab={2
          } admin={true} />
        </div>
      ),
    },
    {
      name: "1 Step Funded",
      content: (
        <div className="traderslist">
          <AllTable activeTab={3} admin={true} />
        </div>
      ),
    },
    {
      name: "2 Step Funded",
      content: (
        <div className="traderslist">
          <AllTable activeTab={4} admin={true} />
        </div>
      ),
    },
    // {
    //   name: "Competition",
    //   content: (
    //     <div className="traderslist">
    //       <AllTable activeTab={2} admin={true} />
    //     </div>
    //   ),
    // },
    // {
    //   name: "Free Trial",
    //   content: (
    //     <div className="traderslist">
    //       <AllTable activeTab={3} admin={true} />
    //     </div>
    //   ),
    // },
  ];
  return (
    <>
      <Tabination variant={3} tabItems={items} activeTab={0} />
    </>
  );
}

export default TradersListSuper;
