import React from "react";
import "./style.scss";
import { Navigate, Route, Routes } from "react-router-dom";

import Tabination from "../../reusableComponents/Tabination";
import Stage1Pass from "../../components/Support/Stage1pass";
import Stage2Pass from "../../components/Support/Stage2pass";

import Withdraw from "../../components/Support/Withdraw";
import StagePass1 from "../../components/SuperAdmin/StagePass1";
import StagePass2 from "../../components/SuperAdmin/StagePass2";
import Support from "../Support";
import Certificate from "../../components/Support/Certificate";
import "./style.scss";
import Funded from "../../components/SuperAdmin/Funded";
function PayoutList() {
  const items = [
    {
      name: "Payout List",
      path: "payout_list",
    },
    // {
    //   name: "2 Step",
    //   path: "step_2",
    // },
  ];

  return (
    <>
      <Tabination variant={3} tabItems={items} activeTab={0} />
      <div className="support_wrapper support">
        <Routes>
          <Route path="/" element={<Navigate to="payout_list" replace={true} />} />
          <Route path="payout_list" element={<StagePass2 type={"stage1_pass"} />} />
        </Routes>
      </div>
    </>
  );
}

export default PayoutList;
