import React, { useState } from 'react'
import './style.scss'
import RequestToChange from '../RequestToChange'
import Banks from './Banks'
import VerifyNow from './VerifyNow'
import Warning from './Warning'
import RecipientBankDetails from '../RecipientBankDetails'

function Verification() {

        
    const [isbankDetailsModal , setBankDetailsModal] = useState(false);

    const openBankDetailsModal = () => {
        setBankDetailsModal(true);
    };

    const closeBankDetailsModal = () => {
        setBankDetailsModal(false);
    };

  return (
    <>
    <div className='verification'>
        <div className='grid_container'>
            <div className='col1'>
        <RequestToChange />
        </div>
        <div className='col2'>
        <Banks />
        
        <VerifyNow openBankDetailsModal={openBankDetailsModal} />

        <Warning />
        </div>
        </div> </div> 
        {isbankDetailsModal ?  <RecipientBankDetails closeBankDetailsModal={closeBankDetailsModal} /> : null } </>


  )
}

export default Verification