import React, { useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
import { usePagination, useTable } from "react-table";
import CalendarIcon from "../../../assets/icons/calender.svg";
import FilterIcon from "../../../assets/icons/filter.svg";
import Search from "../../../assets/icons/search.svg";
import CreateIcon from "../../../assets/icons/plus-circle-blue.svg";

import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import Calendar from "../../../reusableComponents/Calendar";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCertificatesDetails,
  getSupportTableDetails,
} from "../../../utils/api/apis";
import { setIsLoading } from "../../../store/reducers/authSlice";
import { Link } from "react-router-dom";
import { TruncateString } from "../../../utils/helpers/string";
import Select from "react-select";
import { Pagination } from "@mui/material";

function Certificate() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTraders, setTotalTraders] = useState(0);
  const [allTraders, setAllTraders] = useState([]);
  const idToken = useSelector((state) => state.auth.idToken);
  const [phase, setPhase] = useState(null);
  const [search, setSearch] = useState(null);

  const dispatch = useDispatch();

  const phaseOptions = [
    { label: "All", value: null },
    { label: "Stage 1 Pass", value: "Stage 1 Pass" },
    { label: "Stage 2 Pass", value: "Stage 2 Pass" },
    { label: "Profit Share", value: "profit share" },
  ];
  const addPadding = (tableData, pageNumber) => {
    const padding = [];
    for (let index = 0; index < (pageNumber - 1) * 20; index++) {
      padding.push({
        id: `padding-${index}`,
        name: "",
        accountNumber: "",
        startDate: "",
        endDate: "",
        percentageOfGoodTrades: "",
        percentageOfBadTrades: "",
      });
    }
   
    return [...padding, ...tableData];
  };
  const handlePagination = async (pageNumber) => {
    
    if (pageNumber !== currentPage) {
      // let searchValue = search;

      setAllTraders([]);
      const data = await getCertificatesDetails(
        idToken,
        pageNumber,
        phase,
        search
      );
      setAllTraders(addPadding(data.data.results, pageNumber));
      setCurrentPage(pageNumber);
    }
  };
  const fetchData = async () => {
    dispatch(setIsLoading(true));
    try {
      const response = await getCertificatesDetails(
        idToken,
        currentPage ? currentPage : 1,
        phase,
        search
      );
     
      const { data } = response;
      setTotalTraders(data.count);
      setAllTraders(addPadding(data.results, currentPage));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error("Error fetching traders:", error);
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [phase, search]);

  return (
    <div className="stage1pass_table certificate">
      <div className="table_scroll_wrapper">
        <Table
          tableData={allTraders || []}
          handlePagination={handlePagination}
          currentPage={currentPage - 1}
          totalTraders={totalTraders}
          fetchData={fetchData}
          phaseOptions={phaseOptions}
          phase={phase}
          setPhase={setPhase}
          search={search}
          setSearch={setSearch}
        />
      </div>
    </div>
  );
}

export default Certificate;

const Table = ({
  tableData,
  handlePagination,
  currentPage,
  totalTraders,
  fetchData,
  phaseOptions,
  phase,
  setPhase,
  search,
  setSearch,
}) => {
  const [filter, setFilter] = useState("All");
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();

  const handleFilter = (status) => {
    setFilter(status);
  };

  // const filteredData = useMemo(
  //   () =>
  //     filter === "All"
  //       ? data
  //       : data.filter(
  //           (item) => item.status.toLowerCase() === filter.toLowerCase()
  //         ),
  //   [data, filter]
  // );

  const columns = React.useMemo(
    () => [
      {
        Header: "Certificate Id",
        accessor: "id",
        // Cell: ({ value }) => (
        //   <div className="column_wrapper">
        //     <div className="name_initial">{value[0]}</div>
        //     <div className="name_text">{value}</div>
        //   </div>
        // ),
      },
      { Header: "Account ID", accessor: "login_id" },
      { Header: "Title", accessor: "title" },
      { Header: "Date Created", accessor: "created_at" },
      {
        Header: "File Url",
        accessor: "file",
        Cell: ({ value }) => (
          <a
            style={{
              color: "#FFF",
              padding: "6px",
              color: "rgb(255, 255, 255)",
              background: "#36d66b",
              borderRadius: "10px",
            }}
            href={value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value ? TruncateString(value, 40) : "No Url"}
          </a>
        ),
      },
      // {
      //   Header: "Action",
      //   accessor: "action",
      //   Cell: ({ value }) => (
      //     <div className="column_wrapper">
      //       <img className="create_icon" src={CreateIcon} alt="create" />
      //       <p>Create Affiliate Code</p>
      //     </div>
      //   ),
      // },
      // {
      //   Header: "Staus",
      //   accessor: "staus",
      //   Cell: ({ value }) => (
      //     <button className={`metrics_tag`}>Go To Metrics</button>
      //   ),
      // },
    ],
    []
  );

  const initialState = useMemo(
    () => [
      {
        pageSize: 10,
        pageIndex: 0,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: currentPage, pageSize: 20 },
    },
    usePagination
  );

  const totalPages = Math.ceil(totalTraders / pageSize);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const [selectedRow, setSelectedRow] = useState(null);

  let timeoutId;

  const handleSearchChange = (e) => {
    clearTimeout(timeoutId);
    const value = e.target.value;

    timeoutId = setTimeout(() => {
      setSearch(value);
    }, 1500);
  };

  const inputRef = useRef(null);

  const handleResetClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setSearch(null);
    }
  };
  const handleChange = (event, value) => {
    handlePagination(value);
  };
  return (
    <div className="table_scroll">
      <div className="table_wrapper">
        <h2 class="table-head">Certificates</h2>
        <div className="table_header header_filter">
          <div className="search">
            <div className="search_bar">
              <span
                onClick={handleResetClick}
                style={{ padding: "14px", color: "#1154ac", cursor: "pointer" }}
              >
                x
              </span>
              <input
                type="text"
                ref={inputRef}
                className="search_input"
                placeholder="Search..."
                onChange={handleSearchChange}
              />
              <img className="search_icon" src={Search} alt="search icon" />
            </div>

            {/* <div className="select_wrapper">
              <div
                className="calendar-button"
                onClick={() => setIsCalender((prev) => !prev)}
              >
                <img src={CalendarIcon} alt="calender icon" />
              </div>{" "}
            </div> */}
            {/* <div className="calendar_wrapper">
              {isCalender && (
                <Calendar
                  onChange={(date) => setDate(date)}
                  value={date}
                  // formatMonth={"MMM"}
                  selectRange={true}
                  showNeighboringMonth={false}
                />
              )}
            </div>{" "} */}
          </div>
          <div className="sort_buttons">
            <Select
              placeholder="Phase"
              classNamePrefix="react-select"
              options={phaseOptions}
              value={phase}
              onChange={(selectedOption) =>
                selectedOption.value === null
                  ? setPhase(null)
                  : setPhase(selectedOption)
              }
            />
          </div>
          {/* <div>
            <img src={FilterIcon} alt="filter" />
          </div> */}
        </div>
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup?.headers?.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={rowIndex}>
                  <tr
                    {...row.getRowProps()}
                    className={`economiccalender-row ${
                      selectedRow === rowIndex
                        ? "economiccalender-row-active"
                        : ""
                    } `}
                    // onClick={() => setSelectedRow(rowIndex)}
                  >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
        {/* <div className="pagination">
          <img src={CaretLeftIcon} alt="" />
          <button
            onClick={() => {
              if (currentPage === 0) {
                return;
              } else {
                handlePagination(currentPage);
              }
            }}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <span style={{ display: "flex", gap: "15px", fontWeight: "100" }}>
            {pageNumbers.map((pageNumber, idx) => (
              <strong key={pageNumber}>
                {pageIndex + 1 === pageNumber ? (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontWeight: "900",
                    }}
                    // onClick={() => handlePagination(pageNumber)}
                  >
                    {pageNumber}
                  </span>
                ) : (
                  <div
                    onClick={() => {
                      gotoPage(pageNumber - 1);
                      handlePagination(pageNumber);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontWeight: "20",
                      padding: "4px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      cursor: "pointer",
                    }}
                  >
                    {pageNumber}
                  </div>
                )}
              </strong>
            ))}
          </span>
          <button
            onClick={() => {
              if (currentPage + 1 === totalPages) {
                return;
              } else {
                handlePagination(currentPage + 2);
              }
            }}
            disabled={!canNextPage}
          >
            Next
          </button>
          <img src={CaretRightIcon} alt="" />
        </div> */}
        <div className="spacerT30p"></div>
      <Pagination
        onChange={handleChange}
        count={totalPages}
        variant="outlined"
        color="primary"
      />
      </div>
    </div>
  );
};
