import React, { useState } from "react";
import "./style.scss";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as SearchNew } from "../../../assets/icons/search-svgrepo-com (1).svg";
import Select from "react-select";
import CalenderIcon from "../../../assets/icons/calender.svg";
import DropdownIcon from "../../../assets/icons/dropdown-arrow-gray.svg";
import { usePagination, useTable } from "react-table";
import Profile from "../../../assets/icons/profile.svg";
import moment from "moment";
import DeleteIcon from "../../../assets/icons/delete-red.svg";
import Edit from "../../../assets/icons/edit.svg";
import CalendarIcon from "../../../assets/icons/calender.svg";
import CaretDownIcon from "../../../assets/icons/caret-down.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Calendar from "../../../reusableComponents/Calendar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import {
  getAllTradersRequest,
  sendCredential,
  updateUserAccountReq,
  upgradeUserAccountReq,
} from "../../../utils/api/apis";
import { useEffect } from "react";
import axios from "axios";
import { getCompDetails } from "../../../utils/api/apis";
import {
  setActiveAccount,
  setActiveEmail,
  setActiveUser,
} from "../../../store/reducers/accountSlice";
import { setIsLoading } from "../../../store/reducers/authSlice";
import ConfirmationModal from "../../../pages/FundingEvaluation/ConfirmationModal";
import { returnMessages } from "../../../store/reducers/message";
import { returnErrors } from "../../../store/reducers/error";
import { formatText, statusClassMap } from "../../../utils/string";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Tooltip,
} from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";

//PAGINATION REMAINING
function AllTable({ activeTab, admin }) {
  const [active, setActive] = useState(null);
  const [competition, setCompetition] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const activeTab = null
  const [compList, setCompList] = useState([]);
  const dropdownRef = useRef(null);
  const [totalTraders, setTotalTraders] = useState(0);
  const idToken = useSelector((state) => state.auth.idToken);
  const [allTraders, setAllTraders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [phase, setPhase] = useState(null);
  const [tab, setTab] = useState("");
  const phaseOptions = [
    { label: "All", value: null },
    { label: "Stage 1", value: "stage_1" },
    { label: "Stage 2", value: "stage_2" },
    { label: "Funded", value: "step_1" },
  ];
  const actions = [
    { label: "All", value: null },
    { label: "New", value: "New" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "rejected" },
    { label: "pending", value: "pending" },
  ];
  let searchVal = null;
  const addPadding = (tableData, pageNumber) => {
    const padding = [];
    for (let index = 0; index < (pageNumber - 1) * 21; index++) {
      padding.push({
        id: `padding-${index}`,
        name: "",
        accountNumber: "",
        startDate: "",
        endDate: "",
        percentageOfGoodTrades: "",
        percentageOfBadTrades: "",
      });
    }

    return [...padding, ...tableData];
  };

  useEffect(() => {
    if (activeTab === 0) {
      setTab("");
      setCurrentPage(1);
    }
    if (activeTab === 1) {
      setTab("1_step");
      setCurrentPage(1);
    }
    if (activeTab === 2) {
      setTab("2_step");
      setCurrentPage(1);
    }
    if (activeTab === 3) {
      setTab("1_step");
      setCurrentPage(1);
    }
    if (activeTab === 4) {
      setTab("2_step");
      setCurrentPage(1);
    }
  }, [activeTab]);

  // const active = activeTab === 1 ? "True" : activeTab === 2 ? "False" : null;
  // const active = null;

  // const phase =
  //   activeTab === 1
  //     ? "stage_1"
  //     : activeTab === 2
  //     ? "stage_2"
  //     : activeTab === 3
  //     ? "funded"
  //     : null;
  const handlePagination = async (pageNumber) => {
    dispatch(setIsLoading(true));
    let phaseVal = phase;
    let competitionVal = competition;
    let activeVal = active;

    if (activeTab === 0) {
      phaseVal = null;
      competitionVal = null;
    }
    if (activeTab === 1) {
      competitionVal = true;
    }
    if (activeTab === 2) {
      phaseVal = null;
      competitionVal = false;
    }
    // if (activeTab === 3) {
    //   phaseVal = { label: "freetrial", value: "free_trial" };
    //   competitionVal = null;
    // }

    if (pageNumber !== currentPage) {
      let searchValue = search;

      try {
        setAllTraders([]);
        const data = await getAllTradersRequest(
          idToken,
          searchValue,
          pageNumber,
          phaseVal,
          activeVal,
          competitionVal,
          tab,
          dispatch
        );

        setAllTraders(addPadding(data.results, pageNumber));
        setCurrentPage(pageNumber);
      } catch (error) {
        console.error("Error fetching traders:", error);
      }
    }
    dispatch(setIsLoading(false));
  };

  const fetchData = async (noSearchVal) => {
    dispatch(setActiveAccount(null));
    let phaseVal = phase;
    let competitionVal = competition;
    let activeVal = active;

    if (activeTab === 0) {
      phaseVal = null;
      competitionVal = null;
    }
    if (activeTab === 1) {
      competitionVal = true;
      phaseVal = null;
    }
    if (activeTab === 2) {
      phaseVal = null;
      competitionVal = false;
    }
    if (activeTab === 3) {
      phaseVal = { label: "freetrial", value: "funded" };
      competitionVal = null;
    }
    if (activeTab === 4) {
      phaseVal = { label: "freetrial", value: "funded" };
      competitionVal = null;
    }
    dispatch(setIsLoading(true));
    let searchValue = search;

    try {
      // const compData = await getCompDetails(idToken);
      // setCompList(compData);
      const data = await getAllTradersRequest(
        idToken,
        searchValue,
        currentPage ? currentPage : 1,
        phaseVal,
        activeVal,
        competitionVal,
        tab,
        dispatch
      );

      setTotalTraders(data.count);
      setAllTraders(data.results);
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error("Error fetching traders:", error);
      dispatch(setIsLoading(false));
    }
    console.log(phaseVal, activeTab, "phaseValphaseVal");
  };
  // useEffect(() => {
  //   fetchData()
  // }, []);

  useEffect(() => {
    fetchData();
  }, [active, activeTab, phase, competition, tab, currentPage, search]);

  const [searchedUser, setSearchUser] = useState([]);
  const [searchDropDown, setSearchDropDown] = useState(false);

  const handleSearch = async (value) => {
    // dispatch(setIsLoading(true));

    const searchValue = value.toLowerCase();
    // let active = null;
    // let competition = null;
    const response = await getAllTradersRequest(
      idToken,
      searchValue,
      currentPage ? currentPage : 1,
      phase,
      active,
      competition,
      tab,
      dispatch
    );

    setTotalTraders(response?.count);
    setAllTraders(addPadding(response?.results, currentPage));
    dispatch(setIsLoading(false));
  };
  // let debounceTimer;
  // const delayedSearch = (value) => {
  //   return new Promise((resolve) => {
  //     if (debounceTimer) {
  //       clearTimeout(debounceTimer);
  //     }

  //     debounceTimer = setTimeout(() => {
  //       handleSearch(value);
  //       debounceTimer = null;
  //       resolve();
  //     }, 1000);
  //   });
  // };
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  // let timeoutId;
  // const handleSearchChange = (e) => {
  //   clearTimeout(timeoutId);
  //   const value = e.target.value;

  //   timeoutId = setTimeout(() => {
  //     setSearch(value);
  //     setCurrentPage(1);
  //   }, 1500);
  // };

  const handleNavigateToOverView = (id) => {
    navigate(`/trader-overview/search/${id}`);
  };

  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());

  const dummyData = [];

  const inputRef = useRef(null);

  const handleResetClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setSearch(null);
    }
  };
  const [status, setStatus] = useState();
  return (
    <div className="alltable">
      <ToastContainer />
      <div className="alltable_header">
        {/* <div className="search_bar">
          <span
            onClick={handleResetClick}
            style={{ padding: "14px", color: "#1154ac", cursor: "pointer" }}
          >
            x
          </span>
          <input
            type="text"
            className="search_input"
            placeholder="Search with email or login Id"
            ref={inputRef}
            onChange={handleSearchChange}
            // value={search}
            // onChange={(e) => {
            //   setSearch(e.target.value);
            //   delayedSearch(e.target.value);
            // }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch(e.target.value);
              }
            }}
          />
        </div> */}

        <form
          className="search_container"
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(searchValue);
          }}
        >
          <input
            type="text"
            placeholder="Search..."
            onChange={handleSearchChange}
          />
          <button type="submit">
            <div style={{ padding: "3px 10px 0 0" }}>
              <SearchNew style={{ width: "100%" }} />
            </div>
          </button>
        </form>
        <div className="sort_buttons">
          {/* {activeTab === 1 && (
            <Select
              placeholder="Phase"
              classNamePrefix="react-select"
              options={phaseOptions}
              value={phase}
              onChange={(selectedOption) => {
                setCurrentPage(1);
                selectedOption.value === null
                  ? setPhase(null)
                  : setPhase(selectedOption);
              }}
            />
          )} */}
          {/* <button
            onClick={() => {
              // activeTab === 1 && setPhase(null);
              setCompetition(null);
              setActive(null);
            }}
            className={
              active === null && competition === null ? "activeBtn" : ""
            }
          >
            All
          </button> */}
          {/* <button
            onClick={() =>
              phase === "stage_1" ? setPhase(null) : setPhase("stage_1")
            }
            className={phase === "stage_1" ? "activeBtn" : ""}
          >
            Stage 1
          </button>
          <button
            onClick={() =>
              phase === "stage_2" ? setPhase(null) : setPhase("stage_2")
            }
            className={phase === "stage_2" ? "activeBtn" : ""}
          >
            Stage 2
          </button> */}

          {/* <button
            onClick={() =>
              competition === true ? setCompetition(null) : setCompetition(true)
            }
            className={competition === true ? "activeBtn" : ""}
          >
            Competition
          </button>
          <button
            onClick={() =>
              competition === false
                ? setCompetition(null)
                : setCompetition(false)
            }
            className={competition === false ? "activeBtn" : ""}
          >
            Instant Funding
          </button> */}
          <div style={{ flex: "1", display: "flex", gap: "10px" }}>
            {/* {actions.map((item) => (
              <button
                onClick={() => setStatus(item)}
                className={status === item.value ? "activeBtn" : ""}
              >
                {item.label}
              </button>
            ))} */}
            {/* <Select
              placeholder="Status"
              classNamePrefix="react-select"
              options={actions}
              value={status}
              onChange={(selectedOption) => {
                setStatus(selectedOption);
              }}
            /> */}
            {(activeTab === 0 ||
              activeTab === 1 ||
              activeTab === 2 ||
              activeTab === 3) && (
              <button
                onClick={() => {
                  setCurrentPage(1);
                  active === true ? setActive(null) : setActive(true);
                }}
                className={active === true ? "activeBtn" : ""}
              >
                Active
              </button>
            )}
            {(activeTab === 0 ||
              activeTab === 1 ||
              activeTab === 2 ||
              activeTab === 3) && (
              <button
                onClick={() => {
                  setCurrentPage(1);
                  active === false ? setActive(null) : setActive(false);
                }}
                className={active === false ? "activeBtn" : ""}
              >
                Inactive
              </button>
            )}
          </div>
        </div>
        {}
        {/* <div className="select_wrapper">
          <div
            className="calendar-button"
            onClick={() => setIsCalender((prev) => !prev)}
          >
            <img src={CalendarIcon} alt="calender icon" />
            <p>Past 90 Days</p>
            <img src={CaretDownIcon} alt="" className="arrow_down" />
          </div>

          <div className="calendar_wrapper">
            {isCalender && (
              <Calendar
                onChange={(date) => setDate(date)}
                value={date}
                // formatMonth={"MMM"}
                selectRange={true}
                showNeighboringMonth={false}
              />
            )}
          </div>
        </div> */}
      </div>
      {searchDropDown && (
        <div className="challenge_dropdown" ref={dropdownRef}>
          <ul>
            {searchedUser.map((user) => (
              <li
                key={user.email}
                style={{
                  position: "relative",
                  zIndex: "100",
                  backgroundColor: "#111142",
                }}
                onClick={() => {
                  handleNavigateToOverView(user.user_id);
                }}
              >
                {user.email}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="table_wrapper">
        <div className="table_scroll_wrapper">
          {allTraders.length > 0 ? (
            <Table
              active={active}
              admin={admin}
              compList={compList}
              tableData={allTraders || dummyData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalTraders={totalTraders}
              fetchData={fetchData}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
const handleCopyToClipboard = (text) => {
  toast("Copied email", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: "success",
    theme: "dark",
    className: "custom-toast-container",
  });
};
export default AllTable;

const Table = ({
  active,
  tableData,
  currentPage,
  setCurrentPage,
  totalTraders,
  compList,
  admin,
  fetchData,
}) => {
  const dispatch = useDispatch();
  const handleActiveAccount = (row) => {
    row.original?.login_id
      ? dispatch(setActiveAccount(row.original.login_id))
      : dispatch(setActiveAccount(null));
    dispatch(setActiveUser({ ...row.original }));
  };
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [isConfirmationDelete, setIsConfirmationDelete] = useState(false);
  const [userData, setUserData] = useState({});
  const handleUpgradeConfirm = (row) => {
    setUserData({
      user: row.original.user_id,
      challenge: row.original.challenge_id,
    });

    setIsConfirmation(true);
  };
  const handleUpdateConfirm = (row, type) => {
    setUserData({
      login_id: row.original.login_id,
      type: type.type,
    });

    setIsConfirmationDelete(true);
  };

  const idToken = useSelector((state) => state.auth.idToken);
  const handleUpgradeAccount = async () => {
    dispatch(setIsLoading(true));
    const response = await upgradeUserAccountReq(idToken, userData).then(
      (response) => {
        response.status < 399
          ? dispatch(returnMessages("User Created Successfully", 201))
          : dispatch(returnErrors("Failed to Create User", 400));
      }
    );
    fetchData();
  };
  const handleUpdateAccount = async () => {
    dispatch(setIsLoading(true));
    const response = await updateUserAccountReq(idToken, userData).then(
      (response) => {
        let msg = response?.data?.detail || "Account Updated Successfully";
        response.status < 399
          ? dispatch(returnMessages(msg, 201))
          : dispatch(returnErrors("Failed to Update Account", 400));
      }
    );
    fetchData();
  };

  const data = tableData;

  const columns = React.useMemo(
    () =>
      [
        {
          Header: () => (
            <>
              <div className="column_header">Email</div>
            </>
          ),
          accessor: "Name",
          Cell: ({ row }) => {
            const [randomColor, setRandomColor] = useState();
            const getRandomColor = `#${Math.floor(
              Math.random() * 16777215
            ).toString(16)}`;
            if (!randomColor) {
              setRandomColor(getRandomColor);
            }
            const navigate = useNavigate();
            return (
              <div
                onClick={() => {
                  handleActiveAccount(row);
                  navigate("/traders-list-2");
                }}
              >
                <div className="column_one_wrapper">
                  <div
                    className="sno_wrapper"
                    style={{ backgroundColor: randomColor }}
                  >
                    {row.original?.email?.charAt(0)}
                  </div>
                  <div>{row.original?.email}</div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <CopyToClipboard
                      onCopy={(e) => {
                        handleCopyToClipboard();
                      }}
                      text={row.original?.email}
                    >
                      {/* <button> */}
                      <p
                        className="table_copy_button"
                        style={{
                          marginRight: "20px",
                          // backgroundColor: "#35d269",
                          cursor: "pointer",
                        }}
                        // onClick={() => handleCopyToClipboard(row.original?.payment_id)}
                      >
                        {/* <ContentCopyIcon sx={{fontSize: "20px", backgroundColor: "#35d269", color: "black"}} /> */}
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="24" height="24" rx="12" fill="#FFF" />
                          <path
                            d="M17.886 12.574C18 12.2987 18 11.9487 18 11.25C18 10.5513 18 10.2013 17.886 9.926C17.8106 9.74395 17.7001 9.57854 17.5608 9.43922C17.4215 9.29989 17.256 9.18938 17.074 9.114C16.7987 9 16.4487 9 15.75 9H11.4C10.56 9 10.14 9 9.81933 9.16333C9.53684 9.30719 9.30719 9.53684 9.16333 9.81933C9 10.1393 9 10.5593 9 11.4V15.75C9 16.4487 9 16.7987 9.114 17.074C9.26667 17.4413 9.55867 17.734 9.926 17.886C10.2013 18 10.5513 18 11.25 18C11.9487 18 12.2987 18 12.574 17.886M17.886 12.574C17.8106 12.756 17.7001 12.9215 17.5608 13.0608C17.4215 13.2001 17.256 13.3106 17.074 13.386C16.7987 13.5 16.4487 13.5 15.75 13.5C15.0513 13.5 14.7013 13.5 14.426 13.614C14.244 13.6894 14.0785 13.7999 13.9392 13.9392C13.7999 14.0785 13.6894 14.244 13.614 14.426C13.5 14.7013 13.5 15.0513 13.5 15.75C13.5 16.4487 13.5 16.7987 13.386 17.074C13.3106 17.256 13.2001 17.4215 13.0608 17.5608C12.9215 17.7001 12.756 17.8106 12.574 17.886M17.886 12.574C17.5275 13.8107 16.8678 14.9391 15.9661 15.8582C15.0645 16.7774 13.9489 17.4585 12.7193 17.8407L12.574 17.886M15 9V8.4C15 7.56 15 7.14 14.8367 6.81933C14.693 6.53694 14.4636 6.3073 14.1813 6.16333C13.86 6 13.44 6 12.6 6H8.4C7.56 6 7.14 6 6.81933 6.16333C6.53684 6.30719 6.30719 6.53684 6.16333 6.81933C6 7.13933 6 7.55933 6 8.4V12.6C6 13.44 6 13.86 6.16333 14.1807C6.30733 14.4633 6.53667 14.6927 6.81933 14.8367C7.13933 15 7.56 15 8.40067 15H9"
                            stroke="#36D66B"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      {/* </button> */}
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            );
          },
        },
        // {
        //   Header: () => (
        //     <>
        //       <div className="column_header">Email</div>
        //     </>
        //   ),
        //   accessor: "email",
        // },
        {
          Header: () => (
            <>
              <div className="column_header">Login ID</div>
            </>
          ),
          accessor: "login_id",
          Cell: ({ row }) => {
            return (
              <Link
                onClick={() => handleActiveAccount(row)}
                to="/traders-list-2"
              >
                {row.original.login_id}
              </Link>
            );
          },
        },
        // {
        //   Header: () => (
        //     <>
        //       <div className="column_header">Challenge</div>
        //     </>
        //   ),
        //   accessor: "challenge",
        // },

        {
          Header: "Start Date",
          accessor: "start_date",
          Cell: ({ row }) => {
            return (
              <Link
                onClick={() => handleActiveAccount(row)}
                to="/traders-list-2"
              >
                {moment(row.original?.start_date).format("DD-MM-YYYY")}
              </Link>
            );
          },
        },
        // {
        //   Header: "Phase",
        //   accessor: "phase",
        //   Cell: ({ row }) => {
        //     return (
        //       <Link
        //         onClick={() => handleActiveAccount(row)}
        //         to="/traders-list-2"
        //       >
        //         {" "}
        //         {row.original?.phase?.replace(/_/g, " ")}
        //       </Link>
        //     );
        //   },
        // },
        {
          Header: () => (
            <>
              <div className="column_header">Platform</div>
            </>
          ),
          accessor: "trading_platform",
          Cell: ({ row }) => {
            return (
              <Link
                onClick={() => handleActiveAccount(row)}
                to="/traders-list-2"
                style={{ textTransform: "capitalize" }}
              >
                {" "}
                {row.original?.trading_platform}
              </Link>
            );
          },
        },
        {
          Header: "Challenge",
          accessor: "challenge",
          Cell: ({ row }) => {
            return (
              <Link
                onClick={() => handleActiveAccount(row)}
                to="/traders-list-2"
              >
                {" "}
                {row.original?.challenge}
              </Link>
            );
          },
        },
        {
          Header: "Challenge Type",
          accessor: "challenge_type",
          Cell: ({ row }) => {
            return (
              <Link
                onClick={() => handleActiveAccount(row)}
                to="/traders-list-2"
              >
                {" "}
                {formatText(row.original?.challenge_type)}
              </Link>
            );
          },
        },
        {
          Header: "Phase",
          accessor: "phase",
          Cell: ({ row }) => {
            return (
              <Link
                onClick={() => handleActiveAccount(row)}
                to="/traders-list-2"
              >
                {" "}
                {formatText(row.original?.phase)}
              </Link>
            );
          },
        },
        {
          Header: () => (
            <>
              <div className="column_header">Status</div>
            </>
          ),
          accessor: "is_active",
          Cell: ({ row, value }) => {
            return (
              <Link
                onClick={() => handleActiveAccount(row)}
                to="/traders-list-2"
              >
                <div
                  className={`status_wrapper ${
                    value ? statusClassMap.succeeded : "Inactive_tag"
                  }`}
                >
                  {row.original.is_active ? "Active" : "Disabled"}
                </div>
              </Link>
            );
          },
        },
        // admin && {
        //   Header: "Actions",
        //   accessor: "",
        //   Cell: ({ row }) => {
        //     return (
        //       <div>
        //         {/* <button
        //           className="upgrade_button"
        //           onClick={() => {
        //             handleUpgradeConfirm(row);
        //           }}
        //         >
        //           Create Account
        //         </button> */}
        //         <button
        //           className="upgrade_button"
        //           style={{
        //             background: row.original.is_active ? "#df2935" : "#36d66b",
        //           }}
        //           onClick={() => {
        //             handleUpdateConfirm(row, {
        //               type: row.original.is_active
        //                 ? "disable"
        //                 : "reinstantiate",
        //             });
        //           }}
        //         >
        //           {row.original.is_active ? "Disable" : "Activate"}
        //         </button>
        //       </div>
        //     );
        //   },
        // },
        {
          Header: "Action",
          accessor: "",
          Cell: ({ row }) => {
            const [anchorEl, setAnchorEl] = React.useState(null);
            const [confirmationModal, setConfirmationModal] =
              React.useState(false);
            const [confirmationModal1, setConfirmationModal1] =
              React.useState(false);

            const open = Boolean(anchorEl);
            const handleClick = (event) => {
              setAnchorEl(event.currentTarget);
            };
            const handleClose = () => {
              setAnchorEl(null);
            };
            const dispatch = useDispatch();

            const [data, setData] = useState();
            const [email, setEmail] = useState();
            const handleStatus = async (e) => {
              setConfirmationModal(true);
              setData({ status: e.active ? "disable" : "enable" });
              setEmail(e.email);
            };
            const handleSendCredential = async (row) => {
              const login_id = { login_id: row.original.login_id };
              const response = await sendCredential(idToken, login_id);
              if (response?.status < 399) {
                dispatch(
                  returnMessages(response?.data?.detail, response?.status)
                );
              } else {
                let msg =
                  response?.response?.data?.detail || "Something went wrong";
                dispatch(returnErrors(msg, 400));
              }
            };

            return (
              <div>
                {confirmationModal && (
                  <ConfirmationModal
                    setConfirmationModal={setConfirmationModal}
                  />
                )}
                {confirmationModal1 && (
                  <ConfirmationModal
                    setConfirmationModal={setConfirmationModal1}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      minWidth: "150px",
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {/* <MenuItem onClick={() => handleUpgradeConfirm(row)}>
                    Create Account
                  </MenuItem> */}
                  <MenuItem onClick={() => handleSendCredential(row)}>
                    Send Credential
                  </MenuItem>
                  {admin && (
                    <MenuItem
                      onClick={() =>
                        handleUpdateConfirm(row, {
                          type: row.original.is_active
                            ? "disable"
                            : "reinstantiate",
                        })
                      }
                    >
                      {row.original.is_active ? "Disable" : "Activate"}
                    </MenuItem>
                  )}
                </Menu>
              </div>
            );
          },
        },
        // {
        //   Header: "User Action",
        //   accessor: "User Action",
        //   Cell: () => (
        //     <div className="column_data">
        //       <img src={Edit} alt="Edit icon" />
        //       <img src={DeleteIcon} alt="delete icon" />
        //     </div>
        //   ),
        // },
      ].filter(Boolean),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });
  const totalPages = Math.ceil(totalTraders / 20);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePagination = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleChange = (e, value) => {
    setCurrentPage(value);
  };
  const [gotoPageInput, setGotoPageInput] = useState();

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setGotoPageInput("");
    }
  };
  return (
    <>
      <div className="table_scroll">
        {isConfirmation && (
          <ConfirmationModal
            setConfirmationModal={setIsConfirmation}
            onConfirm={handleUpgradeAccount}
          />
        )}
        {isConfirmationDelete && (
          <ConfirmationModal
            setConfirmationModal={setIsConfirmationDelete}
            onConfirm={handleUpdateAccount}
          />
        )}
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="spacerT30p"></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "20px 0px",
        }}
      >
        <Pagination
          onChange={handleChange}
          count={totalPages}
          variant="outlined"
          page={currentPage}
          color="primary"
        />
        <div className="goto-page-input">
          <input
            type="number"
            placeholder="Go to Page"
            value={gotoPageInput}
            onChange={(e) => setGotoPageInput(e.target.value)}
          />
          <button className="goToButton" onClick={handleGotoPage}>
            Go
          </button>
        </div>
      </div>
    </>
  );
};
