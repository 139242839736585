import React, { useEffect } from "react";
import "./style.scss";
import CompetitionDetailsBox from "../CompetitionDetailsBox";
import FirstPrize from "../../../assets/icons/trophy-green.svg";
import TrophyBlue from "../../../assets/icons/trophy-blue.svg";
import ThirdPrize from "../../../assets/icons/medal-orange.svg";
import bgImg1 from "../../../assets/images/competition-bg-img.png";
import bgImg2 from "../../../assets/images/competition-bg-img2.png";
import { useDispatch, useSelector } from "react-redux";
import { getCompData } from "../../../store/reducers/compSlice";
import { getCompDetails } from "../../../utils/api/apis";
import moment from "moment";

function Ongoing({  setModalVisible, setErrorModalVisible }) {

  const compData = useSelector((state)=> state.comp.compData)

  const today = moment();

  const data =
    (Array.isArray(compData) &&
      compData?.filter(
        (comp) =>
          moment(comp.end_date) > today && moment(comp.start_date) < today
      )) ||
    [];

  return (
    <div className="ongoing">
      {data &&
        data?.map((competition, index) => (
          <CompetitionDetailsBox
            key={index}
            competition={competition}
            status={"Ongoing"}
            setModalVisible={setModalVisible}
            setErrorModalVisible={setErrorModalVisible}
          />
        ))}
    </div>
  );
}

export default Ongoing;
