import React from "react";
import './style.scss'


import { Navigate, Route, Routes } from "react-router-dom";

import GeneralInformation from "../../components/MyProfile/GeneralInformation";
import Verification from "../../components/MyProfile/Verification/Index";
import Billing from "../../components/MyProfile/Billing";
import Payout from "../../components/MyProfile/Payout";
import Tabination from "../../reusableComponents/Tabination";

function MyProfile() {
  const items = [
    {
      name: "General Information",
      path: "general-information",
    },
    {
      name: "Verification",
      path: "verification",
    },
    {
      name: "Billing",
      path: "billing",
    },
    {
      name: "Payout",
      path: "payout",
    },
  ];

  return (
    <>
      <Tabination variant={3} tabItems={items} />
      <div className="myprofile">
        <Routes>
          <Route path="/" element={<Navigate to="general-information" replace={true} />} />
          <Route path="general-information" element={<GeneralInformation />} />
          <Route path="verification" element={<Verification />} />
          <Route path="billing" element={<Billing />} />
          <Route path="payout" element={<Payout />} />
        </Routes>
      </div>
    </>
  );
}

export default MyProfile;

