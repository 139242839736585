import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  getPlans,
  getPlansSuccess,
  getPlansFailure,
  setSelectedChallenge,
  getPaymentHistory,
  getPaymentHistorySuccess,
  getPaymentHistoryFailure,
  getCompetitionList,
  getCompetitionListSuccess,
  getCompetitionListFailure,
  getAccountList,
  getAccountListFailure,
  getAccountListSuccess,
  getAccountMetrics,
  getAccountMetricsSuccess,
  getAccountMetricsFailure,
  getPayoutList,
  getPayoutListSuccess,
  getPayoutListFailure,
  getTraderJournal,
  getTraderJournalSuccess,
  getTraderJournalFailure,
} from "../../reducers/accountSlice";
import { getUser } from "../../reducers/userSlice";
import {
  getFundingEvaluationPlansRequest,
  getPaymentHistoryRequest,
  getCompetitionListRequest,
  getUserAccountListRequest,
  getAccountMetricsRequest,
  getBalanceChartRequest,
  getPayoutListRequest,
  getTraderJournalRequest,
} from "../../../utils/api/apis";
import { setIsLoading } from "../../reducers/authSlice";

function* fetchPlans(action) {
 
  try {
    const plans = yield call(getFundingEvaluationPlansRequest, action.payload);

    yield put(getPlansSuccess(plans));
  } catch (error) {
    yield put(getPlansFailure(error));
  }
}

function* fetchPaymentHistory(action) {
 
  try {
    const paymentHistory = yield call(getPaymentHistoryRequest, action.payload);

    yield put(getPaymentHistorySuccess(paymentHistory));
  } catch (error) {
    yield put(getPaymentHistoryFailure(error));
  }
}

function* fetchCompetitionList(action) {
 
  try {
    const competitionList = yield call(
      getCompetitionListRequest,
      action.payload
    );

    yield put(getCompetitionListSuccess(competitionList));
  } catch (error) {
    yield put(getCompetitionListFailure(error));
  }
}
function* fetchAccountList(action) {
 
  try {
    const accountList = yield call(getUserAccountListRequest, action.payload);

    yield put(getAccountListSuccess(accountList));
  } catch (error) {
    yield put(getAccountListFailure(error));
  }
}
function* fetchAccountMetrics(action) {
  yield put(setIsLoading(true));
  try {
    const accountMetrics = yield call(getAccountMetricsRequest, action.payload);
    const balance_chart = yield call(getBalanceChartRequest, action.payload);
   
    const data = {
      ...accountMetrics.data,
      balance_chart: balance_chart,
    };
    if (accountMetrics?.response?.status >= 400) {
      yield put(getAccountMetricsFailure());
      yield put(setIsLoading(false));
    } else {
      yield put(getAccountMetricsSuccess(data));
      yield put(setIsLoading(false));
    }
  } catch (error) {
    yield put(getAccountMetricsFailure());
    yield put(setIsLoading(false));
  }
}
function* fetchPayoutList(action) {
 
  try {
    const payoutList = yield call(getPayoutListRequest, action.payload);

    yield put(getPayoutListSuccess(payoutList));
  } catch (error) {
    yield put(getPayoutListFailure(error));
  }
}

function* fetchTraderJournal(action) {
 
  try {
    const traderJournal = yield call(getTraderJournalRequest, action.payload);
    yield put(getTraderJournalSuccess(traderJournal));
  } catch (error) {
    yield put(getTraderJournalFailure(error));
  }
}

export default function* accountSaga() {
  yield all([
    takeEvery(getPlans.type, fetchPlans),
    takeEvery(getPaymentHistory.type, fetchPaymentHistory),
    takeEvery(getCompetitionList.type, fetchCompetitionList),
    takeEvery(getAccountList.type, fetchAccountList),
    takeLatest(getAccountMetrics.type, fetchAccountMetrics),
    takeEvery(getPayoutList.type, fetchPayoutList),
    takeEvery(getTraderJournal.type, fetchTraderJournal),
    takeEvery(getUser.type, function* (action) {
      yield all([
        call(fetchAccountList, action),
        call(fetchAccountMetrics, action),
      ]);
    }),
  ]);
}
