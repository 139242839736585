import React, { useState } from 'react'
import './style.scss'
import DropdownIcon from '../../../assets/icons/dropdown-arrow-gray.svg'
import { usePagination, useTable } from 'react-table';
import { Link } from 'react-router-dom';
import CalendarIcon from '../../../assets/icons/calender.svg'
import CaretDownIcon from "../../../assets/icons/caret-down.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import Calendar from '../../../reusableComponents/Calendar';
import GreenChart from '../../../assets/icons/green-chart.svg'

function TradingProfit10() {

    const [isCalender, setIsCalender] = useState(false);
    const [date, setDate] = useState(new Date());


    const columns = React.useMemo(
        () => [
          {
            Header: 'S.No',
            accessor: 'sno',
            Cell: ({ value }) => {
              const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
              
              return (
                <div className="column_one_wrapper">
                  <div className="sno_wrapper" style={{ backgroundColor: randomColor }}>
                    {value}
                  </div>
                </div>
              );
            },
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Profit',
            accessor: 'profit',
            Cell: ({ value }) => (
              <div className="column_data">
                {value}
                <img src={GreenChart} alt="Profit" />
              </div>
            ),
          },
          {
            Header: 'Payout',
            accessor: 'payout',
          },
          {
            Header: 'Date',
            accessor: 'date',
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: () => (
              <button className="view_details_btn">View Details</button>
            ),
          },
        ],
        []
      );
      
      const data = React.useMemo(() => [
        { sno: '1', email: 'john.doe@example.com', name: 'John Doe', profit: '$500', payout: '$100', date: '2023-07-01', status: 'Active' },
        { sno: '2', email: 'jane.smith@example.com', name: 'Jane Smith', profit: '$600', payout: '$120', date: '2023-07-02', status: 'Inactive' },
        { sno: '3', email: 'michael.johnson@example.com', name: 'Michael Johnson', profit: '$700', payout: '$140', date: '2023-06-25', status: 'Active' },
        { sno: '4', email: 'emily.williams@example.com', name: 'Emily Williams', profit: '$550', payout: '$110', date: '2023-07-05', status: 'Active' },
        { sno: '5', email: 'robert.lee@example.com', name: 'Robert Lee', profit: '$450', payout: '$90', date: '2023-07-12', status: 'Inactive' },
        { sno: '6', email: 'ava.davis@example.com', name: 'Ava Davis', profit: '$800', payout: '$160', date: '2023-06-29', status: 'Active' },
        { sno: '7', email: 'william.clark@example.com', name: 'William Clark', profit: '$750', payout: '$150', date: '2023-07-08', status: 'Inactive' },
        { sno: '8', email: 'olivia.hall@example.com', name: 'Olivia Hall', profit: '$950', payout: '$190', date: '2023-06-30', status: 'Active' },
        { sno: '9', email: 'james.baker@example.com', name: 'James Baker', profit: '$850', payout: '$170', date: '2023-07-15', status: 'Active' },
        { sno: '10', email: 'sophia.turner@example.com', name: 'Sophia Turner', profit: '$700', payout: '$140', date: '2023-06-28', status: 'Inactive' },
      ], []);
    return (
        <div className='trading_profit'>
            <div className='alltable'>
                <div className='alltable_header'>
                    <h3>Trading Profit</h3>
                    <div className="select_wrapper">
                        <div
                            className="calendar-button"
                            onClick={() => setIsCalender((prev) => !prev)}
                        >
                            <img src={CalendarIcon} alt="calender icon" />
                            <p>Past 90 Days</p>
                            <img src={CaretDownIcon} alt="" className='arrow_down' />
                        </div>

                        <div className="calendar_wrapper">
                            {isCalender && (
                                <Calendar
                                    onChange={(date) => setDate(date)}
                                    value={date}
                                    // formatMonth={"MMM"}
                                    selectRange={true}
                                    showNeighboringMonth={false}
                                />
                            )}
                        </div>

                    </div>

                </div>
                <div className='table_wrapper'>
                    <div className='table_scroll_wrapper'>
                        <Table columns={columns} data={data} /> </div>
                </div>
            </div>    </div>
    )
}


export default TradingProfit10


const Table = ({ columns, data }) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 7 },
        },
        usePagination
    );

    return (
        <>
            <div className='table_scroll'>
                <table {...getTableProps()} className="table">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>
                                            <Link to='/traders-list-2'> {cell.render('Cell')} </Link></td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="pagination">
                    <img src={CaretLeftIcon} alt="" />
                    <button onClick={previousPage} disabled={!canPreviousPage}>
                        Previous
                    </button>
                    {pageOptions.map((pageNumber, index) => (
                        <>
                            <button
                                key={index}
                                onClick={() => gotoPage(index)}
                                className={pageIndex === index ? "btn-active" : "non-active"}
                            >
                                {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
                            </button>
                        </>
                    ))}
                    <button onClick={nextPage} disabled={!canNextPage}>
                        Next
                    </button>
                    <img src={CaretRightIcon} alt="" />
                </div>
            </div>
        </>
    );
};