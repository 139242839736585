import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { usePagination, useTable } from "react-table";
import EditIcon from "../../assets/icons/edit.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteCoupon, getCouponData } from "../../store/reducers/couponSlice";
import { Link, useNavigate } from "react-router-dom";
import { setIsLoading } from "../../store/reducers/authSlice";
import { ReactComponent as SearchNew } from "../../assets/icons/search-svgrepo-com (1).svg";

import CreateIcon from "../../assets/icons/plus-circle-blue.svg";
import { Pagination } from "@mui/material";
function CouponTable() {
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const [filteredCoupon, setFilteredCoupon] = useState(null);
  const couponRedux = useSelector((state) => state.coupon.coupon);
  const [coupon, setCoupon] = useState([]);
  const [inputText, setInputText] = useState(null);
  useEffect(() => {
    setCoupon(couponRedux);
  }, [couponRedux]);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getCouponData({ idToken: idToken }));
    dispatch(setIsLoading(false));
  }, [idToken]);

  // filter logic

  useEffect(() => {
    if (inputText && inputText.trim() !== "") {
      const isValidInput = /[a-zA-Z0-9]/.test(inputText.trim());

      if (isValidInput) {
        const filteredDataCoupon = coupon?.filter((el) => {
          return el.code.toLowerCase().includes(inputText.toLowerCase());
        });

        setFilteredCoupon(filteredDataCoupon);
      } else {
        setFilteredCoupon(null);
      }
    } else {
      setFilteredCoupon(null);
    }
  }, [inputText]);

  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);
  const [deleteConfirmationModalContent, setDeleteConfirmationModalContent] =
    useState(null);
  return (
    <div className="coupon_table">
      <div className="table_scroll_wrapper">
        <Table
          inputText={inputText}
          setInputText={setInputText}
          coupon={filteredCoupon || coupon || []}
          setDeleteConfirmationModalOpen={setDeleteConfirmationModalOpen}
          setDeleteConfirmationModalContent={setDeleteConfirmationModalContent}
        />
        {isDeleteConfirmationModalOpen && (
          <DeleteModal
            isModalOpen={isDeleteConfirmationModalOpen}
            closeModal={() => setDeleteConfirmationModalOpen(false)}
            deleteContent={deleteConfirmationModalContent}
            idToken={idToken}
          />
        )}
      </div>
    </div>
  );
}

export default CouponTable;

const Table = ({
  inputText,
  setInputText,
  coupon,
  setDeleteConfirmationModalOpen,
  setDeleteConfirmationModalContent,
}) => {
  const [filter, setFilter] = useState("All");
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());

  const data = coupon || [];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteAction = (id) => {
    setDeleteConfirmationModalContent(id);
    setDeleteConfirmationModalOpen(true);
  };
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const [code, setCode] = useState();

  const handleDelete = (couponCode) => {
    setCode(couponCode);
    // console.log(code);
  };

  const handleFilter = (status) => {
    setFilter(status);
  };
  const [filteredData, setFilteredData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (filter === "All") {
      setFilteredData(data);
    } else if (typeof filter === "boolean") {
      setFilteredData(data.filter((item) => item.is_active === filter));
    }
  }, [data, filter]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredData?.length / 21));
  }, [filteredData]);
  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
        Cell: ({ value }) => <td className="code">{value}</td>,
      },
      { Header: "Amount", accessor: "amount" },
      { Header: "Coupon %", accessor: "percent" },
      { Header: "Coupon Expiry", accessor: "expiry" },
      {
        Header: "Status",
        accessor: "is_active",
        Cell: ({ value, row }) => (
          <div className="status_wrapper">
            <button
              className={`${value ? "Active_tag" : "Inactive_tag"}`}
              onClick={() => handleDelete(row.original.code)}
            >
              {value ? "Active" : "Inactive"}
            </button>
          </div>
        ),
      },
      {
        Header: "Public",
        accessor: "public",
        Cell: ({ value, row }) => (
          <div className="status_wrapper">
            <button
              className={`${value ? "Active_tag" : "Inactive_tag"}`}
              onClick={() => handleDelete(row.original.code)}
            >
              {value ? "True" : "False"}
            </button>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "delete",
        Cell: ({ value, row }) => (
          <div
            style={{
              display: "flex",
              gap: "14px",
              alignItems: "center",
            }}
          >
            {/* <Link to={`/edit-coupon/${row.original.id}`}>
              {" "}
              <img src={EditIcon} alt="EditIcon" />{" "}
            </Link> */}
            {/* for new UI */}
            <p
              onClick={() =>
                navigate(`/coupons/create-coupon/${row.original.id}`, {
                  state: row.original,
                })
              }
            >
              <img src={EditIcon} alt="EditIcon" />{" "}
            </p>
            <img
              style={{ height: "20px", cursor: "pointer" }}
              onClick={() => handleDeleteAction(row.original.code)}
              src={DeleteIcon}
              alt=""
            />
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, pageCount },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
  } = useTable(
    { columns, data: filteredData, initialState: { pageIndex: 0 } },
    usePagination
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    gotoPage(value - 1);
  };

  const [gotoPageInput, setGotoPageInput] = useState();

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      gotoPage(pageNumber - 1);
      setCurrentPage(pageNumber);
      setGotoPageInput("");
    }
  };
  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  return (
    <div className="table_wrapper">
      <div className="table_header">
        <form
          className="search_container"
          onSubmit={(e) => {
            e.preventDefault();
            setInputText(searchValue);
          }}
        >
          <input
            type="text"
            placeholder="Search..."
            onChange={handleSearchChange}
          />
          <button type="submit">
            <div style={{ width: "20px", padding: "3px 10px 0 0" }}>
              <SearchNew style={{ width: "100%" }} />
            </div>
          </button>
        </form>
        <div className="filter_buttons">
          <div className="buttons">
            {" "}
            <button
              className={filter === "All" ? "active_btn" : ""}
              onClick={() => handleFilter("All")}
            >
              All
            </button>
            <button
              className={filter === true ? "active_btn" : ""}
              onClick={() => handleFilter(true)}
            >
              Active
            </button>
            <button
              className={filter === false ? "active_btn" : ""}
              onClick={() => handleFilter(false)}
            >
              Inactive
            </button>
          </div>

          <Link to="/coupons/create-coupon">
            {" "}
            <div className="create_wrapper">
              <img className="create_icon" src={CreateIcon} alt="create" />
              <p>Create Coupon</p>
            </div>{" "}
          </Link>
        </div>
      </div>
      <div className="table_scroll">
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      className={`${
                        cell.column.Header === "Status" ? "status_column" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>{" "}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "20px 0px",
        }}
      >
        <Pagination
          onChange={handleChange}
          count={totalPages}
          variant="outlined"
          page={currentPage}
          color="primary"
        />
        <div className="goto-page-input">
          <input
            type="number"
            placeholder="Go to Page"
            value={gotoPageInput}
            onChange={(e) => setGotoPageInput(e.target.value)}
          />
          <button className="goToButton" onClick={handleGotoPage}>
            Go
          </button>
        </div>
      </div>
    </div>
  );
};

const DeleteModal = ({
  isModalOpen,
  closeModal,
  deleteContent,
  onConfirmDelete,
  idToken,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteCoupon({ idToken: idToken, code: deleteContent }));
    closeModal();
  };

  return (
    <div className="confirmationModal_wrapper">
      <h2>Are you sure?</h2>
      <div className="buttons_wrapper">
        <button className="confirm" onClick={handleDelete}>
          Confirm
        </button>
        <button className="cancel" onClick={closeModal}>
          No
        </button>
      </div>
    </div>
  );
};
