import React, { useEffect, useState } from "react";
import "./style.scss";
import StageStatistics from "../../AdminOverview/StageStatistics";
import PassRate from "./PassRate";
import InfoBox from "../../../reusableComponents/InfoBox";
import ArrowIncrease from "../../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../../assets/icons/arrow-decrease.svg";
import ReactApexChart from "react-apexcharts";
import Tabination from "../../../reusableComponents/Tabination";
import Select from "react-select";
import IncomeStatistics from "./IncomeStatistics";
import { getOverviewReq } from "../../../utils/api/apis";
import { useDispatch, useSelector } from "react-redux";
import { returnMessages } from "../../../store/reducers/message";
import { returnErrors } from "../../../store/reducers/error";
import { setIsLoading } from "../../../store/reducers/authSlice";
import { formatCurrency } from "../../../utils/helpers/string";
function Stage1({ phase }) {
  const [data, setData] = useState({});
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const fetch = async () => {
    dispatch(setIsLoading(true));
    const response = await getOverviewReq(idToken, phase).then(
      (response) => (response?.status < 399 ? setData(response?.data) : null)

      // dispatch(returnErrors("Failed to fetch Overview", 400))
    );
    dispatch(setIsLoading(false));
  };
  useEffect(() => {
    fetch();
  }, [phase]);

  const options = [
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
    { value: "quarterly", label: "Quarterly" },
  ];

  const dateOptions = [{ value: "d", label: "Dec 2023" }];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "110px",
      height: "20px",
      boxSizing: "content-box",
      fontSize: "12px",
      color: "#133C55",
      fontWeight: "500",
      fontFamily: "Space Grotesk",
      borderRadius: "8px",
      float: "right",
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "#133C55",
        width: "20px",
      }),
    }),
  };

  const infoBoxArr = [
    {
      title: "Total Payouts",
      value: formatCurrency(data.total_payout),
      icon: ArrowIncrease,
      // percentage: "+2.53%",
      color: "#F75C03",
    },
    {
      title: "Funded A/C Current Profits",
      value: formatCurrency(data.funded_account_profit),
      icon: ArrowIncrease,
      // percentage: "-1.2%",
      color: "#4CB944",
    },
    {
      title: "No. of Active funded A/c",
      value: data.active_funded_account,
      icon: ArrowIncrease,
      // percentage: "+5.8%",
      color: "#36d66b",
    },
    {
      title: "Win Rate",
      value: data.win_rate,
      icon: ArrowDecrease,
      // percentage: "+10%",
      color: "#DF2935",
    },
  ];

  return (
    <div className="overview">
      <div className="overview_infobox">
        {infoBoxArr.map((info) => (
          <InfoBox
            key={info.title}
            title={info.title}
            value={info.value}
            icon={info.icon}
            percentage={info.percentage}
            color={info.color}
          />
        ))}
      </div>
      <div className="overview_top">
        <div>
          <PassRate
            title="Pass Rate"
            chartData={data.pass_rate_chart}
            chartType={"line"}
          />
        </div>
        {/* <div>
          <IncomeStatistics />
        </div> */}
      </div>

      {/* <div className="overview_bottom">
        <div className="overview_bottom_header">
          <h2>Pass Rate</h2>
          <div className="overview_bottom_header_tabs">
            <Tabination
              variant={2}
              tabItems={[]}
              isChart={true}
              isToolbar={true}
            />
          </div>
          <Select
            options={options}
            value={options[0]}
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null,
            }}
            menuPortalTarget={document.body}
            menuPlacement="bottom"
            menuPosition="fixed"
            menuStyle={{ width: "120px", zIndex: "100" }}
          />
        </div>
        <ProfitChart />
      </div> */}
    </div>
  );
}

export default Stage1;

const options1 = {
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
  },
  series: [
    {
      name: "Pass",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
    {
      name: "Fail",
      data: [35, 60, 50, 55, 54, 65, 75, 96],
    },
  ],
  legend: {
    position: "bottom",
    horizontalAlign: "left",
    offsetY: 10,
    offsetX: -10,
    itemMargin: {
      horizontal: 16,
    },
  },
  xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
  },
  colors: ["#000000", "#00FF00", "#0000FF"],
  dataLabels: {
    enabled: false,
  },

  stroke: {
    curve: "smooth",
    width: 2,
  },
};

function ProfitChart() {
  const series = [
    {
      name: "Website Blog",
      type: "column",
      data: [10, 40, 20, 40, 10, 30, 20],
    },
    {
      name: "Social Media",
      type: "line",
      data: [20, 40, 60, 45, 25, 22, 17, 31, 70, 80, 12, 16],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
      },
      y: {
        show: true,
      },
    },
    stroke: {
      curve: "straight",
      width: [0, 4],
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    labels: [
      "01 Jan 2001",
      "02 Jan 2001",
      "03 Jan 2001",
      "04 Jan 2001",
      "05 Jan 2001",
      "06 Jan 2001",
      "07 Jan 2001",
      "08 Jan 2001",
      "09 Jan 2001",
      "10 Jan 2001",
      "11 Jan 2001",
      "12 Jan 2001",
    ],
    xaxis: {
      type: "datetime",
    },
    yaxis: [
      {
        title: {
          text: "Website Blog",
        },
      },
      {
        opposite: true,
        title: {
          text: "Social Media",
        },
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
      fontWeight: 700,
    },
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={400} />
  );
}
