import React, {useState} from "react";
import "./style.scss";
import {usePagination, useTable} from "react-table";
import ChartGain from "../../assets/images/chart-rep-gain.png";
import ChartLoss from "../../assets/images/chart-rep-loss.png";
import CalenderIcon from "../../assets/icons/calender.svg";
import Search from "../../assets/icons/search.svg";
import CreateIcon from "../../assets/icons/plus-circle-blue.svg";
import CaretLeftIcon from "../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../assets/icons/caret-right.svg";
import GreenTickIcon from "../../assets/icons/tick-green-circle.svg";
import InProgressIcon from "../../assets/icons/clock-progress.svg";
import ErroIcon from "../../assets/icons/warning-red.svg";
import StarBlueIcon from "../../assets/icons/star-blue-icon.svg";
import StarGreyIcon from "../../assets/icons/star-gray-icon.svg";
import ArrowUpIcon from "../../assets/icons/arrow-up-white.svg";
import ThreeDotsIcon from "../../assets/icons/three-dots.svg";
import {Link} from "react-router-dom";
import {Fragment} from "react";
import {useMemo} from "react";
import Tabination from "../../reusableComponents/Tabination";

function FundingPg() {
  const items = [
    {
      name: "Funding Page",
      path: "/funding/",
    },
  ];

  return (
    <>
      <Tabination variant={3} tabItems={items} />
      <div className="support">
        <div className="economiccalender-container">
          <div className="economiccalender-subcontainer">
            <div className="economiccalender-container_table">
              <Table />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FundingPg;

const Table = () => {
  const [filter, setFilter] = useState("All");

  const data = useMemo(
    () => [
      {
        email: "SaurbhviKi2334@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "In progress",
        credentialgen: "Created",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 1,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "welio@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "Error",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 2,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        profit: "56,345",
        balance: "516,345",
        equity: "516,345",
        reinstate: "Created",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
    ],
    []
  );

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredData = useMemo(
    () =>
      filter === "All"
        ? data
        : data.filter(
            (item) => item.status.toLowerCase() === filter.toLowerCase()
          ),
    [data, filter]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: ({value}) => (
          <div className="column_wrapper">
            <div className="name_initial">{value[0]}</div>
            <div className="name_text">{value}</div>
          </div>
        ),
      },
      {Header: "Account No", accessor: "accountno"},
      {
        Header: "Max Loss",
        accessor: "maxLoss",
        Cell: ({value}) => (
          <div className="red inline-tag">
            -${value}
            <span>
              <img src={ChartLoss} alt="ChartLoss" />
            </span>
          </div>
        ),
      },
      {
        Header: "Daily Loss",
        accessor: "maxDailyLoss",
        Cell: ({value}) => <div className="red inline-tag">-${value}</div>,
      },
      {
        Header: "Profit",
        accessor: "profit",
        Cell: ({value}) => (
          <div className="green inline-tag">
            ${value}
            <span>
              <img src={ChartGain} alt="ChartGain" />
            </span>
          </div>
        ),
      },
      {
        Header: "Balance",
        accessor: "balance",
        Cell: ({value}) => <div className="column_wrapper">${value}</div>,
      },
      {
        Header: "Equity",
        accessor: "equity",
        Cell: ({value}) => <div className="column_wrapper">${value}</div>,
      },
      {
        Header: "Reinstate",
        accessor: "reinstate",
        Cell: ({value}) => (
          <div className={`${value} gen-tag`}>
            <span>
              <img
                src={
                  value === "Created"
                    ? GreenTickIcon
                    : value === "In progress"
                    ? InProgressIcon
                    : ErroIcon
                }
                alt={value}
              />
            </span>
            {value}
          </div>
        ),
      },
      {
        Header: "Disable",
        accessor: "disable",
        Cell: () => (
          <div className="column_wrapper" style={{justifyContent: "center"}}>
            <img src={ThreeDotsIcon} alt="ThreeDotsIcon" />
          </div>
        ),
      },
    ],
    []
  );

  const initialState = useMemo(
    () => [
      {
        pageSize: 10,
        pageIndex: 0,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex, pageSize},
  } = useTable({columns, data: filteredData, initialState}, usePagination);

  //   const [selectedRow, setSelectedRow] = useState(null);

  return (
    <>
      <div className="table_wrapper">
        <div className="table_header">
          <div className="search">
            <div className="search_bar">
              <input
                type="text"
                className="search_input"
                placeholder="Search..."
              />
              <img className="search_icon" src={Search} alt="search icon" />
            </div>
            <img className="" src={CalenderIcon} alt="calender icon" />
          </div>
          <div className="filter_buttons">
            <button
              className={filter === "All" ? "active_btn" : ""}
              onClick={() => handleFilter("All")}
            >
              All
            </button>
            <button
              className={filter === "Accepted" ? "active_btn" : ""}
              onClick={() => handleFilter("Accepted")}
            >
              Accepted
            </button>
            <button
              className={filter === "Rejected" ? "active_btn" : ""}
              onClick={() => handleFilter("Rejected")}
            >
              Rejected
            </button>
            <button
              className={filter === "Flagged" ? "active_btn" : ""}
              onClick={() => handleFilter("Flagged")}
            >
              Flagged
            </button>
          </div>
        </div>
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup?.headers?.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={rowIndex}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell.column.id === "importance" ? (
                          <Importance activeStars={cell.value} />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
        {pageOptions.length > 1 && (
          <div className="economiccalender-pagination">
            <img src={CaretLeftIcon} alt="left-arrow" />
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className={!canPreviousPage ? "disabled" : ""}
            >
              Previous
            </button>
            {pageOptions.map((pageNumber, index) => (
              <>
                <button
                  key={index}
                  onClick={() => gotoPage(index)}
                  className={pageIndex === index ? "btn-active" : "non-active"}
                >
                  {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
                </button>
              </>
            ))}
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className={!canNextPage ? "disabled" : ""}
            >
              Next
            </button>
            <img src={CaretRightIcon} alt="right-arrow" />
          </div>
        )}
      </div>
    </>
  );
};

function Importance({activeStars}) {
  let totalStars = 3;
  let inactiveStars = totalStars - activeStars;
  const stars = [];
  for (let i = 0; i < activeStars; i++) {
    stars.push(
      <img
        src={StarBlueIcon}
        alt=""
        key={`active${i}`}
        className="importance-star"
      />
    );
  }
  for (let i = 0; i < inactiveStars; i++) {
    stars.push(
      <img
        src={StarGreyIcon}
        alt=""
        key={`inactive${i}`}
        className="importance-star"
      />
    );
  }
  return <>{stars}</>;
}
