import { Navigate, Route, Routes } from "react-router-dom";
import Tabination from "../../reusableComponents/Tabination";
import WithdrawalStatus from "../../components/AdvancedStats/withdrawalStatus";
import "./style.scss"
import Withdraw from "../../components/Support/Withdraw";
const AdvancedStats = () => {
  const items = [
    {
      name: "Payout Request",
      path: "payout-request",
    },
    // {
    //   name: "Withdrawals Details",
    //   path: "withdrawals-details",
    // },
    // {
    //   name: "Pass rates",
    //   path: "pass-rate",
    // },
    // {
    //   name: "SPT",
    //   path: "spt",
    // },
  ];
  return (
    <>
      <Tabination variant={3} tabItems={items} activeTab={0} />
      <div className="advancedStats_wrapper">
        <Routes>
          <Route
            path="/"
            element={<Navigate to="payout-request" replace={true} />}
          />
          <Route path="payout-request" element={<Withdraw />}  />
          {/* <Route path="withdrawals-details" element={<StagePass2 />} />
          <Route path="pass-rate" element={<Funded />} /> */}
     
          {/* <Route path="spt" element={<WithdrawalStatus />}/> */}
        </Routes>
      </div>
    </>
  );
};

export default AdvancedStats;
