import React, { useEffect, useState } from "react";
import "./style.scss";
import Stage from "../../components/AdminOverview/Stage";
import RevenueGrowth from "../../components/AdminOverview/RevenueGrowth";
import CurrentFunding from "../../components/AdminOverview/CurrentFunding";
import FundingTotal from "../../components/AdminOverview/FundingTotal";
import StageStatistics from "../../components/AdminOverview/StageStatistics";
import { getAllTradersRequest, getPaymentList, getRiskManagement, getUserList } from "../../utils/api/apis";
import { useDispatch, useSelector } from "react-redux";
import { returnErrors } from "../../store/reducers/error";
import { setIsLoading } from "../../store/reducers/authSlice";

function AdminOverview() {
  const idToken = useSelector((state) => state.auth.idToken);
  const [riskData, setRiskData] = useState(null);
  // stage 1 logic 
  const stage1Pass = (riskData?.stage_1_account_pass / riskData?.stage_1_accounts) * 100;
  const stage1Fail = (riskData?.stage_1_account_fail / riskData?.stage_1_accounts) * 100;

  // stage 2 logic
  const stage2Pass = (riskData?.stage_2_account_pass / riskData?.stage_2_accounts) * 100;
  const stage2Fail = (riskData?.stage_2_account_fail / riskData?.stage_2_accounts) * 100;
  
  const stage1gaugeOptions1 = {
    chart: {
      type: "radialBar",
    },
    series: [isNaN(stage1Pass) ? 0 : stage1Pass.toFixed(2)],
    colors: ["#4cb944"],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: "#EAF0F8",
          startAngle: -135,
          endAngle: 135,
        },
        dataLabels: {
          enabled: true,
          name: {
            show: false,
          },
          value: {
            fontSize: "20px",
            fontFamily: "Space Grotesk, sans-serif",
            fontWeight: 700,
            show: true,
            offsetY: 10,
          },
        },
      },
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "dark",
    //     type: "horizontal",
    //     gradientToColors: ["#4CB944"],
    //     stops: [0, 100],
    //   },
    // },
    stroke: {
      lineCap: "butt",
    },
    annotations: {
      labels: [
        {
          text: "Total",
          textAnchor: "top",
          fontSize: "18px",
          y: -25,
          style: {
            color: "#000",
            fontWeight: 700,
          },
        },
        {
          text: "65%",
          textAnchor: "middle",
          fontSize: "20px",
          y: 0,
          style: {
            color: "#000",
            fontWeight: 700,
          },
        },
      ],
    },
  };

  const stage1gaugeOptions2 = {
    chart: {
      type: "radialBar",
    },
    series: [isNaN(stage1Fail) ? 0 : stage1Fail.toFixed(2)],
    colors: ["#DF2935"],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: "#EAF0F8",
          startAngle: -135,
          endAngle: 135,
        },
        dataLabels: {
          enabled: true,
          name: {
            show: false,
          },
          value: {
            fontSize: "20px",
            fontFamily: "Space Grotesk, sans-serif",
            fontWeight: 700,
            show: true,
            offsetY: 10,
          },
        },
      },
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "dark",
    //     type: "horizontal",
    //     gradientToColors: ["#DF2935"],
    //     stops: [0, 100],
    //   },
    // },
    stroke: {
      lineCap: "butt",
    },
    annotations: {
      labels: [
        {
          text: "Total",
          textAnchor: "top",
          fontSize: "18px",
          y: -25,
          style: {
            color: "#000",
            fontWeight: 700,
          },
        },
        {
          text: "23%",
          textAnchor: "middle",
          fontSize: "20px",
          y: 0,
          style: {
            color: "#000",
            fontWeight: 700,
          },
        },
      ],
    },
  };
  const stage2gaugeOptions1 = {
    chart: {
      type: "radialBar",
    },
    series: [isNaN(stage2Pass) ? 0 : stage2Pass.toFixed(2)],
    colors: ["#4cb944"],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: "#EAF0F8",
          startAngle: -135,
          endAngle: 135,
        },
        dataLabels: {
          enabled: true,
          name: {
            show: false,
          },
          value: {
            fontSize: "20px",
            fontFamily: "Space Grotesk, sans-serif",
            fontWeight: 700,
            show: true,
            offsetY: 10,
          },
        },
      },
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "dark",
    //     type: "horizontal",
    //     gradientToColors: ["#4CB944"],
    //     stops: [0, 100],
    //   },
    // },
    stroke: {
      lineCap: "butt",
    },
    annotations: {
      labels: [
        {
          text: "Total",
          textAnchor: "top",
          fontSize: "18px",
          y: -25,
          style: {
            color: "#000",
            fontWeight: 700,
          },
        },
        {
          text: "65%",
          textAnchor: "middle",
          fontSize: "20px",
          y: 0,
          style: {
            color: "#000",
            fontWeight: 700,
          },
        },
      ],
    },
  };

  const stage2gaugeOptions2 = {
    chart: {
      type: "radialBar",
    },
    series: [isNaN(stage2Fail) ? 0 : stage2Fail?.toFixed(2)],
    colors: ["#DF2935"],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: "#EAF0F8",
          startAngle: -135,
          endAngle: 135,
        },
        dataLabels: {
          enabled: true,
          name: {
            show: false,
          },
          value: {
            fontSize: "20px",
            fontFamily: "Space Grotesk, sans-serif",
            fontWeight: 700,
            show: true,
            offsetY: 10,
          },
        },
      },
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "dark",
    //     type: "horizontal",
    //     gradientToColors: ["#DF2935"],
    //     stops: [0, 100],
    //   },
    // },
    stroke: {
      lineCap: "butt",
    },
    annotations: {
      labels: [
        {
          text: "Total",
          textAnchor: "top",
          fontSize: "18px",
          y: -25,
          style: {
            color: "#000",
            fontWeight: 700,
          },
        },
        {
          text: "23%",
          textAnchor: "middle",
          fontSize: "20px",
          y: 0,
          style: {
            color: "#000",
            fontWeight: 700,
          },
        },
      ],
    },
  };

  const dispatch = useDispatch();
  const [usersData, setUsersData] = useState({});

  const fetchRiskData = async () => {
    dispatch(setIsLoading(true))

    const response = await getRiskManagement(idToken);
    const data = await getUserList(idToken, '', null);
    const dataTrader = await getAllTradersRequest(idToken, '', null);
    const dataPayment = await getPaymentList(idToken, '', null);
    setUsersData({
      Users: data.data,
      Traders: dataTrader,
      Payment: dataPayment.data
    })
    if (response?.status < 399) {
      setRiskData(response.data);
    } else {
      dispatch(returnErrors("Error getting risk management data"));
    }
    dispatch(setIsLoading(false))
  };
  useEffect(() => {
    fetchRiskData();
  }, [idToken]);

  return (
    <div className="adminoverview">
      <div className="adminoverview_grid_container">
        <div className="col1">
          <div className="papercon">
            <div className="paperItem">

              <div className="HeadT">Total Users</div>
              <div className="TextB">{usersData?.Users?.count}</div>
            </div>
            <div className="paperItem">
              <div className="HeadT">Total Accounts</div>
              <div className="TextB">{usersData?.Traders?.count}</div>
            </div>
            <div className="paperItem">
              <div className="HeadT">Total Transactions</div>
              <div className="TextB">{usersData?.Payment?.count}</div>
            </div>

          </div>

          <div className="col1_top">
            <div className="col1_top_left">
              <Stage
                data={riskData}
                title="Stage 01"
                chartData1={stage1gaugeOptions1}
                chartData2={stage1gaugeOptions2}
              />
            </div>
            <div className="col1_top_right">
              <Stage
                data={riskData}
                title="Stage 02"
                chartData1={stage2gaugeOptions1}
                chartData2={stage2gaugeOptions2}
              />
            </div>
          </div>



          <div className="col1_middle">
            {/* <div className="col1_middle_left">
              <CurrentFunding />
            </div> */}
            <div className="col1_middle_right">
              <FundingTotal data={riskData?.funded_stats} />
            </div>
          </div>
          {/* <div className="col1_bottom">
            <div className="col1_bottom_upper">
              <StageStatistics
                title="Stage 01 Statistics"
                chData={options1}
                chartType={"line"}
              />
            </div>
            <div className="col1_bottom_lower">
              <StageStatistics
                title="Stage 01 Statistics"
                chartData={options2}
                chartType={"bar"}
              />
            </div>
          </div> */}
        </div>
        <div className="col2">
          <RevenueGrowth revenuData={riskData?.revenue_growth} />
        </div>
      </div>
    </div>
  );
}

export default AdminOverview;

const options1 = {
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
  },
  series: [
    {
      name: "Pass",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
    {
      name: "Fail",
      data: [35, 60, 50, 55, 54, 65, 75, 96],
    },
  ],
  legend: {
    position: "bottom",
    horizontalAlign: "left",
    offsetY: 10,
    offsetX: -10,
    itemMargin: {
      horizontal: 16,
    },
  },
  xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
  },
  colors: ["#000000", "#00FF00", "#0000FF"],
  dataLabels: {
    enabled: false,
  },

  stroke: {
    curve: "smooth",
    width: 2,
  },
};

const options2 = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  series: [
    {
      name: "Website",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "E-Com",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: "Links",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
    {
      name: "Others",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ],
  legend: {
    position: "bottom",
    horizontalAlign: "left",
    offsetY: 10,
    offsetX: -10,
    itemMargin: {
      horizontal: 16,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "10px",
      endingShape: "rounded",
    },
  },
  colors: ["#4CB944", "#F9C80E", "#133C55", "#36d66b"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
  },
  fill: {
    opacity: 1,
  },
};
