import { combineReducers } from "redux";

import authReducer from "./authSlice";
import userReducer from "./userSlice";
import fundingReducer from "./fundingSlice";
import supportReducer from "./supportSlice";
import affiliateReducer from "./affiliateSlice";
import couponReducer from "./couponSlice";
import advReducer from "./advSlice";
import compReducer from "./compSlice";
import plansReducer from "./plansSlice";
import plansTableReducer from "./plansTableSlice";
import accountReducer from "./accountSlice";
import errorAlertReducer from "./error";
import msgAlertReducer from "./message";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  funding: fundingReducer,
  support: supportReducer,
  affiliate: affiliateReducer,
  coupon: couponReducer,
  adv: advReducer,
  comp: compReducer,
  plans: plansReducer,
  plansTable: plansTableReducer,
  account: accountReducer,
  error: errorAlertReducer,
  message: msgAlertReducer,
});

export default rootReducer;
