import React, { useEffect, useMemo } from "react";
import "./style.scss";

import EditIcon from "./icons/editIcon";
import DeleteIcon from "./icons/deleteIcon";
import TrophyBlue from "../../../assets/icons/trophy-blue.svg";

import bgImg2 from "../../../assets/images/competition-bg-img2.png";

import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteComp, getCompData } from "../../../store/reducers/compSlice";
import { deleteCompDetails } from "../../../utils/api/apis";
import ConfirmationModal from "../../../pages/FundingEvaluation/ConfirmationModal";
import { returnMessages } from "../../../store/reducers/message";
import { returnErrors } from "../../../store/reducers/error";
import { setIsLoading } from "../../../store/reducers/authSlice";
import { getOrdinalSuffix } from "../../../utils/helpers/string";

function CompetitionDetailsBox({
  competition,
  status,
  setModalVisible,
  setErrorModalVisible,
}) {
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [activeColor, setActiveColor] = useState("#36d66b");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedCompId, setSelectedCompId] = useState();
  //
  const sortedAwards =
    competition?.awards?.length > 0
      ? competition?.awards?.slice().sort((a, b) => a.rank - b.rank)
      : [
          {
         
            title: "",
            prize: "",
            rank: 1,
            competition: 10,
            challenge: 1,
          },
          {
         
            title: "",
            prize: "",
            rank: 2,
            competition: 10,
            challenge: 1,
          },
          {
         
            title: "",
            prize: "",
            rank: 3,
            competition: 10,
            challenge: 1,
          },
        ];

  const handleToggleSideMenu = () => {
    if (isSideMenuOpen) {
      setActiveColor("#36d66b");
      setSideMenuOpen(true);
     
    } else setActiveColor("#EAF0F8");
    setSideMenuOpen(!isSideMenuOpen);
  };

  const sideMenuOpts = [
    { title: "Edit", Icon: EditIcon },
    { title: "Delete", Icon: DeleteIcon },
  ];

  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();

  const openConfirmationModal = (id) => {
    setSelectedCompId(id);
    setConfirmationModal(true);
  };
  const handleDelete = async (id) => {
    dispatch(setIsLoading(true));
    setModalVisible(false);
    // setErrorModalVisible(false);
    try {
      const response = await deleteCompDetails(idToken, id);
      response.status < 399
        ? dispatch(returnMessages("Successfully Deleted Competition", 204))
        : dispatch(returnErrors("Failed to Delete Competition", 400));
      dispatch(getCompData(idToken));
      dispatch(setIsLoading(false));
    } catch (error) {
      dispatch(returnErrors("Failed to Delete Competition", 400));
    }
  };
  const participationPercentage =
    (competition.participated_contestants / competition.total_contestants) *
    100;
  const SideMenu = () => (
    <div className="sidemenu">
      <div className="sidemenu_list_container">
        <div className="sidemenu_list">
          {sideMenuOpts?.map(({ title, Icon }, index) => (
            <>
              {title === "Edit" ? (
                <Link to={`/create-competition/${competition.id}`}>
                  <div key={title} className="option_container">
                    <Icon />
                    <h5>{title}</h5>
                  </div>
                </Link>
              ) : (
                <div
                  key={title}
                  onClick={() => openConfirmationModal(competition.id)}
                  className="option_container"
                >
                  <Icon />
                  <h5>{title}</h5>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
  const [combinedDate, setCombinedDate] = useState(null);
  const calculateTimeRemaining = (targetDate) => {
    const now = new Date();
    const timeDifference = targetDate.getTime() - now.getTime();
    if (timeDifference < 0) {
      return { days: 0, hours: 0, minutes: 0 };
    }
    const oneMinute = 60 * 1000;
    const oneHour = oneMinute * 60;
    const oneDay = 1000 * 60 * 60 * 24;
    const days = Math.floor(timeDifference / oneDay)
      .toString()
      .padStart(2, "0");
    const hours = Math.floor((timeDifference % oneDay) / oneHour)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((timeDifference % oneHour) / oneMinute)
      .toString()
      .padStart(2, "0");

    return { days, hours, minutes };
  };
  const end_date = `${competition.end_Date}T00:00:00Z`;
  const start_date = `${competition.start_date}T00:00:00Z`;

  const targetDate = useMemo(() => new Date(combinedDate), [combinedDate]);
  const { days, hours, minutes } = calculateTimeRemaining(targetDate);

  useEffect(() => {
    let newCombinedDate = null;
    if (status === "Ongoing") {
      newCombinedDate = competition.end_date;
    } else if (status === "Upcoming") {
      newCombinedDate = competition.start_date;
    }

    setCombinedDate(newCombinedDate);
    const interval = setInterval(() => {
      calculateTimeRemaining(targetDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div className="competition_details_box">
      {confirmationModal && (
        <ConfirmationModal
          id={selectedCompId}
          setConfirmationModal={setConfirmationModal}
          onConfirm={handleDelete}
        />
      )}
      <div className="competition_details_box_upper">
        <div className="competition_details_box_upper_wrapper">
          <div
            className="bg_img_container"
            style={{ backgroundImage: `url(${bgImg2})` }}
          >
            <div
              className="bg_img_container_header"
              style={
                isSideMenuOpen
                  ? { backgroundColor: "#36d66b" }
                  : { backgroundColor: "#EAF0F8" }
              }
              onClick={handleToggleSideMenu}
            >
              <svg
                className="menu"
                width="22"
                height="6"
                viewBox="0 0 22 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Menu Meatballs">
                  <g id="Vector">
                    <path
                      d="M11 1C12.1046 1 13 1.89543 13 3C13 4.10457 12.1046 5 11 5C9.89543 5 9 4.10457 9 3C9 1.89543 9.89543 1 11 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                    <path
                      d="M3 1C4.10457 1 5 1.89543 5 3C5 4.10457 4.10457 5 3 5C1.89543 5 1 4.10457 1 3C1 1.89543 1.89543 1 3 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                    <path
                      d="M19 1C20.1046 1 21 1.89543 21 3C21 4.10457 20.1046 5 19 5C17.8954 5 17 4.10457 17 3C17 1.89543 17.8954 1 19 1Z"
                      stroke={activeColor}
                      strokeWidth="1.5"
                    />
                  </g>
                </g>
              </svg>
              {isSideMenuOpen ? <SideMenu /> : ""}
            </div>
          </div>
          <Link to={`/leaderboard/${competition.id}`}>
            <div className="competiton_details_container">
              <h2>{competition.name}</h2>
              <div>
                <p>{competition.end_date}</p>
                <span>
                  <img src={TrophyBlue} alt="winners" /> Winners: {competition?.awards?.length}
                  {competition.winners}
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="competiton_details_box_middle">
        <div className="competition_details_box_middle_wrapper">
          <div className="winner_details_container">
            <p>Top 3 winners get</p>
            <ul>
              {sortedAwards?.map((prize, index) => {
                if (index > 2) {
                  return null
                }
                return (
                <li key={index}>
                  {/* <img src={prize.icon} alt={prize.position} /> */}
                  <span>{getOrdinalSuffix(prize.rank)} Prize </span> -{" "}
                  {prize.title}
                </li>
              )
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className="competition_details_box_lower">
        <div className="competition_details_box_lower_wrapper">
          {status === "Ongoing" || status === "Ended" ? (
            <div className="clock_wrapper">
              <h5>Ends in</h5>
              <div className="time_container">
                <div>
                  <h4>{days}</h4>
                  <span>days</span>
                </div>
                <div className="colon">:</div>
                <div>
                  <h4>{hours}</h4>
                  <span>hours</span>
                </div>
                <div className="colon">:</div>
                <div>
                  <h4>{minutes}</h4>
                  <span>mins</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="clock_wrapper">
              <h5>Starts in</h5>
              <div className="time_container">
                <div>
                  <h4>{days}</h4>
                  <span>days</span>
                </div>
                <div className="colon">:</div>
                <div>
                  <h4>{hours}</h4>
                  <span>hours</span>
                </div>
                <div className="colon">:</div>
                <div>
                  <h4>{minutes}</h4>
                  <span>mins</span>
                </div>
              </div>
            </div>
          )}
          <div className="contestants_details_container">
            <div className="grey_text">Total Entries</div>
            <div className="progress_bar">
              <div
                className="progress"
                style={{ width: `${participationPercentage}%` }}
              ></div>
            </div>
            <div className="contestants_participated">
              <p className="grey_text">
                {competition.participated_contestants} contestants
              </p>
              <p className="grey_text">{competition.total_contestants}</p>
            </div>
          </div>
          <Link to={`/leaderboard/${competition.id}`}> <button
          className="view_details_btn">
          View Leaderboard
          </button></Link>
        </div>
      </div>
    </div>
  );
}

export default CompetitionDetailsBox;
