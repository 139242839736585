import "./style.scss";
import addCircle from "../../assets/icons/Add Circle.svg";
import { Link } from "react-router-dom";

function CreateButtonHeader({ buttonText, link }) {
  //
  return (
    <div className="button_wrapper">
      <Link to={link}>
        <button>
          <div className="container_button">
            <img src={addCircle} fill="black" alt="AddIcon" />
            <span>{buttonText}</span>
          </div>
        </button>
      </Link>
    </div>
  );
}

export default CreateButtonHeader;
