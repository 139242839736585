import React, { useState } from "react";
import "./style.scss";

import AddIcon from "../../../assets/icons/add-circle.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import AddAddressModal from "../AddAddressModal";
import EditAddressModal from "../EditAddressModal";
import { useSelector } from "react-redux";

function InvoiceDetails({ userData, setUserAddress }) {
  const userAddress = userData;
  //

  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);

  const [addressId, setaddressId] = useState();
  //

  const openAddAddressModal = () => {
    setIsAddAddressModalOpen(true);
  };

  const closeAddAddressModal = () => {
    setIsAddAddressModalOpen(false);
  };

  const openEditAddressModal = (id) => {
    setaddressId(id);
    setIsEditAddressModalOpen(true);
  };

  const closeEditAddressModal = () => {
    setIsEditAddressModalOpen(false);
  };

  return (
    <>
      <div className="invoicedetails">
        <div className="invoicedetails_header">
          <h4>Invoice Details</h4>
          <div className="add_address_btn" onClick={openAddAddressModal}>
            <img src={AddIcon} alt="plus icon" />
            <p>Add New Address</p>
          </div>
        </div>
        {userAddress && userAddress.length > 0 && (
          <>
            {(() => {
              const addressContainers = [];
              for (let i = 0; i < userAddress.length; i++) {
                addressContainers.push(
                  <AddressContainer
                    key={i}
                    data={userAddress[i]}
                    openEditAddressModal={openEditAddressModal}
                  />
                );
              }
              return addressContainers;
            })()}
          </>
        )}
      </div>

      <div className="billing_model">
        {isAddAddressModalOpen ? (
          <div className="modal_overlay">
            <AddAddressModal
              closeAddAddressModal={closeAddAddressModal}
              data={userAddress}
            />
          </div>
        ) : null}

        {isEditAddressModalOpen ? (
          <div className="modal_overlay">
            <EditAddressModal
              addressId={addressId}
              data={userAddress}
              setUserAddress={setUserAddress}
              closeEditAddressModal={closeEditAddressModal}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default InvoiceDetails;

const AddressContainer = ({ openEditAddressModal, data }) => {
  //
  const user = useSelector((state) => state.user);

  const { first_name, last_name, email, contact, country_code } = user;
  return (
    <div className="address_container">
      <div className="address_container_header">
        <div className="address_container_header_left">
          <input type="radio" id="radio_button" name="radio-address-group" />
          <p>
            {first_name} {last_name}
          </p>
        </div>
        <div className="address_container_header_right">
          <img
            src={EditIcon}
            alt="edit icon"
            onClick={() => openEditAddressModal(data.id)}
          />
        </div>
      </div>

      <div className="address_container_details">
        <div className="address_container_details_left">
          <p>{email}</p>
          <p>
            {country_code}
            {contact}
          </p>
        </div>
        <div className="address_container_details_right">
          <p>
            {data.apartment_no} <br></br>
            {data.street_address} {data.city} {data.zip_code} <br></br>
            {data.country}
          </p>
        </div>
      </div>
    </div>
  );
};
