import { takeEvery, call, put, all } from "redux-saga/effects";
import { getFundingDetails } from "../../../utils/api/apis";
import {
  getFundingDataFailure,
  getFundingDataSuccess,
} from "../../reducers/fundingSlice";

function* fetchFundingData(action) {
  try {
    const idToken = action.payload;
    const fundingData = yield call(getFundingDetails, idToken);

    yield put(getFundingDataSuccess(fundingData.data));
  } catch (error) {
    yield put(getFundingDataFailure());
  }
}

export default function* fundingSaga() {
  yield all([takeEvery("funding/getFundingData", fetchFundingData)]);
}
