import { takeEvery, call, put, all } from "redux-saga/effects";
import { deleteCompDetails, getCompDetails, postCompDetails, updateCompDetails } from "../../../utils/api/apis";
import { createCompDataSuccess, deleteCompSuccess, getCompDataSuccess, setCompDataSuccess } from "../../reducers/compSlice";
import { returnErrors } from "../../reducers/error";
import { setIsLoading } from "../../reducers/authSlice";


function* fetchCompData(action) {
    try {
      const idToken = action.payload;
      const comp = yield call(getCompDetails, idToken);
      yield put(getCompDataSuccess(comp.data));
      yield put(setIsLoading(false))
    } catch (error) {
      yield put(returnErrors("failed to fetch competitions",400))
    }
  }
  

  function* postCompData(action) {
    try {
      const { idToken, data } = action.payload;
      const couponData = yield call(postCompDetails, idToken, data);
      //
      // yield put(createCompDataSuccess(couponData));
    } catch (error) {
       
    }
  }


  function* updateCompData(action) {
    yield put(setIsLoading(true))
    try {
      const { id, idToken, data } = action.payload; 
      const response = yield call(updateCompDetails, idToken, data, id);
      yield put(setCompDataSuccess(response.data)); 
      yield put(setIsLoading(false))
    } catch (error) {
    
      yield put(returnErrors("failed to update competition data", 400))
      yield put(setIsLoading(false))
    }
  }
  
  function* deleteCompData(action) {
    try {
      const { idToken, id } = action.payload;
      yield call(deleteCompDetails , idToken, id);
      yield put(deleteCompSuccess(id));
    } catch (error) {
       console.error("Error deleting comp:", error);
    }
}


export default function* compSaga() {
  yield all([
     takeEvery("comp/getCompData", fetchCompData),
     takeEvery("comp/createCompData", postCompData),
     takeEvery("comp/deleteComp", deleteCompData),
     takeEvery("comp/setCompData", updateCompData)]);

}
