import React, { useEffect, useState } from "react";
import "./style.scss";
import Tabination from "../../reusableComponents/Tabination";
import AllTable from "../../components/TradersList/AllTable";

function TradersList() {
  // const [activeTab,setActiveTab] = useState(0)

  // useEffect(()=>{
  //  localStorage.setItem("ActiveTab", activeTab)
  // },[activeTab])
  // const [active, setActive] = useState(true);

  const items = [
    {
      name: "All",
      content: (
        <div className="traderslist">
          <AllTable activeTab={0} />
        </div>
      ),
    },
    {
      name: "Live",
      content: (
        <div className="traderslist">
          <AllTable activeTab={1} />
        </div>
      ),
    },
    {
      name: "Competition",
      content: (
        <div className="traderslist">
          <AllTable activeTab={2} />
        </div>
      ),
    },
    {
      name: "Free Trial",
      content: (
        <div className="traderslist">
          <AllTable activeTab={3} />
        </div>
      ),
    },
  ];
  return (
    <>
      <Tabination variant={3} tabItems={items} activeTab={0} />
    </>
  );
}

export default TradersList;
