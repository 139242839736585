import React, { useEffect, useState } from "react";
import "./style.scss";

import ArrowUpIcon from "../../../assets/icons/arrow-short-up.svg";
import ArrowDownIcon from "../../../assets/icons/arrow-short-down.svg";

import RevenueGrowth from "../../../components/TradersList/AccountOverview/RevenueGrowth";
import Statistic from "../../../components/TradersList/AccountOverview/Statistic";
import InfoBox from "../../../reusableComponents/InfoBox";
import FinancialPerformance from "../../../components/TradersList/AccountOverview/FinancialPerformance";
import DailyDataBanner from "../../../components/DailyDataBanner";
import ProfileCard from "../../../components/TradersList/AccountOverview/ProfileCard";
import ProfileDropdown from "../../../components/ProfileDropdown";
import { useSelector } from "react-redux";
import moment from "moment/moment";
// import Card from "../../../components/Card";

function AccountOverview({ accountsData }) {

  const [Ametrics, setAmetrics] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [programObjective, setProgramObjective] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(accountsData.error);
  useEffect(() => {
    setAmetrics(accountsData?.account_advance_metrics);
    setMetrics(accountsData?.account_metrics);
    setProgramObjective(accountsData.program_objectives);
    setLastUpdated(accountsData.lastUpdated);
  }, [accountsData]);
  useEffect(() => {
    setErrorModalOpen(accountsData.error);
  }, [accountsData]);

  return (
    <div className="accountoverview-container">
      <div className="col1">
        <DailyDataBanner value={accountsData.account_metrics} />
        <FinancialPerformance chartData={accountsData} />
        <Statistic
          accountsData={accountsData}
          value={Ametrics}
          value2={metrics}
        />
      </div>

      <div className="col2">
        {/* <div className="infobox_wrapper">
          <InfoBox title="Account Size" value="$50,000.00" />
          <InfoBox title="Account Size" value="$20,872" />
        </div> */}
        <ProfileCard />

        <div className="col2">
          <div className="infobox_wrapper">
            <InfoBox
              title="Equity"
              value={
                accountsData?.balance_equity?.equity
                  ? "$ " + accountsData?.balance_equity?.equity
                  : "na"
              }
            />
            <InfoBox
              title="Balance"
              value={
                accountsData?.balance_equity?.balance
                  ? "$ " + accountsData?.balance_equity?.balance
                  : "na"
              }
            />
          </div>
          <div className="infobox_wrapper">
            <InfoBox
              title="Start Date"
              value={moment(accountsData?.start_date).format("ll")}
              // icon={ArrowUpIcon}
              color="var(--blue-color)"
            />
            {accountsData?.expiry_date && (
              <InfoBox
                title="End Date"
                value={moment(accountsData?.expiry_date).format("ll")}
                // icon={ArrowDownIcon}
                color="var(--yellow-warning-color)"
              />
            )}
          </div>
          <RevenueGrowth programObjective={programObjective} />
          {/* <RevenueGrowth metrics={metrics} /> */}
        </div>
      </div>
    </div>
  );
}

export default AccountOverview;
