import React, { useEffect, useState } from 'react'
import "./AdminLogin.scss"
import Select from "react-select";
import { CircularProgress } from "@mui/material";
import { postAdminLogin, UserSearchReq } from '../../utils/api/apis';
import { useDispatch, useSelector } from "react-redux";
import { returnErrors } from '../../store/reducers/error';

const AdminLogin = () => {
    const idToken = useSelector((state) => state.auth.idToken);
    const dispatch = useDispatch();
    const [search, setSearch] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [emailOpts, setEmailOpts] = useState([{ label: "", value: "" }]);
    const [data, setData] = useState({});
    const [isSpinner, setIsSpinner] = useState(false);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            background: "none",
            width: "100%",
            marginTop: "0px",
            height: 60,
            borderRadius: 8,
            border: "1px solid #eaf0f8;",
            boxShadow: "none",
            fontFamily: "DM Sans, sans-serif",
            fontSize: "14px",
            fontWeight: 700,
            minHeight: "48px",
            height: "48px",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "grey",
            fontSize: "14px",
            fontWeight: 500,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: "48px",
            padding: "0 6px",
            fontWeight: 500,
            marginLeft: "10px",
            color: "#000",
        }),
        input: (provided, state) => ({
            ...provided,
            margin: "0px",
            color: "#000",
        }),
        indicatorSeparator: (state) => ({
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "48px",
        }),
    };

    const LogIntoAccount = async () => {
        setIsSpinner(true);

        const response = await postAdminLogin(idToken, data);
        if (response?.status < 399) {
            window.open("https://app.equityedge.co.uk/signin/al/" + response.data.data.idToken, "_blank");

        } else {
            let msg = response?.response?.data?.detail || "Something went wrong";
            dispatch(returnErrors(msg, 400));
        }
        setIsSpinner(false);
    };

    // User search by email
    let timeoutId;
    const fetch = async (value) => {
        setIsLoading(true);
        if (!value) {
            console.log("Please enter a valid email address");
            setEmailOpts([]);
            return;
        }
    
        const response = await UserSearchReq(idToken, value);
        setIsLoading(false);
    
        if (response?.status < 399) {
            let userArray = [];
            response.data.map((item) =>
                userArray.push({
                    label: item?.email,
                    value: item?.id,
                })
            );

            setEmailOpts(userArray);
        } else {
            if (response.response.data.message) {
                return;
            }
            let msg = response?.response?.data?.detail || "Something went wrong";
            dispatch(returnErrors(msg, 400));
        }
    };

    const handleSearchChange = (value) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setSearch(value);

        }, 1500);
    };
    useEffect(() => {
        if (search === null) return;
        fetch(search);
        window.scrollTo(0, 0);
    }, [search]);

    return (
        <>
            <div className="create_trading_account">
                <div className="create_header">
                    <p>SUPER ADMIN LOGIN</p>
                </div>
                <div className="create_trading_account_wrapper">
                    <div className="input_form">

                        <div className="input_form-mid">
                            <div className="input_wrapper">
                                <label htmlFor="Email">Email</label>
                                <Select
                                    isLoading={isLoading}
                                    placeholder={"Search for a user"}
                                    styles={customStyles}
                                    options={emailOpts}
                                    onInputChange={(value) => {
                                        handleSearchChange(value)
                                    }}
                                    onChange={(selectedOption) => {
                                        setData((prev) => ({ ...prev, user: selectedOption.label }))
                                    }
                                    }
                                />
                            </div>
                        </div>

                        <div
                            onClick={() => LogIntoAccount()}
                            className="view_details_btn"
                        >
                            {isSpinner ? (
                                <CircularProgress color="inherit" />
                            ) : (
                                "Log into Account"
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminLogin