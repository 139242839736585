import React from "react";
import "./style.scss";
import moment from "moment";
import { useSelector } from "react-redux";

function Statistic({ accountsData }) {
  const value2 = accountsData.account_metrics;

  const now = new Date();

  const lastUpdated = useSelector(
    (state) => state.account.accountMetrics.lastUpdated
  );
  const options = { timeZoneName: "short" };
  const timeZone = new Intl.DateTimeFormat(undefined, options)
    .formatToParts(now)
    .find((part) => part.type === "timeZoneName").value;

  return (
    <div className="statistic-container">
      <div className="statistic-subcontainer">
        <h4>Statistics</h4>
        <div>
          <div>
            <p
              className={
                accountsData?.balance_equity?.equity < 0 ? "loss" : "profit"
              }
            >
              ${accountsData?.balance_equity?.equity}
            </p>
            <h5>Equity</h5>
          </div>
          <div>
            <p
              className={
                accountsData?.balance_equity?.balance < 0 ? "loss" : "profit"
              }
            >
              ${accountsData?.balance_equity?.balance}
            </p>
            <h5>Balance</h5>
          </div>
          <div>
            <p>{value2?.number_of_trades}</p>
            <h5>No. of trade</h5>
          </div>
          <div>
            <p>{value2?.lots?.toFixed(2)}</p>
            <h5>Lots</h5>
          </div>
          <div>
            <p>{Math.trunc(value2?.win_rate * 100) / 100}</p>
            <h5>Win Rate</h5>
          </div>
          <div>
            <p>{value2?.profit_target?.toFixed(2)}</p>
            <h5>Profit Target</h5>
          </div>
          <div>
            <p
              className={
                accountsData?.account_metrics?.avg_win < 0 ? "loss" : "profit"
              }
            >
              {accountsData?.account_metrics?.avg_win}
            </p>
            <h5>Average profit</h5>
          </div>
          <div>
            <p className={value2?.avg_loss ? "loss" : "profit"}>
              {value2?.avg_loss}
            </p>
            <h5>Average loss</h5>
          </div>
          <div>
            <p>{value2?.rrr}</p>
            <h5>Average RRR</h5>
          </div>
          <div>
            <p>{value2?.expectancy}</p>
            <h5>Expectancy</h5>
          </div>
          <div>
            <p>{value2?.profit_factor}</p>
            <h5>Profit factor</h5>
          </div>
          <div>
            {/* <p>{value2?.interest}</p>
          <h5>Interest</h5> */}
          </div>
        </div>
      </div>
      <div className="statistic-details">
        <p>
          Timezone : <span>{timeZone}</span>
        </p>
        <p className="last_update">
          Last Updated : <span>{lastUpdated}</span>
        </p>
      </div>
    </div>
  );
}

export default Statistic;
