import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import Risk from "../../assets/icons/riskmangement.svg";
import TradersList from "../../assets/icons/traderslist.svg";
import TradersListActive from "../../assets/icons/traderslist-active.svg";
import UserList from "../../assets/icons/userlist.svg";
import UserListActive from "../../assets/icons/userlist-active.svg";
import Payouts from "../../assets/icons/payouts.svg";
import PayoutsActive from "../../assets/icons/payouts-active.svg";

import Payment from "../../assets/icons/payment.svg";
import Bell from "../../assets/icons/bell-gray.svg";
import Leaderboard from "../../assets/icons/leaderboard.svg";
import RiskActive from "../../assets/icons/risk-active.svg";
import Affiliate from "../../assets/icons/affiliate.svg";
import Logout from "../../assets/icons/logout.svg";

import Support from "../../assets/icons/support.svg";
import CrossIcon from "../../assets/icons/cross-gray.svg";
import PaymentActive from "../../assets/icons/payment-active.svg";
import BellActive from "../../assets/icons/bell-active.svg";
import Settings from "../../assets/icons/settings.svg";
import LogoutDarkTheme from "../../assets/icons/logoutDarkTheme.svg";
import SupportActive from "../../assets/icons/support-active.svg";
import Profile from "../../assets/icons/profile.svg";
import LeaderboardActive from "../../assets/icons/leaderboard-active.svg";
import AffiliateActive from "../../assets/icons/affiliate-active.svg";
import Ads from "../../assets/icons/ads.svg";
import AdsActive from "../../assets/icons/ads-active.svg";
import TradingStatsActive from "../../assets/icons/trading-stats-active.svg";
import TradingStats from "../../assets/icons/trading-stats.svg";
import Coupons from "../../assets/icons/coupons.svg";
import CouponsActive from "../../assets/icons/coupons-active.svg";
import ArrowIncrease from "../../assets/icons/arrow-increase.svg";
import ArrowDecrease from "../../assets/icons/arrow-decrease.svg";
import BlueTick from "../../assets/icons/tick-blue.svg";
import DollarGreen from "../../assets/icons/dollar-green.svg";
import Cross from "../../assets/icons/circle-cross.svg";
import Edit from "../../assets/icons/edit-pencil.svg";
import Notification from "../../assets/icons/notification.svg";
import MarkAllRead from "../../assets/icons/tick-blue-circle.svg";
import DollarBlack from "../../assets/icons/dollar-black-square.svg";
import Standard from "../../assets/icons/standard.svg";
import FreeTrial from "../../assets/icons/free-trial.svg";
import Competition from "../../assets/icons/trophy.svg";
import InfoBox from "../../reusableComponents/InfoBox";

import Tabination from "../../reusableComponents/Tabination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deAuthenticate } from "../../store/reducers/authSlice";
import { useDispatch } from "react-redux";
import { clearPersistedData } from "../../store/configureStore";

import StageManager from "../../assets/icons/StageManager.svg";
import Stage_Manager_Active from "../../assets/icons/Stage_Manager_Active.svg";
import Certificates from "../../assets/icons/Certificates.svg";
import Certificates_Active from "../../assets/icons/Certificates_Active.svg";

import { HtmlTooltip } from "../../reusableComponents/Tooltip";

function Sidebar({
  headerName,
  zIndexValue,
  setZIndexValue,
  mobileMenu,
  setMobileMenu,
}) {
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const [bellDropdown, setBellDropdown] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);

  const dispatch = useDispatch();
  const handleLogout = () => {
    clearPersistedData();
    dispatch(deAuthenticate());
    window.location.reload();
  };
  const handleToggleSideMenu = () => {
    setSideMenuOpen(!isSideMenuOpen);
    setZIndexValue(!zIndexValue);
  };

  const closeSideMenu = () => {
    setSideMenuOpen(false);
    setZIndexValue(false);
  };

  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSideMenuOpen(false);
        setZIndexValue(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const location = useLocation();
  const sidebarList = [
    {
      title: "Trading Stats",
      path: "/overview",
      icon: TradingStats,
      activeIcon: TradingStatsActive,
    },
    // {
    //   title: "Advanced Stats",
    //   path: "/advanced-stats",
    //   icon: TradingStats,
    //   activeIcon: TradingStatsActive,
    // },
    {
      title: "Risk Management",
      path: "/risk-management",
      icon: Risk,
      activeIcon: RiskActive,
    },
    {
      title: "Trader List",
      path: "/super-trader-list/",
      icon: TradersList,
      activeIcon: TradersListActive,
    },
    // {
    //   title: "Traders List",
    //   path: "/traders-list",
    //   icon: TradersList,
    //   activeIcon: TradersListActive,
    // },
    {
      title: "User List",
      path: "/user-list",
      icon: UserList,
      activeIcon: UserListActive,
    },
    {
      title: "Stage Manager",
      path: "/stage-management",
      icon: StageManager,
      activeIcon: Stage_Manager_Active,
    },

    // {
    //   title: "Leaderboard",
    //   path: "/competition-list",
    //   icon: Leaderboard,
    //   activeIcon: LeaderboardActive,
    //   showSideMenu: true,
    // },
    {
      title: "Create Account",
      path: "/funding-evaluation",
      icon: Payment,
      activeIcon: PaymentActive,
      submenu: true,
    },
    // {
    //   title: "Funding Eval",
    //   path: "/funding-evaluation",
    //   icon: Payment,
    //   activeIcon: PaymentActive,
    //   submenu: true,
    //   items: [
    //     { title: "Funding Eval", path: "/funding-evaluation", tab: 0 },
    //     {
    //       title: "Create Funding Eval",
    //       path: "/funding-evaluation",
    //       tab: 1,
    //     },
    //     {
    //       title: "Create Trading Account",
    //       path: "/funding-evaluation",
    //       tab: 2,
    //     },
    //   ],
    // },
    {
      title: "Certificates",
      path: "/CertificateManagement",
      icon: Certificates,
      activeIcon: Certificates_Active,
      submenu: true,
      items: [
        { title: "All Certificates", path: "/CertificateManagement", tab: 0 },
        {
          title: "Create Certificate",
          path: "/CertificateManagement",
          tab: 1,
        },
      ],
    },
    // {
    //   title: "Ads ",
    //   path: "/ads",
    //   createPage: "/create-ad",
    //   icon: Ads,
    //   activeIcon: AdsActive,
    // },
    {
      title: "Coupons ",
      path: "/coupons",
      createPage: "/create-coupon",
      icon: Coupons,
      activeIcon: CouponsActive,
      submenu: true,
      items: [
        { title: "All Coupons", path: "/coupons", tab: 0 },
        {
          title: "Create Coupons",
          path: "/coupons/create-coupon",
          tab: 1,
        },
      ],
    },
    {
      title: "Payments ",
      path: "/paymentlist",
      // createPage: "/create-coupon",
      icon: Payouts,
      activeIcon: PayoutsActive,
    },
    {
      title: "Payouts ",
      path: "/payout_list",
      // createPage: "/create-coupon",
      icon: Payouts,
      activeIcon: PayoutsActive,
    },
    {
      title: "Affiliate Market",
      path: "/affiliate-marketing",
      icon: Affiliate,
      activeIcon: AffiliateActive,
      submenu: true,
      items: [
        {
          title: "Affiliates list",
          path: "/affiliate-marketing/affiliate-list",
          tab: 0,
        },
        {
          title: "Codes List",
          path: "/affiliate-marketing/codes-list",
          tab: 1,
        },
        {
          title: "Create Affiliate Code",
          path: "/affiliate-marketing/create-codes",
          tab: 2,
        },
      ],
    },
    // {
    //   title: 'Notifications',
    //   path: '/notifications',
    //   icon: Bell,
    //   activeIcon: BellActive,
    // },
    // {
    //   title: "Support",
    //   path: "/support",
    //   icon: Support,
    //   activeIcon: SupportActive,
    // },

    {
      title: "Admin Support",
      path: "/superadmin",
      icon: Support,
      activeIcon: SupportActive,
    },
    {
      title: "IP Log",
      path: "/ip-log",
      icon: UserList,
      activeIcon: UserListActive,
    },
  ];

  return (
    <>
      <div
        className="sidebar"
        ref={sidebarRef}
        onMouseDown={(e) => e.preventDefault()}
      >
        <ul className="icons_list">
          {sidebarList.map((item, index) => {
            const isActive =
              item.title === "Trading Stats"
                ? location.pathname === "/overview"
                : item.title === "Trader List"
                ? location.pathname.includes("traders-list-2") ||
                  location.pathname.startsWith(item.path)
                : item.title === "User List"
                ? location.pathname.includes("traders-info") ||
                  location.pathname.startsWith(item.path)
                : location.pathname.startsWith(item.path);
            return (
              <li className={`icon ${isActive && "active"}`} key={index}>
                <Link to={item.path} className={`${isActive && "active"}`}>
                  <img
                    src={isActive ? item.activeIcon : item.icon}
                    alt={item.title}
                    onClick={
                      item.showSideMenu ? handleToggleSideMenu : closeSideMenu
                    }
                    className={`${item.showSideMenu ? "sidemenu_icon" : ""} ${
                      item.title === "Stage Manager" ||
                      item.title === "Certificates"
                        ? "padding_apply"
                        : ""
                    }`}
                  />
                  {item.title}
                  {/* <HtmlTooltip
                    title={item.title}
                    placement="right"
                    followCursor={true}
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, 20],
                            },
                          },
                        ],
                      },
                    }}
                    arrow
                  ></HtmlTooltip> */}
                </Link>
                {<SubMenu items={item.items} show={isActive || false} />}
              </li>
            );
          })}
          <li>
            <Link to="/signin">
              <img src={Logout} alt="logout" onClick={handleLogout} />
            </Link>
          </li>
        </ul>
        {/* <span>
        <Link to="/signin">
          <img
            src={theme === "dark-theme" ? LogoutDarkTheme : Logout}
            alt="logout"
          />
        </Link>
      </span> */}
      </div>
      {mobileMenu ? (
        <MobileMenu
          mobileMenu={mobileMenu}
          setMobileMenu={setMobileMenu}
          setBellDropdown={setBellDropdown}
          profileDropdown={profileDropdown}
          bellDropdown={bellDropdown}
          setProfileDropdown={setProfileDropdown}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Sidebar;

const SideMenu = ({ headerName }) => {
  return (
    <div className="sidemenu">
      <div className="sidemenu_list_container">
        <h4>Leaderboard</h4>
        <div className="sidemenu_list">
          <Link to="/leaderboard">
            <h4 className={headerName === "Leaderboard" ? "active" : ""}>
              Leaderboard List
            </h4>
          </Link>
          <Link to="/competition-list">
            <h4 className={headerName === "Competition List" ? "active" : ""}>
              Competition List
            </h4>
          </Link>
        </div>
      </div>
    </div>
  );
};

const SubMenu = ({ items, show }) => {
  const [active, setActive] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setActive(0);
  }, [show]);
  return (
    <ul className={`submenu ${show && "show"}`}>
      {items?.map((item, idx) => (
        <li
          className={active === idx && "active"}
          key={item.title}
          onClick={() => {
            navigate(`${item.path}`, { state: { tab: item.tab } });
            setActive(idx);
          }}
        >
          {item.title}
        </li>
      ))}
    </ul>
  );
};

const MobileMenu = ({
  mobileMenu,
  setMobileMenu,
  theme,
  toggleTheme,
  bellDropdown,
  setBellDropdown,
  profileDropdown,
  setProfileDropdown,
}) => {
  const [activeTab, setActiveTab] = useState("settings");

  return (
    <div className={`mobile-menu ${mobileMenu ? "show" : ""}`}>
      <div className="menu_box">
        <div
          className="close_button"
          onClick={() => setMobileMenu(!mobileMenu)}
        >
          <img src={CrossIcon} alt="cross icon" />
        </div>
        {/* <ul className="menu_list">
          <li>
            <img
              onClick={() => {
                setActiveTab("bell");
                setBellDropdown(true);
                setProfileDropdown(false);
              }}
              src={Bell}
              alt="bell icon"
            />
          </li>
          <li>
            <img
              onClick={() => {
                setActiveTab("settings");
                setBellDropdown(false);
                setProfileDropdown(false);
              }}
              src={Settings}
              alt="settings icon"
            />
          </li>

          <li>
            <img
              src={Profile}
              onClick={() => {
                setActiveTab("profile");
                setBellDropdown(false);
                setProfileDropdown(true);
              }}
              alt="profile"
            />
          </li>
        </ul> */}

        {activeTab === "bell" && (
          <MobileBellDropdown
            activeTab={activeTab}
            bellDropdown={bellDropdown}
            setBellDropdown={setBellDropdown}
          />
        )}

        {activeTab === "profile" && (
          <MobileProfileDropdown
            setProfileDropdown={setProfileDropdown}
            profileDropdown={profileDropdown}
          />
        )}

        {activeTab === "settings" && (
          <SidebarList mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
        )}
      </div>
    </div>
  );
};

const SidebarList = ({ mobileMenu, setMobileMenu }) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    clearPersistedData();
    dispatch(deAuthenticate());
    window.location.reload();
  };
  const menuItems = [
    {
      title: "Trading Stats",
      path: "/overview",
      icon: TradingStats,
      activeIcon: TradingStatsActive,
    },
    // {
    //   title: "Advanced Stats",
    //   path: "/advanced-stats",
    //   icon: TradingStats,
    //   activeIcon: TradingStatsActive,
    // },
    {
      title: "Risk Management",
      path: "/risk-management",
      icon: Risk,
      activeIcon: RiskActive,
    },
    {
      title: "Trader List",
      path: "/super-trader-list/",
      icon: TradersList,
      activeIcon: TradersListActive,
    },
    // {
    //   title: "Traders List",
    //   path: "/traders-list",
    //   icon: TradersList,
    //   activeIcon: TradersListActive,
    // },
    {
      title: "User List",
      path: "/user-list",
      icon: UserList,
      activeIcon: UserListActive,
    },
    {
      title: "Stage Manager",
      path: "/stage-management",
      icon: UserList,
      activeIcon: UserListActive,
    },

    // {
    //   title: "Leaderboard",
    //   path: "/competition-list",
    //   icon: Leaderboard,
    //   activeIcon: LeaderboardActive,
    //   showSideMenu: true,
    // },
    {
      title: "Create Account",
      path: "/funding-evaluation",
      icon: Payment,
      activeIcon: PaymentActive,
      submenu: true,
    },
    // {
    //   title: "Funding Eval",
    //   path: "/funding-evaluation",
    //   icon: Payment,
    //   activeIcon: PaymentActive,
    //   submenu: true,
    //   items: [
    //     { title: "Funding Eval", path: "/funding-evaluation", tab: 0 },
    //     {
    //       title: "Create Funding Eval",
    //       path: "/funding-evaluation",
    //       tab: 1,
    //     },
    //     {
    //       title: "Create Trading Account",
    //       path: "/funding-evaluation",
    //       tab: 2,
    //     },
    //   ],
    // },
    {
      title: "Certificates",
      path: "/CertificateManagement",
      icon: Payment,
      activeIcon: PaymentActive,
      submenu: true,
      items: [
        { title: "All Certificates", path: "/CertificateManagement", tab: 0 },
        {
          title: "Create Certificate",
          path: "/CertificateManagement",
          tab: 1,
        },
      ],
    },
    // {
    //   title: "Ads ",
    //   path: "/ads",
    //   createPage: "/create-ad",
    //   icon: Ads,
    //   activeIcon: AdsActive,
    // },
    {
      title: "Coupons ",
      path: "/coupons",
      createPage: "/create-coupon",
      icon: Coupons,
      activeIcon: CouponsActive,
      submenu: true,
      items: [
        { title: "All Coupons", path: "/coupons", tab: 0 },
        {
          title: "Create Coupons",
          path: "/coupons/create-coupon",
          tab: 1,
        },
      ],
    },
    {
      title: "Payments ",
      path: "/paymentlist",
      // createPage: "/create-coupon",
      icon: Payouts,
      activeIcon: PayoutsActive,
    },
    {
      title: "payouts ",
      path: "/payout_list",
      // createPage: "/create-coupon",
      icon: Payouts,
      activeIcon: PayoutsActive,
    },
    {
      title: "Affiliate Market",
      path: "/affiliate-marketing",
      icon: Affiliate,
      activeIcon: AffiliateActive,
      submenu: true,
      items: [
        {
          title: "Affiliates list",
          path: "/affiliate-marketing/affiliate-list",
          tab: 0,
        },
        {
          title: "Codes List",
          path: "/affiliate-marketing/codes-list",
          tab: 1,
        },
        {
          title: "Create Affiliate Code",
          path: "/affiliate-marketing/create-codes",
          tab: 2,
        },
      ],
    },
    // {
    //   title: 'Notifications',
    //   path: '/notifications',
    //   icon: Bell,
    //   activeIcon: BellActive,
    // },
    // {
    //   title: "Support",
    //   path: "/support",
    //   icon: Support,
    //   activeIcon: SupportActive,
    // },

    {
      title: "Admin Support",
      path: "/superadmin",
      icon: Support,
      activeIcon: SupportActive,
    },
    {
      title: "IP Log",
      path: "/ip-log",
      icon: UserList,
      activeIcon: UserListActive,
    },
  ];

  return (
    <div className="sidebar_list">
      <ul className="menu_item">
        {menuItems?.map((item) => (
          <li key={item.title} onClick={() => setMobileMenu(!mobileMenu)}>
            <Link
              className={`${
                item.title === "Payment" || item.title === "Leaderboard"
                  ? "text_gray"
                  : ""
              }`}
              to={item.path}
            >
              {item.title}
            </Link>
            {item.subItems && (
              <div className="nested-menu">
                <SubMenu items={item.subItems} />
              </div>
            )}
          </li>
        ))}
        <li
          style={{ display: "flex", alignItems: "center", marginLeft: "-14px" }}
        >
          <Link to="/signin">
            <img src={Logout} alt="logout" onClick={handleLogout} />
          </Link>
          Logout
        </li>
      </ul>
    </div>
  );
};

const MobileBellDropdown = ({ activeTab }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const items = [
    { name: "View All", content: "" },
    { name: "By Company", content: "" },
  ];

  return (
    <div
      className={`mobile_bell_dropdown   fade-in ${
        isVisible ? "visible" : ""
      } `}
    >
      <div className="dropdown_header">
        <h2>Notifications</h2>
      </div>
      <div className="notifications_tabs">
        <Tabination variant={4} tabItems={items} />
      </div>
      <div className="notifications_wrapper">
        <div className="notification">
          <div>
            <img
              className="notification_icon"
              src={Notification}
              alt="notification"
            />
          </div>
          <div className="notification_content">
            <div className="notification_content_upper">
              <p>
                <span>Company Accepted</span> your case Account & Billing
                Support & other
              </p>
            </div>
            <div className="notification_content_bottom">
              <span className="time">Friday 2:20pm</span>
              <span className="date">31 Jan 2021</span>
            </div>
          </div>
        </div>
        <div className="notification">
          <div>
            <img src={Notification} alt="notification" />
          </div>
          <div className="notification_content">
            <div className="notification_content_upper">
              <p>
                <span>Company Accepted</span> your case Account & Billing
                Support & other
              </p>
            </div>
            <div className="notification_content_bottom">
              <span className="time">Friday 2:20pm</span>
              <span className="date">31 Jan 2021</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileProfileDropdown = ({ setProfileDropdown, profileDropdown }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  const infoBoxArr = [
    {
      title: "Participants",
      value: "30872",
      icon: ArrowIncrease,
      percentage: "+2.53%",
      color: "#F75C03",
    },
    {
      title: "Account Balance",
      value: "$5000",
      icon: ArrowDecrease,
      percentage: "-1.2%",
      color: "#4CB944",
    },
  ];

  return (
    <div
      className={`mobile_profile_dropdown mobile_dropdown_profile fade-in ${
        isVisible ? "visible" : ""
      } `}
    >
      <div className="dropdown_header">
        <h2>Account Details</h2>
      </div>

      <div className="dropdown_profile_content">
        <div className="profile_details">
          <div className="profile_img_wrapper">
            <img src={Profile} alt="profile icon" />
          </div>
          <div className="profile_details_content">
            <h4>Daniel Radcliff</h4>
            <p>1465376</p>
          </div>
          <div className="edit_img_wrapper">
            {/* <Link to="/myprofile">
              <img src={Edit} alt="edit icon" />
            </Link> */}
          </div>
        </div>
        <div className="info_box_container">
          {infoBoxArr.map((info) => (
            <InfoBox
              key={info.title}
              title={info.title}
              value={info.value}
              icon={info.icon}
              percentage={info.percentage}
              color={info.color}
            />
          ))}
        </div>
        <div className="accounts_type_wrapper">
          <ul className="account_type">
            <li>All Accounts</li>
            <li>
              <img src={DollarGreen} alt="aggressive account" />
              Aggressive
              <img className="blue_tick_icon" src={BlueTick} alt="tick icon" />
            </li>
            <li>
              <img src={DollarBlack} alt="swing account" />
              Swing
            </li>
            <li>
              <img src={Standard} alt="standard account" />
              Standard
            </li>
            <li>
              <img src={FreeTrial} alt="free account" />
              Free Trial
            </li>
            <li>
              <img src={Competition} alt="competitions account" />
              Competitions
            </li>
          </ul>
          <ul className="live_account_type">
            <li>Live Accounts</li>
            <li>
              <img src={DollarBlack} alt="live account" />
              1234345
            </li>
            <li>
              <img src={DollarGreen} alt="live account" />
              736483
              <img className="blue_tick_icon" src={BlueTick} alt="tick icon" />
            </li>
          </ul>
        </div>
      </div>
      <div className="dropdown_footer">
        <div>
          <span>
            <p className="title">Start Date</p>
            <p className="value">31 Jan 2021</p>
          </span>
          <span>
            <p className="title">Expiry Date</p>
            <p className="value">31 Jan 2021 </p>
          </span>
          <span>
            <p className="title">No Of Trades</p>
            <p className="value">48</p>
          </span>
          <span>
            <p className="title">Leverage</p>
            <p className="value">1:2023</p>
          </span>
        </div>
      </div>
    </div>
  );
};
