import { takeEvery, call, put, all } from "redux-saga/effects";
import {
  deleteCouponDetails,
  getCouponDetails,
  postCouponDetails,
  updateCouponDetails,
} from "../../../utils/api/apis";
import {
  createCouponDataFailure,
  createCouponDataSuccess,
  deleteCouponSuccess,
  getCouponDataSuccess,
  setCouponDataSuccess,
} from "../../reducers/couponSlice";
import { returnMessages } from "../../reducers/message";
import { returnErrors } from "../../reducers/error";

function* fetchcouponData(action) {
  const { idToken, inputText } = action.payload;
  
  try {
    
    // const idToken = action.payload.idToken;
 
    const couponData = yield call(getCouponDetails, {idToken,inputText});
    //
    yield put(getCouponDataSuccess(couponData));
  } catch (error) {
    console.error("Error fetching coupon data:", error);
  }
}

function* postCouponData(action) {
  try {
    const { idToken, data } = action.payload;
    const response = yield call(postCouponDetails, idToken, data);
    
    if (response.status < 399) {
      yield put(createCouponDataSuccess(response.data));
      yield put(returnMessages("Coupon Created Successfully", 201));
    } else {
      yield put(createCouponDataFailure(response));
      const error = response.response.data;
      let msg;
      if (Object.keys(error).length > 0) {
        const firstKey = Object.keys(error)[0]; // Get the first key
        
        const firstString = error[firstKey][0]; // Get the first string from the first key's array

        msg = firstString;
      } else {
        msg = "Something went wrong";
      }
      yield put(returnErrors(msg, 400));
    }
  } catch (error) {
    
  }
}

function* deleteCouponData(action) {
  try {
    const { idToken, code } = action.payload;
    yield call(deleteCouponDetails, idToken, code);
    yield put(deleteCouponSuccess(code));
    yield call(returnMessages("Deleted Coupon Successfully", 204));
  } catch (error) {
    yield call(returnMessages("Failed to Delete Coupon", 400));
  }
}

function* updateCouponData(action) {
  
  try {
    const { idToken, formData } = action.payload;
    const response = yield call(updateCouponDetails, idToken, formData);
    yield put(setCouponDataSuccess(response.data));
    yield put(returnMessages("Successfully Updated Coupon", 201));
  } catch (error) {
    yield put(returnErrors("Failed to Updated Coupon", 400));
  }
}

export default function* couponSaga() {
  yield all([
    takeEvery("coupon/getCouponData", fetchcouponData),
    takeEvery("coupon/createCouponData", postCouponData),
    takeEvery("coupon/deleteCoupon", deleteCouponData),
    takeEvery("coupon/setCouponData", updateCouponData),
  ]);
}
