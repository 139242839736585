import { takeEvery, call, put, all } from "redux-saga/effects";

import { createPlansTableDataSuccess, deletePlansTableSuccess, getPlansTableDataSuccess, setPlansTableDataSuccess } from "../../reducers/plansTableSlice";
import { deletePlansTableDetails, getPlansTableDetails, postPlansTableDetails, updatePlanTableDetails } from "../../../utils/api/apis";
import { getPlansDataFailure } from "../../reducers/plansSlice";


function* fetchPlansTableData(action) {
  try {
    const idToken = action.payload;
    const plansTable = yield call(getPlansTableDetails, idToken);
    //
    yield put(getPlansTableDataSuccess(plansTable));
  } catch (error) {
    yield put(getPlansDataFailure(error))
    console.error("Error fetching plansTable data:", error);
  }
}

function* postPlansTableData(action) {
  try {
    const { idToken, data } = action.payload;
    const createdData = yield call(postPlansTableDetails, idToken, data);
    yield put(createPlansTableDataSuccess(createdData));
  } catch (error) {
    console.error("Error creating plan in plansTable:", error);
  }
}

function* updatePlansTableData(action) {
  try {
    const { id, idToken, data } = action.payload;
    const updatedData = yield call(updatePlanTableDetails, idToken, data, id);
    yield put(setPlansTableDataSuccess(updatedData));
  } catch (error) {
    console.error("Error updating plan in plansTable:", error);
  }
}

function* deletePlansTableData(action) {
  try {
    const { idToken, id } = action.payload;
    yield call(deletePlansTableDetails, idToken, id);
    yield put(deletePlansTableSuccess(id));
  } catch (error) {
    console.error("Error deleting plan in plansTable:", error);
  }
}

export default function* plansTableSaga() {
  yield all([
    takeEvery("plansTable/getPlansTableData", fetchPlansTableData),
    takeEvery("plansTable/createPlansTableData", postPlansTableData),
    takeEvery("plansTable/setPlansTableData", updatePlansTableData),
    takeEvery("plansTable/deletePlansTable", deletePlansTableData),
  ]);
}
