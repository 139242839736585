import React, { useEffect, useState } from 'react';
import PlanDetailsBox from '../PlanDetailsBox';
import './style.scss';
import { useSelector } from 'react-redux';
import SearchBar from '../../SearchBar';

function All({ name , plans }) {
  const [filteredPlans, setFilteredPlans] = useState([]);
  const plansData = plans || []

  useEffect(() => {
    if (plansData) {
      const filtered = plans ?  plans?.filter((plan) => plan.plan === name) : window.location.reload
      setFilteredPlans(filtered);
    }
  }, [name, plansData]);

  const [inputText, setInputText] = useState("");

  const filteredPlansData = filteredPlans.filter((el) => {
    return el.name.toLowerCase().includes(inputText)

  })
  //


  return (
    <>
          <SearchBar inputText={inputText} setInputText={setInputText} />

    <div className="all_plans">
      {filteredPlansData.map((plan, index) => (
        <PlanDetailsBox plan={plan} key={index} />
      ))}
    </div>
    </>
  );
}

export default All;
