import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { usePagination, useTable } from "react-table";
import CalenderIcon from "../../../../assets/icons/calender.svg";
import Search from "../../../../assets/icons/search.svg";
import CreateIcon from "../../../../assets/icons/plus-circle-blue.svg";

import CaretLeftIcon from "../../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../../assets/icons/caret-right.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getAffiliateList, getCodelistV2 } from "../../../../utils/api/apis";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../../../store/reducers/authSlice";
import { returnErrors } from "../../../../store/reducers/error";
import { formatCurrency } from "../../../../utils/helpers/string";
import moment from "moment";
import { Pagination } from "@mui/material";

function OverviewTable({ codeData }) {
  console.log("codeDatanew", codeData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedTable, setSelectedTable] = useState("succeeded_conversions");

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setIsModalOpen(false);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      marginTop: "4px",
      height: 48,
      borderRadius: 8,
      border: "none",
      boxShadow: "none",
      paddingRight: "20px",
      fontFamily: "DM Sans , sans-serif",
      fontSize: "14px",
      fontWeight: 700,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
  };

  return (
    <div className="overview_table">
      <div className=" table_wrapper">
        <div className="table_header">
          <div></div>
          {/* <div className="search">
            <div className="search_bar">
              <input
                type="text"
                className="search_input"
                placeholder="Search..."
              />
              <img className="search_icon" src={Search} alt="search icon" />
            </div>
            <img className="" src={CalenderIcon} alt="calender icon" />
          </div> */}
          {/* <Link to="/affiliate-marketing/create-codes">
            {" "}
            <div className="create_wrapper">
              <img className="create_icon" src={CreateIcon} alt="create" />
              <p>Create Affiliate Code</p>
            </div>{" "}
          </Link> */}
          <div style={{ display: "flex", gap: "10px", width: "40%" }}>
            <button
              className={`table_btn ${
                selectedTable === "succeeded_conversions" ? "activeTable" : ""
              }`}
              onClick={() => setSelectedTable("succeeded_conversions")}
            >
              Succeeded Conversions
            </button>
            <button
              className={`table_btn ${
                selectedTable === "failed_conversions" ? "activeTable" : ""
              }`}
              onClick={() => setSelectedTable("failed_conversions")}
            >
              Failed Conversions
            </button>
          </div>
        </div>
        <div className="table_scroll_wrapper">
          {selectedTable === "succeeded_conversions" && (
            <TableOne openModal={openModal} codeData={codeData || []} />
          )}
          {selectedTable === "failed_conversions" && (
            <TableTwo openModal={openModal} codeData={codeData || []} />
          )}
        </div>
        <ModalPopup
          isModalOpen={isModalOpen}
          modalContent={modalContent}
          openModal={openModal}
          closeModal={closeModal}
        />
      </div>{" "}
    </div>
  );
}

export default OverviewTable;

const TableOne = ({ openModal, codeData }) => {
  console.log("newData", codeData);
  const [isChallengeDropdown, setChallengeDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.auth.idToken);
  const [tableDataSuccess, setTableDataSuccess] = useState([]);

  // const newData = tableData || [];
  // console.log("tableData123", tableDataNew);
  const navigate = useNavigate();
  const location = useLocation();

  const handleRowClick = (affiliateId, email) => {
    const url = `/affiliate-marketing/overview?affiliateId=${affiliateId}&email=${email}`;
    navigate(url);
  };
  const fetch = async () => {
    dispatch(setIsLoading(true));

    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    const response = await getCodelistV2(idToken, email);
    console.log(response?.data?.results, "TestData");
    setTableDataSuccess(codeData?.succeeded_conversions || []);
    response?.status > 399
      ? dispatch(returnErrors("Error Fetching Affiliate List", 400))
      : setTableDataSuccess(codeData?.succeeded_conversions || []);
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    fetch();
  }, [codeData]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setChallengeDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const data = React.useMemo(() => tableDataSuccess, [tableDataSuccess]);

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Affiliate",
      //   accessor: "affiliate",
      //   Cell: ({ value }) => (
      //     <div className="column_one_wrapper">
      //       <div className="name_initial">{value[0]}</div>
      //       <div className="name_text">{value}</div>
      //     </div>
      //   ),
      // },

      {
        Header: "Referred Trader",
        accessor: "refered_trader",
        // Cell: ({ value }) => {
        //   return <td>${value}</td>;
        // },
      },
      {
        Header: "Paid Amount",
        accessor: "paid_amount",
        Cell: ({ value }) => {
          return <div>{formatCurrency(Number(value))}</div>;
        },
      },
      {
        Header: "Commission Amount",
        accessor: "amount",
        Cell: ({ value }) => (
          <div className="column_one_wrapper">
            <div className="name_text">{formatCurrency(Number(value))}</div>
          </div>
        ),
      },
      // {
      //   Header: "Commission Amount",
      //   accessor: "payment",
      //   Cell: ({ value }) => {
      //     return <div>{value}</div>;
      //   },
      // },
      {
        Header: "Percentage",
        accessor: "percentage",
        Cell: ({ value }) => {
          return <div>{value}%</div>;
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => (
          <div className="column_one_wrapper">
            <div className="name_text">
              {moment(value).format("DD-MM-YYYY")}
            </div>
          </div>
        ),
      },

      {
        Header: "Payment ID",
        accessor: "id",
        // Cell: ({ value }) => {
        //   return <td>{value}</td>;
        // },
      },

      {
        Header: "Status",
        accessor: "status",
        // Cell: ({ value }) => {
        //   return <td>${value}</td>;
        // },
      },
      // {
      //   Header: "updated_on",
      //   accessor: "updated_on",
      //   // Cell: ({ value }) => {
      //   //   return <td>${value}</td>;
      //   // },
      // },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    totalPages,
    pageCount,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  console.log("totalPages", totalPages);

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= pageCount) {
      setCurrentPage(pageNumber);
      gotoPage(pageNumber - 1); // Adjust pageIndex when page is changed
      setGotoPageInput("");
    }
  };

  const [gotoPageInput, setGotoPageInput] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    gotoPage(value - 1); // Adjust pageIndex when page is changed
  };

  // const handleGotoPage = () => {
  //   const pageNumber = parseInt(gotoPageInput, 10);
  //   if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
  //     setCurrentPage(pageNumber);
  //     setGotoPageInput("");
  //   }
  // };

  return (
    <div className="table_scroll">
      <table {...getTableProps()} className="leaderboard-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                onClick={() => handleRowClick(row.original.id)}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    {cell.column.Header === "Referred List" ? (
                      <button
                        className="view_details_btn"
                        onClick={() => openModal(cell.value)}
                      >
                        View Details
                      </button>
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="spacerT30p"></div>

      {page?.length > 0 ? (
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Pagination
            onChange={handleChange}
            count={pageCount}
            variant="outlined"
            page={currentPage}
            color="primary"
          />
          <div className="goto-page-input">
            <input
              type="number"
              placeholder="Go to Page"
              value={gotoPageInput}
              onChange={(e) => setGotoPageInput(e.target.value)}
            />
            <button className="goToButton" onClick={handleGotoPage}>
              Go
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          No Data
        </div>
      )}

      {/* <div className="pagination">
        <img src={CaretLeftIcon} alt="" />
        <button onClick={previousPage} disabled={!canPreviousPage}>
          Previous
        </button>
        {pageOptions.map((pageNumber, index) => (
          <>
            <button
              key={index}
              onClick={() => gotoPage(index)}
              className={pageIndex === index ? "btn-active" : "non-active"}
            >
              {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
            </button>
          </>
        ))}
        <button onClick={nextPage} disabled={!canNextPage}>
          Next
        </button>
        <img src={CaretRightIcon} alt="" />
      </div> */}
    </div>
  );
};

const TableTwo = ({ openModal, codeData }) => {
  console.log("newData", codeData);
  const [isChallengeDropdown, setChallengeDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.auth.idToken);
  const [tableDataSuccess, setTableDataSuccess] = useState([]);

  // const newData = tableData || [];
  // console.log("tableData123", tableDataNew);
  const navigate = useNavigate();
  const location = useLocation();

  const handleRowClick = (affiliateId, email) => {
    const url = `/affiliate-marketing/overview?affiliateId=${affiliateId}&email=${email}`;
    navigate(url);
  };
  const fetch = async () => {
    dispatch(setIsLoading(true));

    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    const response = await getCodelistV2(idToken, email);
    console.log(response?.data?.results, "TestData");
    setTableDataSuccess(codeData?.failed_conversions || []);
    response?.status > 399
      ? dispatch(returnErrors("Error Fetching Affiliate List", 400))
      : setTableDataSuccess(codeData?.failed_conversions || []);
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    fetch();
  }, [codeData]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setChallengeDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const data = React.useMemo(() => tableDataSuccess, [tableDataSuccess]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Account",
        accessor: "account",
        Cell: ({ value }) => (
          <div className="column_one_wrapper">
            <div className="name_text">{value || "Na"}</div>
          </div>
        ),
      },
      {
        Header: "Account Balance",
        accessor: "account_balance",
        Cell: ({ value }) => (
          <div className="column_one_wrapper">
            <div className="name_text">{formatCurrency(Number(value))}</div>
          </div>
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => {
          return <div>{formatCurrency(Number(value))}</div>;
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => (
          <div className="column_one_wrapper">
            <div className="name_text">
              {moment(value).format("DD-MM-YYYY")}
            </div>
          </div>
        ),
      },

      // {
      //   Header: "id",
      //   accessor: "id",
      //   // Cell: ({ value }) => {
      //   //   return <td>{value}</td>;
      //   // },
      // },

      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Funding evaluation",
        accessor: "funding_evaluation",
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: "Payment Id",
        accessor: "id",
        // Cell: ({ value }) => {
        //   return <td>${value}</td>;
        // },
      },
      // {
      //   Header: "payment_id",
      //   accessor: "payment_id",
      //   // Cell: ({ value }) => {
      //   //   return <td>${value}</td>;
      //   // },
      // },
      {
        Header: "Plan",
        accessor: "plan",
        // Cell: ({ value }) => {
        //   return <td>${value}</td>;
        // },
      },
      // {
      //   Header: "promo",
      //   accessor: "promo",
      //   // Cell: ({ value }) => {
      //   //   return <td>${value}</td>;
      //   // },
      // },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        // Cell: ({ value }) => {
        //   return <td>${value}</td>;
        // },
      },
      // {
      //   Header: "updated_on",
      //   accessor: "updated_on",
      //   // Cell: ({ value }) => {
      //   //   return <td>${value}</td>;
      //   // },
      // },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    totalPages,
    pageCount,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 7 },
    },
    usePagination
  );

  const handleGotoPage = () => {
    const pageNumber = parseInt(gotoPageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= pageCount) {
      setCurrentPage(pageNumber);
      gotoPage(pageNumber - 1); // Adjust pageIndex when page is changed
      setGotoPageInput("");
    }
  };

  const [gotoPageInput, setGotoPageInput] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);

  const handleChange = (event, value) => {
    setCurrentPage(value);
    gotoPage(value - 1); // Adjust pageIndex when page is changed
  };

  // const handleGotoPage = () => {
  //   const pageNumber = parseInt(gotoPageInput, 10);
  //   if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
  //     setCurrentPage(pageNumber);
  //     setGotoPageInput("");
  //   }
  // };

  return (
    <div className="table_scroll">
      <table {...getTableProps()} className="leaderboard-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                onClick={() => handleRowClick(row.original.id)}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    {cell.column.Header === "Referred List" ? (
                      <button
                        className="view_details_btn"
                        onClick={() => openModal(cell.value)}
                      >
                        View Details
                      </button>
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="spacerT30p"></div>

      {page?.length > 0 ? (
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Pagination
            onChange={handleChange}
            count={pageCount}
            variant="outlined"
            page={currentPage}
            color="primary"
          />
          <div className="goto-page-input">
            <input
              type="number"
              placeholder="Go to Page"
              value={gotoPageInput}
              onChange={(e) => setGotoPageInput(e.target.value)}
            />
            <button className="goToButton" onClick={handleGotoPage}>
              Go
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          No Data
        </div>
      )}

      {/* <div className="pagination">
        <img src={CaretLeftIcon} alt="" />
        <button onClick={previousPage} disabled={!canPreviousPage}>
          Previous
        </button>
        {pageOptions.map((pageNumber, index) => (
          <>
            <button
              key={index}
              onClick={() => gotoPage(index)}
              className={pageIndex === index ? "btn-active" : "non-active"}
            >
              {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
            </button>
          </>
        ))}
        <button onClick={nextPage} disabled={!canNextPage}>
          Next
        </button>
        <img src={CaretRightIcon} alt="" />
      </div> */}
    </div>
  );
};

const ModalPopup = ({ isModalOpen, modalContent, closeModal }) => {
  if (!isModalOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close" onClick={closeModal}>
          &times;
        </button>
        <h2>Referred List</h2>
        <p>{modalContent}</p>
      </div>
    </div>
  );
};
