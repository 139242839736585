import React from "react";
import "./style.scss";
import PreviousDetailsBox from "../PreviousDetailsBox";
import CompetitionDetailsBox from "../CompetitionDetailsBox";
import { useSelector } from "react-redux";
import moment from "moment";

function Previous({  setModalVisible, setErrorModalVisible }) {
  const compData = useSelector((state)=> state.comp.compData)
  const today = moment();
  const data = 
  (Array.isArray(compData) &&
    compData?.filter((comp) => moment(comp.end_date) < today)) ||
  [];
  
  return (
    <div className="previous">
      {data &&
        data?.map((competition, index) => (
          <CompetitionDetailsBox
            key={index}
            competition={competition}
            status={"Ended"}
            setModalVisible={setModalVisible}
            setErrorModalVisible={setErrorModalVisible}
          />
        ))}
    </div>
  );
}

export default Previous;
