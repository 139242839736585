import React from "react";
import "./style.scss";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import {formatDate} from "react-calendar/dist/cjs/shared/dateFormatter";

function Calendar({ onChange, value, selectRange, showNeighboringMonth }) {
  return (
    <div className="calendar">
      <ReactCalendar
        onChange={(date) => onChange(date)}
        value={value}
        selectRange={selectRange}
        showNeighboringMonth={showNeighboringMonth}
      />
    </div>
  );
}

export default Calendar;
