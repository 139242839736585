import React from "react";
import "./style.scss";
import AccountDetails from "../../../components/TradersList/ProfileDetails/AccountDetails";
import TierBanner from "../../../components/TradersList/ProfileDetails/TierBanner";
import InfoBox from "../../../reusableComponents/InfoBox";

import Cup from "../../../assets/icons/cup.svg";
import Drop from "../../../assets/icons/drop.svg";
import StarSmooth from "../../../assets/icons/star-smooth.svg";
import ThumbsDown from "../../../assets/icons/thumbs-down.svg";
import BillingInfo from "../../../components/TradersList/ProfileDetails/BillingInfo";

function ProfileDetails({ activeUser }) {
  //
  return (
    <div className="profiledetails-container">
      <h3>General Information</h3>
      <div className="profiledetails-subcontainer">
        <div className="profiledetails-container_left">
          <AccountDetails activeUser={activeUser} />
          {/* <BillingInfo /> */}
        </div>
        {/* <div className="profiledetails-container_right">
          <TierBanner Ametrics={Ametrics} />
          <div className="profiledetails-container_infobox">
            <InfoBox
              title="Competition"
              value="303"
              icon={Cup}
              color="#36d66b"
            />
            <InfoBox
              title="Total Funding"
              value="$ 20,872"
              icon={Drop}
              percentage="+2.53%"
              color="#F9C80E"
            />
          </div>
          <div className="profiledetails-container_infobox">
            <InfoBox
              title="Total Win"
              value="$ 20,872"
              icon={StarSmooth}
              percentage="+2.53%"
              color="#4CB944"
            />
            <InfoBox
              title="Total Win"
              value="$ 20,872"
              icon={ThumbsDown}
              percentage="+2.53%"
              color="#DF2935"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ProfileDetails;
