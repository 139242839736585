import { takeEvery, call, put, all } from "redux-saga/effects";
import { deleteAdvDetails, getAdvDetails, postAdvDetails, updateAdvDetails } from "../../../utils/api/apis";
import { getAdvData,createAdvDataSuccess, deleteAdvSuccess, getAdvDataSuccess, setAdvDataSuccess } from "../../reducers/advSlice";


function* fetchAdvData(action) {
    try {
      const idToken = action.payload;
      const advData = yield call(getAdvDetails, idToken);
      //
      yield put(getAdvDataSuccess(advData));
    } catch (error) {
      console.error("Error fetching adv data:", error);
    }
  }
  

  function* postAdvData(action) {
    try {
      const { idToken, data } = action.payload;
      const advData = yield call(postAdvDetails, idToken, data);
      //
      yield put(createAdvDataSuccess(advData));
    } catch (error) {
       
    }
  }

  function* deleteAdvData(action) {
    try {
      const { idToken, Id } = action.payload;
      yield call(deleteAdvDetails, idToken, Id);
      yield put(deleteAdvSuccess(Id));
    } catch (error) {
       console.error("Error deleting coupon:", error);
    }
}


function* updateAdvData(action) {
  try {
    const { id, idToken, data } = action.payload
    const response = yield call(updateAdvDetails, idToken , data , id)
    yield put(setAdvDataSuccess(response.data)); 
  } catch (error) {
    console.error('Failed to update ad details', error);
  }
}



export default function* advSaga() {
  yield all([
     takeEvery(getAdvData.type, fetchAdvData), 
     takeEvery("adv/createAdvData", postAdvData), 
     takeEvery("adv/deleteAdv", deleteAdvData), 
     takeEvery("adv/setAdvData", updateAdvData), ]);
}
