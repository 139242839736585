import React from "react";
import "./style.scss";
import SigninBackground from "../../../assets/images/signin-background.jpg";
import FXLogo from "../../../assets/icons/fx-logo.svg";
import LaptopDashboard from "../../../assets/images/laptop-dashboard.png";

function AuthFrame() {
  return (
    <div className="authframe-container">
      {/* <img src={FXLogo} alt="logo" /> */}
      {/* <img className="laptop" src={LaptopDashboard} alt="Laptop Dashboard" /> */}
    </div>
  );
}

export default AuthFrame;
