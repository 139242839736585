import ArrowIcon from "../../../assets/icons/caret-down.svg";
import "./style.scss";

function ElevatorInput({ name, label, value, setValue, max }) {
  const incrementValue = () => {
    setValue(value + 1);
  };

  const decrementValue = () => {
    value >= 1 && setValue(value - 1);
  };
  const handleChange = (e) => {
    e.target.value < 0 ? setValue(0) : setValue(e.target.value);
  };
  return (
    <div className="elevator_input">
      <div className="elevator_input_wrapper">
        <input
          name={name}
          type="number"
          className="value_input"
          value={value}
          onChange={handleChange}
        />
        <div className="buttons_wrapper">
          <img
            src={ArrowIcon}
            className="arrow_up"
            alt="arrow_up"
            onClick={incrementValue}
          />
          <img
            src={ArrowIcon}
            className="arrow_down"
            alt="arrow_down"
            onClick={decrementValue}
          />
        </div>
      </div>
    </div>
  );
}

export default ElevatorInput;
