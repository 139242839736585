import React from "react";
import "./style.scss";
import CrossIcon from "../../../assets/icons/error-cross.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../../store/reducers/error";

function ErrorModal() {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const dispatch = useDispatch();
  // const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    dispatch(clearErrors());
  };

  const { title, msg, status } = useSelector((state) => state.error);

  React.useEffect(() => {
   
    setOpen(true);
    setMessage(msg);
    if (status === 500 && !msg) {
      setMessage("Internal server error. Please try again later.");
    }
  }, [title, msg, status]);
  return msg && status ? (
    <div className="error_modal_overlay">
      <div className="error_modal_container">
        <div className="error_modal_container_top">
          <div className="tick_icon">
            <img src={CrossIcon} alt="error" />
          </div>
        </div>

        <div className="error_modal_container_bottom">
     <div
     style={{flex:"1", display:"flex", justifyContent:"space-between", flexDirection:"column"}}
     >     <h1>Error</h1>
          <p>{msg}</p></div>
          <button
            className="error_modal_close_btn"
            onClick={() => handleClose(false)}
          >
            {"Close"}
          </button>
        </div>
      </div>
    </div>
  ) : (
    false
  );
}

export default ErrorModal;
