import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { usePagination, useSortBy, useTable } from "react-table";
import TrophyGreen from "../../../assets/icons/trophy-green.svg";
import TrophyYellow from "../../../assets/icons/trophy-yellow.svg";
import MedalOrange from "../../../assets/icons/medal-orange.svg";
import MedalBlue from "../../../assets/icons/medal-blue.svg";
import DropdownIcon from "../../../assets/icons/dropdown-arrow-gray.svg";
import BlueTickIcon from "../../../assets/icons/tick-blue-circle.svg";
import CalendarIcon from "../../../assets/icons/calender.svg";

import CaretDownIcon from "../../../assets/icons/caret-down.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import Select from "react-select";
import Calendar from "../../../reusableComponents/Calendar";
import { useSelector } from "react-redux";

function LeaderboardTable({ tableData, id }) {
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());
  const selectedCompetition = useSelector((state) =>
    state.comp.compData.filter((item) => item?.id === parseInt(id))
  );
  useEffect(() => {
   
  }, []);

  return (
    <div className="leaderboard_table">
      <div className=" table_wrapper">
        <div className="table_header">
          <p>{selectedCompetition[0]?.name}</p>
          {/* <div className="select_wrapper">
            <div
              className="calendar-button"
              onClick={() => setIsCalender((prev) => !prev)}
            >
              <img src={CalendarIcon} alt="calender icon" />
              <p>Past 90 Days</p>
              <img src={CaretDownIcon} alt="" className="arrow_down" />
            </div>

            <div className="calendar_wrapper">
              {isCalender && (
                <Calendar
                  onChange={(date) => setDate(date)}
                  value={date}
                  // formatMonth={"MMM"}
                  selectRange={true}
                  showNeighboringMonth={false}
                />
              )}
            </div>
          </div> */}
        </div>
        <div className="table_scroll_wrapper">
          <Table data={tableData} />
        </div>
      </div>{" "}
    </div>
  );
}

export default LeaderboardTable;

const Table = ({ data }) => {
  const [isChallengeDropdown, setChallengeDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const [isSorted, setIsSorted] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("column_header")
      ) {
        setChallengeDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const customSortDate = (rowA, rowB) => {
    const dateA = rowA.original.date;
    const dateB = rowB.original.date;
    return dateA - dateB;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <>
            <div className="column_one_header">#</div>
          </>
        ),
        accessor: "#",
        Cell: ({ row }) => (
          <>
            <div className="column_one_wrapper" style={{ marginLeft: "-10px" }}>
              <div
                className={`sno_wrapper ${
                  row.index + 1 === 1
                    ? "bg_green"
                    : row.index + 1 === 2
                    ? "bg_yellow"
                    : row.index + 1 === 3
                    ? "bg_orange"
                    : "bg_default"
                }`}
              >
                {row.index + 1}
              </div>
              <div className="icon_wrapper">
                <img
                  src={`${
                    row.index + 1 === 1
                      ? TrophyGreen
                      : row.index + 1 === 2
                      ? TrophyYellow
                      : row.index + 1 === 3
                      ? MedalOrange
                      : MedalBlue
                  }`}
                  alt="trophy icon"
                />{" "}
              </div>
            </div>
          </>
        ),
      },
      { Header: "Name", accessor: "email" },
      // {
      //   Header: (
      //     <>
      //       <div className="column_header">
      //         Date <img src={DropdownIcon} alt="arrow-down" />
      //       </div>
      //     </>
      //   ),
      //   accessor: "date",
      //   sortType: customSortDate,
      //   Cell: ({ value }) => new Date(value).toLocaleDateString(),
      // },
      {
        Header: "Account Bal",
        accessor: "account_balance",
        Cell: ({ value }) => {
          return <div>${value}</div>;
        },
      },
      // {
      //   Header: (
      //     <>
      //       <div
      //         className="column_header"
      //         onClick={(e) => {
      //           e.stopPropagation();
      //           setChallengeDropdown(!isChallengeDropdown);
      //         }}
      //       >
      //         {" "}
      //         Challenge <img src={DropdownIcon} alt="arrow-down" />{" "}
      //       </div>

      //       {isChallengeDropdown && (
      //         <div className="challenge_dropdown" ref={dropdownRef}>
      //           <ul>
      //             <li>
      //               All
      //               <img src={BlueTickIcon} alt="blue tick" />
      //             </li>
      //             <li>25K Challenge</li>
      //             <li>50K Challenge</li>
      //             <li>100K Challenge</li>
      //             <li>200K Challenge</li>
      //           </ul>
      //         </div>
      //       )}
      //     </>
      //   ),
      //   accessor: "challenge",
      // },
      {
        Header: "Profit",
        accessor: "profit",
        Cell: ({ value }) => {
          return <div style={{ color: "#4cb944" }}>${value}</div>;
        },
      },
      {
        Header: "Max Loss",
        accessor: "max_loss",
        Cell: ({ value }) => {
          return <div style={{ color: "#9e1d26" }}>${value}</div>;
        },
      },
      // { Header: "Prize Won", accessor: "prizeWon" },
      {
        Header: "Status",
        accessor: "is_Active",
        Cell: ({ value }) =>
          value ? (
            <button className="tag">Active</button>
          ) : (
            <button
              style={{ background: "#f5bdc0", color: "#9e1d26" }}
              className="view_details_btn"
            >
              Inactive
            </button>
          ),
      },
      {
        Header: "Disqualified",
        accessor: "is_disqualified",
        Cell: ({ value }) =>
          !value ? (
            <button className="tag">False</button>
          ) : (
            <button
              style={{ background: "#f5bdc0", color: "#9e1d26" }}
              className="view_details_btn"
            >
              Disqualified
            </button>
          ),
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   Cell: () => <button className="view_details_btn">View Details</button>,
      // },
    ],
    [isChallengeDropdown]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 7 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table_scroll">
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className=""
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="header-content">
                      <span>{column.render("Header")}</span>
                      {/* {column.isSorted ? (
              column.isSortedDesc ? (
                setIsSorted(false)
              ) : (
                 setIsSorted(true)
              )
            ) : (
              ""
            )} */}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <img src={CaretLeftIcon} alt="" />
          <button onClick={previousPage} disabled={!canPreviousPage}>
            Previous
          </button>
          {pageOptions.map((pageNumber, index) => (
            <>
              <button
                key={index}
                onClick={() => gotoPage(index)}
                className={pageIndex === index ? "btn-active" : "non-active"}
              >
                {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
              </button>
            </>
          ))}
          <button onClick={nextPage} disabled={!canNextPage}>
            Next
          </button>
          <img src={CaretRightIcon} alt="" />
        </div>{" "}
      </div>
    </>
  );
};
