import React from "react";
import "./style.scss";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";

function PushLeads({ data }) {
  const chartData = data || [];
  const options = [
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
    { value: "quarterly", label: "Quarterly" },
  ];

  const dateOptions = [{ value: "d", label: "Dec 2023" }];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "110px",
      height: "20px",
      boxSizing: "content-box",
      fontSize: "12px",
      color: "#fff",
      fontWeight: "500",
      fontFamily: "Space Grotesk",
      borderRadius: "8px",
      float: "right",
      background: "#111512",
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "#80E0C4",
        width: "20px",
      }),
    }),
  };
  return (
    <div className="pushleads">
      <div className="pushleads_wrapper">
        <div className="chart_wrapper">
          <div className="chart_header">
            <p className="chart_title">Push Leads</p>
            <div>
              {/* <Select
                options={options}
                value={options[0]}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPlacement="bottom"
                menuPosition="fixed"
                menuStyle={{width: "120px"}}
              />

              <Select
                options={dateOptions}
                value={dateOptions[0]}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPlacement="bottom"
                menuPosition="fixed"
                menuStyle={{width: "120px"}}
              /> */}
            </div>
          </div>
          <Chart data={chartData} />
        </div>
      </div>
    </div>
  );
}

export default PushLeads;

export function Chart({ data }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const xLabels = data?.map((item) => formatDate(item?.created_day));

  const yData = data?.map((item) => item?.clicks);

  const series = [
    {
      name: "Clicks",
      data: yData,
    },
  ];

  const options = {
    chart: {
      type: "area",
      stacked: false,
      toolbar: {
        show: true,
      },
    },
    xaxis: {
      categories: xLabels,
      labels: {
        style: {
          colors: "#000",
        },
        rotate: -55,
      },
      axisBorder: {
        show: true,
        color: "#000",
      },
      axisTicks: {
        show: true,
        color: "#000",
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#000",
        },
      },
      axisBorder: {
        show: true,
        color: "#000",
      },
      axisTicks: {
        show: true,
        color: "#000",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#80E0C4"],
    },
    fill: {
      colors: ["#80E0C4"],
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      style: {
        background: "#010101",
        color: "#fff",
      },
    },
  };

  return (
    <div className="area-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={280}
      />
    </div>
  );
}
