import React, { useEffect, useState } from "react";
import "./style.scss";
import RequestToChange from "../RequestToChange";
import InvoiceDetails from "../InvoiceDetails";
import { useSelector } from "react-redux";
import { getUserAddress } from "../../../utils/api/apis";

function Billing() {
  const idToken = useSelector((state) => state.auth.idToken);
  const [userAddress, setUserAddress] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserAddress(idToken);
      setUserAddress(data);
      //
    };
    fetchData();
  }, []);
  return (
    <div className="billing">
      <div className="grid_container">
        <div className="col1">
          <RequestToChange />
        </div>
        <div className="col2">
     <InvoiceDetails
          userData={userAddress}
          setUserAddress={setUserAddress}
        />
          <AchDetails />
          <AccountType />
        </div>
      </div>
    </div>
  );
}

export default Billing;

const AchDetails = () => {
  return (
    <div className="ach_details">
      <div className="ach_details_header">
        <p>Account Type</p>
        <span>Save</span>
      </div>
      <div className="ach_details_input_container">
        <div className="account_input">
          <label htmlFor="achRoutingNumber" className="label_black">
            Ach Routing Number
          </label>
          <br></br>
          <input
            className="ach_details_input"
            type="text"
            value="1232343"
            name="achRoutingNumber"
          />
        </div>

        <div className="account_input">
          <label htmlFor="accNumber" className="label_black">
            Account Number
          </label>
          <br></br>
          <input
            className="ach_details_input"
            type="password"
            value="kghjhgkd"
            name="accNumber"
          />
        </div>
      </div>
    </div>
  );
};

const AccountType = () => {
  return (
    <div className="account_type">
      <p>Account type</p>
      <div className="radio_container">
        <div>
          <input type="radio" name="account_type" />
          <label>Saving </label>
        </div>

        <div>
          <input type="radio" name="account_type" />
          <label>Current acccount</label>
        </div>
      </div>
    </div>
  );
};
