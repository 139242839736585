import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import Card from "../../../../reusableComponents/Card";
import ProfileDropdown from "../../../ProfileDropdown";
import "./style.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import Profile from "../../../../assets/icons/profile.svg";
import CopyIcon from "../../../../assets/icons/copy.svg";
import { useSelector } from "react-redux";
import moment from "moment";
function AccountDetails({ activeUser }) {
  const [profileDropdown, setProfileDropdown] = useState(false);

  const [eye, setEye] = useState(false);
  const [eye2, setEye2] = useState(false);

  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [isTooltipOpen1, setTooltipOpen1] = useState(false);

  const handleCopyClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setTooltipOpen(true);
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  const handleCopyClick1 = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setTooltipOpen1(true);
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
  };

  const handleTooltipClose1 = () => {
    setTooltipOpen1(false);
  };
  const userDetails = activeUser;
  const Ametrics = useSelector((state) => state.account?.accountMetrics?.data);
  

  return (
    <Card className="accountdetails-container">
      <div className="accountdetails-subcontainer">
        <img
          src={Profile}
          alt="profile icon"
          className="accountdetails-image_dp"
        />
        <div className="accountdetails-innercontainer">
          <div
            className="accountdetails-container_header"
            onClick={() => setProfileDropdown(true)}
          >
            <h4>{userDetails.name}</h4>
            <p>{Ametrics?.phase?.replace("_", " ")}</p>
          </div>
          {/* <img src={Edit} alt="edit icon" /> */}
        </div>
        {/* <div className="profileDropdown"> */}
        {profileDropdown ? (
          <ProfileDropdown
            profileDropdown={profileDropdown}
            setProfileDropdown={setProfileDropdown}
          />
        ) : (
          <></>
        )}
        {/* </div> */}
      </div>
      <div className="accountdetails-subcontainer accountdetails-container_details">
        <div>
          <h5>Challenge</h5>
          <p>{Ametrics?.challenge}</p>
        </div>
       
        <div>
          <h5>Investor password</h5>
          {Ametrics?.investor_password ? (
            <p>
              <div style={{ width: "110px", marginRight: "4px" }}>
                {eye ? Ametrics?.investor_password : "*************"}
              </div>
              <div onClick={() => setEye(!eye)} style={{ cursor: "pointer" }}>
                {eye ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
              </div>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    arrow
                    onClose={handleTooltipClose}
                    open={isTooltipOpen}
                    title={
                      <span className="custom-tooltip-title">
                        Copied to Clipboard
                      </span>
                    }
                  >
                    <p
                      onClick={() =>
                        handleCopyClick(Ametrics?.investor_password)
                      }
                    >
                      <img src={CopyIcon} alt="copy" />
                    </p>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </p>
          ) : (
            <p>No Data</p>
          )}
        </div>
        <div>
          <h5>Master password </h5>
          {Ametrics.password ? (
            <p>
              <div style={{ width: "110px", marginRight: "4px" }}>
                {eye2 ? Ametrics?.password : "*************"}
              </div>
              <div onClick={() => setEye2(!eye2)} style={{ cursor: "pointer" }}>
                {eye2 ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
              </div>
              <ClickAwayListener onClickAway={handleTooltipClose1}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    arrow
                    onClose={handleTooltipClose1}
                    open={isTooltipOpen1}
                    title="Copied to Clipboard"
                  >
                    <p onClick={() => handleCopyClick1(Ametrics?.password)}>
                      <img src={CopyIcon} alt="copy" />
                    </p>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </p>
          ) : (
            <p>No Data</p>
          )}
        </div>
        <div>
          <h5>Leverage </h5>
          <p className="accountdetails-profit">
            1:{Ametrics?.account_metrics?.leverage}
          </p>
        </div>
        <div>
          <h5>Starting balance </h5>
          <p>${Ametrics?.starting_balance}</p>
        </div>
        <div>
          <h5>Account start date</h5>
          <p>{moment(Ametrics?.start_date).format("ll")}</p>
        </div>
        <div>
          <h5>Server</h5>
          <p>{Ametrics?.server}</p>
        </div>
        <div>
          <h5> Server Name</h5>
          <p>{Ametrics?.server}</p>
        </div>

        {Ametrics?.expiry_date && (
          <div>
            <h5>Account expiry date</h5>
            <p>{moment(Ametrics?.expiry_date).format("ll")}</p>
          </div>
        )}
      </div>
    </Card>
  );
}

export default AccountDetails;
