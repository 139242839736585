import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  authenticate,
  deAuthenticate,
  googleAuth,
  setAuthenticationStatus,
  setGoogleAuth,
} from "../../reducers/authSlice";
import { getUser, setUser } from "../../reducers/userSlice";
import { authApi } from "../apis/authApi";
import { returnErrors } from "../../reducers/error";

function* authUser({ payload }) {
  try {
    const response = yield call(authApi, payload);

    if (response.status < 399) {
     
      yield put(setAuthenticationStatus(response));
      yield put(getUser(response.data.idToken));
    } else {
     
      yield put(setAuthenticationStatus(false));
      let msg;
      if (response.response.data.detail) {
        const error = response.response.data.detail;
        if (typeof error === 'object') {
          const firstKey = Object.values(error)[0];
         
          msg = typeof firstKey === "string" ? firstKey : firstKey[0];
        } else {
          msg = response.response.data.detail || "Something went wrong";
        }

        yield put(returnErrors(msg, 400));
      } else {
        const error = response.response.data;
        if (Object.keys(error).length > 0) {
          const firstKey = Object.keys(error)[0];
         
          const firstString = error[firstKey] || error[firstKey][0];
          msg = firstString;
        } else {
          msg = "Something went wrong";
        }
        yield put(returnErrors(msg, 400));
      }
    }
  } catch (response) {}
}

function* deAuthUser() {
  yield put(setUser(null));
}

export default function* authSaga() {
  yield all([
    takeEvery(authenticate.type, authUser),
    takeEvery(deAuthenticate.type, deAuthUser),
  ]);
}
