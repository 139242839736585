
const SearchBar = ({ inputText, setInputText }) => {


    return (
      <div className="competitionList_header">
        <div className="search_bar">
          <input
            type="text"
            className="search_input"
            placeholder="Search..."
            onChange={(e) => setInputText(e.target.value)}
          />
  
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="20" fill="none" />
            <g clipPath="url(#clip0_206_9292)">
              <path
                d="M26.364 24.4694C26.0712 24.1765 25.5963 24.1765 25.3034 24.4694C25.0105 24.7623 25.0105 25.2372 25.3034 25.5301L26.364 24.4694ZM28.6366 28.8634C28.9295 29.1563 29.4044 29.1563 29.6973 28.8634C29.9902 28.5705 29.9902 28.0956 29.6973 27.8027L28.6366 28.8634ZM25.3034 25.5301L28.6366 28.8634L29.6973 27.8027L26.364 24.4694L25.3034 25.5301ZM19.167 25.083C15.4391 25.083 12.417 22.0609 12.417 18.333H10.917C10.917 22.8894 14.6106 26.583 19.167 26.583V25.083ZM25.917 18.333C25.917 22.0609 22.8949 25.083 19.167 25.083V26.583C23.7233 26.583 27.417 22.8894 27.417 18.333H25.917ZM19.167 11.583C22.8949 11.583 25.917 14.6051 25.917 18.333H27.417C27.417 13.7767 23.7233 10.083 19.167 10.083V11.583ZM19.167 10.083C14.6106 10.083 10.917 13.7767 10.917 18.333H12.417C12.417 14.6051 15.4391 11.583 19.167 11.583V10.083Z"
                fill="#A4A8AE"
              />
            </g>
            <defs>
              <clipPath id="clip0_206_9292">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(10 10)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    )
  }

  export default SearchBar