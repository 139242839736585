import React, { useState } from 'react'
import './style.scss'
import Select from 'react-select';

function AccountSettings() {

    const LanguageOptions = [
        { value: 'en', label: 'English' },
      ];

      const TimeZoneOptions = [
        { value: 'ad', label: 'Auto Detected' },
       
      ];
      
        const customStyles = {
          control: (provided) => ({
            ...provided,
            width:'100%',
            height: 48,
            marginTop:'12px',
            borderRadius: 8,
            border: '1px solid #EAF0F8',
            boxShadow: 'none',
            paddingRight:'20px',
            fontFamily: "DM Sans , sans-serif",
            fontSize:'14px',
            fontWeight:500

          }),
    

        };
    return (
        <div className='accountsettings'>
            <div className='accountsettings_upper'>
            <div className='accountsettings_input_container'>
                <div className='account_input'>
                    <label htmlFor="usernamedCommands"  className='label_black'>UsernamedCommands</label><br></br>
                    <div className='account_input_inner_wrapper'>
                        <input
                            className='account_settings_input'
                            type='email'
                            value='Davidhanson@gmail.com'
                            name='usernamedCommands'
                            id='usernameCommands'
                        />
                    </div>

                </div>
                <div className='account_input'>
                    <label htmlFor="Language">Language</label><br></br>
                    <div className='account_input_inner_wrapper'>
                    <Select
                                options={LanguageOptions}
                                styles={customStyles}
                                value={LanguageOptions[0]}
                                components={{
                                    IndicatorSeparator: () => null, 
                                  }}
                                  name='language'
                                  id='language'
                            />
                    </div>
                </div>
                <div className='account_input'>
                    <label htmlFor="timeZone">Time Zone</label><br></br>
                    <div className='account_input_inner_wrapper'>
                    <Select
                                options={TimeZoneOptions}
                                styles={customStyles}
                                value={TimeZoneOptions[0]}
                                components={{
                                    IndicatorSeparator: () => null, 
                                  }}
                                  name='timeZone'
                                  id='timeZone'
                            />
                    </div>
                </div>
                </div>
            </div>
            <div className='accountsettings_lower'>
                 <div className='accountsettings_lower_left'>
                    <p>Publically accessable data</p>
                    <div><ToggleSwitch /></div>
                 </div>
                 <div className='accountsettings_lower_right'>
                    <p>Delete Account</p>
                 </div>
            </div>
        </div>
    )
}

export default AccountSettings

const ToggleSwitch = () => {
    const [isChecked, setIsChecked] = useState(false);
  
    const toggleSwitch = () => {
      setIsChecked(!isChecked);
    };
  
    return (
      <div className={`toggle-switch ${isChecked ? 'checked' : ''}`} onClick={toggleSwitch}>
        <div className="slider" />
      </div>
    );
  };
  