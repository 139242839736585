import React, { useEffect, useState } from "react";
import bg1 from "../../../assets/images/card-background.jpg";
import img1 from "../../../assets/images/laptop-signin.png";
import AdDetailsBox from "../AdDetailsBox";
import "./style.scss";
import { useSelector } from "react-redux";
import SearchBar from "../../SearchBar";

function All(props) {
  const adv = useSelector((state) => state.adv.advData);
  const data = adv || [];
  const [inputText, setInputText] = useState("");

  const filteredData = data.filter((el) => {
    //
    return el.event_name.toLowerCase().includes(inputText)

  })
  //

  useEffect(() => {

  }, [filteredData])

  return (
    <>
      <SearchBar inputText={inputText} setInputText={setInputText} />
      <div className="all">

        {filteredData.map((adData, index) => (
          <AdDetailsBox adData={adData} key={index} />
        ))}
      </div>
    </>
  );
}

export default All;

