import React, { useState } from "react";
import "./style.scss";
import AccountDetails from "./AccountDetails";
import AccountPassword from "./AccountPassword";
import AccountSettings from "./AccountSettings";
import { useSelector } from "react-redux";

function GeneralInformation() {
  const userDetails = useSelector((state) => state.user);
  const [editMode, setEditMode] = useState(true);

  return (
    <div className="generalinfo">
      <h4>My Profile</h4>
      <div className="generalinfo_top">
        <div className="generalinfo_top_wrapper">
          <AccountDetails
            userDetails={userDetails}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </div>
      </div>

      <h4>Password</h4>

      <div className="generalinfo_middle">
        <AccountPassword editMode={editMode} />
      </div>
      <h4>Account Settings</h4>

      <div className="generalinfo_bottom">
        <AccountSettings editMode={editMode} />
      </div>
    </div>
  );
}

export default GeneralInformation;
