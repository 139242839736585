import React from "react";
import "./style.scss";
import CouponTable from "../../components/Coupons";
import CreateButtonHeader from "../../components/CreateButtonHeader/index";
import PaymentListTable from "../../components/PaymentList";

function PaymentList() {
  return (
    <div className="coupons_wrapper">
      <div className="coupons">
        <PaymentListTable />
      </div>
      {/* <CreateButtonHeader buttonText="Create Coupons" link={"/create-coupon"} /> */}
    </div>
  );
}

export default PaymentList;
