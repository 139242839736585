import React from "react";
import "./style.scss";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import { formatCurrency } from "../../../utils/helpers/string";
function FundingTotal({ data }) {
  const profit = (data?.profit / data?.funding_total) * 100;
  const loss = (data?.loss / data?.funding_total) * 100;
  return (
    <div className="fundingtotal">
      <div className="fundingtotal_wrapper">
        <h3 className="chart_title">Funding Total</h3>
        <div className="chart_wrapper">
          <div className="chart_header">
            <h4>{formatCurrency(data?.funding_total)}</h4>
            <p>Total Funding</p>
          </div>

          <div>
            {/* <div className="progress-bar">
        
              <div className="green-segment"></div>
              <div className="red-segment"></div>
            </div> */}
            <StackedBarChart data={{ profit, loss }} />

            <div className="chart_details">
              <span className="profit row">
                <p className="profit">Profit:</p>
                <h3 className="profit">{formatCurrency(data?.profit)}</h3>
              </span>
              <span className="loss row">
                <p>Loss:</p>
                <h3 className="loss">{formatCurrency(data?.loss)}</h3>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FundingTotal;

const StackedBarChart = ({ data }) => {
  const { profit, loss } = data;

  const options = {
    chart: {
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      // categories: [10],

      axisBorder: {
        show: false, // Hide x-axis border
      },
      axisTicks: {
        show: false, // Hide x-axis ticks
      },
      labels: {
        show: false, // Hide x-axis labels
      },
    },
    yaxis: {
      max: 100,
      show: false,

      axisBorder: {
        show: false, // Hide x-axis border
      },
      axisTicks: {
        show: false, // Hide x-axis ticks
      },
      labels: {
        show: false, // Hide x-axis labels
      },
    },
    legend: {
      position: "top",
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    toolbar: {
      show: false,
    },
    export: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Profit",
      data: [isNaN(profit) ? 0 : profit?.toFixed(2)],
      color: "#4CB944",
    },
    {
      name: "Loss",
      data: [isNaN(loss) ? 0 : loss?.toFixed(2)],
      color: "#DF2935",
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height="150"
    />
  );
};
