import React, { useRef, useState } from "react";
import "./style.scss";
import Tabination from "../../../reusableComponents/Tabination";
import Deel from "../../../assets/images/deel.png";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import { ImageDrop } from "quill-image-drop-module";
import { Quill } from "react-quill";
import EmailIcon from '../../../assets/icons/email-green.svg'
import LaptopSignin from "../../../assets/images/laptop-signin.png";
import Select from "react-select";
import ArrowLeft from "../../../assets/icons/dropdown-arrow-gray.svg";
import { Link } from "react-router-dom";


Quill.register("modules/imageDrop", ImageDrop);


function CreateTemplate() {
 

  const options = [{ value: "ac", label: "Account" }];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 384,
      width: "100%",
      height: 48,
      marginTop: "12px",
      borderRadius: 8,
      border: "1px solid var(--primary-color)",
      boxShadow: "none",
      paddingRight: "20px",
      fontFamily: "DM Sans , sans-serif",
      fontSize: "14px",
      fontWeight: 500,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#36d66b",
    }),
  };
  return (
    <div className="create_template">
      <div className="grid_container">
        <div className="col1">
          <div className="col1_upper">
          <Link to='/traders-info/discussion'>
            <div className="col1_header" >
              <img src={ArrowLeft} alt="back icon" />
              New Email/Template 
            </div>
            </Link>
            <div className="col1_wrapper">
            <div className="col1_content">
              <h4>Create  Template</h4>
              <p>
              Lorem ipsum dolor sit amet consectetur. Ornare non purus orci magna quis mollis vel. Cras quisque congue orci integer convallis euismod. Quis mauris nulla venenatis eros gravida et orci eget ac risus.
              </p>
          </div>
          <div className="col1_lower">
            <Notification />
            <div className="col1_lower_select_container">
              <p>Send To</p>
              <Select
                options={options}
                styles={customStyles}
                value={options[0]}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="col1_lower_select_container">
              <p>Template Name</p>
              <Select
                options={options}
                styles={customStyles}
                value={options[0]}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="col1_lower_select_container">
              <p>Title</p>
              <input className="col1_input" type="text" />
            </div>
            <div className="col1_lower_select_container">
              <p>Subject</p>
              <div className="textarea_wrapper">
              <div className="text_edit">
                <MyQuillEditor />
            </div>            </div>
          
            </div>
          </div>
        </div>
        </div>
      </div>{" "}
    </div> </div>
  );
}

export default CreateTemplate

const Notification = () => {
  return (
    <div className="notification">
      <div className="radiobox">
        <div className="radiobox_header">
          <div>
            <img src={Deel} alt="" />
            <p>Email</p>
          </div>
          <input type="radio" name="bank-radio" />
        </div>
        <div className="radiobox_details">
          <p>
            Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit.
          </p>
        </div>
      </div>

      <div className="radiobox">
        <div className="radiobox_header">
          <div>
            <img src={EmailIcon} alt="" />
            <p>Notification</p>
          </div>
          <input type="radio" name="bank-radio" />
        </div>
        <div className="radiobox_details">
          <p>
            Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit.
          </p>
        </div>
      </div>
    </div>
  );
};




function MyQuillEditor() {
    const [editorHtml, setEditorHtml] = useState("");

    const handleChange = (html) => {
      setEditorHtml(html);
    };
  
    const modules = {
        toolbar: {
          container: [
            [{ font: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            ["link", "image", "video"],
            ["clean"],
          ],
        },
      };
      
  
    return (
        <ReactQuill
          value={editorHtml}
          onChange={handleChange}
          modules={modules}
          placeholder=""
        />
    );
  }