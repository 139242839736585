import React, { useEffect, useState } from "react";
import "./style.scss";
import Tabination from "../../reusableComponents/Tabination";
import CreateButtonHeader from "../../components/CreateButtonHeader";
import CompetitionListHeader from "../../components/CompetitionList/CompetitionListHeader";
import OnGoing from "../../components/CompetitionList/Ongoing";
import Upcoming from "../../components/CompetitionList/Upcoming";
import Previous from "../../components/CompetitionList/Previous";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompData,
  getCompDataSuccess,
} from "../../store/reducers/compSlice";
import { Link } from "react-router-dom";
import moment from "moment";
import SuccessModal from "../../reusableComponents/SuccessModal";
import ErrorModal from "../../reusableComponents/ErrorModal";
import { getCompDetails, getFundingDetails } from "../../utils/api/apis";
import { returnErrors } from "../../store/reducers/error";
import { setIsLoading } from "../../store/reducers/authSlice";
import {
  getFundingDataFailure,
  getFundingDataSuccess,
} from "../../store/reducers/fundingSlice";
import CreateIcon from "../../assets/icons/plus-circle-blue.svg";
function CompetitionList() {
  const [modalVisible, setModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();
  const compDataRedux = useSelector((state) => state.comp.compData);
  const [compData, setCompData] = useState(compDataRedux);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    dispatch(setIsLoading(true));
    const response = await getFundingDetails(idToken);

    if ((response.status = 200)) {
      dispatch(getFundingDataSuccess(response.data));
      dispatch(setIsLoading(false));
    } else {
      dispatch(getFundingDataFailure());
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    setCompData(compDataRedux);
  }, [compDataRedux]);
  const fetch = async () => {
    dispatch(setIsLoading(true));

    const response = await getCompDetails(idToken);
    if (response?.status < 399) {
      dispatch(getCompDataSuccess(response?.data));
      dispatch(setIsLoading(false));
    } else {
      dispatch(returnErrors("Failed to fetch competitions list", 400));
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetch();
  }, [idToken]);

  const today = moment();

  const ongoingComps =
    (Array.isArray(compData) &&
      compData?.filter(
        (comp) =>
          moment(comp.end_date) > today && moment(comp.start_date) < today
      )) ||
    [];

  const upcomingComps =
    (Array.isArray(compData) &&
      compData?.filter((comp) => moment(comp.start_date) > today)) ||
    [];
  const endedComps =
    (Array.isArray(compData) &&
      compData?.filter((comp) => moment(comp.end_date) < today)) ||
    [];

  const items = [
    {
      name: "On going competitions",
      content: (
        <div className="competition_list_wrapper">
       
          <div className="competition_list">
          {/* <Link to="/create-competition">
         
         <div className="create_wrapper">
           <img className="create_icon" src={CreateIcon} alt="create" />
           <p>Create Competition</p>
         </div>
       </Link> */}
            {/* <CompetitionListHeader /> */}
            <OnGoing
              data={ongoingComps}
              setErrorModalVisible={setErrorModalVisible}
              setModalVisible={setModalVisible}
            />
          </div>
          <CreateButtonHeader
            buttonText="Create Competition"
            link={"/create-competition"}
          />
        </div>
      ),
    },
    {
      name: "Upcoming competitions",
      content: (
        <div className="competition_list_wrapper">
          <div className="competition_list">
            {/* <CompetitionListHeader /> */}
            <Upcoming
              data={upcomingComps}
              setErrorModalVisible={setErrorModalVisible}
              setModalVisible={setModalVisible}
            />
                 <CreateButtonHeader
            buttonText="Create Competition"
            link={"/create-competition"}
          />
          </div>
     
        </div>
      ),
    },
    {
      name: "Previous",
      content: (
        <div className="competition_list_wrapper">
          <div className="competition_list">
            {/* <CompetitionListHeader sortBy={true} /> */}
            <Previous
              data={endedComps}
              setErrorModalVisible={setErrorModalVisible}
              setModalVisible={setModalVisible}
            />
          </div>
          <CreateButtonHeader
            buttonText="Create Competition"
            link={"/create-competition"}
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <Tabination variant={3} tabItems={items} activeTab={0} />
      {modalVisible ? (
        <SuccessModal
          setModalOpen={setModalVisible}
          title="Successful!!"
          desc="Competition Deleted Successfully"
          btnText="Close"
        />
      ) : (
        ""
      )}

      {errorModalVisible ? (
        <ErrorModal
          setModalOpen={setErrorModalVisible}
          title="Error!!"
          desc="Could'nt Delete Competition"
          btnText="Close"
        />
      ) : (
        ""
      )}
    </>
  );
}

export default CompetitionList;
