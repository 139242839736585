import React from "react";
import "./style.scss";

import { Navigate, Route, Routes } from "react-router-dom";

import Tabination from "../../reusableComponents/Tabination";
import TradingStats from "../../components/TradingStats";
import TradingTag from "../../components/TradingTag";
import OverviewPage from "../../components/Overview";

function Overview() {
  const items = [
    {
      name: "Overview",
      path: "",
    },
    // {
    //   name: "Trading Stats",
    //   path: "trading-stats",
    // },
    // {
    //   name: "Trading Tag",
    //   path: "trading-tag",
    // },
  ];

  return (
    <>
      <Tabination variant={3} tabItems={items} activeTab={0} />
      <div className="myprofile">
        <Routes>
          {/* <Route path="/" element={<Navigate to="overview" replace={true} />} /> */}
          <Route path="/" element={<OverviewPage />} />
          <Route path="/trading-stats" element={<TradingStats />} />
          <Route path="/trading-tag" element={<TradingTag />} />
        </Routes>
      </div>
    </>
  );
}

export default Overview;
