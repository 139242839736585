import React from "react";
import "./style.scss";
import CrossIcon from "../../assets/icons/error-cross.svg";
import { TruncateString } from "../../utils/helpers/string";

function ErrorModal({ setModalOpen, desc, btnText }) {
  return (
    <div className="error_modal_overlay">
      <div className="error_modal_container">
        <div className="error_modal_container_top">
          <div className="tick_icon">
            <img src={CrossIcon} alt="error" />
          </div>
        </div>

        <div className="error_modal_container_bottom">
        <div>  <h1>Error</h1>
          <p>{desc}</p></div>
          <button
            className="error_modal_close_btn"
            onClick={() => setModalOpen(false)}
          >
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
