import React from "react";
import "./style.scss";
import { Navigate, Route, Routes } from "react-router-dom";

import Tabination from "../../reusableComponents/Tabination";
import Stage1Pass from "../../components/Support/Stage1pass";
import Stage2Pass from "../../components/Support/Stage2pass";

import Withdraw from "../../components/Support/Withdraw";
import StagePass1 from "../../components/SuperAdmin/StagePass1";
import StagePass2 from "../../components/SuperAdmin/StagePass2";
import Support from "../Support";
import Certificate from "../../components/Support/Certificate";
import "./style.scss";
import Funded from "../../components/SuperAdmin/Funded";
function StageManagement() {
  const items = [
    // {
    //   name: "Stage 1 Pass",
    //   path: "stagepass1",
    // },
    {
      name: "1 Step",
      path: "step_1",
    },
    {
      name: "2 Step",
      path: "step_2",
    },
    // {
    //   name: "Funded",
    //   path: "funded",
    // },
    // {
    //   name: "Tickets",
    //   path: "support",
    // },
    // {
    //   name: "Certificates",
    //   path: "certificates",
    // },
    // {
    //   name: "Withdraw Requests",
    //   path: "withdraw-requests",
    // },
  ];

  return (
    <>
      <Tabination variant={3} tabItems={items} activeTab={0} />
      <div className="support_wrapper support">
        <Routes>
          <Route path="/" element={<Navigate to="step_1" replace={true} />} />
          <Route path="step_1" element={<StagePass2 type={"1_step"} />} />
          <Route path="step_2" element={<StagePass2 type={"2_step" } />} />
          <Route path="stagepass1" element={<StagePass1 />} />
          <Route path="funded" element={<Funded />} />
          {/* <Route path="support" element={<Support />} /> */}
          {/* <Route path="certificates" element={<Certificate />} /> */}
          <Route path="withdraw-requests" element={<Withdraw />} />
        </Routes>
      </div>
    </>
  );
}

export default StageManagement;
