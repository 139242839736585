import React, { useState, useEffect } from "react";
import {
  getSupportData,
  setSupport,
} from "../../../store/reducers/supportSlice";
import { useDispatch, useSelector } from "react-redux";
// import Select from 'react-select/dist/declarations/src/Select';
import Select from "react-select";

const AddSupportModal = ({
  isOpen,
  onClose,
  selectedRowData,
  setIsAddModalOpen,
}) => {
  const [newSupportData, setNewSupportData] = useState(selectedRowData || {});
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();

  const statusOptions = [
    { label: "Resolved", value: "resolved" },
    { label: "In Progress", value: "in_progress" },
    { label: "Dismissed", value: "dismissed" },
  ];
  const severityOptions = [
    { label: "Low", value: "low" },
    { label: "Medium", value: "medium" },
    { label: "High", value: "high" },
    { label: "Critical", value: "critical" },
  ];
  useEffect(() => {
    setNewSupportData(selectedRowData || {});
  }, [selectedRowData]);

  const handleFieldChange = (field, value) => {
    setNewSupportData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // useEffect(() => {
  //   dispatch(getSupportData(idToken));
  // }, [idToken]);

  const handleEdit = () => {
    const data = new FormData();
    data.append("case_type", newSupportData.case_type);
    data.append("subject", newSupportData.subject);
    data.append("category", newSupportData.category);
    data.append("status", newSupportData.status);
    data.append("severity", newSupportData.severity);
    data.append("comment", newSupportData.comment);
    data.append("description", newSupportData.description);
   
    const { id } = selectedRowData;

    dispatch(setSupport({ id: id, idToken: idToken, formData: data }));
    // dispatch(getSupportData(idToken));
    setIsAddModalOpen(false);
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <div className="modal_header">
          <h2>Edit Support</h2>
          <button onClick={onClose}> X </button>
        </div>
        <div>
          <label>Subject</label>
          <input
            type="text"
            value={newSupportData.subject || ""}
            onChange={(e) => handleFieldChange("subject", e.target.value)}
            className="support_inputs"
          />
        </div>
        {/* <div>
          <label>Created</label>
          <input
            type="text"
            value={newSupportData.created || ""}
            onChange={(e) => handleFieldChange("created", e.target.value)}
            className="support_inputs"
          />
        </div> */}
        <div>
          <label>Case ID</label>
          <input
            type="text"
            value={newSupportData.id || ""}
            onChange={(e) => handleFieldChange("id", e.target.value)}
            className="support_inputs"
          />
        </div>
        <div>
          <label>Case Type</label>
          <input
            type="text"
            value={newSupportData.case_type || ""}
            onChange={(e) => handleFieldChange("case_type", e.target.value)}
            className="support_inputs"
          />
        </div>
        <div>
          <label>Description</label>
          <input
            type="text"
            value={newSupportData.description || ""}
            onChange={(e) => handleFieldChange("description", e.target.value)}
            className="support_inputs"
          />
        </div>
        <div>
          <label>Comment</label>
          <input
            type="text"
            value={newSupportData.comment || ""}
            onChange={(e) => handleFieldChange("comment", e.target.value)}
            className="support_inputs"
          />
        </div>
        <div>
          <label>Severity</label>
          <Select
            className="selectInput"
            options={severityOptions}
            value={severityOptions.filter(
              (option) => option.value === newSupportData.severity
            )}
            onChange={(e) => handleFieldChange("severity", e.value)}
          />
        </div>
        <div>
          <label>Status</label>
          <Select
            className="selectInput"
            options={statusOptions}
            value={statusOptions.filter(
              (option) => option.value === newSupportData.status
            )}
            onChange={(e) => handleFieldChange("status", e.value)}
          />
        </div>
        <button className="create_btn" onClick={handleEdit}>
          Update Support Case
        </button>
      </div>
    </div>
  );
};

export default AddSupportModal;
