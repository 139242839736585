import React, { useEffect, useState } from "react";
import "./style.scss";
import Tabination from "../../reusableComponents/Tabination";
import CreateButtonHeader from "../../components/CreateButtonHeader";
import CompetitionListHeader from "../../components/CompetitionList/CompetitionListHeader";
import OnGoing from "../../components/CompetitionList/Ongoing";
import Upcoming from "../../components/CompetitionList/Upcoming";
import Previous from "../../components/CompetitionList/Previous";
import All from "../../components/Ads/All";
import PublishedAds from "../../components/Ads/PublishedAds";
import UpcomingAds from "../../components/Ads/UpcomingAds";
import { useDispatch, useSelector } from "react-redux";
import { getAdvData } from "../../store/reducers/advSlice";

function Ads() {
  const idToken = useSelector((state) => state.auth.idToken);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdvData(idToken));
  }, [idToken]);

  const [inputText, setInputText] = useState("");


 

  const items = [
    {
      name: "All",
      content: (
        <div className="ad_list_wrapper">
          <div className="ad_list">
            <All input={inputText}  />
          </div>
          <CreateButtonHeader buttonText="Create Ad" link={"/create-ad"} />
        </div>
      ),
    },
    {
      name: "Upcoming Ads",
      content: (
        <div className="ad_list_wrapper">
          <div className="ad_list">
            <CompetitionListHeader  />
            <UpcomingAds />
          </div>
          <CreateButtonHeader buttonText="Create Ad" link={"/create-ad"} />
        </div>
      ),
    },
    {
      name: "Published Ads",
      content: (
        <div className="ad_list_wrapper">
          <div className="ad_list">
            <CompetitionListHeader  />
            <PublishedAds />
          </div>
          <CreateButtonHeader buttonText="Create Ad" link={"/create-ad"} />
        </div>
      ),
    },
  ];

  return (
    <>
      <Tabination variant={3} tabItems={items} activeTab={0} />
    </>
  );
}

export default Ads;

