import React from "react";
import "./style.scss";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";

function PassRate({ chartData, title, chartType }) {
  const passData = chartData?.pass_account?.map((item) => item?.category_count);
  const failData = chartData?.fail_account?.map((item) => item?.category_count);
  const dates = chartData?.pass_account?.map((item) => item?.date);
  // const options = [
  //   { value: "monthly", label: "Monthly" },
  //   { value: "yearly", label: "Yearly" },
  //   { value: "quarterly", label: "Quarterly" },
  // ];
  const options = {
    chart: {
      type: chartType,
      toolbar: {
        show: false,
      },
    },
    series: [
      { name: "Pass", data: passData },
      { name: "Fail", data: failData },
    ],
    legend: {
      position: "bottom",
      horizontalAlign: "left",
      offsetY: 10,
      offsetX: -10,
      itemMargin: {
        horizontal: 16,
      },
    },
    xaxis: {
      categories: dates,
    },
    colors: ["#000000", "#00FF00", "#0000FF"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  };

  const dateOptions = [{ value: "d", label: "Dec 2023" }];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "110px",
      height: "20px",
      boxSizing: "content-box",
      fontSize: "12px",
      color: "#133C55",
      fontWeight: "500",
      fontFamily: "Space Grotesk",
      borderRadius: "8px",
      float: "right",
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "#133C55",
        width: "20px",
      }),
    }),
  };
  return (
    <div className="stagestats">
      <div className="stagestats_wrapper">
        <div className="chart_wrapper">
          <div className="chart_header">
            <h3 className="chart_title">{title}</h3>
            <div>
              {/* <Select
                options={options}
                value={options[0]}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                menuPortalTarget={document.body}
                menuPlacement="bottom"
                menuPosition="fixed"
                menuStyle={{ width: "120px", zIndex: "100" }}
              /> */}
            </div>
          </div>
          <Chart options={options} chartType={chartType} />
        </div>
      </div>
    </div>
  );
}

export default PassRate;

function Chart({ options, chartType }) {
  return (
    <ReactApexChart
      options={options}
      series={options.series}
      type={chartType}
      height={280}
    />
  );
}
