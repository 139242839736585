import React, { useState } from "react";
import "./style.scss";
import Close from "../../../assets/icons/circle-cross.svg";
import Select from "react-select";
import ApartmentNoIcon from "../../../assets/icons/apartment-no.svg";
import StreetI from "../../../assets/icons/street.svg";
import StateIcon from "../../../assets/icons/state.svg";
import ZipCodeIcon from "../../../assets/icons/zip-code.svg";
import CountryIcon from "../../../assets/icons/country.svg";
import { updateUserAddress } from "../../../utils/api/apis";
import { getUserAddress } from "../../../utils/api/apis";
import { useSelector } from "react-redux";
import { country } from "../../../utils/constants/country";

function EditAddressModal({
  closeEditAddressModal,
  addressId,
  data,
  setUserAddress,
}) {
  //

  const idToken = useSelector((state) => state.auth.idToken);

  const [inputValues, setInputValues] = useState({
    id: addressId,
    apartment_no: "",
    city: "",
    country: null,
    default: false,
    state: null,
    street_address: "",
    zip_code: "",
    profile: 52,
  });

  const [inputErrors, setInputErrors] = useState({
    apartment_no: "",
    street_address: "",
    state: "",
    zip_code: "",
    country: "",
    general: "",
  });

  const [focusedInput, setFocusedInput] = useState(null);

  const stateOptions = [{ value: "ca", label: "California" }];

  const countryOptions = [{ value: "ca", label: "Canada" }];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      marginTop: "-10px",
      background: "transparent",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        borderColor: "#A4A8AE",
      },
    }),
    menu: (provided) => ({
      ...provided,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#0A3266",
    }),
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
    setFocusedInput(name);

    if (name === "zip_code") {
      const numericOnly = value.replace(/[^0-9]/g, "");
      setInputValues((prev) => ({ ...prev, [name]: numericOnly }));

      if (numericOnly.length < 5) {
        setInputErrors((prev) => ({
          ...prev,
          zip_code: "Zip Code must be at least 5 characters",
        }));
      } else {
        setInputErrors((prev) => ({ ...prev, zip_code: "" }));
      }
    } else {
      setInputErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSelectChange = (name, selectedOption) => {
    setInputValues((prev) => ({ ...prev, [name]: selectedOption }));
    setInputErrors((prev) => ({ ...prev, [name]: "" })); // Clear error for this field
  };

  const handleInputFocus = (name) => {
    setFocusedInput(name);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };

  const handleSubmit = () => {
    try {
      const fetch = async (e) => {
        // e.preventDefault();
        await updateUserAddress(idToken, inputValues);
        const data = await getUserAddress(idToken);
        setUserAddress(data);
      };
      fetch();
    } catch (error) {
     
    }

    const errors = {};

    // Check for empty fields
    if (!inputValues.apartment_no.trim())
      errors.apartment_no = "Please fill in the apartment number field";
    if (!inputValues.street_address.trim())
      errors.street_address = "Please fill in the street_address address field";
    if (!inputValues.state) errors.state = "Please select a state";
    if (!inputValues.zip_code.trim())
      errors.zip_code = "Please fill in the zip code field";
    if (!inputValues.country) errors.country = "Please select a country";

    setInputErrors(errors);

    if (Object.keys(errors).length === 0) {
      //
      closeEditAddressModal();
      getUserAddress(idToken);
    }
  };

  return (
    <div className="edit_address_modal_container">
      <div className="modal_header">
        <h4>Edit New Address</h4>
        <img src={Close} alt="close icon" onClick={closeEditAddressModal} />
      </div>
      <div className="edit_address_info_container">
        <div className="billing_info_form_container">
          <p>Billing Address</p>
          <div className="billing_info_form">
            <div
              className={`address_info_form_input_container ${
                focusedInput === "apartment_no" ? "focused" : ""
              } ${inputErrors.apartment_no ? "input_error" : ""}`}
            >
              <img
                src={ApartmentNoIcon}
                alt="apartment icon"
                className="input_icon"
              />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="apartment_no">
                  Apartment No
                </label>
                <input
                  type="text"
                  name="apartment_no"
                  id="apartment_no"
                  value={inputValues.apartment_no}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("apartment_no")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            {/* city */}
            <div
              className={`address_info_form_input_container ${
                focusedInput === "city" ? "focused" : ""
              } ${inputErrors.city ? "input_error" : ""}`}
            >
              <img src={StreetI} alt="city addrress" className="input_icon" />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="city">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={inputValues.city}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("city")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>

            <div
              className={`address_info_form_input_container ${
                focusedInput === "street_address" ? "focused" : ""
              } ${inputErrors.street_address ? "input_error" : ""}`}
            >
              <img
                src={StreetI}
                alt="street_address addrress"
                className="input_icon"
              />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="street_address">
                  Street Address
                </label>
                <input
                  type="text"
                  name="street_address"
                  id="street_address"
                  value={inputValues.street_address}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("street_address")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            <div
              className={`address_info_form_input_container ${
                focusedInput === "state" ? "focused" : ""
              } ${inputErrors.state ? "input_error" : ""}`}
            >
              <img src={StateIcon} alt="state" className="input_icon" />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="state">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  value={inputValues.street_address}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("state")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            <div
              className={`address_info_form_input_container ${
                focusedInput === "zip_code" ? "focused" : ""
              } ${inputErrors.zip_code ? "input_error" : ""}`}
            >
              <img src={ZipCodeIcon} alt="zip code" className="input_icon" />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="zip_code">
                  Zip Code
                </label>
                <input
                  type="text"
                  name="zip_code"
                  id="zip_code"
                  value={inputValues.zip_code}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("zip_code")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            <div
              className={`address_info_form_input_container ${
                focusedInput === "country" ? "focused" : ""
              } ${inputErrors.country ? "input_error" : ""}`}
            >
              <img src={CountryIcon} alt="country" className="input_icon" />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="country">
                  Country
                </label>
                <Select
                  styles={customStyles}
                  options={country?.map((item) => ({
                    value: item?.country,
                    label: item?.country,
                  }))}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="react_select_container"
                  name="country"
                  // value={inputValues.country}
                  onChange={(selectedOption) =>
                    handleSelectChange("country", selectedOption.label)
                  }
                  onFocus={() => handleInputFocus("country")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn_container">
        <button
          onClick={() => handleSubmit(addressId)}
          className="save_info_btn"
        >
          Save Info
        </button>
      </div>
    </div>
  );
}

export default EditAddressModal;
