import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Profile from "../../../../assets/icons/profile.svg";
import { returnErrors } from "../../../../store/reducers/error";
import { returnMessages } from "../../../../store/reducers/message";
import {
  baseUrl,
  getUserDetailsnew,
  updateUserDetailsnew,
  // getUserDetailsnew,
  // updateUserDetailsnew,
} from "../../../../utils/api/apis";
import { country } from "../../../../utils/constants/country.js";
import EditIcon from "../../../CompetitionList/CompetitionDetailsBox/icons/editIcon";
import "./style.scss";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

function AccountDetails({ userDetails }) {
  const idToken = useSelector((state) => state.auth.idToken);
  const options = country;
  const [userDetail, setUserDetail] = useState();
  const fetchUserDetails = async () => {
    const respo = await getUserDetailsnew(idToken, userDetails?.email);
    //removed clg
    console.log(respo,"resporesporespo")
    setUserDetail(userDetails);
  };
  useEffect(() => {
    // fetchUserDetails();
    setUserDetail(userDetails);

  }, [idToken]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: 48,
      marginTop: "12px",
      borderRadius: 8,
      border: "1px solid #EAF0F8",
      boxShadow: "none",
      paddingRight: "20px",
      fontFamily: "DM Sans , sans-serif",
      fontSize: "14px",
      fontWeight: 500,
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#1877F2",
    }),
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  // Modal functions
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [emptyField, setEmptyField] = useState(false);
  const [password, setPassword] = useState({
    email: userDetails?.email,
    password: newPassword,
  });

  const dispatch = useDispatch();
  // Handle Change Password

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("New Password is required")
        .min(8, "Password must be at least 8 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const payload = {
          email: userDetails.email,
          password: values.newPassword,
        };
        const response = await axios.post(
          `${baseUrl}auth/admin/change-user-password/`,
          payload,
          config
        );
        if (response.status < 400) {
          dispatch(returnMessages("Password Reset Successfully", 200));
        } else {
          dispatch(returnErrors("Failed to update password", 400));
        }
        handleClose();
      } catch (error) {
        console.error("Error changing password:", error);
      }
    },
  });

  //removed clg
  const navigate = useNavigate();
  const handleUpdateProfile = async () => {
    const response = await updateUserDetailsnew(idToken, userDetail);
    //removed clg
    if (response.status === 200) {
      dispatch(returnMessages("profile updated successfully", 200));
      navigate(-1)
    } else {
      dispatch(returnErrors("profile updated failed", 400));
    }
  };

  return (
    <div className="account_details">
      <div className="account_details_upper_wrapper">
        <div className="account_details_upper">
          <div className="account_img_wrapper">
            <img src={Profile} alt="profile icon" />
          </div>
          <div className="account_details_upper_content">
            <h4>{userDetails?.email}</h4>
            {/* <p>Funded Trader</p> */}
          </div>
          <div className="account_edit_img_wrapper">
            {" "}
            {/* <img src={Edit} alt="edit icon" /> */}
          </div>
        </div>
        {/* <img src={MenuDots} alt="" /> */}
      </div>
      <hr></hr>

      <div className="account_details_lower">
        <div className="account_form">
          <form>
            <div className="account_input_container_upper">
              {/* <div className="account_input">
                <label htmlFor="nickname" className="label_black">
                  Nick Name
                </label>

                <input
                  className="account_details_input"
                  type="text"
                  value={userDetail?.nickname}
                  name="nickname"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      nickname: e.target.value,
                    }))
                  }
                />
              </div> */}
              <div className="account_input">
                <label htmlFor="firstName" className="label_black">
                  First Name
                </label>

                <input
                  className="account_details_input"
                  type="text"
                  value={userDetail?.first_name}
                  name="firstName"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      first_name: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="account_input">
                <label htmlFor="lastName">Last Name</label>

                <input
                  className="account_details_input"
                  type="text"
                  name="lastName"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      last_name: e.target.value,
                    }))
                  }
                  value={userDetail?.last_name}
                />
              </div>
              <div className="account_input">
                <label htmlFor="contact">Contact Number</label>

                <input
                  className="account_details_input"
                  type="text"
                  //   value="+91  9797987891"
                  name="contact"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      contact: e.target.value,
                    }))
                  }
                  value={userDetail?.contact}
                />
              </div>{" "}
            </div>

            <div className="account_input_container_lower">
              <div className="account_input">
                <label htmlFor="country_code" className="label_black">
                  Country code
                </label>

                <input
                  className="account_details_input"
                  type="text"
                  value={userDetail?.country_code}
                  name="country_code"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      country_code: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="account_input">
                <label htmlFor="country">Country</label>

                <Select
                  options={options?.map((item) => ({
                    label: item.country,
                    value: item.country,
                    code: item.code,
                  }))}
                  styles={customStyles}
                  value={
                    {
                      value: userDetail?.country,
                      label: userDetail?.country,
                      code: userDetail?.code,
                    } || options[0]
                  }
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  name="country"
                  id="country"
                  onChange={(selectedOption) =>
                    setUserDetail((prevUserDetail) => ({
                      ...prevUserDetail,
                      country: selectedOption.value,
                      country_code: `+${selectedOption.code}`,
                    }))
                  }
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
              <div className="account_input">
                <label htmlFor="city" className="label_black">
                  City
                </label>

                <input
                  className="account_details_input"
                  type="text"
                  value={userDetail?.city}
                  name="city"
                  onChange={(e) =>
                    setUserDetail((pre) => ({ ...pre, city: e.target.value }))
                  }
                />
              </div>
            </div>
            <div
              className="account_input_container_lower"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="account_input">
                <label htmlFor="email" className="label_black">
                  Email
                </label>

                <input
                  className="account_details_input"
                  type="text"
                  value={userDetail?.email}
                  name="email"
                  disabled
                  onChange={(e) =>
                    setUserDetail((pre) => ({ ...pre, name: e.target.value }))
                  }
                />
              </div>

              {/* <div className="account_input">
                <label htmlFor="trader_badge" className="label_black">
                  Trader badge
                </label>
               
                <input
                  className="account_details_input"
                  type="text"
                  placeholder="trader_badge"
                  value={userDetail?.trader_badge}
                  name="Trader badge"
                  onChange={(e) =>
                    setUserDetail((pre) => ({
                      ...pre,
                      trader_badge: e.target.value,
                    }))
                  }
                />
              </div> */}

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    ...style,
                    width: 370,
                    mx: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    borderRadius: "20px",
                  }}
                >
                  <form
                    onSubmit={formik.handleSubmit}
                    className="changePassword_form"
                  >
                    <div>
                      <TextField
                        label="New Password"
                        type="password"
                        fullWidth
                        name="newPassword"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.newPassword &&
                          Boolean(formik.errors.newPassword)
                        }
                        helperText={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                        }
                      />
                    </div>
                    <div>
                      <TextField
                        label="Confirm New Password"
                        type="password"
                        fullWidth
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.confirmPassword &&
                          Boolean(formik.errors.confirmPassword)
                        }
                        helperText={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                        }
                      />
                    </div>
                    <Button
                      variant="contained"
                      style={{ background: "#1877f2" }}
                      type="submit"
                    >
                      Update Password
                    </Button>
                  </form>
                </Box>
              </Modal>
            </div>

            <div className="account_input_container_lower">
              {/* <div
                style={{ display: "flex", gap: "10px", cursor: "pointer" }}
                onClick={handleOpen}
              >
                <label
                  style={{ margin: "0", cursor: "pointer" }}
                  className="label_black"
                >
                  Change Password
                </label>
                <EditIcon />
              </div> */}
              <Button
                variant="contained"
                style={{ background: "#1877f2", textTransform: "capitalize" }}
                onClick={handleUpdateProfile}
              >
                Update Profile
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
