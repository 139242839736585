import React, { useState } from "react";
import "./style.scss";
import Close from "../../../assets/icons/circle-cross.svg";
import EmailIcon from "../../../assets/icons/email.svg";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import UserIcon from "../../../assets/icons/user.svg";
import PhoneIcon from "../../../assets/icons/phone.svg";
import ApartmentNoIcon from "../../../assets/icons/apartment-no.svg";
import StreetIcon from "../../../assets/icons/street.svg";
import StateIcon from "../../../assets/icons/state.svg";
import ZipCodeIcon from "../../../assets/icons/zip-code.svg";
import CountryIcon from "../../../assets/icons/country.svg";
import Select from "react-select";
import { useSelector } from "react-redux";
import { validateEmail } from "../../../utils/helpers/string";
import { addUserAddress } from "../../../utils/api/apis";
import { country } from "../../../utils/constants/country";

function AddAddressModal({ closeAddAddressModal }) {
  const idToken = useSelector((state) => state.auth.idToken);

  const [inputValues, setInputValues] = useState({
    apartment_no: "",
    city: "",
    country: "",
    default: false,
    state: "",
    street_address: "",
    zip_code: "",
    profile: 52,
    contact: "",
  });
  //

  const [state, setstate] = useState(null);

  // const [country, setcountry] = useState(null);

  const [inputErrors, setInputErrors] = useState({
    apartment_no: "",
    city: "",
    country: "",
    default: false,
    state: "",
    street_address: "",
    zip_code: "",
    profile: 52,
    contact: "",
  });

  const [focusedInput, setFocusedInput] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
    setFocusedInput(name);

    if (name === "email") {
      const isValidEmail = validateEmail(value);
      setInputErrors((prev) => ({
        ...prev,
        email: isValidEmail ? "" : "Invalid email format",
      }));
    } else if (name === "zip_code") {
      const numericOnly = value.replace(/[^0-9]/g, "");
      setInputValues((prev) => ({ ...prev, [name]: numericOnly }));

      if (numericOnly.length < 5) {
        setInputErrors((prev) => ({
          ...prev,
          zip_code: "Zip Code must be at least 5 characters",
        }));
      } else {
        setInputErrors((prev) => ({ ...prev, zip_code: "" }));
      }
    } else {
      setInputErrors((prev) => ({ ...prev, [name]: "" })); // Clear error for this field
    }

    if (name === "name") {
      const alphabeticOnly = value.replace(/[^a-zA-Z]/g, "");
      setInputValues((prev) => ({ ...prev, [name]: alphabeticOnly }));
    } else if (name === "contact") {
      const numericOnly = value.replace(/[^0-9]/g, "");
      setInputValues((prev) => ({ ...prev, [name]: numericOnly }));
    }
  };

  const handleSelectChange = (name, selectedOption) => {
    setInputValues((prev) => ({ ...prev, [name]: selectedOption }));
    setInputErrors((prev) => ({ ...prev, [name]: "" })); // Clear error for this field
  };
  const stateOptions = [{ value: "ca", label: "California" }];

  const countryOptions = [{ value: "ca", label: "Canada" }];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      marginTop: "-10px",
      background: "transparent",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        borderColor: "#A4A8AE",
      },
    }),
    menu: (provided) => ({
      ...provided,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#0A3266",
    }),
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    addUserAddress(idToken, inputValues);
    //
    const errors = {};

    if (!inputValues.name.trim()) errors.name = "Please fill in the name field";
    if (!validateEmail(inputValues.email))
      errors.email = "Invalid email format";
    if (!inputValues.contact.trim())
      errors.contact = "Please fill in the contact field";
    if (!inputValues.apartment_no.trim())
      errors.apartment_no = "Please fill in the apartment number field";
    if (!inputValues.street_address.trim())
      errors.street_address = "Please fill in the street address field";
    if (!inputValues.state) errors.state = "Please select a state";
    if (!inputValues.zip_code.toString().trim())
      errors.zip_code = "Please fill in the zip code field";
    if (!inputValues.country) errors.country = "Please select a country";

    setInputErrors(errors);

    if (Object.keys(errors).length === 0) {
      //
      closeAddAddressModal();
    }
  };
  const handleInputFocus = (name) => {
    setFocusedInput(name);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);
  };

  return (
    <div className="add_address_modal_container">
      <div className="modal_header">
        <h4>Add New Address</h4>
        <img src={Close} alt="close icon" onClick={closeAddAddressModal} />
      </div>
      <div className="add_address_info_container">
        <div className="general_info_form_container">
          <p>General Information</p>
          <div className="general_info_form">
            <div
              className={`address_info_form_input_container ${
                focusedInput === "name" ? "focused" : ""
              } ${inputErrors.name ? "input_error" : ""}`}
            >
              <img src={UserIcon} alt="" className="input_icon" />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={inputValues.name}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("name")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            <div
              className={`address_info_form_input_container ${
                focusedInput === "email" ? "focused" : ""
              } ${inputErrors.email ? "input_error" : ""} `}
            >
              <img src={EmailIcon} alt="" className="input_icon" />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={inputValues.email}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("email")}
                  onBlur={handleInputBlur}
                />
              </div>
              {validateEmail(inputValues.email) && (
                <img src={CheckSolidGreenIcon} alt="" className="input_icon" />
              )}
            </div>
            <div
              className={`address_info_form_input_container ${
                focusedInput === "contact" ? "focused" : ""
              } ${inputErrors.contact ? "input_error" : ""}`}
            >
              <img src={PhoneIcon} alt="" className="input_icon" />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="contact">
                  Contact Number
                </label>
                <input
                  type="text"
                  name="contact"
                  id="contact"
                  value={inputValues.contact}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("contact")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="billing_info_form_container">
          <p>Billing Address</p>
          <div className="billing_info_form">
            <div
              className={`address_info_form_input_container ${
                focusedInput === "apartment_no" ? "focused" : ""
              } ${inputErrors.apartment_no ? "input_error" : ""}`}
            >
              <img
                src={ApartmentNoIcon}
                alt="apartment icon"
                className="input_icon"
              />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="apartment_no">
                  Apartment No
                </label>
                <input
                  type="text"
                  name="apartment_no"
                  id="apartment_no"
                  value={inputValues.apartment_no}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("apartment_no")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            <div
              className={`address_info_form_input_container ${
                focusedInput === "street_address" ? "focused" : ""
              } ${inputErrors.street_address ? "input_error" : ""}`}
            >
              <img
                src={StreetIcon}
                alt="street addrress"
                className="input_icon"
              />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="street">
                  Street Address
                </label>
                <input
                  type="text"
                  name="street_address"
                  id="street_address"
                  value={inputValues.street_address}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("street_address")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            <div
              className={`address_info_form_input_container ${
                focusedInput === "street_address" ? "focused" : ""
              } ${inputErrors.street_address ? "input_error" : ""}`}
            >
              <img
                src={StreetIcon}
                alt="street addrress"
                className="input_icon"
              />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="street">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={inputValues.city}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("city")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            <div
              className={`address_info_form_input_container ${
                focusedInput === "state" ? "focused" : ""
              } ${inputErrors.state ? "input_error" : ""}`}
            >
              <img src={StateIcon} alt="state" className="input_icon" />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="state">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  value={inputValues.state}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("state")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            <div
              className={`address_info_form_input_container ${
                inputErrors.zip_code ? "input_error" : ""
              } ${focusedInput === "zip_code" ? "focused" : ""}`}
            >
              <img src={ZipCodeIcon} alt="zip code" className="input_icon" />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="zipcode">
                  Zip Code
                </label>
                <input
                  type="text"
                  name="zip_code"
                  id="zip_code"
                  value={inputValues.zip_code}
                  onChange={handleInputChange}
                  onFocus={() => handleInputFocus("zip_code")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
            <div
              className={`address_info_form_input_container ${
                focusedInput === "country" ? "focused" : ""
              } ${inputErrors.country ? "input_error" : ""}`}
            >
              <img src={CountryIcon} alt="country" className="input_icon" />
              <div className="add_address_input general_info_input">
                <label className="general_info_label" htmlFor="country">
                  Country
                </label>
                <Select
                  styles={customStyles}
                  options={country?.map((item) => ({
                    value: item?.country,
                    label: item?.country,
                  }))}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="react_select_container"
                  name="country"
                  // value={inputValues.country}
                  onChange={(selectedOption) =>
                    handleSelectChange("country", selectedOption.label)
                  }
                  onFocus={() => handleInputFocus("country")}
                  onBlur={handleInputBlur}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn_container">
        <button onClick={handleSubmit} className="save_info_btn">
          Save Info
        </button>
      </div>
    </div>
  );
}

export default AddAddressModal;
