import React from "react";
import "./style.scss";

import { Navigate, Route, Routes } from "react-router-dom";

import Tabination from "../../reusableComponents/Tabination";
import General from "./General";
import Volume from "./Volume";

function TradingStats() {
  const items = [
    { name: "Growth", content: <General /> },
    { name: "Volume", content: <Volume /> },
  ];
  return (
    <>
      <div className="myprofile">
        <div className="trading_stats_tab">
          <Tabination
            activeTab={0}
            variant={1}
            tabItems={items}
            radioButton={true}
          />
        </div>
      </div>
    </>
  );
}

export default TradingStats;
