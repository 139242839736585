import React, { useState } from 'react'
import './style.scss'
import DropdownIcon from '../../../assets/icons/dropdown-arrow-gray.svg'
import { usePagination, useTable } from 'react-table';
import { Link } from 'react-router-dom';
import CalendarIcon from '../../../assets/icons/calender.svg'
import CaretDownIcon from "../../../assets/icons/caret-down.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import Calendar from '../../../reusableComponents/Calendar';
import GreenChart from '../../../assets/icons/green-chart.svg'

function Volume() {

    const [isCalender, setIsCalender] = useState(false);
    const [date, setDate] = useState(new Date());


    const columns = React.useMemo(
        () => [
    
            {
                Header: 'S.No',
                accessor: 'sno',
                Cell: ({ value }) => {
                    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
                    
                    return (
                      <div className="column_one_wrapper">
                        <div className="sno_wrapper" style={{ backgroundColor: randomColor }}>
                          {value}
                        </div>
                      </div>
                    );
                  },
            },
            {
                Header: () => (
                    <div className='column_data'>
                        Symbol <img src={DropdownIcon} alt='arrow-down' />
                    </div>
                ),
                accessor: 'symbol',
            },
            {
                Header:  () => (
                    <div className='column_data'>
                    Name 
                    </div>
                    ),
                accessor: 'name',
            },
            {
                Header: () => (
                    <>
                        Traded Volume
                    </>
                ),
                accessor: 'tradedVolume',
            },
            {
                Header: () => (
                    <div className='column_data'>
                        Volume (24h) <img src={DropdownIcon} alt='arrow-down' />
                    </div>
                ),
                accessor: 'volume24h',
            },
            {
                Header: () => (
                    <div className='column_data'>
                        Last 7 Days <img src={DropdownIcon} alt='arrow-down' />
                    </div>
                ),
                accessor: 'last7Days',
                Cell: () => <div className='column_data'><img src={GreenChart} alt='' />
                </div>
            },
            {
                Header: '%1H',
                accessor: 'percent1h',
            },
            {
                Header: '%7 Days',
                accessor: 'percent7d',
            },
            {
                Header: '%7 Mon',
                accessor: 'percent7m',
            },
        ],
        []
    );

    const data = React.useMemo(() => [
        { sno: '1', symbol: 'BTC', name: 'Bitcoin', tradedVolume: '1000', volume24h: '2000', last7Days: 'img_url_1', percent1h: '5%', percent7d: '10%', percent7m: '15%' },
        { sno: '2', symbol: 'ETH', name: 'Ethereum', tradedVolume: '800', volume24h: '1800', last7Days: 'img_url_2', percent1h: '3%', percent7d: '8%', percent7m: '12%' },
        { sno: '3', symbol: 'XRP', name: 'Ripple', tradedVolume: '600', volume24h: '1200', last7Days: 'img_url_3', percent1h: '2%', percent7d: '6%', percent7m: '9%' },
        { sno: '4', symbol: 'LTC', name: 'Litecoin', tradedVolume: '500', volume24h: '1000', last7Days: 'img_url_4', percent1h: '1.5%', percent7d: '5%', percent7m: '8%' },
        { sno: '5', symbol: 'ADA', name: 'Cardano', tradedVolume: '400', volume24h: '900', last7Days: 'img_url_5', percent1h: '1%', percent7d: '4%', percent7m: '7%' },
        { sno: '6', symbol: 'DOT', name: 'Polkadot', tradedVolume: '300', volume24h: '800', last7Days: 'img_url_6', percent1h: '0.5%', percent7d: '3%', percent7m: '6%' },
        { sno: '7', symbol: 'LINK', name: 'Chainlink', tradedVolume: '200', volume24h: '700', last7Days: 'img_url_7', percent1h: '0.3%', percent7d: '2%', percent7m: '5%' },
        { sno: '8', symbol: 'BCH', name: 'Bitcoin Cash', tradedVolume: '100', volume24h: '600', last7Days: 'img_url_8', percent1h: '0.2%', percent7d: '1%', percent7m: '4%' },
        { sno: '9', symbol: 'XLM', name: 'Stellar', tradedVolume: '50', volume24h: '500', last7Days: 'img_url_9', percent1h: '0.1%', percent7d: '0.5%', percent7m: '3%' },
        { sno: '10', symbol: 'DOGE', name: 'Dogecoin', tradedVolume: '20', volume24h: '400', last7Days: 'img_url_10', percent1h: '0.05%', percent7d: '0.2%', percent7m: '2%' },
    ], []);


    return (
        <div className='volume'>
            <div className='alltable'>
                <div className='alltable_header'>
                    <h3>Volume</h3>
                    <div className="select_wrapper">
                        <div
                            className="calendar-button"
                            onClick={() => setIsCalender((prev) => !prev)}
                        >
                            <img src={CalendarIcon} alt="calender icon" />
                            <p>Past 90 Days</p>
                            <img src={CaretDownIcon} alt="" className='arrow_down' />
                        </div>

                        <div className="calendar_wrapper">
                            {isCalender && (
                                <Calendar
                                    onChange={(date) => setDate(date)}
                                    value={date}
                                    // formatMonth={"MMM"}
                                    selectRange={true}
                                    showNeighboringMonth={false}
                                />
                            )}
                        </div>

                    </div>

                </div>
                <div className='table_wrapper'>
                    <div className='table_scroll_wrapper'>
                        <Table columns={columns} data={data} /> </div>
                </div>
            </div>    </div>
    )
}


export default Volume


const Table = ({ columns, data }) => {





    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 7 },
        },
        usePagination
    );

    return (
        <>
            <div className='table_scroll'>
                <table {...getTableProps()} className="table">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>
                                            <Link to='/traders-list-2'> {cell.render('Cell')} </Link></td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="pagination">
                    <img src={CaretLeftIcon} alt="" />
                    <button onClick={previousPage} disabled={!canPreviousPage}>
                        Previous
                    </button>
                    {pageOptions.map((pageNumber, index) => (
                        <>
                            <button
                                key={index}
                                onClick={() => gotoPage(index)}
                                className={pageIndex === index ? "btn-active" : "non-active"}
                            >
                                {pageNumber < 10 ? `0${pageNumber + 1}` : pageNumber}
                            </button>
                        </>
                    ))}
                    <button onClick={nextPage} disabled={!canNextPage}>
                        Next
                    </button>
                    <img src={CaretRightIcon} alt="" />
                </div>
            </div>
        </>
    );
};