import { takeEvery, call, put, all } from "redux-saga/effects";
import {
  deleteSupportDetails,
  getSupportTableDetails,
  updateSupportDetails,
} from "../../../utils/api/apis";
import {
  getSupportDataSuccess,
  getSupportData,
  setSupportSuccess,
  setSupportFailure,
  deleteSupportSuccess,
} from "../../reducers/supportSlice";
import { returnMessages } from "../../reducers/message";
import { returnErrors } from "../../reducers/error";
import { setIsLoading } from "../../reducers/authSlice";

function* fetchSupportData(action) {
  yield put(setIsLoading(true));
  try {
    const idToken = action.payload;
    const supportData = yield call(getSupportTableDetails, idToken);
    yield put(getSupportDataSuccess(supportData));
    if (supportData.response.status > 399) {
      yield put(returnErrors("Something went wrong", 400));
    }

    yield put(setIsLoading(false));
  } catch (error) {
    yield put(setIsLoading(false));
  }
}

function* updateSupportData(action) {
  try {
    const { id, formData, idToken } = action.payload;

    const serializedFormData = serializeFormData(formData);

    const response = yield call(
      updateSupportDetails,
      idToken,
      id,
      serializedFormData
    );
    if (response.status < 399) {
      yield put(setSupportSuccess(response));
      yield put(returnMessages("Case Updated Successfully", 204));
    } else {
      const error = response.response.data;
      let msg;
      if (Object.keys(error).length > 0) {
        const firstKey = Object.keys(error)[0];
       
        const firstString = error[firstKey] || error[firstKey][0];

        msg = firstString;
      } else {
        msg = "Something went wrong";
      }
      yield put(returnErrors(msg, 400));
    }
    yield put(getSupportData(idToken));
  } catch (error) {
    console.error("Error updating support data:", error);
    yield put(setSupportFailure("Failed to update support data."));
    yield put(returnErrors("Failed to Update Case", 400));
  }
}

function serializeFormData(formData) {
  const serializedData = {};
  formData.forEach((value, key) => {
    serializedData[key] = value;
  });
  return serializedData;
}

function* deleteSupportData(action) {
  yield put(setIsLoading(true))
  try {
    const { idToken, id } = action.payload;
   const response =  yield call(deleteSupportDetails, idToken, id);
  
    if (response.status < 399) {
      yield put(returnMessages("Case Deleted Successfully", 204));
      yield put(deleteSupportSuccess(idToken));
    } else {
      yield put(returnErrors("Failed to Delete Case", 400));
    }
    yield put(setIsLoading(false))
  } catch (error) {
    yield put(setIsLoading(false))
   
  }
}

export default function* supportSaga() {
  yield all([
    takeEvery("support/getSupportData", fetchSupportData),
    takeEvery("support/setSupport", updateSupportData),
    takeEvery("support/deleteSupport", deleteSupportData),
    takeEvery("support/deleteSupportSuccess", fetchSupportData),
  ]);
}
