import React from 'react'
import './style.scss'
import EmailIcon from "../../../assets/icons/email.svg";
import CheckSolidGreenIcon from "../../../assets/icons/check-solid-green.svg";
import UserIcon from "../../../assets/icons/user.svg";
import PhoneIcon from "../../../assets/icons/phone.svg";
import ApartmentNoIcon from '../../../assets/icons/apartment-no.svg'
import StateIcon from '../../../assets/icons/state.svg'
import CountryIcon from '../../../assets/icons/country.svg'
import Warning from '../Verification/Warning';
import Close from '../../../assets/icons/circle-cross.svg'
import Select from 'react-select'
import Tabination from '../../../reusableComponents/Tabination';

function RecipientBankDetails({closeBankDetailsModal}) {

    
  const items = [
    {
      name: "ACH",
      content: (
     <div className='form_container'>
    <GeneralInfo />
    <Button />
    </div>
             
      ),
    },
    {
        name: "Wire",
        content: (
       
        
            <div className='form_container'>
            <GeneralInfo />
            <Warning />
            <RecipientAddress />
            <Button />
            </div>
        ),
      },
      {
        name: "Swift",
        content: (
       
            <div className='form_container'>
            <GeneralInfo />
            <Warning />
            <RecipientAddress />
            <Button />
            </div>               
       ),
      },


  ];

  return (
    <div className='bankdetails_modal_overlay'>
        <div className='bankdetails_modal_container'>
        <div className='modal_header'>
        <p>Recipients Bank Details</p>
                <img src={Close} alt='close icon' onClick={closeBankDetailsModal} />
            </div>
        <Tabination tabItems={items} activeTab={0} />

        </div>
    </div>
  )
}

export default RecipientBankDetails

const GeneralInfo = () => {
    return (
        <div className='general_info_form_container'>

                    <div className="general_info_form">

                        {/* name */}
                        <div className='general_info_form_input_container'>
                            <img src={UserIcon} alt="" className="input_icon" />
                            <div className=" general_info_input">
                                <label className="general_info_label" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value="Daniel Radcliff"
                                />
                            </div>
                        </div>

                        {/* email */}
                        <div className='general_info_form_input_container'>
                            <img src={EmailIcon} alt="" className="input_icon" />
                            <div className=" general_info_input">
                                <label className="general_info_label" htmlFor="email">
                                    Email Address
                                </label>
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    value="danielradcliff@gmail.com"
                                />
                            </div>
                            <img src={CheckSolidGreenIcon} alt="" className="input_icon" />
                        </div>

                        {/* contact */}
                        <div className='general_info_form_input_container'>
                            <img src={PhoneIcon} alt="" className="input_icon" />
                            <div className=" general_info_input">
                                <label className="general_info_label" htmlFor="contact">
                                    Contact Number
                                </label>
                                <input
                                    type="text"
                                    name="contact"
                                    id="contact"
                                    value="danielradcliff@gmail.com"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='account_type'>
                        <p>Account type</p>
                        <div className='radio_container'>
                        <div>
                        <input type='radio' name='account_type' />
                        <label>Saving </label>
                        </div>

                        <div>
                        <input type='radio' name='account_type' />
                        <label>Current acccount</label>
                        </div>
                        </div>
                    </div>

                </div>
    )
}

const Button = () => {
    return (
        <div className='btn_container'>
    <button className='save_info_btn'>Save info</button>
    </div>
    )
}

const RecipientAddress = () => {

    const stateOptions = [
        { value: 'ca', label: 'California' },

    ];
    const countryOptions = [
        { value: 'ca', label: 'Canada' },

    ];
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            marginTop: '-10px',
            background: 'transparent',
            boxShadow: state.isFocused ? 'none' : 'none',
            '&:hover': {
                borderColor: '#A4A8AE',
            },
        }),
        menu: (provided) => ({
            ...provided,
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#0A3266'
        })
    };
    return (
        <div className='recipient_info_form_container'>
                    <p >Recipients Address</p>
                    <div className='recipient_info_form'>
                    <div className="recipient_info_form_input_container">
                            <img src={CountryIcon} alt="country" className="input_icon" />
                            <div className=" recipient_info_input">
                                <label className="general_info_label" htmlFor="country">
                                    Country
                                </label>
                                <Select
                                    styles={customStyles}
                                    options={countryOptions}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    className="react_select_container"
                                    value={countryOptions[0]}
                                />
                            </div>
                        </div>


                        

                        <div className="recipient_info_form_input_container">
                            <img src={StateIcon} alt="state" className="input_icon" />
                            <div className=" recipient_info_input">
                                <label className="general_info_label" htmlFor="state">
                                    State
                                </label>
                                <Select
                                    styles={customStyles}
                                    options={stateOptions}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    className="react_select_container"
                                    value={stateOptions[0]}
                                />
                            </div>
                        </div>
                        
                        <div className='recipient_info_form_input_container'>
                            <img src={ApartmentNoIcon} alt="apartment icon" className="input_icon" />
                            <div className="form_input recipient_info_input">
                                <label className="general_info_label" htmlFor="aprtmentNo">
                                    Apartment No
                                </label>
                                <input
                                    type="text"
                                    name="apartmentNo"
                                    id="apartmentNo"
                                    value="Daniel Radcliff"
                                />
                            </div>
                        </div>

                    </div>
                </div>

    )
}