import { Checkbox, CircularProgress, TextField, Tooltip } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calender from "../../assets/icons/calender.svg";

import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import SliderComponent from "../../components/Slider";
import CopyButton from "../../reusableComponents/CopyButton";
import { returnErrors } from "../../store/reducers/error";
import {
  getFundingDataFailure,
  getFundingDataSuccess,
} from "../../store/reducers/fundingSlice";
import { returnMessages } from "../../store/reducers/message";
import {
  UserSearchReq,
  getFundingDetails,
  postCouponDetailsnew,
  updateCouponDetails,
} from "../../utils/api/apis";
import "./style.scss";
import dayjs from "dayjs";
function CreateCoupon() {
  const idToken = useSelector((state) => state?.auth.idToken);
  const dispatch = useDispatch();
  const [visibiltyValidation, setVisibiltyValidation] = useState(false);
  const [code, setcode] = useState(false);
  const [email, setemail] = useState(false);
  const [expiry, setexpiry] = useState(false);
  const { state } = useLocation();

  const [couponDetails, setCouponDetails] = useState({
    code: state ? state?.code : "",
    percent: state ? state?.percent : 0,
    public: state ? state?.public : null,
    single_use: state ? state?.single_use : false,
    expiry: state ? state?.expiry : "",
    user_emails: state ? state?.user_emails : "",
  });

  console.log(couponDetails, "couponDetails");
  const [isSpinner, setIsSpinner] = useState(false);

  const handleSubmit = async () => {
    let isValid = true;

    if (
      couponDetails?.public === null ||
      couponDetails?.public === "" ||
      couponDetails?.public === undefined
    ) {
      setVisibiltyValidation(true);
      isValid = false;
    } else {
      setVisibiltyValidation(false);
    }

    if (couponDetails?.code === "" || couponDetails?.code === undefined) {
      setcode(true);
      isValid = false;
    } else {
      setcode(false);
    }

    if (!couponDetails?.public) {
      if (
        couponDetails?.user_emails === "" ||
        couponDetails?.user_emails === undefined ||
        couponDetails?.user_emails.length <= 0
      ) {
        setemail(true);
        isValid = false;
      } else {
        setemail(false);
      }
    }

    if (couponDetails?.expiry === undefined || couponDetails?.expiry === "") {
      setexpiry(true);
      isValid = false;
    } else {
      setexpiry(false);
    }

    if (!isValid) {
      return; // Exit function if any field is invalid
    }

    setIsSpinner(true);
    if (state?.id) {
      const respo = await updateCouponDetails(
        idToken,
        couponDetails,
        state?.id
      );
      console.log(respo, "idid");
      if (respo.status < 399) {
        setIsSpinner(false);
        dispatch(returnMessages("Coupon updated successfully", 200));
      } else {
        const msg = "Something went wrong";
        dispatch(returnErrors(msg, 500));
      }
      setIsSpinner(false);
      return;
    }
    const respo = await postCouponDetailsnew(idToken, couponDetails);
    console.log(respo, "resporespo");
    if (respo.status < 399) {
      setIsSpinner(false);
      dispatch(returnMessages(respo.data.detail, 200));
    } else {
      const error = respo.response.data;
      let msg;
      if (Object.keys(error).length > 0) {
        const firstKey = Object.keys(error)[0];
        const firstString = error[firstKey] || error[firstKey][0];
        msg = firstString;
      } else {
        msg = "Something went wrong";
      }
      setIsSpinner(false);
      dispatch(returnErrors(msg, 500));
    }
  };

  const customStylesVisibility = {
    control: (provided) => ({
      ...provided,
      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      // border: `${visibiltyValidation} &&1px solid #eaf0f8",
      border: `1px solid ${visibiltyValidation ? "red" : "#EAF0F8"}`,
      boxShadow: "none",
      fontFamily: "DM Sans, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
      color: "#000",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "#000",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };
  const customStylesEmail = {
    control: (provided) => ({
      ...provided,
      background: "none",
      width: "100%",
      marginTop: "0px",
      height: 60,
      borderRadius: 8,
      // border: `${visibiltyValidation} &&1px solid #eaf0f8",
      border: `1px solid ${email ? "red" : "#EAF0F8"}`,
      boxShadow: "none",
      fontFamily: "DM Sans, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      minHeight: "48px",
      height: "48px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "grey",
      fontSize: "14px",
      fontWeight: 500,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
      fontWeight: 500,
      marginLeft: "10px",
      color: "#000",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "#000",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
    }),
  };

  const [copyToClipboard, setCopyToClipboard] = useState({
    value: "",
    copied: false,
  });
  const fundingData = useSelector((state) => state?.funding.fundingData);
  const [isLoading, setIsLoading] = useState(false);
  const [emailOpts, setEmailOpts] = useState([{ label: "", value: "" }]);
  const [planOptions, setPlanOptions] = useState([]);
  const [competitonOptions, setCompetitionOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCompetition, setSelectedCompetition] = useState(null);

  const getData = async () => {
    // dispatch(setIsLoading(true));
    const response = await getFundingDetails(idToken);

    if ((response.status = 200)) {
      dispatch(getFundingDataSuccess(response.data));
      // dispatch(setIsLoading(false));
    } else {
      dispatch(getFundingDataFailure());
      // dispatch(setIsLoading(false));
    }
  };
  useEffect(() => {
    getData();
  }, []);
  // User search by email
  const fetch = async (value) => {
    setIsLoading(true);
    const response = await UserSearchReq(idToken, value);
    setIsLoading(false);
    if (response?.status < 399) {
      let userArray = [];
      response.data.map((item) =>
        userArray.push({
          label: item?.email,
          value: item?.id,
        })
      );
      // console.log(userArray, "UserArray");
      setEmailOpts(userArray);
    } else {
      // console.log(response);
      if (response.response.data.message) {
        return;
      }
      let msg = response?.response?.data?.detail || "Something went wrong";
      dispatch(returnErrors(msg, 400));
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedCompetition(null);
  };
  const handleSelectCompetitionChange = (selectedOption) => {
    // console.log(selectedCompetition, "test2");
    setSelectedCompetition(selectedOption);
  };
  useEffect(() => {
    let planArray = [];
    fundingData.map((item) => {
      planArray.push({ value: item.id, label: item.name });
    });
    setPlanOptions(planArray);
  }, [fundingData]);

  useEffect(() => {
    let competitionArray = [];

    fundingData?.map((item) => {
      if (selectedOption?.value === item?.id) {
        item.challenges?.map((item) => {
          competitionArray.push({
            value: item?.id,
            label: `${item?.name}  Max Loss ${item.max_daily_loss}%`,
          });
        });
      }

      setCompetitionOptions(competitionArray);
    });
  }, [selectedOption]);
  let timeoutId = "";

  const handleOnInputChange = (value) => {
    if (typeof value === "string" && value.length > 0) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fetch(value);
      }, 1500);
    }
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [challege, setChallege] = useState(false);
  return (
    <div className="create_coupon">
      <div className="coupon_main">
        <div className="create_coupon_top">
          <div className="coupon_wrapper">
            <div className="back_button_wrapper">
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.53522 0.714697C6.19351 0.372988 5.63949 0.372988 5.29779 0.714697L0.631118 5.38136C0.28941 5.72307 0.28941 6.27709 0.631118 6.6188L5.29778 11.2855C5.63949 11.6272 6.19351 11.6272 6.53522 11.2855C6.87693 10.9438 6.87693 10.3897 6.53522 10.048L2.48727 6.00008L6.53522 1.95213C6.87693 1.61042 6.87693 1.05641 6.53522 0.714697Z"
                  fill="var(--text-grey)"
                />
              </svg>
              <Link to="/coupons">
                <button>Back</button>
              </Link>
            </div>
            <h2>{state?.id ? "Update Coupon" : "Create coupon"} Code</h2>
            <div className="coupon_input_wrapper">
              <div className="first_con">
                <div className="input_wrapper">
                  <label htmlFor="Email">Visibility</label>
                  <Select
                    isSearchable={false}
                    isLoading={isLoading}
                    placeholder={"Visibility"}
                    styles={customStylesVisibility}
                    value={[
                      { label: "Public", value: true },
                      { label: "Private", value: false },
                    ].filter((item) => item.value === couponDetails?.public)}
                    options={[
                      { label: "Private", value: false },
                      { label: "Public", value: true },
                    ]}
                    onChange={(selectedOption) => {
                      setCouponDetails((pre) => ({
                        ...pre,
                        public: selectedOption.value,
                      }));
                      setVisibiltyValidation(false);
                    }}
                  />
                  {visibiltyValidation && (
                    <p style={{ color: "red", marginTop: "4px" }}>
                      Please Select Visibility
                    </p>
                  )}
                </div>
                {!couponDetails.public && (
                  <div className="input_wrapper">
                    <label htmlFor="Email">Email</label>
                    <Select
                      isLoading={isLoading}
                      placeholder={"Search for a user"}
                      styles={customStylesEmail}
                      options={emailOpts}
                      // value={
                      //   couponDetails?.email
                      //     ? { label: state?.email, value: state?.email }
                      //     : ""
                      // }
                      isMulti
                      onInputChange={(value) => handleOnInputChange(value)}
                      // onChange={(selectedOptions) => {
                      //   const selectedLabels = selectedOptions.map(
                      //     (option) => option.label
                      //   );
                      //   setCouponDetails((prev) => ({
                      //     ...prev,
                      //     user_emails: selectedLabels,
                      //   }));
                      //   setemail(false);
                      // }}
                      onChange={(selectedOptions) => {
                        const selectedLabels = selectedOptions.map(
                          (option) => option.label
                        );

                        // If visibility is public, clear the email field
                        if (couponDetails.public === true) {
                          setCouponDetails((prev) => ({
                            ...prev,
                            user_emails: [],
                          }));
                        } else {
                          setCouponDetails((prev) => ({
                            ...prev,
                            user_emails: selectedLabels,
                          }));
                        }

                        setemail(false);
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="second_com">
                <div className="date_picker">
                  <label htmlFor="">Coupon Code</label>
                  <div className="coupon_code_wrapper">
                    <input
                      className="coupon_code"
                      type="text"
                      name="couponCode"
                      value={couponDetails.code}
                      style={code ? { border: "1px solid red" } : {}}
                      onChange={(e) =>
                        setCouponDetails(
                          (prev) => ({
                            ...prev,
                            code: e.target.value,
                          }),
                          setcode(false)
                        )
                      }
                    />
                    <div>
                      <CopyButton moveY={0}>
                        <CopyToClipboard
                          text={couponDetails?.code}
                          onCopy={() => setCopyToClipboard({ copied: true })}
                        >
                          <Tooltip title="Copy to Clipboard" placement="top">
                            <div className="copy_button">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M14.6 5.27832C15.2365 5.27832 15.847 5.53118 16.2971 5.98126C16.7471 6.43135 17 7.0418 17 7.67832L17 14.6783C17 15.3148 16.7471 15.9253 16.2971 16.3754C15.847 16.8255 15.2365 17.0783 14.6 17.0783L7.4 17.0783C6.76348 17.0783 6.15303 16.8255 5.70295 16.3754C5.25286 15.9253 5 15.3148 5 14.6783L5 7.67832C5 7.0418 5.25286 6.43135 5.70294 5.98127C6.15303 5.53118 6.76348 5.27832 7.4 5.27832L14.6 5.27832ZM15.8 7.67832C15.8 7.36006 15.6736 7.05484 15.4485 6.82979C15.2235 6.60475 14.9183 6.47832 14.6 6.47832L7.4 6.47832C7.08174 6.47832 6.77652 6.60475 6.55147 6.82979C6.32643 7.05484 6.2 7.36006 6.2 7.67832L6.2 14.6783C6.2 14.9966 6.32643 15.3018 6.55147 15.5269C6.77652 15.7519 7.08174 15.8783 7.4 15.8783L14.6 15.8783C14.9183 15.8783 15.2235 15.7519 15.4485 15.5268C15.6736 15.3018 15.8 14.9966 15.8 14.6783L15.8 7.67832Z"
                                  fill="white"
                                />
                                <path
                                  d="M19.3984 7.0784C19.3984 6.65712 19.2875 6.24327 19.0769 5.87843C18.8662 5.5136 18.5633 5.21064 18.1984 5L18.1984 15.2784C18.1984 16.0741 17.8824 16.8371 17.3198 17.3997C16.7572 17.9623 15.9941 18.2784 15.1984 18.2784L7.72004 18.2784C7.93068 18.6432 8.23364 18.9462 8.59847 19.1568C8.96331 19.3675 9.37716 19.4784 9.79844 19.4784L15.1984 19.4784C16.3123 19.4784 17.3806 19.0359 18.1683 18.2483C18.9559 17.4606 19.3984 16.3923 19.3984 15.2784L19.3984 7.0784Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          </Tooltip>
                        </CopyToClipboard>
                      </CopyButton>
                    </div>
                  </div>
                </div>
                <div className="date_picker">
                  <label for="date_wrapper" className="label_black">
                    Expiry Date
                  </label>
                  <div
                    className="date-container"
                    style={
                      expiry
                        ? { border: "1px solid red", borderRadius: "8px" }
                        : {}
                    }
                  >
                    <img src={Calender} alt="" className="date_image" />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoItem>
                        <MobileDatePicker
                          format="DD-MM-YYYY"
                          value={
                            couponDetails.expiry
                              ? dayjs(couponDetails.expiry)
                              : null
                          } // Convert expiry to dayjs if it exists
                          onChange={(date) => {
                            setCouponDetails((prev) => ({
                              ...prev,
                              expiry: date ? date.format("YYYY-MM-DD") : null,
                            }));
                            setexpiry(false);
                          }}
                        />
                      </DemoItem>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                With Challenge
                <Checkbox
                  {...label}
                  checked={challege}
                  sx={{ marginRight: "10px" }}
                  onClick={() => setChallege((pre) => !pre)}
                />
              </div>
              {challege && (
                <div className="input_form-btm">
                  <div className="input_wrapper">
                    <label htmlFor="">Plan Type</label>
                    <Select
                      className="plan_selector"
                      options={planOptions}
                      styles={customStylesVisibility}
                      value={selectedOption}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="input_wrapper">
                    <label htmlFor="">Account Size</label>
                    <Select
                      className="plan_selector"
                      options={competitonOptions}
                      styles={customStylesVisibility}
                      value={selectedCompetition}
                      onChange={(selectedOption) => {
                        setCouponDetails((prev) => ({
                          ...prev,
                          challenge: selectedOption.value,
                        }));

                        handleSelectCompetitionChange(selectedOption);
                      }}
                    />
                  </div>
                </div>
              )}
              {/* <br />
              <div className="note_wrapper">
                <h3>NOTE: Fixed Commission Income rates for comapny is 15%</h3>
                <br />
              </div> */}
            </div>
          </div>
        </div>
        <div className="create_coupon_bottom">
          <div className="coupon_opts_wrapper">
            <div className="middle">
              <div className="wrapper">
                <label for="coupon_value" className="label_black">
                  Coupon % -{" "}
                  {Number(couponDetails?.percent) > 0 &&
                    (couponDetails.percent ? couponDetails.percent : 0) + "%"}
                </label>
                <div className="slider-container">
                  <SliderComponent
                    value={couponDetails.percent ? couponDetails.percent : 0}
                    handleChange={(e) =>
                      setCouponDetails({
                        ...couponDetails,
                        percent: parseFloat(e.target.value),
                      })
                    }
                    min={0}
                    max={100}
                    step={1}
                  />
                </div>
              </div>
            </div>
            <div className="lower">
              <div className="toggle_switches_wrapper">
                <div className="switch_wrapper">
                  <label className="switch">
                    <input
                      name="isPublic"
                      type="checkbox"
                      checked={couponDetails.single_use}
                      onChange={(event) =>
                        setCouponDetails((prev) => ({
                          ...prev,
                          single_use: event.target.checked ? true : false,
                        }))
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                  <span>Single Use</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="create_coupon_button">
        {
          <button onClick={handleSubmit} style={{ backgroundColor: "#36d66b" }}>
            {isSpinner ? (
              <CircularProgress color="inherit" />
            ) : (
              <>{state?.id ? "Update Coupon" : "Create coupon"}</>
            )}
          </button>
        }
      </div>
    </div>
  );
}

export default CreateCoupon;
