import React, { useEffect } from "react";
import "./style.scss";

import AuthFrame from "../../../components/Auth/AuthFrame";
import SigninForm from "../../../components/Auth/SigninForm";

function Signin() {
  useEffect(()=> {},[])

  return (
    <div className="signin-container">
 
      <div className="signin-right">
        <SigninForm />
      </div>
      <div className="signin-left">
        <AuthFrame />
      </div>
    </div>
  );
}

export default Signin;
