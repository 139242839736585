import React from "react";
import "./style.scss";
import Card from "../Card";
import calenderLight from "../../assets/icons/calenderLight.svg";
import tradeLight from "../../assets/icons/tradeLight.svg";
import accountBalance from "../../assets/icons/accountBalance.svg";
import equity from "../../assets/icons/equity.svg";

const InfoBox = ({
  textcolor,
  idx,
  title,
  value,
  icon1,
  icon,
  percentage,
  color,
  variant = 1,
}) => {
  const arrowStyle = {
    backgroundColor: color,
  };

  return (
    <>
      {variant === 1 && (
        <Card className="info_box">
          <div className="info_box_wrapper upper_div_info">
            <div className="info_box_upper">
              <div className="info_box_lower">
                <div className="icon_div">
                  <span
                    className="arrow_wrapper"
                    // style={arrowStyle}
                  >
                    {idx === 0 ? (
                      <img src={accountBalance} alt="info icon" />
                    ) : (
                      <img src={equity} alt="info icon" />
                    )}
                  </span>
                </div>
                <div className="content_div">
                  <div>{<h4 className="upper_title">{title}</h4>}</div>
                  <div
                    style={{
                      display: "flex",
                      // flexDirection: "column",
                      gap: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    <h2
                      className={`upper_value ${
                        textcolor === "red"
                          ? "text_red"
                          : textcolor === "green"
                          ? "text_green"
                          : ""
                      }`}
                      style={{ color: "#80E0C4" }}
                    >
                      {value || 0}
                    </h2>
                  </div>
                  <p className="lower_value">{percentage}</p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}

      {variant === 2 && (
        <Card className="info_box info_box_variant2">
          <div className="info_box_wrapper">
            <div className="info_box_main">
              <div className="info_value">
                {icon && <img src={icon} alt="info icon" />}
                <p className="info_title">{title}</p>
              </div>
              <h4 className="info_value">{value}</h4>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default InfoBox;
