import React, { useState } from "react";
import "./style.scss";
import { usePagination, useTable } from "react-table";
import ChartGain from "../../../assets/images/chart-rep-gain.png";
import ChartLoss from "../../../assets/images/chart-rep-loss.png";
import CalendarIcon from "../../../assets/icons/calender.svg";
import Search from "../../../assets/icons/search.svg";
import CreateIcon from "../../../assets/icons/plus-circle-blue.svg";
import CaretLeftIcon from "../../../assets/icons/caret-left.svg";
import CaretRightIcon from "../../../assets/icons/caret-right.svg";
import GreenTickIcon from "../../../assets/icons/tick-green-circle.svg";
import InProgressIcon from "../../../assets/icons/clock-progress.svg";
import ErroIcon from "../../../assets/icons/warning-red.svg";
import StarBlueIcon from "../../../assets/icons/star-blue-icon.svg";
import StarGreyIcon from "../../../assets/icons/star-gray-icon.svg";
import ArrowUpIcon from "../../../assets/icons/arrow-up-white.svg";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { useMemo } from "react";
import Calendar from "../../../reusableComponents/Calendar";
import { Pagination } from "@mui/material";

function StagePass1() {
  return (
    <div className="economiccalender-container">
      <div className="economiccalender-subcontainer">
        <div className="economiccalender-container_table">
          <Table />
        </div>
      </div>
    </div>
  );
}

export default StagePass1;

const Table = () => {
  const [filter, setFilter] = useState("All");
  const [isCalender, setIsCalender] = useState(false);
  const [date, setDate] = useState(new Date());

  const data = useMemo(
    () => [
      {
        email: "SaurbhviKi2334@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "In progress",
        credentialgen: "Created",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 1,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "welio@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "Error",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 2,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
      {
        email: "AbhiwhereShey@gmail.com",
        accountno: "21786897",
        username: "SaurbhviKi2334",
        emailgen: "Created",
        credentialgen: "In progress",
        action: "View Details",
        status: "Active",
        desc: "Lorem ipsum dolor sit amet consectetur. Quis eu a tincidunt dictum. Diam ut justo felis egestas nulla enim. Vulputate dolor aliquam purus praesent dignissim diam. Libero in urna feugiat nulla nunc.",
        importance: 3,
        successRate: "30,872",
        maxLoss: "56,345",
        maxDailyLoss: "56,345",
        minTradeDays: "+15.41%",
        created: "1 Jan, 2022  02:11:42",
        category: "Loreum Ipsum",
        purchaseDt: "12 Jan, 2022  06:11:42",
        reportDt: "12 Jan, 2022  06:11:42",
        accStartDt: "12 Jan, 2022  06:11:42",
        accPassDt: "12 Jan, 2022  06:11:42",
      },
    ],
    []
  );

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredData = useMemo(
    () =>
      filter === "All"
        ? data
        : data.filter(
            (item) => item.status.toLowerCase() === filter.toLowerCase()
          ),
    [data, filter]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => (
          <div className="column_wrapper">
            <div className="name_initial">{value[0]}</div>
            <div className="name_text">{value}</div>
          </div>
        ),
      },
      { Header: "Account No", accessor: "accountno" },
      { Header: "Username", accessor: "username" },
      {
        Header: "Email Generated",
        accessor: "emailgen",
        Cell: ({ value }) => (
          <div className={`${value} gen-tag`}>
            <span>
              <img
                src={
                  value === "Created"
                    ? GreenTickIcon
                    : value === "In progress"
                    ? InProgressIcon
                    : ErroIcon
                }
                alt={value}
              />
            </span>
            {value}
          </div>
        ),
      },
      {
        Header: "Credentials Gen.",
        accessor: "credentialgen",
        Cell: ({ value }) => (
          <div className={`${value} gen-tag`}>
            <span>
              <img
                src={
                  value === "Created"
                    ? GreenTickIcon
                    : value === "In progress"
                    ? InProgressIcon
                    : ErroIcon
                }
                alt={value}
              />
            </span>
            {value}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => (
          <div className="column_wrapper">
            <img className="create_icon" src={CreateIcon} alt="create" />
            <p>Create Account</p>
          </div>
        ),
      },
      {
        Header: "Staus",
        accessor: "status",
        Cell: ({ value }) => (
          <button className={`metrics_tag`}>Go To Metrics</button>
        ),
      },
    ],
    []
  );

  const initialState = useMemo(
    () => [
      {
        pageSize: 10,
        pageIndex: 0,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data: filteredData, initialState }, usePagination);

  const [selectedRow, setSelectedRow] = useState(null);
  const totalPages = Math.ceil(data.length / pageSize);
  return (
    <>
      <div className="table_wrapper">
        <div className="table_header">
          <div className="search">
            <div className="search_bar">
              <input
                type="text"
                className="search_input"
                placeholder="Search..."
              />
              <img className="search_icon" src={Search} alt="search icon" />
            </div>
            <div className="select_wrapper">
              <div
                className="calendar-button"
                onClick={() => setIsCalender((prev) => !prev)}
              >
                <img src={CalendarIcon} alt="calender icon" />
              </div>
              <div className="calendar_wrapper">
                {isCalender && (
                  <Calendar
                    onChange={(date) => setDate(date)}
                    value={date}
                    // formatMonth={"MMM"}
                    selectRange={true}
                    showNeighboringMonth={false}
                  />
                )}
              </div>{" "}
            </div>{" "}
          </div>
          <div className="filter_buttons">
            <button
              className={filter === "All" ? "active_btn" : ""}
              onClick={() => handleFilter("All")}
            >
              All
            </button>
            <button
              className={filter === "Accepted" ? "active_btn" : ""}
              onClick={() => handleFilter("Accepted")}
            >
              Accepted
            </button>
            <button
              className={filter === "Rejected" ? "active_btn" : ""}
              onClick={() => handleFilter("Rejected")}
            >
              Rejected
            </button>
            <button
              className={filter === "Flagged" ? "active_btn" : ""}
              onClick={() => handleFilter("Flagged")}
            >
              Flagged
            </button>
          </div>
        </div>
        <table {...getTableProps()} className="leaderboard-table">
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup?.headers?.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Fragment key={rowIndex}>
                  <tr
                    {...row.getRowProps()}
                    className={`economiccalender-row ${
                      selectedRow === rowIndex
                        ? "economiccalender-row-active"
                        : ""
                    } `}
                    onClick={() => {
                      setSelectedRow(rowIndex);
                    }}
                  >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell.column.id === "importance" ? (
                          <Importance activeStars={cell.value} />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                  {selectedRow === rowIndex && (
                    <tr>
                      <td colSpan="7">
                        <div className="supportcases-td-container">
                          <div className="td-main-div">
                            <h3>Stage - I Evaluation Summary</h3>
                            <div className="supportcases-type-details">
                              <div className="supportcases-box">
                                <p>Martingale success</p>
                                <h4>${row.original.successRate}</h4>
                                <div className="success-ratio">
                                  <span>
                                    <img src={ArrowUpIcon} alt="ArrowUpIcon" />
                                  </span>
                                  +2.53%
                                </div>
                              </div>
                              <div className="supportcases-box">
                                <p>Max Loss</p>
                                <h4 className="red">${row.original.maxLoss}</h4>
                                <img src={ChartLoss} alt="ChartLoss" />
                              </div>
                              <div className="supportcases-box">
                                <p>Max Daily Loss</p>
                                <h4 className="red">
                                  ${row.original.maxDailyLoss}
                                </h4>
                                <img src={ChartLoss} alt="ChartLoss" />
                              </div>
                              <div className="supportcases-box">
                                <p>Min Trading Days</p>
                                <h4 className="green">
                                  {row.original.minTradeDays}
                                </h4>
                                <img src={ChartGain} alt="ChartGain" />
                              </div>
                              <div className="supportcases-box dates">
                                <div className="report-dates">
                                  <div className="upper-date">
                                    <p>Purchase Date</p>
                                    <h4>{row.original.purchaseDt}</h4>
                                  </div>
                                  <div className="lower-date">
                                    <p>Account Started</p>
                                    <h4>{row.original.reportDt}</h4>
                                  </div>
                                </div>
                                <div className="report-dates">
                                  <div className="upper-date">
                                    <p>ADTI Report</p>
                                    <h4>{row.original.accStartDt}</h4>
                                  </div>
                                  <div className="lower-date">
                                    <p>Account Pass</p>
                                    <h4>{row.original.accPassDt}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="supportcases-comments">
                              <p>Comments</p>
                              <h4>{row.original.desc}</h4>
                              <Link to="">
                                Click here for full event details.
                              </Link>
                            </div>
                            <div className="supportcases-category">
                              <div className="category-details">
                                <p>Importance:</p>
                                <h5>
                                  <Importance
                                    activeStars={row.original.importance}
                                  />
                                </h5>
                              </div>
                              <div className="category-details">
                                <p>Created</p>
                                <h5>{row.original.created}</h5>
                              </div>
                              <div className="category-details">
                                <p>Category</p>
                                <h5>{row.original.category}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
        <div className="spacerT30p"></div>
        <Pagination
          // onChange={handleChange}
          count={totalPages}
          variant="outlined"
          color="primary"
        />
      </div>
    </>
  );
};

function Importance({ activeStars }) {
  let totalStars = 3;
  let inactiveStars = totalStars - activeStars;
  const stars = [];
  for (let i = 0; i < activeStars; i++) {
    stars.push(
      <img
        src={StarBlueIcon}
        alt=""
        key={`active${i}`}
        className="importance-star"
      />
    );
  }
  for (let i = 0; i < inactiveStars; i++) {
    stars.push(
      <img
        src={StarGreyIcon}
        alt=""
        key={`inactive${i}`}
        className="importance-star"
      />
    );
  }
  return <>{stars}</>;
}
